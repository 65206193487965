<template>
  <LoadingScreen v-if="modelTypesLoading" />
  <div v-else class="model-selection content-width">
    <div class="model-selection__header-area">
      <div class="model-selection__header">
        <div class="model-selection__headline">
          <h1 class="wb-type-heading-m">
            {{ localization?.mainPage?.modelSelection?.title }}
          </h1>
        </div>
        <div class="wb-type-copy-strong model-selection__header-link">
          <router-link
            :to="{ name: 'home' }"
            class="model-selection__back-to-home"
          >
            {{ localization?.resultPage?.overview?.btnBackToHome }}
          </router-link>
        </div>
      </div>
      <div
        class="wb-type-copy wb-margin-bottom-s richtext"
        v-html="localization?.mainPage?.modelSelection?.text"
      ></div>
    </div>

    <AttributesFilter
      v-if="attributes.length"
      :filterByText="localization.resultPage.filter.filterBy"
      :attributes="attributes"
      @selectedAttrs="toggleSelectedAttrs"
    />

    <ModelOfferList :modelTypes="filteredTypes" :localization="localization" />
  </div>
</template>

<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import ModelOfferList from "@/components/ModelSelection/ModelOfferList.vue";
import LoadingScreen from "@/components/LoadingScreen";
import AttributesFilter from "@/components/ModelSelection/AttributesFilter";
import useTrackingPage from "@/compositions/useTrackingPage";
import useModelSelection from "@/compositions/useModelSelection";

export default {
  name: "ModelSelection",
  setup() {
    const store = useStore();
    const localization = computed(
      () => store.getters["localization/localization"]
    );

    const {
      readyToFetchModels,
      fetchModelTypes,
      modelTypesLoading,
      modelTypesForModelSelection,
      filteredTypes,
      toggleSelectedAttrs,
      attributes,
      modelTypesReloadRequired,
    } = useModelSelection();

    watch(
      [() => readyToFetchModels.value, () => modelTypesReloadRequired.value],
      ([ready, isRequired]) => {
        if (modelTypesForModelSelection.value.length && !isRequired) {
          modelTypesLoading.value = false;
          return;
        }
        if (ready || isRequired) {
          fetchModelTypes();
        }
      },
      {
        immediate: true,
      }
    );

    const { trackModelSearchOverview } = useTrackingPage();
    const trackingLoaded = computed(
      () => store.getters["marketConfig/trackingScriptLoaded"]
    );
    watch(
      () => trackingLoaded.value,
      (loaded) => {
        if (loaded) {
          trackModelSearchOverview();
        }
      },
      {
        immediate: true,
      }
    );

    return {
      filteredTypes,
      localization,
      modelTypesLoading,
      toggleSelectedAttrs,
      attributes,
    };
  },
  components: { ModelOfferList, LoadingScreen, AttributesFilter },
};
</script>
<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.model-selection {
  margin-top: 70px;

  &__header-area {
    margin: 0 5px 0 15px;

    @include breakpoint-from(mq3) {
      margin: 0 30px 0 15px;
    }

    @include breakpoint-from(mq4) {
      margin: 0 60px 0 15px;
    }

    @include breakpoint-from(mq6) {
      margin: 0 100px 0 15px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px 0 30px 0;

    @include breakpoint-from(mq3) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__header-link {
    margin-top: 15px;

    @include breakpoint-from(mq3) {
      margin-top: 0;
    }
  }

  &__back-to-home {
    color: var(--wb-blue-45);
  }

  &__back-to-home:hover {
    color: var(--wb-white);
  }
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import {
  applyPolyfills,
  defineCustomElements,
} from "@workbench/core/dist/loader";
import "./styles/global.scss";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import "here-js-api/styles/mapsjs-ui.css";
import smoothscroll from "smoothscroll-polyfill";
import LazyLoadDirective from "./directives/LazyLoadDirective";

smoothscroll.polyfill();

applyPolyfills().then(() => {
  defineCustomElements(window);
});

const app = createApp(App);

app.use(store);
app.use(router);
app.directive("lazyload", LazyLoadDirective);

app.mount("#app");

<template>
  <wb-datepicker
    class="calendar jsCalendar"
    :class="{ dark: themeStyle === 'dark', bright: themeStyle === 'bright' }"
    range-select
    :activeMonth="activeMonth"
    :activeYear="activeYear"
    :minDate="minDateCalendar"
    :maxDate="maxDate"
    :selectedDate="date?.from"
    :selectedEndDate="date?.to"
    ref="datepickerRef"
  ></wb-datepicker>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import {
  getBlockedDates,
  getMonthNumber,
  getYearNumber,
} from "@/utilities/date";
import useLockScroll from "@/compositions/useLockScroll";

export default {
  props: {
    dateFormat: String,
    refElement: HTMLElement,
    themeStyle: {
      type: String,
      default: "dark",
    },
  },
  setup(props) {
    const refElement = props.refElement;
    const store = useStore();
    const datepickerRef = ref(null);
    const date = computed(() => store.getters["search/selectedDate"]);
    const defaultHolidays = computed(
      () => store.getters["marketConfig/holidays"]
    );
    const locationSelected = computed(
      () => store.getters["search/locationSelected"]
    );
    const minDate = computed(() => store.getters["search/minDate"]);
    const maxToDate = computed(() => store.getters["search/maxToDate"]);
    const maxFromDate = computed(() => store.getters["search/maxFromDate"]);
    const blockedDates = computed(() => store.getters["search/blockedDates"]);

    const activeMonth = ref(null);
    const activeYear = ref(null);

    onMounted(() => {
      if (date.value.from) {
        activeMonth.value = getMonthNumber(date.value.from);
        activeYear.value = getYearNumber(date.value.from);
      }

      store.dispatch(
        "search/updateBlockedDates",
        getBlockedDates({
          holidays: defaultHolidays.value,
          locations: [...locationSelected.value],
          minDate: minDate.value,
          maxDate: maxToDate.value,
          dateFormat: props.dateFormat,
        })
      );
      datepickerRef.value.setBlockedDates(blockedDates.value);
      datepickerRef.value.addEventListener("wbchange", ({ detail }) => {
        const updateDispatch = "search/updateDate";
        // same day
        if (
          date.value?.from &&
          !detail.selectedDate &&
          !detail.selectedEndDate
        ) {
          store.dispatch(updateDispatch, {
            from: date.value?.from ?? null,
            to: date.value?.from ?? null,
          });
          // reset if from & to selected
        } else if (date.value?.from && date.value?.to) {
          store.dispatch(updateDispatch, {
            from: detail.selectedEndDate ?? null,
            to: null,
          });
        } else {
          store.dispatch(updateDispatch, {
            from: detail.selectedDate ?? null,
            to: detail.selectedEndDate ?? null,
          });
        }
      });
    });

    const minDateCalendar = computed(() => minDate.value);

    const maxDate = computed(() =>
      date.value?.from ? maxToDate.value : maxFromDate.value
    );

    return {
      datepickerRef,
      date,
      minDateCalendar,
      maxDate,
      activeMonth,
      activeYear,
      // @ts-ignore
      ...useLockScroll(refElement),
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/components/calendar";
</style>

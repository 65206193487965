<template>
  <div class="model-selection-ltr">
    <p class="model-selection-ltr__headline wb-type-copy-strong">
      {{ localization?.requestModePage?.modelSelectionTitle }}
    </p>
    <wb-select-control>
      <wb-select>
        <label></label>
        <select v-model="selectedModelType">
          <option :value="null">
            {{ localization?.requestTermPage?.pleaseSelect }}
          </option>
          <option
            v-for="model in currentModelTypes"
            :key="model.modelClassId + model.modelBodyStyleId"
            :value="model"
          >
            {{ `${model.modelClassName} ${model.modelBodyStyleName}` }}
          </option>
        </select>
      </wb-select>
    </wb-select-control>
  </div>
</template>
<script>
import { watch, ref, computed } from "vue";

export default {
  name: "ModelSelection",
  props: ["localization", "modelTypes"],
  setup(props, { emit }) {
    const selectedModelType = ref(null);
    const currentModelTypes = computed(() => props["modelTypes"]);

    watch(
      () => selectedModelType.value,
      () => {
        emit("modelSelectionChanged", selectedModelType.value);
      }
    );

    return { selectedModelType, currentModelTypes };
  },
};
</script>
<style lang="scss" scoped>
.model-selection-ltr {
  &__headline {
    margin-bottom: 10px;
  }
}

// overwrite workbench select
wb-select-control wb-select select {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
wb-select-control wb-select option {
  color: black;
}
</style>

import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { daysPeriod } from "@/utilities/date";

const useRequestMode = function () {
  const store = useStore();
  const requestMode = computed(
    () => store.getters["marketConfig/requestModeLongTerm"]
  );
  const requestModeFrom = computed(
    () => store.getters["marketConfig/requestModeLongTermFrom"]
  );
  const selectedDate = computed(() => store.getters["search/selectedDate"]);
  const selectedTime = computed(() => store.getters["search/selectedTime"]);
  const modalClosed = ref(false);

  // reset modalClosed back to false if date/time changes
  watch(
    () => [selectedDate.value, selectedTime.value],
    ([date, time]) => {
      if (
        modalClosed.value &&
        date?.from &&
        date?.to &&
        time?.from &&
        time?.to
      ) {
        modalClosed.value = false;
      }
    }
  );

  const showRequestModal = computed(() => {
    const { from, to } = selectedDate.value;
    const { from: timeFrom, to: timeTo } = selectedTime.value;
    const selectedPeriod = daysPeriod(from, to);

    return !!(
      requestMode.value &&
      requestModeFrom.value <= selectedPeriod &&
      timeFrom &&
      timeTo
    );
  });

  return { showRequestModal, modalClosed };
};

export default useRequestMode;

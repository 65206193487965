<template>
  <span class="icon" :class="'icon-' + name" :style="iconStyle">
    <span
      v-for="n in paths"
      :key="n"
      :class="'path' + n"
      :style="iconStyle"
    ></span>
  </span>
</template>

<script>
import { onMounted, reactive } from "vue";
export default {
  name: "Icon",
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 24,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    paths: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const iconStyle = reactive({
      fontSize: null,
      width: null,
      height: null,
    });

    onMounted(() => {
      iconStyle.fontSize = `${props.size}px`;
      iconStyle.width = props.width ? `${props.width}px` : `${props.size}px`;
      iconStyle.height = props.height ? `${props.height}px` : `${props.size}px`;
    });

    return {
      iconStyle,
    };
  },
};
</script>
<style lang="scss" scoped>
.icon {
  display: inline-flex;
  vertical-align: middle;

  span {
    display: block;
  }
}
</style>

<template>
  <wb-tooltip-host position="top" class="tooltip-host">
    <Icon
      name="alert-circle"
      :size="20"
      :height="22"
      :width="22"
      class="tooltip-icon"
      :paths="2"
    />
    <wb-tooltip>
      {{ text }}
    </wb-tooltip>
  </wb-tooltip-host>
</template>
<script>
import Icon from "@/components/Icon";

export default {
  name: "ToolTip",
  components: { Icon },
  props: {
    text: {
      type: String,
    },
  },
};
</script>
<style lang="scss">
.tooltip-icon {
  padding: 1px;
}

.tooltip-host {
  top: 3px;
}

.tooltip-icon.icon-alert-circle .path1:before {
  color: var(--wb-grey-45);
}
</style>

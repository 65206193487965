<template>
  <div class="model-offer-list__wrapper">
    <ModelOffer
      v-for="model in modelTypes"
      :key="model.modelTypeId"
      :modelType="model"
      :localization="localization"
      :active="isActive(model.modelTypeId)"
      @toggle="setModelActive($event)"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import ModelOffer from "@/components/ModelSelection/ModelOffer.vue";

export default {
  props: {
    modelTypes: Array,
    localization: Object,
  },
  setup() {
    const store = useStore();
    const activeModelId = computed(
      () => store.getters["vehicles/activeModelId"]
    );

    const setModelActive = (modelId) => {
      store.dispatch("vehicles/setActiveModelType", modelId);
    };

    const isActive = (modelId) => {
      return modelId === activeModelId.value;
    };

    return { setModelActive, isActive, activeModelId };
  },
  components: { ModelOffer },
};
</script>

<style lang="scss" scoped="true">
.model-offer-list {
  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.model-offer-item {
  margin-bottom: 20px;
}
</style>

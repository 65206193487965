<template>
  <div
    v-if="localization"
    :style="{ backgroundImage: `url(${imageUrl})` }"
    class="content-box-big"
  >
    <div class="content-box-big__box">
      <div class="wb-type-heading-m">
        {{ localization?.header }}
      </div>
      <div class="wb-type-copy">
        {{ localization?.text }}
      </div>
      <button
        @click="openUrl(localization?.url)"
        class="wb-button wb-button--secondary wb-button--medium content-box-big__button"
      >
        <Icon
          class="content-box-big__button__color"
          name="chevron-right"
          :size="12"
          :width="18"
          :height="12"
        />
        <span
          class="content-box-big__button__color"
          v-if="localization?.button"
        >
          {{ localization?.button }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import useImage from "@/compositions/useImage";
import Icon from "@/components/Icon";

export default {
  name: "ContentBoxBig",
  props: ["localization"],
  setup(props) {
    const imageUrl = ref(null);

    const { getImageUrl } = useImage();
    watch(
      () => props.localization,
      () => {
        imageUrl.value = getImageUrl(props.localization?.imageIdDesktop, false);
      },
      { immediate: true }
    );

    return {
      imageUrl,
    };
  },
  methods: {
    openUrl: function (url) {
      window.open(url, "_blank");
    },
  },
  components: {
    Icon,
  },
};
</script>
<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.content-box-big {
  width: 100%;
  background-size: cover;
  background-position: right center;
  height: 300px;
  padding-top: 20px;

  @include breakpoint-from(mq3) {
    height: 400px;
  }

  @include breakpoint-from(mq4) {
    height: 500px;
  }

  &__box {
    background-color: rgba(0, 0, 0, 0.7);
    margin: 30px 0 0 50px;
    width: 55%;
    padding: 20px;

    @include breakpoint-from(mq4) {
      width: 45%;
    }
  }

  &__button {
    margin-top: 20px;

    .content-box & {
      border-color: var(--wb-grey-40);
    }

    &__color {
      color: var(--wb-white);
    }
  }
}
</style>

<template>
  <div
    class="tag wb-type-copy-strong"
    :class="{ active, disabled }"
    @click="toggle"
  >
    <slot>
      {{ tag.name }}
    </slot>
  </div>
</template>

<script>
export default {
  props: ["tag", "active", "disabled"],
  setup(props, { emit }) {
    const toggle = () => {
      if (!props.disabled) {
        if (props.active) {
          emit("toggle", {
            tag: props.tag,
            mode: "delete",
          });
        } else {
          emit("toggle", {
            tag: props.tag,
            mode: "add",
          });
        }
      }
    };

    return {
      toggle,
    };
  },
};
</script>

<style lang="scss" scoped>
.tag {
  display: inline-block;
  padding: 7px 12px;
  margin: 0 12px 15px 0;
  color: var(--wb-grey-20);
  border: 1px solid var(--wb-grey-45);
  font-size: 0.875rem;
  line-height: 1rem;
  border-radius: 50px;
  cursor: pointer;

  &.active {
    color: var(--wb-blue-45);
    border-color: var(--wb-blue-45);
  }

  &.disabled {
    color: var(--wb-grey-60);
    border: 1px solid var(--wb-grey-60);
    cursor: not-allowed;
  }
}
</style>

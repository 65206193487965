<template>
  <div class="filter wb-type-copy">
    <div class="filter__button-container" @click="open = !open">
      <span class="filter__button__text">
        {{ localization?.filter?.filterBy }}
      </span>
      <button type="button" class="filter__button wb-round-button">
        <Icon
          name="filter"
          :size="10"
          class="filter__icon wb-round-button__icon"
        />
      </button>
    </div>
  </div>
  <transition name="slide">
    <FilterModal
      v-if="open"
      @close="open = false"
      :localization="localization"
    />
  </transition>
</template>

<script>
import { ref, watch } from "vue";
import FilterModal from "@/components/Vehicle/Filter/FilterModal";
import Icon from "@/components/Icon";

export default {
  name: "Filter",
  props: ["localization"],
  setup(_, { emit }) {
    const open = ref(false);
    watch(
      () => open.value,
      () => {
        emit("open", open.value);
      }
    );
    return {
      open,
    };
  },
  components: {
    FilterModal,
    Icon,
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.filter {
  &__button-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__button {
    background: var(--wb-blue-45);
    border: none;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover,
    &:active,
    &:focus {
      background: var(--wb-blue-35) !important;
    }

    &__text {
      color: var(--wb-white);
      padding-right: 10px;
      font-size: 0.875rem;

      @include breakpoint-from(mq3) {
        font-size: 1rem;
      }
    }
  }

  &__icon {
    color: var(--wb-white);
    width: 14px;
    height: 14px;
  }
}
</style>

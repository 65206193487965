<template>
  <div class="more-topics">
    <h3 class="more-topics__headline wb-type-heading-m">{{ header }}</h3>
    <div class="more-topics__desktop" v-if="!isMobile">
      <Topic
        v-for="topic in topics"
        :key="topic.topTeaserTitle"
        :topic="topic"
      />
    </div>
    <div class="more-topics__mobile" v-if="isMobile">
      <wb-slider show-dot-navigation scroll-snap>
        <div
          class="wb-slider__item wb-slider__item--full-width"
          v-for="topic in topics"
          :key="topic.topTeaserTitle"
          :topic="topic"
        >
          <Topic :topic="topic" />
        </div>
      </wb-slider>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import Topic from "@/components/ContentPage/Topic";
const store = useStore();
const isMobile = computed(() => store.getters["localization/isMobile"]);
const props = defineProps(["topics", "header"]);
</script>
<style lang="scss">
.more-topics {
  &__headline {
    margin-bottom: 20px;
  }
  &__desktop {
    display: flex;
    justify-content: space-between;
    column-gap: 4%;
  }
  wb-dot-navigation {
    margin-top: 25px;
    --dot-color: var(--wb-white);
    --active-dot-color: var(--wb-white);
  }
}
</style>

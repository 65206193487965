import {
  addDays,
  addWeeks,
  parseISO,
  setDay,
  sub,
  isAfter,
  isBefore,
  isValid,
  format,
  getMonth,
  getYear,
  isEqual,
  differenceInDays,
  nextFriday,
  isSameDay,
  addHours,
  addMinutes,
} from "date-fns";

export const DEFAULT_FORMAT = "yyyy-MM-dd";

function parseDate(input, dateFormat) {
  dateFormat = (dateFormat || "yyyy-mm-dd").toLowerCase(); // default format
  const parts = input.match(/(\d+)/g),
    fmt = {};
  let i = 0;
  // extract date-part indexes from the format
  dateFormat.replace(/(yyyy|dd|mm)/g, function (part) {
    fmt[part] = i;
    i++;
  });

  const date = [parts[fmt["yyyy"]], parts[fmt["mm"]], parts[fmt["dd"]]]
    .filter((x) => x !== undefined)
    .join("-");

  if (!isValid(parseISO(date))) {
    return null;
  }

  return new Date(date);
}

export function formatDateString(
  stringDate,
  stringDateFormat,
  requiredFormat = DEFAULT_FORMAT
) {
  if (!stringDate) {
    return stringDate;
  }
  const date = parseDate(stringDate, stringDateFormat);

  if (!date) {
    return null;
  }

  return format(date, requiredFormat);
}

export function formatDisplayDate(stringDate, isoCode, requiredFormat) {
  if (!stringDate || !isoCode || !requiredFormat) {
    return stringDate;
  }

  const parsedDate = parseISO(stringDate);

  let isoWeekday = parsedDate.toLocaleDateString(isoCode, {
    weekday: "short",
  });

  if (isoWeekday) {
    isoWeekday = isoWeekday.substr(0, 2);
  }

  const date = format(parsedDate, requiredFormat);

  return `${isoWeekday}, ${date}`;
}

export function compressArray(original) {
  const compressed = [];
  // make a copy of the input array
  const copy = original.slice(0);

  // first loop goes over every element
  for (const element of original) {
    let myCount = 0;
    // loop over every element in the copy and see if it's the same
    for (let w = 0; w < copy.length; w++) {
      if (element === copy[w]) {
        // increase amount of times duplicate is found
        myCount++;
        // sets item to undefined
        delete copy[w];
      }
    }

    if (myCount > 0) {
      const a = {
        value: element,
        count: myCount,
      };
      compressed.push(a);
    }
  }

  return compressed;
}

function addToRespectiveList(
  allOpenHours,
  affectedDates,
  allSpecialClosedDates,
  allSpecialOpenDates
) {
  if (allOpenHours.closed) {
    affectedDates.forEach((date) =>
      allSpecialClosedDates.push(getFormattedDate(date))
    );
  } else {
    affectedDates.forEach((date) =>
      allSpecialOpenDates.push(getFormattedDate(date))
    );
  }
}

export function getBlockedDates({
  holidays,
  locations,
  minDate,
  maxDate,
  dateFormat,
}) {
  const blockedDates = new Set();

  holidays?.forEach((holiday) => {
    blockedDates.add(holiday.date);
  });

  let allHolidaysFromLocations = [];
  let allClosingDays = [];
  let allSpecialClosedDates = [];
  let allSpecialOpenDates = [];

  locations?.forEach((location) => {
    location?.outlet?.openingHours.forEach((openingHour) => {
      if (openingHour.closed) {
        allClosingDays.push(openingHour.day);
      }
    });

    location?.outlet?.specialHolidays.forEach((holiday) =>
      allHolidaysFromLocations.push(holiday.date)
    );

    location?.outlet?.specialOpeningHours.forEach((specialOpening) => {
      const affectedDates = getDatesBetween(
        specialOpening.startDate,
        specialOpening.endDate
      );

      const allOpenHours = specialOpening.dayOpeningHours.find(
        (open) => open.day === "ALL" // if all days affected by the special opening hours have the same data, the Day is "ALL"
      );

      if (allOpenHours) {
        addToRespectiveList(
          allOpenHours,
          affectedDates,
          allSpecialClosedDates,
          allSpecialOpenDates
        );
      } else {
        affectedDates.forEach((date) => {
          const openHours = specialOpening.dayOpeningHours.find(
            (open) => convertStringDayToNumber(open.day) === date.getDay()
          );

          if (!openHours) {
            return;
          }

          date = getFormattedDate(date);

          if (openHours.closed) {
            allSpecialClosedDates.push(date);
          } else {
            allSpecialOpenDates.push(date);
          }
        });
      }
    });
  });

  allSpecialClosedDates = compressArray(allSpecialClosedDates);
  allSpecialOpenDates = compressArray(allSpecialOpenDates);
  allHolidaysFromLocations = compressArray(allHolidaysFromLocations);
  allClosingDays = compressArray(allClosingDays);

  allClosingDays.forEach((day) => {
    if (day.count === locations?.length) {
      const allClosingDaysTillMaxDate = addAllClosingDaysTillMaxDate(
        day.value,
        minDate,
        maxDate,
        dateFormat
      );
      allClosingDaysTillMaxDate.forEach((closingDay) =>
        blockedDates.add(formatDateString(closingDay, dateFormat))
      );
    }
  });

  const addIfTrueForAll = (date) => {
    if (date.count === locations?.length) {
      blockedDates.add(date.value);
    }
  };

  allHolidaysFromLocations.forEach((date) => addIfTrueForAll(date));
  allSpecialClosedDates.forEach((date) => addIfTrueForAll(date));

  allSpecialOpenDates.forEach((date) => {
    if (blockedDates.has(date.value)) {
      blockedDates.delete(date.value);
    }
  });

  return Array.from(blockedDates);
}

export function addAllClosingDaysTillMaxDate(
  day,
  minDate,
  maxDate,
  dateFormat
) {
  const numDay = convertStringDayToNumber(day);
  const start = parseISO(minDate);
  const end = parseISO(maxDate);
  const result = [];
  let date = setDay(start, numDay);

  while (isBefore(date, end)) {
    result.push(format(date, dateFormat));
    date = addWeeks(date, 1);
  }

  return result;
}

export function getDatesBetween(minDate, maxDate) {
  const start = parseISO(minDate);
  const end = parseISO(maxDate);
  const result = [];
  let date = start;

  while (isBefore(date, end)) {
    result.push(date);
    date = addDays(date, 1);
  }

  result.push(date); // include maxDate
  return result;
}

export function convertStringDayToNumber(stringDay) {
  switch (stringDay) {
    case "SU":
      return 0;
    case "MO":
      return 1;
    case "TU":
      return 2;
    case "WE":
      return 3;
    case "TH":
      return 4;
    case "FR":
      return 5;
    case "SA":
      return 6;
    default:
      return undefined;
  }
}

export function getMonthNumber(date) {
  return getMonth(parseISO(date));
}

export function getYearNumber(date) {
  return getYear(parseISO(date));
}

export function addOneDay(stringDay) {
  let date = parseISO(stringDay);
  date = addDays(date, 1);
  return format(date, DEFAULT_FORMAT);
}

export function subtractOneDay(stringDay) {
  let date = parseISO(stringDay);
  date = sub(date, {
    days: 1,
  });
  return format(date, DEFAULT_FORMAT);
}

/**
 * This method returns true/false if given date is disabled/invalid
 * @param date is the given date
 * @param minDate is the minimum date user can select
 * @param maxDate is the maximum date user can select
 * @param blockedDates is a array of blockedDates
 */
export function dateIsDisabled(date, minDate, maxDate, blockedDates) {
  const parsedDate = parseISO(date);

  if (
    !isValid(parsedDate) ||
    blockedDates.includes(date) ||
    isBefore(parsedDate, parseISO(minDate)) ||
    isAfter(parsedDate, parseISO(maxDate))
  ) {
    return true;
  }

  return false;
}

export function isBeforeDate(to, from) {
  if (!to || !from) {
    return false;
  }

  const parsedFrom = parseISO(from);
  const parsedTo = parseISO(to);

  if (isBefore(parsedTo, parsedFrom)) {
    return true;
  }

  return false;
}

export function getFormatedDateForTracking(date, withWeekday) {
  const weekday = new Intl.DateTimeFormat("en", { weekday: "short" }).format(
    date
  );
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
    date
  );
  const hour = new Intl.DateTimeFormat("en", { hour: "2-digit" })
    .format(date)
    .substring(0, 2);
  let minute = new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(
    date
  );
  let result = "";
  if (withWeekday) {
    result = `${weekday}-`;
  }
  if (minute.length < 2) {
    minute = `0${minute}`;
  }
  result = `${result}${year}-${day}-${month}T${hour}:${minute}`;
  return result;
}

export function getFormattedDateAndTime(date, time) {
  const weekday = new Intl.DateTimeFormat("en", { weekday: "short" }).format(
    date
  );
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
    date
  );
  return `${weekday}-${year}-${day}-${month}T${time}`;
}

export function getFormattedDate(date) {
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
    date
  );

  return `${year}-${month}-${day}`;
}

export function getDaysBetweenTwoDates(date1, date2) {
  var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  return Math.round(Math.abs((date1.getTime() - date2.getTime()) / oneDay));
}

export function isInDateRange(date, startDate, endDate) {
  return (
    (isAfter(date, startDate) && isBefore(date, endDate)) ||
    isEqual(date, startDate) ||
    isEqual(date, endDate)
  );
}

export function daysPeriod(from, to) {
  if (!from || !to) {
    return null;
  }

  return differenceInDays(new Date(to), new Date(from));
}

/**
 * This method returns true if day is today, tomorrow, nextFriday
 * @param day string day
 */
export function isDynamicDay(day) {
  return day === "today" || day === "tomorrow" || day === "nextFriday";
}
/**
 * This method returns a boolean if fromTime is before minFromTime
 * @param fromDate string fromDate
 * @param fromTime string fromTime
 * @param minFromTime string minFromTime
 */
export function fromTimeAfterMinFromTime(fromDate, fromTime, minFromTime) {
  if (!fromDate || !fromTime || !minFromTime) {
    return false;
  }
  let configTime = parseISO(fromDate);
  configTime = addHours(configTime, minFromTime.substring(0, 2));
  configTime = addMinutes(configTime, minFromTime.substring(3, 5));
  let enteredTime = parseISO(fromDate);
  enteredTime = addHours(enteredTime, fromTime.substring(0, 2));
  enteredTime = addMinutes(enteredTime, fromTime.substring(3, 5));

  if (isSameDay(parseISO(fromDate), new Date())) {
    return isBefore(enteredTime, configTime);
  }
  return false;
}
/**
 * This method returns the next availabe day which is not included in blockedDates
 * @param day is the given date
 * @param blockedDates is an array of blocked dates
 */
export function nextAvailableDay(day, blockedDates = []) {
  let availableDate = day;

  while (blockedDates.includes(format(availableDate, DEFAULT_FORMAT))) {
    availableDate = addDays(availableDate, 1);
  }
  return format(availableDate, DEFAULT_FORMAT) || null;
}
/**
 * This method returns the calculated from day catching also string values to return dynamic days
 * @param from is the given from day
 * @param minDate is the minimal day available to select
 * @param maxFromDate is the maximal day available to select
 * @param minFromTime is the minimal time which can be choosen if from day is today
 * @param blockedDates is an array of blocked dates
 */
export function calculateFromDay(
  from,
  minDate,
  maxFromDate,
  minFromTime,
  blockedDates
) {
  let fromDay = from;

  switch (from) {
    case "today":
      let minDay = parseISO(minDate);
      minDay = addHours(minDay, minFromTime.substring(0, 2));
      minDay = addMinutes(minDay, minFromTime.substring(3, 5));
      // if minDate is today & minFromTime is > current Time
      if (isSameDay(new Date(), minDay) && isBefore(new Date(), minDay)) {
        fromDay = nextAvailableDay(new Date(), blockedDates);
        break;
      }
      fromDay = nextAvailableDay(addDays(new Date(), 1), blockedDates);
      break;
    case "tomorrow":
      fromDay = nextAvailableDay(addDays(new Date(), 1), blockedDates);
      break;
    case "nextFriday":
      const nxtFriday = nextFriday(new Date());
      const tomorrow = addDays(new Date(), 1);
      // if tomorrow is friday, select weekAfterNxtFriday
      if (isSameDay(nxtFriday, tomorrow)) {
        const weekAfterNxtFriday = addDays(nxtFriday, 7);
        fromDay = nextAvailableDay(weekAfterNxtFriday, blockedDates);
        break;
      }
      fromDay = nextAvailableDay(nxtFriday, blockedDates);
      break;
    default:
  }
  const formattedFrom = format(parseISO(fromDay), DEFAULT_FORMAT);
  return dateIsDisabled(formattedFrom, minDate, maxFromDate, blockedDates)
    ? null
    : formattedFrom;
}
/**
 * This method returns the calculated to day catching also x + day values to return dynamic days
 * @param to is the given to day
 * @param from is the given from day
 * @param minDate is the minimal day available to select
 * @param maxFromDate is the maximal day available to select
 * @param blockedDates is an array of blocked dates
 */
export function calculateToDay(to, from, minDate, maxFromDate, blockedDates) {
  let toDay = to;
  if (isDynamicDay(from.entered)) {
    toDay = nextAvailableDay(
      addDays(parseISO(from.calculated), to),
      blockedDates
    );
  }
  const formattedTo = format(parseISO(toDay), DEFAULT_FORMAT);
  return dateIsDisabled(formattedTo, minDate, maxFromDate, blockedDates)
    ? null
    : formattedTo;
}

export function sortVehicles(vehicles, sort) {
  if (sort === "price") {
    return vehicles.sort(
      (a, b) =>
        a.pricingDetails.totalGrossPrice - b.pricingDetails.totalGrossPrice
    );
  } else if (sort === "distance") {
    return vehicles.sort((a, b) => a.distance - b.distance);
  }
  return vehicles;
}

export function addOrderToVehicles(vehicles, orderList) {
  let result;
  result = vehicles.map((vehicle) => {
    orderList.find((sortedVehicle) => {
      if (
        sortedVehicle.globalVehicleType === vehicle.modelType.globalVehicleType
      ) {
        vehicle.order = sortedVehicle.order;
      }
      return (
        sortedVehicle.globalVehicleType === vehicle.modelType.globalVehicleType
      );
    });
    return vehicle;
  });

  return result;
}

export function setVehicleTypesAndSort(orderList, vehicleTypeMap) {
  return new Map(
    orderList
      .map((vehicle) => {
        const orderKey = vehicle.vehicleTypeId
          ? vehicle.vehicleTypeId
          : vehicle.order;

        const nameLocalization = vehicleTypeMap.get(vehicle.globalVehicleType)
          ? vehicleTypeMap.get(vehicle.globalVehicleType).singular
          : vehicle.vehicleType;

        return [
          orderKey,
          {
            name: nameLocalization,
            order: vehicle.order,
            globalVehicleType: vehicle.globalVehicleType,
          },
        ];
      })
      .sort(([, firstVal], [, secondVal]) => firstVal.order - secondVal.order)
  );
}

export function addDistanceKeyAndCityToVehicles(vehicles, locations) {
  const arrLocations = Array.from(locations);
  let vehiclesWithDistance = [];
  arrLocations.forEach((location) => {
    vehiclesWithDistance = vehicles.map((vehicle) => {
      if (vehicle.outletId === location?.outlet?.outletId) {
        vehicle.distance = parseFloat(location.distance).toFixed(2) ?? null;
        vehicle.city = location.outlet?.latinAddress?.city ?? "";
        vehicle.originalCity = location.outlet?.originalAddress?.city ?? "";
      }
      return vehicle;
    });
  });
  return vehiclesWithDistance;
}

const trailerHitch = "TRAILER_HITCH";
const fourMatic = "ALL_WHEEL_DRIVE";
const transmissionAutomatic = "TRANSMISSION_AUTOMATIC";
const transmissionManual = "TRANSMISSION_MANUAL";
const navigation = "NAVIGATION";
const airCondition = "AIR_CONDITION";
const heatedSeats = "HEATED_SEATS";
const winterTyres = "WINTER_TYRES";
const parkingCamera = "PARKING_CAMERA";
const mbux = "MBUX";
const sunRoof = "SUN_ROOF";
const cruiseControl = "CRUISE_CONTROL";
const fuelTypeUnknown = "UNKNOWN";

export function createEquipment(localization, vehicle, forceMobile = false) {
  return {
    kw: getKwPowerString(localization, vehicle),
    ps: getPsPowerString(localization, vehicle),
    fuelType: getFuelTypeString(localization, vehicle),
    transition: getTransmissionString(localization, vehicle),
    seats: {
      included: vehicle.seats > 0,
      text: vehicle.seats === 0 ? "-" : vehicle.seats,
    },
    navigation: {
      included: vehicle.equipments.includes(navigation),
      text: localization.vehicleDetails?.navigationSystem,
    },
    airCondition: {
      included: vehicle.equipments.includes(airCondition),
      text: getAirConditionString(localization),
    },
    trailerHitch: {
      included: vehicle.equipments.includes(trailerHitch),
      text: localization.vehicleDetails?.trailerHitch_short,
    },
    fourMatic: {
      included: vehicle.equipments.includes(fourMatic),
      text: localization.vehicleDetails?.fourMatic,
    },
    forceMobile: forceMobile,
  };
}

export function createAdditionalEquipment(equipment, localization) {
  const result = [];
  if (equipment.includes(cruiseControl)) {
    result.push(localization.vehicleDetails?.cruiseControl);
  }
  if (equipment.includes(parkingCamera)) {
    result.push(localization.vehicleDetails?.parkingCamera);
  }
  if (equipment.includes(heatedSeats)) {
    result.push(localization.vehicleDetails?.heatedSeats);
  }
  if (equipment.includes(mbux)) {
    result.push(localization.vehicleDetails?.mbux);
  }
  if (equipment.includes(sunRoof)) {
    result.push(localization.vehicleDetails?.sunRoof);
  }
  if (equipment.includes(winterTyres)) {
    result.push(localization.vehicleDetails?.winterTyres);
  }
  return result;
}

export function getKwPowerString(localization, vehicle) {
  return vehicle.enginePowerKw
    ? vehicle.enginePowerKw + " " + localization?.vehicleDetails?.kw
    : null;
}

export function getPsPowerString(localization, vehicle) {
  return vehicle.enginePowerPs
    ? "(" + vehicle.enginePowerPs + " " + localization.vehicleDetails?.ps + ")"
    : null;
}

export function getTransmissionString(localization, vehicle) {
  let transmissionString;
  const automaticTransitionIncluded = vehicle.equipments.includes(
    transmissionAutomatic
  );
  if (automaticTransitionIncluded) {
    transmissionString = localization.vehicleDetails?.automatic;
  } else {
    transmissionString = localization.vehicleDetails?.manualTransmission;
  }
  return transmissionString;
}

export function getFuelTypeString(localization, vehicle) {
  const fuelType = vehicle.fuelType;

  if (!fuelType || fuelType === fuelTypeUnknown) {
    return null;
  }

  switch (fuelType) {
    case "SUPER_PLUS":
      return localization.vehicleDetails.superPlus;
    case "SUPER_E10":
      return localization.vehicleDetails.superE10;
    case "ELECTRO":
      return localization.vehicleDetails.electric;
    default:
      return localization.vehicleDetails[fuelType.toLowerCase()];
  }
}

export function getAirConditionString(localization) {
  return localization.vehicleDetails?.airConditioner_short;
}

export function getGroupedVehiclesByAttribute(vehicles) {
  return vehicles.reduce((accumulator, currentValue) => {
    currentValue["modelType"]["globalModelClassAttributes"].forEach(
      (attribute) => {
        accumulator[attribute] = accumulator[attribute] || [];
        accumulator[attribute].push(currentValue);
      }
    );
    return accumulator;
  }, {});
}

export function getGroupedVehicles(vehicles) {
  const groupedVehicles = vehicles.reduce((accumulator, currentValue) => {
    accumulator[currentValue["modelType"]["vehicleTypePlural"]] =
      accumulator[currentValue["modelType"]["vehicleTypePlural"]] || [];
    accumulator[currentValue["modelType"]["vehicleTypePlural"]].push(
      currentValue
    );
    return accumulator;
  }, {});
  return Object.fromEntries(
    Object.entries(groupedVehicles).sort((a, b) => {
      return a[1][0].order - b[1][0].order;
    })
  );
}

export function getFilteredVehicles(vehicles, filters, options = {}) {
  let filteredVehicles = vehicles;

  if (filters.get("price")) {
    filteredVehicles = filteredVehicles.filter((vehicle) =>
      inPriceRange(vehicle, filters.get("price"))
    );
  }
  if (filters.get("age")) {
    filteredVehicles = filteredVehicles.filter((vehicle) =>
      inAgeRange(vehicle, filters.get("age"))
    );
  }
  if (filters.get("model")?.models && !options.excludeModel) {
    filteredVehicles = filteredVehicles.filter((vehicle) =>
      hasModel(vehicle, filters.get("model"))
    );
  }
  if (filters.get("model")?.vehicleTypes && !options.excludeVehicleType) {
    filteredVehicles = filteredVehicles.filter((vehicle) =>
      hasVehicleType(vehicle, filters.get("model"))
    );
  }
  if (filters.get("equipment")) {
    filteredVehicles = filteredVehicles.filter((vehicle) =>
      hasEquipment(vehicle, filters.get("equipment"))
    );
  }
  if (filters.get("seats")) {
    filteredVehicles = filteredVehicles.filter((vehicle) =>
      matchSeats(vehicle, filters.get("seats"))
    );
  }
  if (filters.get("attribute")) {
    filteredVehicles = filteredVehicles.filter((vehicle) =>
      hasAttribute(vehicle, filters.get("attribute"))
    );
  }
  return filteredVehicles;
}

export function inPriceRange(vehicle, price) {
  return (
    price &&
    vehicle?.pricingDetails?.totalGrossPrice <= price?.max &&
    vehicle?.pricingDetails?.totalGrossPrice >= price?.min
  );
}

export function inAgeRange(vehicle, filter) {
  const { minAge } = vehicle;
  if (filter?.age) {
    switch (filter?.age?.value) {
      case "18-20":
        return minAge <= 20;
      case "21-24":
        return minAge <= 24;
      case "25+":
        return true;
      default:
        return true;
    }
  }
  return false;
}

export function hasModel(vehicle, model) {
  return (
    model?.models &&
    model.models.includes(vehicle?.modelType?.modelClassNameShort)
  );
}

export function hasVehicleType(vehicle, model) {
  return (
    model?.vehicleTypes &&
    (model?.vehicleTypes.includes(vehicle?.modelType?.vehicleTypeSingular) ||
      model?.vehicleTypes.includes(vehicle?.modelType?.globalVehicleType))
  );
}

export function hasEquipment(vehicle, equipment) {
  if (equipment?.equipments) {
    const convertedEquipments = convertEquipmentNames(equipment.equipments);
    return convertedEquipments.every((value) =>
      vehicle?.equipments.includes(value)
    );
  }
  return false;
}

function convertEquipmentNames(equipments) {
  return equipments.map((name) => {
    switch (name) {
      case "navigation":
        return "NAVIGATION";
      case "allWheel":
        return "ALL_WHEEL_DRIVE";
      case "heatedSeats":
        return "HEATED_SEATS";
      case "trailerHitch":
        return "TRAILER_HITCH";
      default:
        return "";
    }
  });
}

export function matchSeats(vehicle, seats) {
  if (seats?.seats) {
    const matchSeat = seats?.seats.filter((seat) => vehicle.seats === seat);
    return !!matchSeat.length;
  }
  return false;
}

export function hasAttribute(vehicle, attributes) {
  let result = false;
  attributes.attribute.some((attribute) => {
    if (
      vehicle.modelType?.globalModelClassAttributes.includes(attribute.name)
    ) {
      result = true;
      // leave the some()-iteration by returning true
      return true;
    }
  });
  return result;
}

export function localizeEquipment(localization, equipment) {
  switch (equipment) {
    case "trailerHitch":
      return localization.vehicleDetails.trailerHitch;
    case "allWheel":
      return localization.vehicleDetails.fourMatic;
    case "navigation":
      return localization.vehicleDetails.navigationSystem;
    case "heatedSeats":
      return equipment; //no translation of "heatedSeats" in the vehicleDetails-localization-object at the moment
    default:
      return equipment;
  }
}

export function createVehicleTypeMap(vehicles) {
  const vehicleTypeMap = new Map();
  vehicles.forEach((vehicle) => {
    if (vehicle.modelType?.globalVehicleType) {
      vehicleTypeMap.set(vehicle.modelType?.globalVehicleType, {
        singular: vehicle.modelType?.vehicleTypeSingular,
        plural: vehicle.modelType?.vehicleTypePlural,
      });
    }
  });
  return vehicleTypeMap;
}

import ApiService from "@/services/ApiService";
import { stepNames } from "@/utilities/checkout";
import DOMPurify from "dompurify/dist/purify";

// initial state
const checkoutState = () => ({
  steps: [],
  clickableSteps: new Set([1]),
  form: {
    disabled: false,
    disabledPersonalData: false,
    disabledRentalData: false,
    disabledAdditionalFieldsData: false,
    personalData: [],
    additionalFields: [],
    rentalData: [],
  },
  loading: false,
  error: false,
  errorMessage: null,
  reservationId: null,
  selectedProfileType: "",
  paymentLink: null,
  finishedLoadingReservationDetails: false,
});

// mutations
const checkoutMutations = {
  RESET_FORM_FIELDS(state) {
    state.form.personalData = [];
    state.form.additionalFields = [];
    state.form.rentalData = [];
  },
  SET_FINISHED_RESERVATION_DETAILS(state, finished) {
    state.finishedLoadingReservationDetails = finished;
  },
  SET_STEPS(state, steps) {
    state.steps = steps;
  },
  SET_CLICKABLE_STEP(state, step) {
    const clickable = new Set([...state.clickableSteps]);
    clickable.add(step);
    state.clickableSteps = clickable;
  },
  REMOVE_CLICKABLE_STEP(state, step) {
    const clickable = new Set([...state.clickableSteps]);
    clickable.delete(step);
    state.clickableSteps = clickable;
  },
  SET_DISABLED(state, disabled) {
    state.form.disabled = disabled;
  },
  SET_STEP_DISABLED(state, { disabled, stepName }) {
    state.form[`disabled${stepName}`] = disabled;
  },
  SET_PERSONAL_DATA(state, personalData) {
    state.form.personalData = personalData;
  },
  SET_ADDITIONAL_FIELDS(state, additionalFields) {
    state.form.additionalFields = additionalFields;
  },
  SET_RENTAL_DATA(state, rentalData) {
    state.form.rentalData = rentalData;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_RESERVATION_ID(state, reservationId) {
    state.reservationId = reservationId;
  },
  RESET_RESERVATION_ID(state) {
    state.reservationId = null;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  RESET_ERROR(state) {
    state.error = false;
  },
  SET_ERROR_MESSAGE(state, error) {
    state.errorMessage = error;
  },
  RESET_ERROR_MESSAGE(state) {
    state.errorMessage = null;
  },
  SET_SELECTED_PROFILE_TYPE(state, type) {
    state.selectedProfileType = type;
  },
  SET_PAYMENT_LINK(state, link) {
    state.paymentLink = link;
  },
  RESET_CHECKOUT_STATE(state) {
    Object.assign(state, checkoutState());
  },
};

// actions
const checkoutActions = {
  resetForm({ commit }) {
    commit("RESET_FORM_FIELDS");
  },
  setFinishedReservationDetails({ commit }, finished) {
    commit("SET_FINISHED_RESERVATION_DETAILS", finished);
  },
  setSteps({ commit }, steps) {
    commit("SET_STEPS", steps);
  },
  setClickableStep({ commit }, step) {
    commit("SET_CLICKABLE_STEP", step);
  },
  removeClickableStep({ commit }, step) {
    commit("REMOVE_CLICKABLE_STEP", step);
  },
  setDisabled({ commit }, disabled) {
    commit("SET_DISABLED", disabled);
  },
  setStepDisabled({ commit }, { disabled, stepName }) {
    commit("SET_STEP_DISABLED", { disabled, stepName });
  },
  setFormData({ commit }, { form, stepName }) {
    const formData = [];
    form.forEach((field) => {
      formData.push({
        type: field.type,
        value: DOMPurify.sanitize(field.value) ?? "",
      });
    });

    if (stepName === stepNames.personalData) {
      commit("SET_PERSONAL_DATA", formData);
    } else if (stepName === stepNames.rentalData) {
      commit("SET_RENTAL_DATA", formData);
    } else if (stepName === stepNames.additionalFields) {
      commit("SET_ADDITIONAL_FIELDS", formData);
    }
  },
  setReservationId({ commit }, reservationId) {
    commit("SET_RESERVATION_ID", reservationId);
  },
  setLoading({ commit }, loading) {
    commit("SET_LOADING", loading);
  },
  async requestBooking({ rootGetters, commit }, request) {
    commit("SET_LOADING", true);
    const mockMode = rootGetters["marketConfig/mockMode"];

    try {
      const booking = mockMode
        ? await ApiService.requestMockBooking(request)
        : await ApiService.requestBooking(request);
      commit("SET_RESERVATION_ID", booking?.data?.reservationId ?? "");
      commit("SET_LOADING", false);
      commit("SET_ERROR", false);
    } catch (e) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", true);
      commit("SET_ERROR_MESSAGE", e.response.data.localizedMessage);
    }
  },
  async requestBookingPayment({ rootGetters, commit }, request) {
    commit("SET_LOADING", true);
    const mockMode = rootGetters["marketConfig/mockMode"];

    try {
      const booking = mockMode
        ? await ApiService.requestMockBookingPayment(request)
        : await ApiService.requestBookingPayment(request);

      commit("SET_PAYMENT_LINK", booking?.data?.paymentLink ?? "");
      commit("SET_LOADING", false);
      commit("SET_ERROR", false);
    } catch (e) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", true);
      commit("SET_ERROR_MESSAGE", e.response.data.localizedMessage);
    }
  },
  resetReservation({ commit }) {
    commit("RESET_RESERVATION_ID");
  },
  resetError({ commit }) {
    commit("RESET_ERROR");
    commit("RESET_ERROR_MESSAGE");
  },
  setSelectedProfileType({ commit }, type) {
    commit("SET_SELECTED_PROFILE_TYPE", type);
  },
  resetCheckoutState({ commit }) {
    commit("RESET_CHECKOUT_STATE");
  },
};

// getters
const checkoutGetters = {
  finishedLoadingReservationDetails: (state) => {
    return state.finishedLoadingReservationDetails;
  },
  steps: (state) => {
    return state.steps;
  },
  clickableSteps: (state) => {
    return state.clickableSteps;
  },
  disabled: (state) => {
    return state.form.disabled;
  },
  disabledPersonalData: (state) => {
    return state.form.disabledPersonalData;
  },
  disabledRentalData: (state) => {
    return state.form.disabledRentalData;
  },
  disabledAdditionalFieldsData: (state) => {
    return state.form.disabledAdditionalFieldsData;
  },
  personalData: (state) => {
    return state.form.personalData;
  },
  additionalFields: (state) => {
    return state.form.additionalFields;
  },
  rentalData: (state) => {
    return state.form.rentalData;
  },
  checkoutFields: (state) => {
    return [
      ...state.form.personalData,
      ...state.form.additionalFields,
      ...state.form.rentalData,
    ];
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
  errorMessage: (state) => {
    return state.errorMessage;
  },
  reservationId: (state) => {
    return state.reservationId;
  },
  paymentLink: (state) => {
    return state.paymentLink;
  },
  selectedProfileType: (state) => {
    return state.selectedProfileType;
  },
};

function makeState(override) {
  return { ...checkoutState(), ...override };
}

export function createCheckoutModule(override) {
  return {
    namespaced: true,
    state: makeState(override),
    getters: checkoutGetters,
    actions: checkoutActions,
    mutations: checkoutMutations,
  };
}

export default {
  namespaced: true,
  state: checkoutState,
  getters: checkoutGetters,
  actions: checkoutActions,
  mutations: checkoutMutations,
};

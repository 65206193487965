<template>
  <div class="filter-items">
    <p class="filter-items__label wb-type-copy-strong">
      {{ localization?.filter?.attribute }}
    </p>

    <div style="display: flex; flex-wrap: wrap">
      <VehicleAttribute
        class="vehicle-attribute"
        v-for="vehicleAttribute of vehicleAttributes"
        :key="vehicleAttribute.name"
        :vehicleAttribute="vehicleAttribute"
        @toggle="toggleVehicleAttribute($event)"
      />
    </div>

    <p class="filter-items__label wb-type-copy-strong extraMargin">
      {{ localization?.filter?.vehicleType }}
    </p>

    <div style="display: flex; flex-wrap: wrap">
      <VehicleType
        class="vehicle-type"
        v-for="vehicleType of vehicleTypes"
        :key="vehicleType[0]"
        :vehicleType="vehicleType[1]"
        @toggle="toggleVehicleType($event)"
      />
    </div>

    <p class="filter-items__label wb-type-copy-strong wb-margin-top-s">
      {{ filterName(name, localization?.filter) }}
    </p>

    <div class="filter-items__tags">
      <TagItem
        v-for="model in models"
        :key="model.name"
        :tag="model.name"
        :active="activeModel(model.short)"
        :disabled="model.disabled"
        @toggle="toggle($event, model.short)"
      >
        {{ model.name }}
      </TagItem>
    </div>
  </div>
</template>

<script>
import { computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import { filterName } from "@/utilities/filter";
import TagItem from "@/components/Vehicle/Filter/TagItem";
import VehicleType from "@/components/Vehicle/Filter/VehicleType";
import VehicleAttribute from "@/components/Vehicle/Filter/VehicleAttribute";
import { attributeList } from "../constants/index";

export default {
  name: "model",
  props: ["localization", "name"],
  setup() {
    const store = useStore();
    const modelFilter = computed(() =>
      store.getters["vehicles/getFilter"]("model")
    );
    const attributeFilters = computed(() =>
      store.getters["vehicles/getFilter"]("attribute")
    );
    const models = computed(() => store.getters["vehicles/models"]);
    const groupedVehiclesExModel = computed(
      () => store.getters["vehicles/groupedVehiclesExModel"]
    );
    const groupedVehiclesExVehicleType = computed(
      () => store.getters["vehicles/groupedVehiclesExVehicleType"]
    );
    const vehicleTypes = computed(() => store.getters["vehicles/vehicleTypes"]);

    const vehicleAttributes = reactive(attributeList);
    const attributesActive = computed(() =>
      store.getters["vehicles/getFilter"]("attribute")
    );

    const isModelDisabled = (model) => {
      return (
        !activeModel(model) &&
        groupedVehiclesExModel.value &&
        Object.entries(groupedVehiclesExModel.value).filter(
          (group) =>
            group[1].find(
              (vehicle) => vehicle?.modelType?.modelClassNameShort === model
            ) !== undefined
        ).length === 0
      );
    };

    const activeModel = (model) => modelFilter.value?.models?.includes(model);

    const toggleVehicleType = (event) => {
      const { vehicleType, mode } = event;

      let newVehicleTypes = [];
      if (modelFilter.value?.vehicleTypes) {
        newVehicleTypes = [...modelFilter.value?.vehicleTypes];
      }

      const selectedType = vehicleType.globalVehicleType;
      if (mode === "add") {
        vehicleType.active = true;
        newVehicleTypes.push(selectedType);
      } else {
        vehicleType.active = false;
        newVehicleTypes = newVehicleTypes.filter(
          (item) => item !== selectedType
        );
      }

      if (newVehicleTypes.length) {
        store.dispatch("vehicles/addFilter", {
          type: "model",
          models: modelFilter.value?.models,
          vehicleTypes: newVehicleTypes,
        });
      } else {
        if (!modelFilter.value.models) {
          store.dispatch("vehicles/removeFilter", "model");
        } else {
          store.dispatch("vehicles/addFilter", {
            type: "model",
            models: modelFilter.value?.models,
            vehicleTypes: undefined,
          });
        }
      }
    };

    const toggleVehicleAttribute = (event) => {
      const { vehicleAttribute, mode } = event;

      let currentAttributes = [];
      if (attributeFilters.value) {
        currentAttributes = [...attributeFilters.value?.attribute];
      }

      if (mode === "add") {
        vehicleAttribute.active = true;
        currentAttributes.push({ ...vehicleAttribute });
        store.dispatch("vehicles/addFilter", {
          type: "attribute",
          attribute: [...currentAttributes],
        });
      } else {
        currentAttributes = currentAttributes.filter(
          (item) => item.name !== vehicleAttribute.name
        );
        if (currentAttributes.length) {
          store.dispatch("vehicles/addFilter", {
            type: "attribute",
            attribute: [...currentAttributes],
          });
        } else {
          store.dispatch("vehicles/removeFilter", "attribute");
        }
        vehicleAttribute.active = false;
      }
    };

    const toggle = (event, shortName) => {
      const { mode } = event;

      let newModels = [];
      if (modelFilter.value?.models) {
        newModels = [...modelFilter.value?.models];
      }

      if (mode === "add") {
        newModels.push(shortName);
      } else {
        newModels = newModels.filter((item) => item !== shortName);
      }

      if (newModels.length) {
        store.dispatch("vehicles/addFilter", {
          type: "model",
          models: newModels,
          vehicleTypes: modelFilter.value?.vehicleTypes,
        });
      } else {
        if (!modelFilter.value.vehicleTypes) {
          store.dispatch("vehicles/removeFilter", "model");
        } else {
          store.dispatch("vehicles/addFilter", {
            type: "model",
            models: undefined,
            vehicleTypes: modelFilter.value?.vehicleTypes,
          });
        }
      }
    };

    watch(
      () => [groupedVehiclesExVehicleType.value],
      (vehicles) => {
        if (vehicles.length) {
          vehicleTypes.value.forEach((vehicleType) => {
            const foundGroup = Object.keys(vehicles[0]).find((group) =>
              group.includes(vehicleType.name)
            );
            vehicleType.disabled =
              foundGroup === undefined && !vehicleType.active;
          });
        }
      },
      {
        immediate: true,
      }
    );

    watch(
      () => [groupedVehiclesExModel.value],
      (vehicles) => {
        if (vehicles.length) {
          models.value.map(
            (model) => (model.disabled = isModelDisabled(model.short))
          );
        }
      },
      {
        immediate: true,
      }
    );

    watch(
      () => attributesActive.value,
      (active) => {
        if (!active) {
          vehicleAttributes.forEach((attribute) => {
            attribute.active = false;
          });
        } else {
          // set active filter on load
          active.attribute.forEach((attribute) => {
            vehicleAttributes.forEach((attr) => {
              if (attribute.name === attr.name) {
                attr.active = true;
              }
            });
          });
        }
      },
      {
        immediate: true,
      }
    );

    return {
      filterName,
      activeModel,
      models,
      toggle,
      vehicleTypes,
      vehicleAttributes,
      toggleVehicleType,
      toggleVehicleAttribute,
    };
  },
  components: {
    TagItem,
    VehicleType,
    VehicleAttribute,
  },
};
</script>
<style lang="scss" scoped>
.vehicle-type {
  margin-bottom: 10px;
  margin-right: 10px;
}
.extraMargin {
  margin-top: 10px;
}
</style>

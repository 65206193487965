<template>
  <header class="header">
    <a
      v-if="
        marketConfigPreAuthData?.domainActive &&
        marketConfigPreAuthData?.maintenancePage &&
        marketConfigPreAuthData?.authenticationRequired &&
        !isLoggedIn
      "
      class="header__login"
      :href="redirectUrl"
    />
    <div class="header__inner wb-grid-container">
      <div class="header__row wb-grid-row">
        <div class="wb-grid-col-mq1-6 wb-grid-col-mq4-6">
          <div @click="redirectToHomeOrDealer()" class="header__logo">
            <img
              class="header__logo__star"
              src="@/assets/images/mb-logo.svg"
              alt="Mercedes Benz"
            />
            <img
              class="header__logo__text"
              src="@/assets/images/MB-logo-text.svg"
              alt="Mercedes Benz"
            />
          </div>
        </div>
        <Menu
          :header="header"
          :contentPages="contentPages"
          :is-logged-in="isLoggedIn"
          :show-application-info="
            !marketConfigPreAuthData?.maintenancePage || isLoggedIn
          "
          v-if="marketConfigPreAuthData?.domainActive"
        />
      </div>
    </div>
    <SideBar
      :header="header"
      :contentPages="contentPages"
      :show-application-info="
        !marketConfigPreAuthData?.maintenancePage || isLoggedIn
      "
      v-if="marketConfigPreAuthData?.domainActive"
    />
    <transition name="Slide">
      <CookieLayer v-if="openCookieLayer" @close="cookieLayerClosed" />
    </transition>
  </header>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, onMounted, watch } from "vue";
import Menu from "./Menu";
import SideBar from "./SideBar";
import ApiService from "@/services/ApiService";
import useHomeRedirect from "@/compositions/useHomeRedirect";
import CookieLayer from "@/views/CookieLayer";
import cookies from "vue-cookies";

export default {
  props: ["isLoggedIn"],
  setup() {
    const store = useStore();
    const marketConfigPreAuthData = computed(
      () => store.getters["marketConfig/preAuthData"]
    );

    const { redirectToHomeOrDealer } = useHomeRedirect();
    const isCookieNoteActive = computed(
      () => store.getters["marketConfig/cookieNoteActive"]
    );

    const openCookieLayer = ref(false);
    const computeCookieLayerStatus = () => {
      if (!isCookieNoteActive.value) {
        return false;
      }

      // if we have a cookie we don't show the cookie layer
      // @ts-ignore
      const obpCookie = cookies.get("MBRENT");
      return !obpCookie;
    };

    const cookieLayerClosed = () => {
      // Write cookie to indicate that user was noticed about possible cookies
      cookies.set("MBRENT", "true", "7d");
      openCookieLayer.value = computeCookieLayerStatus();
    };

    watch(
      () => isCookieNoteActive.value,
      () => {
        openCookieLayer.value = computeCookieLayerStatus();
      }
    );

    onMounted(() => {
      if (marketConfigPreAuthData.value?.authenticationRequired) {
        requestLoginRedirectUrl();
      }
    });

    const redirectUrl = ref(null);

    const requestLoginRedirectUrl = async () => {
      const request = await ApiService.authLogin();

      if (request.status === 200 && request.data) {
        redirectUrl.value = request.data;
      }
    };

    return {
      header: computed(() => store.getters["localization/header"]),
      contentPages: computed(() => store.getters["localization/contentPages"]),
      redirectToHomeOrDealer,
      marketConfigPreAuthData,
      redirectUrl,
      openCookieLayer,
      cookieLayerClosed,
    };
  },
  components: {
    CookieLayer,
    Menu,
    SideBar,
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  z-index: 50;
  width: 100%;
  min-height: 100px;
  padding-bottom: 10px;
  background: var(--wb-black);

  &__inner,
  &__row {
    height: 100%;
    justify-content: space-between;
  }

  &__row {
    width: 100%;
  }

  &__logo {
    display: flex;
    height: 100%;
    width: 250px;
    text-align: left;
    cursor: pointer;

    &__star {
      display: block;
      align-self: center;
      padding-top: 18px;
      padding-right: 45px;
    }

    &__text {
      display: block;
      align-self: center;
      padding-top: 54px;
      width: 105px;
    }
  }

  &__login {
    margin-top: 15px;
    height: 35px;
    width: 55px;
    float: right;
  }
}
</style>

<template>
  <a
    class="wb-link"
    :class="{
      'wb-link--inline': linkType === 'inline',
      'wb-link--standalone': linkType === 'standalone',
    }"
    :href="url"
    target="_blank"
    >{{ text }}</a
  >
</template>

<script>
export default {
  props: {
    url: String,
    text: String,
    linkType: {
      type: String,
      default: "inline",
    },
  },
};
</script>

import ApiService from "@/services/ApiService";

// initial state
const captchaState = () => ({
  captcha: {},
  userInput: "",
  isValid: null,
});

//mutations
const mutations = {
  REFRESH(state, captcha) {
    state.captcha = captcha;
  },
  SET_USER_INPUT(state, input) {
    state.userInput = input;
  },
  SET_VALID(state, isValid) {
    state.isValid = isValid;
  },
};

//actions
const actions = {
  async getCaptcha({ rootGetters, commit }) {
    const mockMode = rootGetters["marketConfig/mockMode"];
    let getCaptcha;
    if (mockMode) {
      getCaptcha = await ApiService.getMockCaptcha();
    } else {
      getCaptcha = await ApiService.get("/image/captcha");
    }
    try {
      if (getCaptcha.status === 200) {
        const captcha = getCaptcha.data;
        commit("REFRESH", captcha);
      }
    } catch (e) {}
  },
  setUserInput({ commit }, input) {
    commit("SET_USER_INPUT", input);
  },
  async validate({ commit, rootGetters }, request) {
    commit("SET_VALID", null);
    try {
      const mockMode = rootGetters["marketConfig/mockMode"];
      if (mockMode) {
        commit("SET_VALID", request.text.toUpperCase() === "AA4T");
      } else {
        const getValidity = await ApiService.validateCaptcha(request);
        if (getValidity.status === 200) {
          commit("SET_VALID", true);
        } else {
          commit("SET_VALID", false);
        }
      }
    } catch (e) {
      commit("SET_VALID", false);
    }
  },
};

// getters
const getters = {
  captcha: (state) => {
    return state.captcha;
  },
  userInput: (state) => {
    return state.userInput;
  },
  isValid: (state) => {
    return state.isValid;
  },
  captchaData: (state) => {
    return {
      isValid: state.isValid,
      validationData: { id: state.captcha.id, text: state.userInput },
    };
  },
};

function makeState(override) {
  return { ...captchaState(), ...override };
}

export function createCaptchaModule(override) {
  return {
    namespaced: true,
    state: makeState(override),
    getters,
    actions,
    mutations,
  };
}

export default {
  namespaced: true,
  state: captchaState,
  getters,
  actions,
  mutations,
};

<template>
  <div class="additional-fields">
    <h2 class="wb-type-heading-m">
      {{ localization?.checkoutPage?.termsAndSpecialWishesHeadline }}
    </h2>
    <p class="additional-fields__subline">
      {{ localization?.checkoutPage?.requiredText }}
    </p>

    <div class="additional-fields__form wb-margin-top-s">
      <div
        class="additional-fields__form__element wb-margin-bottom-s"
        v-for="(element, index) in form.elements"
        :key="element.type"
      >
        <template
          v-if="
            element.formType === 'input' &&
            (element.type === checkoutFields.drivingLicenceNumber ||
              element.type === checkoutFields.passportNumber)
          "
        >
          <slot name="input" :element="element" :index="index"></slot>
        </template>
        <template
          v-if="
            element.formType === 'checkbox' &&
            element.type === checkoutFields.minimumAge
          "
        >
          <slot name="checkbox" :element="element" :index="index"></slot>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { checkoutFields } from "@/utilities/checkout";

export default {
  name: "AdditionalFields",
  props: {
    localization: Object,
    activeField: { type: String, default: "checkoutActive" },
    form: Object,
  },
  emits: ["validateInput", "radioCheckboxChanged", "getFieldData"],
  setup(_, { emit }) {
    const getFieldData = (type) => {
      emit("getFieldData", type);
    };

    const radioCheckboxChanged = (index) => {
      emit("radioCheckboxChanged", index);
    };

    const validateInput = (index) => {
      emit("validateInput", index);
    };

    return {
      getFieldData,
      radioCheckboxChanged,
      validateInput,
      checkoutFields,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@workbench/core/dist/css/extensions/spacing";

.additional-fields {
  &__subline {
    color: var(--wb-grey-60);
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
</style>

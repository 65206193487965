<template>
  <div class="dealer-wrapper">
    <div class="icon-wrapper wb-margin-top-3xs">
      <Icon
        name="mercedes-pin"
        :width="48"
        :height="48"
        :size="48"
        class="pin"
        :paths="2"
      />
    </div>
    <div class="content-wrapper">
      <h3 class="wb-type-copy-strong dealer-text">
        {{ location.outlet.name }}
      </h3>
      <div class="wb-type-copy dealer-text">
        <div>
          {{ location.outlet.latinAddress.street }}
        </div>
        <div class="wb-margin-bottom-xs">
          {{
            location.outlet.latinAddress.zipCode +
            " " +
            location.outlet.latinAddress.city
          }}
        </div>
        <div class="info-flex wb-margin-bottom-xs">
          <div class="wb-margin-end-xs">
            <Icon name="call" :size="20" :height="20" />
          </div>
          <a
            class="wb-type-copy contact-link"
            :href="'tel:' + location.outlet.communicationData.phone"
          >
            {{ location.outlet.communicationData.phone }}
          </a>
        </div>
        <div class="info-flex wb-margin-bottom-xs">
          <div class="wb-margin-end-xs">
            <Icon name="contact" :size="13" :width="20" />
          </div>
          <a
            class="wb-type-copy contact-link"
            :href="'mailto:' + location.outlet.communicationData.email"
          >
            {{ location.outlet.communicationData.email }}
          </a>
        </div>
        <div class="info-flex wb-margin-bottom-xs">
          <div class="wb-margin-end-xs">
            <Icon name="language" :size="20" :width="20" />
          </div>
          <a
            class="wb-type-copy contact-link"
            :href="location.outlet.communicationData.url"
          >
            {{ localization?.contactPage?.toWebsite }}
          </a>
        </div>
        <div class="wb-margin-bottom-xs">
          <div>{{ localization?.contactPage?.openings }}</div>
          <div
            v-for="openHour of openingHours"
            :key="openHour.from"
            class="info-flex__opening-hours"
          >
            <div class="wb-margin-end-xs">{{ openHour.from }}</div>
            <div class="add-margin">
              {{ openHour.openingHours }}
            </div>
          </div>
        </div>
        <div class="info-flex distance-wrapper wb-margin-bottom-xs">
          <div class="wb-margin-end-xs">
            <Icon name="location" :size="20" :width="20" />
          </div>
          <div>
            {{
              `${location.distance.toFixed(2)} ${
                localization?.contactPage?.distanceLabel
              }`
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import Icon from "@/components/Icon";
import {
  getFormatedOpeningHours,
  getIntlWeekDay,
} from "@/utilities/openingHours";
import { useStore } from "vuex";

export default {
  name: "Dealer",
  components: { Icon },
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const isoCode = computed(() => store.getters["localization/isoCode"]);
    const localization = computed(
      () => store.getters["localization/localization"]
    );

    const localizationOpeningHours = computed(() => ({
      closed: localization.value.resultPage?.partner?.closed,
      days: {
        mo: getIntlWeekDay("MO", isoCode.value),
        tu: getIntlWeekDay("TU", isoCode.value),
        we: getIntlWeekDay("WE", isoCode.value),
        th: getIntlWeekDay("TH", isoCode.value),
        fr: getIntlWeekDay("FR", isoCode.value),
        sa: getIntlWeekDay("SA", isoCode.value),
        su: getIntlWeekDay("SU", isoCode.value),
      },
      timeLabel: localization.value.timeLabel,
    }));

    const openingHours = computed(() =>
      getFormatedOpeningHours(
        props.location.outlet.openingHours,
        localizationOpeningHours.value
      ).filter(
        (it) => it.openingHours !== localizationOpeningHours.value.closed
      )
    );

    return {
      openingHours,
      localization,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";
.content-wrapper {
  width: 100%;
}

.dealer-wrapper {
  display: flex;
  justify-content: left;
  width: 100%;
  @include breakpoint-from(mq3) {
    width: 400px;
  }
}

.dealer-text {
  max-width: 330px;
}

.icon-wrapper {
  padding-inline-end: 16px;
}

.info-flex {
  display: flex;

  &__opening-hours {
    display: flex;
    justify-content: space-between;
    .add-margin {
      margin: auto 25% auto auto;
    }
  }
}

.contact-link {
  color: var(--wb-white);
  text-decoration: none;
}

.contact-link:hover {
  color: var(--wb-blue-45);
}

.pin.icon-mercedes-pin .path1:before {
  color: var(--wb-white);
}

.pin.icon-mercedes-pin .path2:before {
  color: var(--wb-black);
}

.distance-wrapper {
  color: var(--wb-grey-45);
}
</style>

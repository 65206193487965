import { ref, watch } from "vue";

const useScrollbarEvent = function (propsLocations) {
  const scrollbarRef = ref(null);
  const scrollTop = ref(0);
  const firstYReachEnd = ref(true);

  watch(
    () => propsLocations(),
    () => {
      firstYReachEnd.value = true;
    }
  );

  watch(
    () => scrollbarRef.value,
    (scrollbar) => {
      if (scrollbar) {
        scrollbarRef.value.$el.addEventListener("ps-y-reach-end", (e) => {
          if (firstYReachEnd.value) {
            scrollTop.value = e.target.scrollTop;
            firstYReachEnd.value = false;
          }
          if (e.target.scrollTop >= scrollTop.value) {
            scrollbarRef.value.$el.scrollTop = scrollTop.value;
          }
        });

        // use mutation observer to refactor out setTimeout
        setTimeout(() => {
          scrollbar.update();
        }, 1000);
      }
    }
  );

  return {
    scrollbarRef,
  };
};

export default useScrollbarEvent;

export function filterName(name, localization) {
  if (localization && localization[name]) {
    return localization[name];
  }
  return name;
}

export function parseModelsFromUrl(availableModels, models) {
  if (!availableModels.length || !models) {
    return [];
  }

  const splitModels = models.split(",");
  const parsedModels = new Set();

  availableModels.forEach((availableModel) => {
    splitModels.map((model) => {
      if (model === availableModel.short && !availableModel.disabled) {
        parsedModels.add(availableModel.short);
      }
    });
  });

  return Array.from(parsedModels);
}

export function parseBrandsFromUrl(availableBrands, brands) {
  if (!availableBrands.length || !brands) {
    return [];
  }

  const splitBrands = brands.split(",");
  const attributes = [];

  availableBrands.forEach((avBrand) => {
    splitBrands.forEach((spBrand) => {
      if (avBrand.name === spBrand) {
        attributes.push({
          ...avBrand,
          active: true,
        });
      }
    });
  });

  return attributes;
}

<template>
  <div
    v-if="!isMobile"
    class="teaser"
    :style="{
      backgroundImage: `url(${imageUrl})`,
    }"
  >
    <div class="teaser__content content-width">
      <h1 v-if="content?.title1" class="teaser__titles wb-type-heading-m">
        <span class="teaser__title">{{ content?.title1 }}</span>
        <span class="teaser__title"> {{ content?.title2 }} </span>
      </h1>

      <p class="teaser__subtitle wb-type-copy">{{ content?.subtitle }}</p>
    </div>
  </div>
  <div v-else>
    <div class="teaser__content content-width">
      <h1 v-if="content?.title1" class="teaser__titles wb-type-heading-m">
        <span class="teaser__title">{{ content?.title1 }}</span>
        <span class="teaser__title"> {{ content?.title2 }} </span>
      </h1>

      <p class="teaser__subtitle wb-type-copy">{{ content?.subtitle }}</p>
    </div>
    <div
      class="teaser"
      :style="{
        backgroundImage: `url(${imageUrl})`,
      }"
    />
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  props: ["content"],
  setup() {
    const store = useStore();
    const isMobile = computed(() => store.getters["localization/isMobile"]);

    const preAuthData = computed(
      () => store.getters["marketConfig/preAuthData"]
    );
    const imageName = computed(() => preAuthData.value?.startingPageImage);

    const imageUrl = computed(() => {
      let image = "";
      if (isMobile.value) {
        image = require(`@/assets/images/startingPageImage/mobile/${imageName.value}`);
      } else {
        image = require(`@/assets/images/startingPageImage/desktop/${imageName.value}`);
      }
      return image;
    });

    return {
      imageUrl,
      isMobile,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.teaser {
  position: relative;
  width: 100%;
  min-height: 200px;
  background-position: 90% 0;
  background-repeat: no-repeat;
  background-color: #b1b0b5;
  background-size: cover;
  margin-bottom: 50px;

  @include breakpoint-from(mq2) {
    min-height: 35vh;
  }

  @include breakpoint-from(mq3) {
    min-height: 500px;
    margin-bottom: 0;
  }

  &__content {
    padding: 5% 0 0 15px;
    margin-bottom: 30px;

    @include breakpoint-from(mq3) {
      padding-left: 0;
      margin-bottom: 0px;
    }
  }

  &__titles {
    font-size: 1.625rem;
    text-shadow: 1px 1px 1px rgba(51, 51, 51, 0.2);

    @include breakpoint-from(mq3) {
      font-size: 2.2rem;
      line-height: 2.5rem;
    }

    @include breakpoint-from(mq4) {
      font-size: 2.6rem;
      line-height: 3rem;
    }

    @include breakpoint-from(mq5) {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }

  &__title {
    display: block;
  }

  &__subtitle {
    padding-top: 5px;
    font-size: 0.875rem;
    text-shadow: 1px 1px 1px rgba(51, 51, 51, 0.1);

    @include breakpoint-from(mq2) {
      padding-top: 10px;
    }

    @include breakpoint-from(mq4) {
      font-size: 1rem;
    }

    @include breakpoint-from(mq5) {
      font-size: 1rem;
    }
    @include breakpoint-from(mq6) {
      font-size: 1rem;
    }
  }
}
</style>

import { computed } from "vue";
import { useStore } from "vuex";
import { localizeEquipment } from "@/utilities/vehicle";

const MAIN_FILTER_SEPARATOR = "~";
const SUB_FILTER_SEPARATOR = "_";
const EMPTY_FILTER_VALUE = "none";

const usePageTracking = function () {
  const store = useStore();

  const localization = computed(
    () => store.getters["localization/localization"]
  );

  const language = computed(() => store.getters["localization/activeLanguage"]);
  const generalConfig = computed(() => store.getters["marketConfig/general"]);

  const getBasicPageTracking = (pageName, primaryCategory) => {
    return {
      pageInfo: {
        pageName,
        destinationURL: document.location,
        referringURL: document.referrer,
        appVersion: process.env.VERSION || "0",
        language: language.value?.isoCode,
        geoRegion: generalConfig.value?.countryCode,
      },
      category: {
        primaryCategory,
      },
    };
  };

  const getSearchPageTracking = (pageName, primaryCategory) => {
    const query = computed(() => store.getters["search/query"]);
    const location = computed(() => store.getters["search/location"]);
    const searchType = computed(() => store.getters["search/searchType"]);
    let page = getBasicPageTracking(pageName, primaryCategory);
    page.pageInfo.onsiteSearchTerm = query.value;
    page.pageInfo.onsiteSearchResults = location.value?.locations?.length;
    page.pageInfo.onsiteSearchType = searchType.value;
    return page;
  };

  const getSearchDatePickerPageTracking = (pageName, primaryCategory) => {
    const location = computed(() => store.getters["search/location"]);
    const radius = computed(() => store.getters["search/radius"]);
    const selected = computed(() => store.getters["search/locationSelected"]);
    let page = getBasicPageTracking(pageName, primaryCategory);
    page.pageInfo.onsiteSearchResults = location.value?.locations?.length;
    page.pageInfo.onsiteFilter = {
      km: radius.value,
      contractPartner: selected.value?.size,
    };
    return page;
  };

  const getFilterPageTracking = (pageName, primaryCategory) => {
    const activeFilters = computed(
      () => store.getters["vehicles/activeFilters"]
    );
    const searchType = computed(() => store.getters["search/searchType"]);
    const filtersMap = activeFilters.value;
    let filterInfo = "";

    if (filtersMap.has("price")) {
      filterInfo = addPriceFilter(filterInfo, filtersMap);
    } else {
      filterInfo = addEmptyMainFilter(filterInfo);
    }

    if (filtersMap.has("age")) {
      filterInfo = addAgeFilter(filterInfo, filtersMap);
    } else {
      filterInfo = addEmptyMainFilter(filterInfo);
    }

    if (filtersMap.has("seats")) {
      filterInfo = addSeatFilter(filterInfo, filtersMap);
    } else {
      filterInfo = addEmptyMainFilter(filterInfo);
    }

    if (filtersMap.has("equipment")) {
      filterInfo = addEquipmentFilter(filterInfo, filtersMap);
    } else {
      filterInfo = addEmptyMainFilter(filterInfo);
    }

    if (filtersMap.has("model")) {
      filterInfo = addModelFilter(filterInfo, filtersMap);
    } else {
      filterInfo = addEmptyMainFilter(filterInfo);
      filterInfo = addEmptyMainFilter(filterInfo);
    }

    let page = getBasicPageTracking(pageName, primaryCategory);

    page.pageInfo.onsiteFilter = {
      other: filterInfo,
    };
    page.pageInfo.onsiteSearchType = searchType.value;
    return page;
  };

  const addEmptyMainFilter = (filterInfo) => {
    if (filterInfo.length > 0) {
      filterInfo += MAIN_FILTER_SEPARATOR;
    }
    filterInfo += EMPTY_FILTER_VALUE;
    return filterInfo;
  };

  const addAgeFilter = (filterInfo, filtersMap) => {
    const ageFilter = JSON.parse(JSON.stringify(filtersMap.get("age")));
    filterInfo += MAIN_FILTER_SEPARATOR;
    filterInfo += ageFilter.age.value;
    return filterInfo;
  };

  const addPriceFilter = (filterInfo, filtersMap) => {
    const priceFilter = JSON.parse(JSON.stringify(filtersMap.get("price")));

    filterInfo = filterInfo + priceFilter.min + "-" + priceFilter.max;
    return filterInfo;
  };

  const addSeatFilter = (filterInfo, filtersMap) => {
    const seatsFilter = filtersMap.get("seats");
    filterInfo += MAIN_FILTER_SEPARATOR;
    filterInfo += seatsFilter.seats.join(SUB_FILTER_SEPARATOR);
    return filterInfo;
  };

  const addEquipmentFilter = (filterInfo, filtersMap) => {
    const equipmentsFilter = filtersMap.get("equipment");
    filterInfo += MAIN_FILTER_SEPARATOR;
    filterInfo += equipmentsFilter.equipments
      .map((item) => {
        return localizeEquipment(localization.value, item);
      })
      .join(SUB_FILTER_SEPARATOR);
    return filterInfo;
  };

  const addModelFilter = (filterInfo, filtersMap) => {
    const modelFilter = JSON.parse(JSON.stringify(filtersMap.get("model")));

    if (modelFilter.models && modelFilter.models.length > 0) {
      filterInfo += MAIN_FILTER_SEPARATOR;
      filterInfo += modelFilter.models.join(SUB_FILTER_SEPARATOR);
    } else {
      filterInfo = addEmptyMainFilter(filterInfo);
    }

    if (modelFilter.vehicleTypes && modelFilter.vehicleTypes.length > 0) {
      filterInfo += MAIN_FILTER_SEPARATOR;
      filterInfo += modelFilter.vehicleTypes.join(SUB_FILTER_SEPARATOR);
    } else {
      filterInfo = addEmptyMainFilter(filterInfo);
    }
    return filterInfo;
  };

  return {
    getBasicPageTracking,
    getSearchPageTracking,
    getSearchDatePickerPageTracking,
    getFilterPageTracking,
  };
};

export default usePageTracking;

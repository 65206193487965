import { useStore } from "vuex";
import { computed, watch } from "vue";
import { stepNames } from "@/utilities/checkout";
import {
  createVehicleFromPaymentRedirect,
  createCheckoutFieldsFromPaymentRedirect,
} from "@/utilities/paymentRedirect";

const useGetAndStoreReservation = function (mode = "payment") {
  const store = useStore();
  const reservationDetails = computed(() =>
    mode === "payment"
      ? store.getters["paymentRedirect/reservationDetails"]
      : store.getters["cancellationPage/reservationDetails"]
  );

  watch(
    () => reservationDetails.value,
    () => {
      if (reservationDetails.value) {
        storeReservationDetails();
        store.dispatch("checkout/setFinishedReservationDetails", true);
      }
    }
  );

  function storeReservationDetails() {
    storeVehicle(reservationDetails.value);
    storeLocation(reservationDetails.value.outlet);
    storeDateAndTime(reservationDetails.value.rentalPeriod);
    storePersonalData(reservationDetails.value.customerData);
    storeReservationId(reservationDetails.value.reservationId);
  }

  function storeVehicle(reservation) {
    store.dispatch(
      "vehicles/setSelectedVehicle",
      createVehicleFromPaymentRedirect(reservation)
    );
  }

  function storeLocation(outlet) {
    // we only get one location from the backend but the store action expects an array
    store.dispatch("search/updateLocations", [{ outlet: outlet }]);
    store.dispatch("search/updateSelectedLocations", { outlet: outlet });
  }

  function storeDateAndTime(rentalPeriod) {
    store.dispatch("search/updateDate", {
      from: rentalPeriod.fromDate,
      to: rentalPeriod.toDate,
    });
    store.dispatch("search/updateTimeFrom", rentalPeriod.fromTime);
    store.dispatch("search/updateTimeTo", rentalPeriod.toTime);
  }

  const activeFields = computed(() => store.getters["marketConfig/fields"]);
  function storePersonalData(personalData) {
    personalData = createCheckoutFieldsFromPaymentRedirect(
      personalData,
      JSON.parse(JSON.stringify(activeFields.value))
    );
    storeFormData(personalData, stepNames.personalData);
  }

  function storeFormData(data, stepName) {
    store.dispatch("checkout/setFormData", {
      form: data,
      stepName,
    });
  }

  function storeReservationId(reservationId) {
    store.dispatch("checkout/setReservationId", reservationId);
  }

  const getReservationDetails = (reservationId, email, isoCode, domainId) => {
    const payload = {
      reservationId,
      email,
      isoCode,
      domainId,
    };
    switch (mode) {
      case "payment":
        store.dispatch("paymentRedirect/setPaymentError", false);
        store.dispatch("paymentRedirect/getReservationDetails", payload);
        break;
      case "cancellation":
        store.dispatch("cancellationPage/setCancellationError", false);
        store.dispatch("cancellationPage/getReservationDetails", payload);
        break;
      default:
    }
  };

  return { getReservationDetails };
};

export default useGetAndStoreReservation;

<template>
  <transition name="fast-fade">
    <div v-if="visible" class="confirm-notification content-width">
      <div class="confirm-notification__inner">
        <Icon
          class="confirm-notification__icon"
          name="checkmark-circle"
          :size="40"
          :paths="2"
        />
        <div>
          <div class="confirm-notification__content">
            <h1
              v-if="headline"
              class="confirm-notification__headline wb-type-heading-m"
            >
              {{ headline }}
            </h1>
            <p
              v-if="message"
              class="confirm-notification__text wb-type-copy richtext"
              v-html="message"
            />
          </div>

          <div
            class="confirm-notification__call-to-action"
            v-if="useCallToAction"
          >
            <p
              class="confirm-notification__call-to-action__text wb-type-copy richtext"
              v-html="ctaText"
            />
            <button
              type="button"
              class="wb-button wb-button--primary wb-button--large"
              @click="ctaClicked"
            >
              {{ buttonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from "@/components/Icon";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
  name: "ConfirmNotification",
  props: [
    "headline",
    "message",
    "delay",
    "ctaText",
    "buttonText",
    "useCallToAction",
  ],
  setup(props, { emit }) {
    const visible = ref(false);
    onMounted(() => {
      setTimeout(() => {
        visible.value = true;
      }, props.delay);
    });

    const ctaClicked = () => {
      emit("ctaClicked");
    };
    return {
      ctaClicked,
      visible,
    };
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";
@import "~@workbench/core/dist/scss/utility/_grid";

.confirm-notification {
  width: 100%;
  background: var(--wb-grey-20);
  margin-top: 0 !important;

  &__inner {
    max-width: $wb-content-grid-max-width;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    display: flex;
    align-items: flex-start;

    @include breakpoint-from(mq3) {
      width: 92%;
      margin: auto;
    }
  }

  &__call-to-action {
    max-width: $wb-content-grid-max-width;
    padding-left: 20px;

    @include breakpoint-from(mq3) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-left: 70px;
    }

    &__text {
      color: var(--wb-grey-60);
      padding-right: 40px;

      @include breakpoint-to(mq2) {
        padding-bottom: 20px;
      }
    }
  }

  &__icon {
    .path1:before {
      color: var(--wb-green-45) !important;
    }
    .path2:before {
      color: var(--wb-white) !important;
    }
  }

  &__content {
    margin-top: -10px;
    padding-left: 20px;

    @include breakpoint-from(mq3) {
      padding-left: 30px;
    }
  }

  &__headline {
    padding-bottom: 10px;
  }

  &__text {
    color: var(--wb-grey-60);
  }
}
</style>

<template>
  <GoogleMap
    ref="mapRef"
    :api-key="mapConfig.apiKey"
    :style="mapStyle"
    :center="center"
    :zoom="zoom"
    draggable
    zoomControl
    mapTypeControl
    :language="activeLanguage.mapsLanguage"
  >
    <Marker
      v-for="markerOptions in rentLocationMarkersOptions"
      :key="markerOptions.id"
      :options="markerOptions"
      @click="markerClick"
    />
  </GoogleMap>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useStore } from "vuex";
import { GoogleMap, Marker } from "vue3-google-map";

export default defineComponent({
  components: { GoogleMap, Marker },
  props: {
    locations: Object,
    selection: Object,
    zoom: Number,
    mapConfig: Object,
    mapWidth: Number,
    mapHeight: Number,
    isDesktop: {
      type: Boolean,
      default: true,
    },
    isPartnerView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const activeLanguage = computed(
      () => store.getters["localization/activeLanguage"]
    );
    const rentLocationMarkersOptions = ref([]);
    const center = ref();
    const mapStyle = ref("width: 100%; height: 100%");

    const updateMarkerOptions = () => {
      let isFirst = true;
      rentLocationMarkersOptions.value.length = 0;
      props.locations.forEach((location) => {
        if (isFirst) {
          center.value = createPosition(location);
          isFirst = false;
        }
        const currPosition = createPosition(location);
        const markerOptions = {
          position: currPosition,
          icon: createMarkerIcon(isLocationSelected(location)),
          id: location?.outlet?.outletId,
          title: createMarkerTitle(location),
        };
        rentLocationMarkersOptions.value.push(markerOptions);
      });
    };

    const createMarkerIcon = (active = false) => {
      const type = active ? "selected" : "default";
      return {
        url: require(`@/assets/images/location/pin-${type}.svg`),
        scaledSize: {
          height: 50,
          width: 50,
        },
      };
    };

    const markerClick = (eventSource) => {
      const lat = eventSource.latLng.lat();
      const lng = eventSource.latLng.lng();

      const clickedLocation = props.locations
        .filter(
          (location) =>
            location?.outlet.lat === lat && location?.outlet.lng === lng
        )
        .at(0);
      props.selection?.toggle(clickedLocation);
      updateMarkerOptions();
    };

    onBeforeMount(() => {
      updateMarkerOptions();
      resizeMap();
    });

    watch(
      () => [props.selection?.selected.value, props.locations],
      ([curSelection, curLocations]) => {
        // remove all markers on change to re-init markers
        if (curSelection || curLocations) {
          updateMarkerOptions();
        }
      }
    );

    watch(
      () => [props.mapWidth, props.mapHeight],
      () => {
        resizeMap();
      }
    );

    const resizeMap = () => {
      if (props.isPartnerView) {
        mapStyle.value = `width: ${props.mapWidth}px; height:  ${props.mapWidth}px;`;
      }
    };

    const isLocationSelected = (location) => {
      return props.selection.selected.value.has(location);
    };

    const createPosition = (location) => {
      return {
        lat: location?.outlet?.lat,
        lng: location?.outlet?.lng,
      };
    };

    const createMarkerTitle = (location) => {
      return activeLanguage.value?.useOriginalOutletAddress
        ? `${location?.outlet?.name}, ${location?.outlet?.originalAddress?.street}`
        : `${location?.outlet?.name}, ${location?.outlet?.latinAddress?.street}`;
    };

    return {
      center,
      rentLocationMarkersOptions,
      activeLanguage,
      markerClick,
      mapStyle,
    };
  },
});
</script>

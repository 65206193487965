<template>
  <div
    class="additional-services__drop-down-collection"
    v-for="item in service.additionalServices"
    :key="item.serviceName"
  >
    <DropDown
      v-if="service.displayType === 'DROPDOWN'"
      :service="item"
      :isoCode="isoCode"
      :currencyCode="currencyCode"
      :numberOfDays="numberOfDays"
      :pricingDetails="pricingDetails"
      :localization="localization"
      @quantityChanged="serviceChanged"
    />
  </div>
</template>
<script>
import DropDown from "@/components/AdditionalServices/FieldTypes/DropDown.vue";

export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
    currencyCode: {
      type: String,
      required: true,
    },
    isoCode: {
      type: String,
      required: true,
    },
    numberOfDays: {
      type: Number,
      required: true,
    },
    pricingDetails: {
      type: Object,
      required: true,
    },
    localization: {
      type: Object,
      required: true,
    },
  },
  emits: ["selectedItemsChanged"],
  setup(props, { emit }) {
    const serviceObject = {};
    const serviceChanged = (service, quantity) => {
      if (quantity > 0) {
        serviceObject[service.serviceName] = {
          service: service,
          quantity: quantity,
        };
      } else {
        delete serviceObject[service.serviceName];
      }
      emit(
        "selectedItemsChanged",
        props["service"].categoryName,
        serviceObject
      );
    };

    return { serviceChanged };
  },
  components: { DropDown },
};
</script>
<style lang="scss" scoped>
.additional-services__drop-down-collection {
  padding-bottom: 10px;
}
</style>

export const stepNames = {
  personalData: "PersonalData",
  additionalFields: "AdditionalFields",
  rentalData: "RentalData",
  summary: "Summary",
};

export const stepDefault = [
  {
    number: 1,
    name: stepNames.personalData,
  },
  {
    number: 2,
    name: stepNames.rentalData,
  },
  {
    number: 3,
    name: stepNames.summary,
  },
];

export const checkoutFields = {
  profileType: "PROFILE_TYPE",
  company: "COMPANY",
  taxNumber: "TAX_NUMBER",
  salutation: "SALUTATION",
  gender: "GENDER",
  firstName: "FIRST_NAME",
  firstNameAdd: "FIRST_NAME_ADD",
  lastName: "LAST_NAME",
  lastNameAdd: "LAST_NAME_ADD",
  passportNumber: "PASSPORT_NUMBER",
  email: "EMAIL",
  phoneNumber: "PHONE_NUMBER",
  street: "STREET",
  houseNumber: "HOUSE_NUMBER",
  postCode: "POSTCODE",
  city: "CITY",
  reasonForRent: "REASON_FOR_RENT",
  promotionCode: "PROMOTION_CODE",
  specialWishes: "ADDITIONAL_REQUESTS_TEXTFIELD",
  drivingLicenceNumber: "DRIVING_LICENCE_NUMBER",
  minimumAge: "MINIMUM_AGE",
  reservationNumber: "RESERVATION_NUMBER",
  terms: "TERMS",
  promotionConsent: "PROMOTION_CONSENT",
  promotionConsentEmail: "PROMOTION_CONSENT_EMAIL",
  promotionConsentPhone: "PROMOTION_CONSENT_PHONE",
  promotionConsentSMS: "PROMOTION_CONSENT_SMS",
  promotionConsentPost: "PROMOTION_CONSENT_POST",
  consentPrivacyData: "CONSENT_PRIVACY_DATA",
  birthday: "BIRTHDAY",
  countryOfResidence: "COUNTRY_OF_RESIDENCE",
};

export const personalPossibleFields = [
  checkoutFields.profileType,
  checkoutFields.company,
  checkoutFields.taxNumber,
  checkoutFields.salutation,
  checkoutFields.gender,
  checkoutFields.firstName,
  checkoutFields.lastName,
  checkoutFields.email,
  checkoutFields.phoneNumber,
  checkoutFields.street,
  checkoutFields.houseNumber,
  checkoutFields.postCode,
  checkoutFields.city,
  checkoutFields.firstNameAdd,
  checkoutFields.lastNameAdd,
  checkoutFields.consentPrivacyData,
  checkoutFields.birthday,
  checkoutFields.countryOfResidence,
];

export const additionalFieldsPossibleFields = [
  checkoutFields.drivingLicenceNumber,
  checkoutFields.minimumAge,
  checkoutFields.passportNumber,
];

export const rentalPossibleFields = [
  checkoutFields.drivingLicenceNumber,
  checkoutFields.minimumAge,
  checkoutFields.passportNumber,
  checkoutFields.reasonForRent,
  checkoutFields.promotionCode,
  checkoutFields.specialWishes,
  checkoutFields.terms,
  checkoutFields.promotionConsent,
  checkoutFields.promotionConsentEmail,
  checkoutFields.promotionConsentPhone,
  checkoutFields.promotionConsentSMS,
  checkoutFields.promotionConsentPost,
];

const getRegexForField = (type, localizationFields) => {
  if (localizationFields) {
    return localizationFields[type];
  }
  return {};
};

export const extractFieldsForStep = (
  possibleFields,
  fields,
  localizationFields,
  activeField,
  voucherEnabled
) => {
  return fields.filter((field) => {
    if (field[activeField] && possibleFields.includes(field.type)) {
      field.value = null;
      const fieldRegex = getRegexForField(field.type, localizationFields);
      field.regex = fieldRegex?.regex ? new RegExp(fieldRegex?.regex) : null;
      field.regexValidationMessage = fieldRegex?.regexValidationMessage ?? "";
      field.error = null;

      switch (field.type) {
        case checkoutFields.salutation:
        case checkoutFields.reasonForRent:
        case checkoutFields.profileType:
          field.formType = "radio";
          break;
        case checkoutFields.terms:
        case checkoutFields.minimumAge:
        case checkoutFields.promotionConsent:
        case checkoutFields.promotionConsentEmail:
        case checkoutFields.promotionConsentPhone:
        case checkoutFields.promotionConsentSMS:
        case checkoutFields.promotionConsentPost:
        case checkoutFields.consentPrivacyData:
          field.formType = "checkbox";
          break;
        case checkoutFields.specialWishes:
          field.formType = "textarea";
          break;
        case checkoutFields.gender:
        case checkoutFields.countryOfResidence:
          field.formType = "select";
          break;
        case checkoutFields.promotionCode:
          if (voucherEnabled) {
            field.formType = "voucher";
          } else {
            field.formType = "input";
          }
          break;
        case checkoutFields.birthday:
          field.formType = "date";
          break;
        default:
          field.formType = "input";
          break;
      }

      // delete not needed keys
      delete field.inquiryActive;
      delete field.checkoutActive;
      return field;
    }

    return null;
  });
};

export const hasFieldsForStep = (possibleFields, fields, activeField) => {
  var filteredFields = fields.filter((field) => {
    return field[activeField] && possibleFields.includes(field.type);
  });

  return filteredFields.length > 0;
};

export const contactDataString = (personalData, localizationFields) => {
  const profileType = personalData.findIndex(
    (data) => data.type === checkoutFields.profileType
  );
  const company = personalData.findIndex(
    (data) => data.type === checkoutFields.company
  );
  const taxNumber = personalData.findIndex(
    (data) => data.type === checkoutFields.taxNumber
  );
  const salutation = personalData.findIndex(
    (data) => data.type === checkoutFields.salutation
  );
  const firstName = personalData.findIndex(
    (data) => data.type === checkoutFields.firstName
  );
  const lastName = personalData.findIndex(
    (data) => data.type === checkoutFields.lastName
  );
  const firstNameAdd = personalData.findIndex(
    (data) => data.type === checkoutFields.firstNameAdd
  );
  const lastNameAdd = personalData.findIndex(
    (data) => data.type === checkoutFields.lastNameAdd
  );
  const email = personalData.findIndex(
    (data) => data.type === checkoutFields.email
  );
  const phone = personalData.findIndex(
    (data) => data.type === checkoutFields.phoneNumber
  );
  const birthday = personalData.findIndex(
    (data) => data.type === checkoutFields.birthday
  );

  let contactData = "";
  if (profileType !== -1) {
    const profileTypeValues =
      localizationFields[checkoutFields.profileType]?.valuesMap;

    const selectedProfileTypeLocalization =
      profileTypeValues[personalData[profileType]?.value];

    contactData = `${
      localizationFields[checkoutFields.profileType]?.label
    }: ${selectedProfileTypeLocalization}<br />`;
  }
  if (
    company !== -1 &&
    personalData?.[company]?.value &&
    profileType !== -1 &&
    personalData[profileType].value === "PROFILE_TYPE_VALUE_BUSINESS"
  ) {
    contactData = `${contactData} ${personalData[company]?.value}<br />`;
  }
  if (
    taxNumber !== -1 &&
    profileType !== -1 &&
    personalData[profileType].value === "PROFILE_TYPE_VALUE_BUSINESS"
  ) {
    contactData = `${contactData} ${
      localizationFields[checkoutFields.taxNumber].label
    }: ${personalData[taxNumber]?.value}<br />`;
  }
  const salutationData =
    localizationFields[checkoutFields.salutation]?.valuesMap;
  if (salutation !== -1 && personalData[salutation]?.value !== "") {
    if (salutationData[personalData[salutation]?.value]) {
      contactData = `${contactData} ${
        salutationData[personalData[salutation]?.value]
      } <br />`;
    } else {
      contactData = `${contactData} ${personalData[salutation]?.value} <br />`;
    }
  }
  if (firstName !== -1 && lastName !== -1) {
    contactData = `${contactData} ${personalData[firstName]?.value} ${personalData[lastName]?.value}<br />`;
  }
  if (email !== -1) {
    contactData = `${contactData} ${personalData[email]?.value}<br />`;
  }
  if (phone !== -1) {
    contactData = `${contactData} ${personalData[phone]?.value}`;
  }
  if (firstNameAdd !== -1) {
    contactData = `${contactData}<br /> ${personalData[firstNameAdd]?.value}`;
  }
  if (lastNameAdd !== -1) {
    contactData = `${contactData}<br /> ${personalData[lastNameAdd]?.value}`;
  }
  if (birthday !== -1) {
    contactData = `${contactData}<br /> ${personalData[birthday]?.value}`;
  }
  return contactData;
};

export const addressDataString = (personalData) => {
  const street = personalData.findIndex(
    (data) => data.type === checkoutFields.street
  );
  const number = personalData.findIndex(
    (data) => data.type === checkoutFields.houseNumber
  );
  const postCode = personalData.findIndex(
    (data) => data.type === checkoutFields.postCode
  );
  const city = personalData.findIndex(
    (data) => data.type === checkoutFields.city
  );
  const countryOfResidence = personalData.findIndex(
    (data) => data.type === checkoutFields.countryOfResidence
  );

  let addressData = `${personalData[street]?.value} ${personalData[number]?.value}
          <br />${personalData[postCode]?.value} ${personalData[city]?.value}`;

  return addressData;
};

export const reasonForRentString = (rentalData, localizationFields) => {
  const reasonIndex = rentalData.findIndex(
    (data) => data.type === checkoutFields.reasonForRent
  );
  const reasonField = localizationFields[rentalData[reasonIndex]?.type];
  return reasonField?.valuesMap[rentalData[reasonIndex]?.value];
};

export const getLabelIndex = (localizationFields, fieldType) => {
  localizationFields.findIndex((data) => data.type === fieldType);
};

import { computed, ref, reactive, watch } from "vue";
import { useStore } from "vuex";

const useTimeValidation = function () {
  const store = useStore();
  const isOpen = ref(false);
  const mode = ref(null);
  const timeFrom = ref(null);
  const timeTo = ref(null);
  const timeFromRef = ref(null);
  const timeToRef = ref(null);
  const success = reactive({
    from: null,
    to: null,
  });
  const selectedTime = computed(() => store.getters["search/selectedTime"]);

  // watch storeTime to clear timeFrom/timeTo if store is cleared
  watch(
    () => selectedTime.value,
    (time) => {
      if (time?.from) {
        timeFrom.value = time?.from;
        success.from = true;
      } else {
        timeFrom.value = null;
        success.from = null;
      }
      if (time?.to) {
        timeTo.value = time?.to;
        success.to = true;
      } else {
        timeTo.value = null;
        success.to = null;
      }
      timeTo.value = time?.to ?? null;
    },
    {
      immediate: true,
    }
  );

  // if from/to same day, and from time is updated, reset to time
  const selectedDate = computed(() => store.getters["search/selectedDate"]);
  watch(
    () => selectedTime.value?.from,
    (from) => {
      if (from && selectedDate.value?.from === selectedDate.value?.to) {
        store.dispatch("search/updateTimeTo", "");
      }
    }
  );

  const timeSelected = ({ time, mode }) => {
    if (mode === 1) {
      success.from = true;
      store.dispatch("search/updateTimeFrom", time);
      timeFrom.value = time;
      timeToRef.value.focus();
    } else if (mode === 2) {
      success.to = true;
      store.dispatch("search/updateTimeTo", time);
      timeTo.value = time;
      isOpen.value = false;
    }
  };

  const focus = (ref) => {
    // there is sometimes a race condition between closeOverlay and focus
    // to fix this, run focus code a bit later
    setTimeout(() => {
      mode.value = ref;
      isOpen.value = true;
    }, 1);
  };

  const closeOverlay = () => {
    isOpen.value = false;
  };

  return {
    isOpen,
    mode,
    timeFrom,
    timeTo,
    timeSelected,
    focus,
    timeFromRef,
    timeToRef,
    closeOverlay,
    success,
  };
};

export default useTimeValidation;

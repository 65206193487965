<template>
  <div class="error-message wb-type-copy">
    <div class="error-message__outer">
      <div class="error-message__inner">
        <Icon
          class="error-message__icon"
          name="alert-circle"
          :size="30"
          :paths="2"
        />
        <p class="error-message__text">{{ message }}</p>
      </div>

      <div class="error-message__inner">
        <button
          v-if="homeButton && buttonText"
          type="button"
          class="error-message__button wb-button wb-button--primary wb-button--medium"
          @click="buttonClicked"
        >
          <Icon name="chevron-left" :size="12" :width="18" :height="12" />
          {{ buttonText }}
        </button>
        <Icon
          class="error-message__icon"
          name="close"
          :size="25"
          :paths="1"
          @click="closeClicked()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import { useRouter } from "vue-router";

export default {
  name: "ErrorMessage",
  props: ["message", "homeButton", "buttonText"],
  setup(props, { emit }) {
    const router = useRouter();
    const closeClicked = () => {
      router.push({
        name: "home",
      });
    };
    const buttonClicked = () => {
      emit("buttonClicked");
    };
    return { closeClicked, buttonClicked };
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss">
.error-message {
  position: fixed;
  top: 0;
  z-index: 500;
  left: 0;
  width: 100%;
  background: var(--wb-red-45);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);

  &__outer {
    padding: 25px;
    display: flex;
    justify-content: space-between;
  }

  &__inner {
    padding: 25px;
    color: var(--wb-white);
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 70%;
  }

  &__icon {
    .path1:before {
      color: var(--wb-white);
    }

    .path2:before {
      color: var(--wb-grey-20);
    }
  }

  &__text {
    padding-left: 20px;
  }

  &__button {
    margin-right: 30px;
  }
}
</style>

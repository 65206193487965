import { onMounted, onBeforeUnmount, ref } from "vue";
import { ViewportObserver } from "@workbench/core";

const useStickyButtons = function (initOnMount = true) {
  const scrolling = ref(true);
  const scrollObserver = ref(null);

  const setScrollObserver = () => {
    const element = document.querySelector("footer");
    if (element) {
      scrollObserver.value = new ViewportObserver({
        element,
        viewportEnterFn: () => {
          scrolling.value = false;
        },
        viewportLeaveFn: () => {
          scrolling.value = true;
        },
      });
    }
  };

  const resetScrollObserver = () => {
    scrolling.value = false;

    if (scrollObserver.value) {
      scrollObserver.value.disconnect();
    }
  };

  onMounted(() => {
    if (initOnMount) {
      setScrollObserver();
    } else {
      resetScrollObserver();
    }
  });

  onBeforeUnmount(() => {
    resetScrollObserver();
  });

  return {
    scrolling,
    setScrollObserver,
    resetScrollObserver,
  };
};

export default useStickyButtons;

import ApiService, { OBP_ACCESS_TOKEN } from "@/services/ApiService";

// initial state
const configState = () => ({
  marketConfig: {},
  preAuthData: {},
  accessToken: null,
  mockMode: false,
  basicAuthRequired: false,
  trackingScriptLoaded: false,
});

// mutations
const mutations = {
  SET_PRE_AUTH_DATA(state, data) {
    state.preAuthData = data;
  },
  SET_MARKET_CONFIG(state, marketConfig) {
    state.marketConfig = marketConfig;
  },
  SET_ACCESS_TOKEN(state, token) {
    state.accessToken = token;
  },
  TOGGLE_MOCK_MODE(state, mockMode) {
    state.mockMode = mockMode;
  },
  SET_BASIC_AUTH(state, basicAuth) {
    state.basicAuthRequired = basicAuth;
  },
  SET_TRACKING_SCRIPT_LOADED(state, loaded) {
    state.trackingScriptLoaded = loaded;
  },
};

// actions
const actions = {
  async getPreAuthData({ state, commit }) {
    try {
      const preAuthData = state.mockMode
        ? await ApiService.getMockPreAuthData()
        : await ApiService.getPreAuthData();

      if (preAuthData.status === 200) {
        commit("SET_PRE_AUTH_DATA", preAuthData.data);
      }
    } catch (error) {
      //when a 401 happens here, BASIC auth is activated on the REST API
      if (error.message.includes("401")) {
        commit("SET_BASIC_AUTH", true);
      }
    }
  },
  async getMarketConfig({ state, commit, dispatch }, domainId) {
    try {
      const getMarketConfig = state.mockMode
        ? await ApiService.getMockConfig(domainId)
        : await ApiService.getConfig(domainId);

      if (getMarketConfig.status === 200) {
        const marketConfig = getMarketConfig.data;
        commit("SET_MARKET_CONFIG", marketConfig);
      }
    } catch (error) {
      // retry if unauthorized, maybe token is outdated
      if (error.message.includes("401")) {
        sessionStorage.removeItem(OBP_ACCESS_TOKEN);
        dispatch("getPreAuthData");
      }
    }
  },
  async getAccessToken({ commit }, code) {
    try {
      const request = await ApiService.getAccessToken(code);

      if (request.status === 200) {
        const token = request.data;
        commit("SET_ACCESS_TOKEN", token);
      }
    } catch (e) {
      commit("SET_ACCESS_TOKEN", null);
    }
  },
  toggleMockMode({ commit }, mockMode) {
    commit("TOGGLE_MOCK_MODE", mockMode);
  },
  setBasicAuthRequired({ commit }, required) {
    commit("SET_BASIC_AUTH", required);
  },
  trackingScriptLoaded({ commit }, loaded) {
    commit("SET_TRACKING_SCRIPT_LOADED", loaded);
  },
};

// getters
const getters = {
  useVoucher: (state) => {
    return state.marketConfig?.general?.voucherEnabled;
  },
  useNewAboutUs: (state) => {
    return state.marketConfig?.general?.newAboutUs;
  },
  contentPages: (state) => {
    return state.marketConfig?.general?.contentPages;
  },
  basicAuthRequired: (state) => {
    return state.basicAuthRequired;
  },
  preAuthData: (state) => {
    return state.preAuthData;
  },
  config: (state) => {
    return state.marketConfig;
  },
  defaultLanguagePreAuthData: (state) => {
    const defaultLanguagePreAuthData =
      state.preAuthData?.languageDataPreAuthData?.find(
        (preAuthData) => preAuthData.language.default
      );
    if (defaultLanguagePreAuthData) {
      return defaultLanguagePreAuthData;
    }
    return state.preAuthData?.languageDataPreAuthData?.[0];
  },
  authenticationRequired: (state) => {
    return state.preAuthData?.authenticationRequired;
  },
  maintenancePage: (state) => {
    return state.preAuthData?.maintenancePage;
  },
  accessToken: (state) => {
    return state.accessToken;
  },
  general: (state) => {
    return state.marketConfig?.general;
  },
  radius: (state) => {
    return state.marketConfig?.general?.radius;
  },
  radiusMin: (state) => {
    return state.marketConfig?.general?.radiusMin;
  },
  radiusMax: (state) => {
    return state.marketConfig?.general?.radiusMax;
  },
  radiusStepping: (state) => {
    return state.marketConfig?.general?.radiusStepping;
  },
  mapConfig: (state) => {
    return state.marketConfig?.general?.mapConfig;
  },
  captcha: (state) => {
    return state.marketConfig?.general?.captcha;
  },
  serviceHotline: (state) => {
    return state.marketConfig?.general?.serviceHotline;
  },
  holidays: (state) => {
    return state.marketConfig?.holidays;
  },
  languages: (state) => {
    return state.marketConfig?.languages;
  },
  defaultLanguage: (state) => {
    const defaultLanguage = state.marketConfig?.languages?.find(
      (language) => language.default
    );
    if (defaultLanguage) {
      return defaultLanguage;
    }
    return state.marketConfig?.languages?.[0];
  },
  blockedMinutesOpening: (state) => {
    return state.marketConfig?.general?.blockedMinutesOpening;
  },
  blockedMinutesClosing: (state) => {
    return state.marketConfig?.general?.blockedMinutesClosing;
  },
  activeFilters: (state) => {
    const activeFilters = Object.entries(state.marketConfig?.filter).filter(
      (item) => {
        if (item[0] === "equipment") {
          let hasActiveEquipmentFilter = false;
          Object.entries(item[1]).forEach((equipment) => {
            if (equipment[1]) {
              hasActiveEquipmentFilter = true;
            }
          });
          return hasActiveEquipmentFilter;
        }
        return item[1];
      }
    );
    return sortFilters(activeFilters.map((filters) => filters[0]));
  },
  activeEquipmentsFilters: (state) => {
    const activeFilters = Object.entries(
      state.marketConfig?.filter?.equipment
    ).filter((equipment) => equipment[1]);

    return activeFilters.map((filters) => filters[0]);
  },
  domainId: (state) => {
    return state.preAuthData?.domainId;
  },
  mockMode: (state) => {
    return state.mockMode;
  },
  checkout: (state) => {
    return state.marketConfig?.checkout;
  },
  additionalServices: (state) => {
    return state.marketConfig?.general?.additionalServices;
  },
  fields: (state) => {
    return state.marketConfig?.checkout?.fields;
  },
  trackingScriptUrl: (state) => {
    return state.marketConfig?.general?.trackingScriptUrl;
  },
  trackingScriptLoaded: (state) => {
    return state.trackingScriptLoaded;
  },
  inquiryMode: (state) => {
    return state.marketConfig?.general?.inquiryMode;
  },
  mainPageBigContentBoxActive: (state) => {
    return state.marketConfig?.general?.mainPageBigContentBoxActive;
  },
  conditionsPromotionActive: (state) => {
    return state.marketConfig?.general?.conditionsPromotionActive;
  },
  modelSelection: (state) => {
    return state.marketConfig?.general?.modelSelection;
  },
  cookieNoteActive: (state) => {
    return state.marketConfig?.general?.cookieNoteActive;
  },
  dsaEnabled: (state) => {
    return state.marketConfig?.general?.dsaEnabled;
  },
  dsaReportEmail: (state) => {
    return state.marketConfig?.general?.dsaReportEmail;
  },
  eclEnabled: (state) => {
    return state.marketConfig?.general?.eclEnabled;
  },
  eclDiagnosisEnabled: (state) => {
    return state.marketConfig?.general?.eclDiagnosisEnabled;
  },
  requestModeLongTerm: (state) => {
    return state.marketConfig?.general?.requestModeLongTerm;
  },
  requestModeLongTermFrom: (state) => {
    return state.marketConfig?.general?.requestModeLongTermFrom;
  },
  hourlyPricing: (state) => {
    return state.marketConfig?.general?.hourlyPricing;
  },
  oneDocActive: (state) => {
    return state.marketConfig?.general?.oneDocActive;
  },
  singleSelectionDealer: (state) => {
    if (state.marketConfig?.general?.singleSelectionDealer) {
      return state.marketConfig?.general?.singleSelectionDealer;
    }

    return false;
  },
  showDealerSelectAll: (state) => {
    if (state.marketConfig?.general?.singleSelectionDealer) {
      return !state.marketConfig?.general?.singleSelectionDealer;
    }

    return true;
  },
};

function sortFilters(filters) {
  const sortedFilters = [];

  if (filters.includes("model")) {
    sortedFilters.push("model");
  }
  if (filters.includes("price")) {
    sortedFilters.push("price");
  }
  if (filters.includes("age")) {
    sortedFilters.push("age");
  }
  if (filters.includes("equipment")) {
    sortedFilters.push("equipment");
  }
  if (filters.includes("seats")) {
    sortedFilters.push("seats");
  }

  return sortedFilters;
}

function makeState(override) {
  return { ...configState(), ...override };
}

export function createMarketConfigModule(override) {
  return {
    namespaced: true,
    state: makeState(override),
    getters,
    actions,
    mutations,
  };
}

export default {
  namespaced: true,
  state: configState,
  getters,
  actions,
  mutations,
};

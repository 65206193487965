<template>
  <div
    class="model-offer wb-slider__item wb-grid-col-mq1-12 wb-grid-col-mq3-6 wb-grid-col-mq4-4"
    :class="{ 'model-offer__suggestion-space': isSuggestionsOverlapping() }"
  >
    <div class="model-offer__inner">
      <div class="model-offer__top">
        <h3 class="model-offer__class wb-type-heading-m">
          {{ modelType?.modelClassName }}
        </h3>
        <h4 class="model-offer__type wb-type-copy-strong">
          {{ modelType?.vehicleTypeSingular }}
        </h4>
      </div>
      <div class="model-offer__center">
        <div class="model-offer__image">
          <img
            v-lazyload
            :data-url="getModelTypeImagePath"
            :alt="modelType?.modelClassName"
          />
        </div>
        <div v-if="isActive" class="model-offer__text">
          <h3 class="wb-type-heading-m model-offer__search-heading">
            {{ localization.mainPage?.modelSelection?.pickupAndReturn }}
          </h3>
          <SearchInput
            :localization="localization.mainPage.search"
            :themeStyle="'bright'"
            class="model-offer__input"
            @onSuggestSelect="getAndSetLocations($event)"
          />
          <Datepicker
            :disabled="datePickerDisabled"
            :localization="localization.mainPage.search"
            :themeStyle="'bright'"
            @calendarEnter="calendarLeft = false"
            @calendarLeave="calendarLeft = true"
            class="model-offer__input"
          />
          <Timepicker
            :disabled="timePickerDisabled"
            :localization="localization.mainPage.search"
            :calendarLeft="calendarLeft"
            :themeStyle="'bright'"
          />
          <button
            type="submit"
            class="model-offer__submit wb-button wb-button--primary wb-button--medium"
            @click="sendRequest"
            :disabled="formDisabled"
          >
            <Icon name="chevron-right" :size="12" :width="18" :height="12" />
            {{ localization.mainPage?.modelSelection?.searchButton }}
          </button>
        </div>
        <div v-else>
          <button
            type="submit"
            class="model-offer__expand wb-button wb-button--primary wb-button--medium"
            @click="toggle"
          >
            <Icon name="chevron-right" :size="12" :width="18" :height="12" />
            {{ localization.mainPage?.modelSelection?.moreInfos }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import SearchInput from "@/components/Search/SearchInput";
import Datepicker from "@/components/Datepicker/Datepicker";
import Timepicker from "@/components/Timepicker/Timepicker";
import { computed, ref, watch } from "vue";
import useImage from "@/compositions/useImage";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { scrollToTop } from "@/utilities/scroll";

export default {
  name: "ModelOffer",
  props: ["modelType", "localization", "active"],

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const { getModelImageUrl } = useImage();
    const getModelTypeImagePath = computed(() =>
      getModelImageUrl(props.modelType.modelTypeId, "SMALL")
    );
    const isActive = ref(props.active);

    const toggle = () => {
      emit("toggle", props.modelType.modelTypeId);
    };

    const domainId = computed(() => store.getters["localization/domainId"]);
    const isoCode = computed(() => store.getters["localization/isoCode"]);
    const selectedPlace = computed(() => store.getters["search/query"]);
    const selectedDate = computed(() => store.getters["search/selectedDate"]);
    const selectedTime = computed(() => store.getters["search/selectedTime"]);
    const formDisabled = computed(() => {
      return !(
        selectedDate.value?.from &&
        selectedDate.value?.to &&
        selectedTime.value?.from &&
        selectedTime.value?.to &&
        domainId.value &&
        isoCode.value
      );
    });

    const suggestions = computed(() => store.getters["search/suggestion"]);
    const isSuggestionsOverlapping = () => {
      return (
        suggestions.value.length >= 5 &&
        computed(() => store.getters["search/suggestionOpen"]).value &&
        props.active
      );
    };

    const getAndSetLocations = async (query) => {
      const configRadius = computed(() => store.getters["marketConfig/radius"]);
      const radius = computed(() => store.getters["search/radius"]);
      const locations = computed(() => store.getters["search/locations"]);

      await store.dispatch("search/getLocation", {
        query,
        radius: radius.value ?? configRadius.value,
        domainId: domainId.value,
      });
      await store.dispatch(
        "search/addMultipleLocationSelection",
        locations.value
      );
    };

    const sendRequest = () => {
      const currentPlace = JSON.parse(JSON.stringify(selectedPlace.value));
      const currentDate = JSON.parse(JSON.stringify(selectedDate.value));
      const currentTime = JSON.parse(JSON.stringify(selectedTime.value));
      const currentModelType = JSON.parse(JSON.stringify(props.modelType));
      const request = createModelSelectionRequest(
        currentPlace,
        currentDate,
        currentTime,
        currentModelType
      );
      store.dispatch("vehicles/clearVehicles");
      store.dispatch("vehicles/getModelSelectionVehicles", {
        request: request,
        domainId: domainId.value,
        isoCode: isoCode.value,
      });
      store.dispatch("search/setSearchType", "model");
      store.dispatch("vehicles/resetFilters");
      router.push({ name: "modelSelectionResult" });
      scrollToTop();
    };

    const datePickerDisabled = ref(true);
    const locationSelected = computed(
      () => store.getters["search/locationSelected"]
    );
    watch(
      () => locationSelected.value,
      (location) => {
        datePickerDisabled.value = !!!location.size;
      },
      {
        immediate: true,
      }
    );

    watch(
      () => props.active,
      () => {
        isActive.value = props.active;
      }
    );

    const createModelSelectionRequest = (place, date, time, modelType) => {
      let request;
      if (place.length === 0) {
        return null;
      } else {
        request = {
          rentalPeriod: {
            fromDate: date.from,
            fromTime: time.from,
            toDate: date.to,
            toTime: time.to,
          },
          modelTypeId: modelType.modelTypeId,
          locationName: place,
        };
        return request;
      }
    };

    const timePickerDisabled = computed(() => {
      return !!!(
        !datePickerDisabled.value &&
        selectedDate.value?.from &&
        selectedDate.value?.to
      );
    });

    return {
      Icon,
      getModelTypeImagePath,
      toggle,
      sendRequest,
      isActive,
      calendarLeft: ref(false),
      datePickerDisabled,
      timePickerDisabled,
      isSuggestionsOverlapping,
      formDisabled,
      getAndSetLocations,
    };
  },

  components: {
    Icon,
    SearchInput,
    Datepicker,
    Timepicker,
  },
};
</script>

<style lang="scss" scoped="true">
@import "@/styles/components/modelOffer";
</style>

import store from "@/store";
import { computed, onMounted, onUnmounted } from "vue";

const useLockScroll = function (element) {
  const isMobile = computed(() => store.getters["localization/isMobile"]);

  const lockScroll = () => {
    const padding = isMobile.value ? 90 : 200;
    const lockContainer = element;
    if (lockContainer) {
      document.body.classList.add("scroll-lock");
      document.body.style.top = `-${
        lockContainer.getBoundingClientRect().top - padding
      }px`;
    }
  };

  let timeoutRef = null;
  onMounted(() => {
    if (timeoutRef !== null) {
      clearTimeout(timeoutRef);
    }

    // wait till scrollTo is finished, and disable body scroll
    // without this hack the calendar would instantly close again on iOS
    timeoutRef = setTimeout(() => {
      lockScroll();
    }, 800);
  });

  onUnmounted(() => {
    clearTimeout(timeoutRef);
    const scrollY = document.body.style.top;
    document.body.classList.remove("scroll-lock");
    document.body.style.top = "";
    if (scrollY) {
      window.scrollTo(0, parseInt(scrollY) * -1);
    }
  });
};

export default useLockScroll;

import {
  addMinutes,
  subMinutes,
  format,
  isBefore,
  isWithinInterval,
  parseISO,
  isValid,
} from "date-fns";

export function getDayChar(stringDate) {
  const from = parseISO(stringDate);
  return format(from, "EE").toUpperCase().substr(0, 2);
}

export function beforeTime(minMax, time) {
  if (!minMax || !time) return false;
  const [minMaxHour, minMaxMinute] = minMax.split(":");
  const [timeHour, timeMinute] = time.split(":");
  if (
    isBefore(
      newDateWithTime(timeHour, timeMinute),
      newDateWithTime(minMaxHour, minMaxMinute)
    )
  ) {
    return true;
  }
  return false;
}
export function inTimeRange(min, time) {
  if (!min.rangeTimes || !time) return false;

  let inRange = false;
  min?.rangeTimes.map((rangeTime) => {
    const [beginHour, beginMinute] = rangeTime.begin.split(":");
    const [endHour, endMinute] = rangeTime.end.split(":");
    const minTime = newDateWithTime(beginHour, beginMinute, 0);
    const maxTime = newDateWithTime(endHour, endMinute, 10);
    if (
      isWithinInterval(time, {
        start: minTime,
        end: maxTime,
      })
    ) {
      inRange = true;
    }
  });
  return inRange;
}

export function addTime(time, minutes) {
  let date = new Date();
  const [hour, minute] = time.split(":");
  date.setHours(hour);
  date.setMinutes(minute);
  date.setSeconds(0);
  date = addMinutes(date, minutes);
  return format(date, "HH:mm");
}

export function subtractTime(time, minutes) {
  let date = new Date();
  const [hour, minute] = time.split(":");
  date.setHours(hour);
  date.setMinutes(minute);
  date.setSeconds(0);
  date = subMinutes(date, minutes);
  return format(date, "HH:mm");
}

export function newDateWithTime(hour, minute, seconds = 5) {
  const date = new Date();
  date.setHours(hour);
  date.setMinutes(minute);
  date.setSeconds(seconds);
  return date;
}

export function getTimeFromUrl(time) {
  if (!time || time.length !== 4) {
    return null;
  }

  time = time.match(/.{2}/g);
  const hour = parseInt(time[0]);
  let minute = parseInt(time[1]);
  if (isNaN(hour) || isNaN(minute) || hour > 24 || minute > 60) {
    return null;
  }

  // round minute to 0 if < 30 && round minute to 30 if > 30
  if (minute < 30) {
    minute = 0;
  } else if (minute > 30) {
    minute = 30;
  }

  const date = new Date();
  date.setHours(hour);
  date.setMinutes(minute);

  if (!isValid(date)) {
    return null;
  }

  return format(date, "HH:mm");
}

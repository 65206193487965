<template>
  <form @submit="basicAuthSubmit" class="basic-auth-modal">
    <wb-modal mode="edit">
      <wb-modal-content headline="BASIC AUTH Login">
        <wb-input-control class="basic-auth-modal__input-control">
          <wb-input>
            <label>User</label>
            <input type="text" v-model="basicAuth.username" />
          </wb-input>
        </wb-input-control>
        <wb-input-control class="basic-auth-modal__input-control">
          <wb-input>
            <label>Password</label>
            <input type="password" v-model="basicAuth.password" />
          </wb-input>
        </wb-input-control>
      </wb-modal-content>
      <wb-modal-footer class="basic-auth-modal__footer">
        <button
          @click="closeBasicAuthModal"
          type="button"
          class="wb-button wb-button--secondary wb-button--large basic-auth-modal__button"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="wb-button wb-button--primary wb-button--large basic-auth-modal__button"
        >
          Send
        </button>
      </wb-modal-footer>
    </wb-modal>
  </form>
</template>

<script>
import { reactive } from "vue";
import { OBP_BASIC_AUTH_TOKEN } from "@/services/ApiService";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const basicAuth = reactive({ username: null, password: null });
    const basicAuthSubmit = () => {
      sessionStorage.setItem(
        OBP_BASIC_AUTH_TOKEN,
        window.btoa(
          `${encodeURIComponent(basicAuth.username)}:${encodeURIComponent(
            basicAuth.password
          )}`
        )
      );
      store.dispatch("marketConfig/getPreAuthData");
      store.dispatch("marketConfig/setBasicAuthRequired", false);
      basicAuth.username = null;
      basicAuth.password = null;
    };
    const closeBasicAuthModal = () => {
      store.dispatch("marketConfig/setBasicAuthRequired", false);
    };

    return {
      basicAuth,
      basicAuthSubmit,
      closeBasicAuthModal,
    };
  },
};
</script>

<style lang="scss">
.basic-auth-modal {
  &__input-control {
    margin-top: 20px;
  }
  &__footer {
    display: flex;
  }
  wb-modal[mode="edit"] wb-modal-footer :not(:last-child) {
    display: inherit;
  }
  wb-modal[mode="edit"] wb-modal-footer {
    position: relative;
  }
  wb-modal[mode="edit"] .wb-modal__outer {
    align-self: center;
  }
  .wb-modal--with-footer .wb-modal__inner > wb-modal-content {
    margin-bottom: 0px;
  }
  &__button {
    flex: 1 0 auto;
    text-align: center;
  }
}
</style>

import { useStore } from "vuex";
import { computed, ref, watch, onUnmounted, watchEffect, onMounted } from "vue";

const useMap = function (transitionComplete) {
  const store = useStore();
  const zoom = ref(10);
  const radius = computed(() => store.getters["search/radius"]);

  watchEffect(() => {
    if (radius.value <= 50) {
      zoom.value = 10;
    } else {
      zoom.value = 8;
    }
  });

  const mapRef = ref(null);
  watchEffect(() => {
    if (mapRef.value) {
      resizeEvent();
    }
  });

  const mapWidth = ref(0);
  const mapHeight = ref(0);
  let eventAttached = false;
  const resizeEvent = () => {
    mapWidth.value = mapRef.value ? mapRef.value.clientWidth : null;
    mapHeight.value = mapRef.value ? mapRef.value.clientHeight : null;
    eventAttached = true;
  };

  const addEventListener = () => {
    if (!eventAttached) {
      window.addEventListener("resize", resizeEvent);
    }
  };

  watch(
    () => transitionComplete(),
    () => {
      addEventListener();
    }
  );

  onMounted(() => {
    addEventListener();
  });

  onUnmounted(() => {
    window.removeEventListener("resize", resizeEvent);
  });

  return {
    zoom,
    mapWidth,
    mapHeight,
    mapConfig: computed(() => store.getters["marketConfig/mapConfig"]),
    mapRef,
  };
};

export default useMap;

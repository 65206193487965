<template>
  <div class="cancel-reservation">
    <h1 class="wb-type-heading-l cancel-reservation__headline">
      {{ localization?.cancellationPage?.header }}
    </h1>
    <p
      class="wb-type-copy cancel-reservation__text"
      v-if="additionalServicesBooked"
    >
      {{ localization?.resultPage?.overview?.additionalServicesBooked }}
    </p>
    <p class="wb-type-copy cancel-reservation__text">
      {{ localization?.cancellationPage?.text }}
    </p>
    <button
      @click="cancelReservation"
      type="button"
      class="wb-button wb-button--primary wb-button--medium"
      data-test-id="cancel-reservation"
    >
      {{ localization?.cancellationPage?.cancellationBtnLabel }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    localization: {
      type: Object,
    },
    additionalServicesBooked: {
      type: Boolean,
    },
  },
  setup(_, { emit }) {
    const cancelReservation = () => {
      emit("cancelReservation");
    };

    return {
      cancelReservation,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.cancel-reservation {
  margin: 0 30px auto;
  padding: 20px 0;
  border-bottom: 1px solid rgba(40, 40, 40, 0.3);
  color: var(--wb-black);

  @include breakpoint-from(mq3) {
    margin: 0 40px auto;
    padding: 40px 0;
  }

  &__headline {
    padding-bottom: 10px;
  }

  &__text {
    padding-bottom: 20px;
  }
}
</style>

<template>
  <div v-if="data?.label" class="radio-group-label">
    {{ data.label }}<span v-if="required">*</span>
  </div>
  <div class="base-radio" :class="{ row }">
    <div
      class="base-radio__item"
      v-for="option in sortedOptionKeys"
      :key="option"
    >
      <wb-radio-control :error="error">
        <label>
          <input
            type="radio"
            :checked="modelValue === option"
            :value="option"
            v-bind="$attrs"
            @change="radioChanged(option)"
          />
          <span>{{ options[option] }}</span>
        </label>
      </wb-radio-control>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    options: {
      type: Object,
      required: true,
    },
    sortedOptionKeys: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    error: {
      type: Boolean,
      default: null,
    },
    row: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const radioChanged = (key) => {
      emit("update:modelValue", key);
      emit("changed");
    };

    return {
      radioChanged,
    };
  },
};
</script>
<style lang="scss" scoped>
.base-radio {
  &.row {
    display: flex;
  }

  &__item {
    padding-bottom: 20px;

    .row & {
      padding-bottom: 0;
      padding-right: 20px;
    }
  }
}
.radio-group-label {
  padding-bottom: 10px;
}
</style>

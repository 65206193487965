<template>
  <div class="menu">
    <div class="menu__top">
      <span class="logout" v-if="isLoggedIn" @click="$emit('logout')">
        Logout
      </span>

      <div
        class="menu__top__item"
        v-if="showApplicationInfo && isServiceHotlineEnabled"
      >
        <wb-tooltip-host position="top">
          <a class="menu__top__link" :href="'tel:' + header?.serviceHotline">
            <Icon name="call" :size="16" />
            <span v-if="serviceText" class="menu__top__text">
              {{ serviceText }}
            </span>
          </a>
          <wb-tooltip class="wb-type-copy">
            <span class="richtext" v-html="header?.serviceHotlineTooltip" />
          </wb-tooltip>
        </wb-tooltip-host>
      </div>
      <LanguageSwitcher />
    </div>
    <div class="menu__nav">
      <router-link
        :to="routeTo('about')"
        class="menu__nav__item"
        v-if="showApplicationInfo && !useNewAboutUs"
      >
        {{ header?.linkAbout }}
      </router-link>
      <router-link
        :to="routeTo('aboutUs')"
        class="menu__nav__item"
        v-if="showApplicationInfo && useNewAboutUs"
      >
        {{ header?.linkAbout }}
      </router-link>

      <ContentPageDropDown
        :contentPages="contentPages"
        :contentPagesActive="contentPagesActive"
      />

      <router-link
        :to="routeTo('faq')"
        class="menu__nav__item"
        v-if="showApplicationInfo"
      >
        {{ header?.linkFAQ }}
      </router-link>

      <div>
        <span
          class="menu__nav__item provider_modal"
          @click="openModalProvider = !openModalProvider"
        >
          {{ header?.linkProviderAndDataProtection }}
        </span>
        <transition name="Slide">
          <Provider
            v-if="openModalProvider"
            @close="openModalProvider = false"
            preselectedTab=""
          />
        </transition>
      </div>
    </div>
    <Burger />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import LanguageSwitcher from "@/components/Header/LanguageSwitcher";
import Burger from "./Burger";
import useMediaQuery from "@/compositions/useMediaQuery";
import Icon from "@/components/Icon";
import Provider from "@/views/Provider";
import { useStore } from "vuex";
import useRouteTo from "@/compositions/useRouteTo";
import ContentPageDropDown from "@/components/Header/ContentPageDropDown";

export default {
  props: ["header", "contentPages", "isLoggedIn", "showApplicationInfo"],
  setup(props) {
    const { mediaQuery } = useMediaQuery();
    const store = useStore();
    const serviceText = computed(() => {
      if (mediaQuery.value === "mq1" || mediaQuery.value === "mq2") {
        return null;
      }
      return props.header?.serviceHotline;
    });

    const isServiceHotlineEnabled = computed(
      () => store.getters["marketConfig/serviceHotline"]
    );
    const contentPagesActive = computed(
      () => store.getters["marketConfig/contentPages"]
    );

    const { routeTo } = useRouteTo();
    const useNewAboutUs = computed(
      () => store.getters["marketConfig/useNewAboutUs"]
    );
    return {
      serviceText,
      openModalProvider: ref(false),
      isServiceHotlineEnabled,
      routeTo,
      contentPagesActive,
      useNewAboutUs,
    };
  },
  components: {
    Provider,
    LanguageSwitcher,
    Burger,
    Icon,
    ContentPageDropDown,
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_typography";
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.menu {
  width: 50%;
  color: var(--wb-white);
  text-align: right;
  padding-top: 15px;
  @include wb-type-copy;
  font-size: 1rem !important;
  line-height: 1.5rem !important;

  &__top {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 15px;
  }

  &__top__link {
    color: var(--wb-white);
    text-decoration: none;
  }

  &__top__item {
    padding-left: 15px;
  }

  &__top__text {
    display: inline-block;
  }

  &__nav {
    display: none;
  }

  @include breakpoint-from(mq3) {
    &__nav {
      display: block;
      display: flex;
      justify-content: flex-end;
    }

    &__nav__item {
      color: var(--wb-white);
      text-decoration: none;
      padding-left: 25px;
    }
  }
}

.logout {
  padding-left: 10px;
  cursor: pointer;
}
.provider_modal {
  cursor: pointer;
}
</style>

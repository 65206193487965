<template>
  <div v-if="costsAvailable">
    <h2
      v-text="
        localization?.resultPage?.selectedOfferAccordion?.eclLabelHeadline
      "
    ></h2>
    <owcc-energy-consumption-labeling
      id="owccapp"
      thumbnail="true"
      @click="toggleThumbnail()"
    ></owcc-energy-consumption-labeling>
  </div>
  <div v-else>
    <p
      v-text="localization?.resultPage?.selectedOfferAccordion?.eclNoLabelText"
    ></p>
  </div>
  <div v-if="eclDiagnosisEnabled && messages.length">
    <br />
    <hr />
    <h2>Diagnosis for EnergyConsumptionLabel</h2>
    <br />
    <ul>
      <li v-for="(message, index) in messages" :key="index">
        <p>- {{ message }}</p>
      </li>
    </ul>
    <form @submit.prevent="fetchEnergyLabel">
      <button type="submit">Get Energy Label</button>
    </form>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import { useStore } from "vuex";
import { computed } from "vue";

if (!customElements.get("owcc-energy-consumption-labeling")) {
  const script = document.createElement("script");
  script.setAttribute("type", "module");
  script.setAttribute(
    "src",
    "https://assets.oneweb.mercedes-benz.com/plugin/owcc-energy-consumption-labeling/3/index.wc.js"
  );
  document.querySelector("head").appendChild(script);
}

export default {
  name: "EnergyConsumptionLabel",
  setup() {
    const store = useStore();
    const eclDiagnosisEnabled = computed(() => {
      return store.getters["marketConfig/eclDiagnosisEnabled"];
    });

    const localization = computed(
      () => store.getters["localization/localization"]
    );

    return {
      eclDiagnosisEnabled: eclDiagnosisEnabled,
      localization,
    };
  },
  data() {
    return {
      energyLabel: null,
      error: null,
      showThumbnail: true,
      messages: [],
      costsAvailable: true,
    };
  },
  methods: {
    toggleThumbnail() {
      this.showThumbnail = !this.showThumbnail;
      document
        .getElementById("owccapp")
        .setAttribute("thumbnail", `${this.showThumbnail}`);
    },
    async fetchEnergyLabel() {
      const domainId = this.$store.getters["localization/domainId"];
      const isoCode = this.$store.getters["localization/isoCode"];
      const selectedVehicleVin =
        this.$store.getters["vehicles/selectedVehicle"].vehicleVin;

      this.error = null;
      this.energyLabel = null;
      try {
        const response = await ApiService.getEnergyConsumptionLabel(
          domainId,
          isoCode,
          selectedVehicleVin
        );
        this.energyLabel = response.data;
        document
          .getElementById("owccapp")
          .setAttribute("label-data", JSON.stringify(response.data));

        const costs = response.data.costs;
        if (Array.isArray(costs)) {
          this.costsAvailable = costs.length > 0;
        } else {
          this.costsAvailable = false;
        }

        const messages = response.data.messages;
        if (Array.isArray(messages)) {
          this.messages = messages;
        } else {
          this.error = "An error occurred";
        }
      } catch (err) {
        this.error = err.response ? err.response.data : "An error occurred";
      }
    },
  },
  mounted() {
    this.fetchEnergyLabel();
  },
};
</script>

<template>
  <div class="contact">
    <section
      :style="{
        backgroundImage: `url(${imageUrl})`,
      }"
      class="teaser"
    >
      <div class="teaser__content">
        <h1 class="teaser__titles wb-type-heading-m">
          <span class="teaser__title">{{
            localization?.contactPage?.mainTitle
          }}</span>
        </h1>
      </div>
    </section>
    <section class="content-width">
      <div class="wb-padding-top-m wb-padding-bottom-xxs"></div>
      <h3
        class="wb-type-heading-m wb-padding-bottom-xs"
        v-html="localization?.contactPage?.contentTitle"
      />
      <div
        class="wb-type-copy content-text"
        v-html="localization?.contactPage?.contentText"
      ></div>
      <div class="wb-padding-top-m wb-padding-bottom-xxs"></div>
      <h3 class="wb-type-heading-m wb-padding-bottom-xs">
        {{ localization?.contactPage?.searchTitle }}
      </h3>
      <div class="wb-type-copy">
        {{ localization?.contactPage?.searchText }}
      </div>
      <div class="search-form">
        <div class="wb-padding-vertical-m">
          <SearchInput
            class="search-input"
            :error="searchError"
            :localization="localization?.mainPage?.search"
            @onSuggestSelect="requestLocations()"
          />
        </div>
        <button
          :onclick="requestLocations"
          class="contact-search-btn wb-button wb-button--with-icon wb-button--primary wb-margin-bottom-m wb-button--large"
          type="submit"
        >
          <Icon :height="12" :size="12" :width="18" name="chevron-right" />
          <span v-if="localization?.contactPage?.btnSearch">{{
            localization?.contactPage?.btnSearch
          }}</span>
        </button>
      </div>
      <div v-if="!searchError && locations.length > 0">
        <h3 class="wb-type-heading-m wb-margin-bottom-xs wb-margin-top-3xs">
          {{ localization?.contactPage?.resultTitle }}
        </h3>

        <div class="result-container">
          <Dealer
            class="wb-margin-bottom-l"
            v-for="location of locations"
            :key="location.outlet.outletId"
            :location="location"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SearchInput from "@/components/Search/SearchInput";
import { computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import Icon from "@/components/Icon";
import Dealer from "@/components/Contact/Dealer";
import useTrackingPage from "@/compositions/useTrackingPage";

export default {
  name: "Contact",
  setup() {
    const store = useStore();
    const search = computed(() => store.getters["search/query"]);
    const searchError = ref(false);
    const domainId = computed(() => store.getters["localization/domainId"]);
    const locations = computed(() => store.getters["search/locations"]);

    const activeLanguage = computed(
      () => store.getters["localization/activeLanguage"]
    );
    const tsLoaded = computed(
      () => store.getters["marketConfig/trackingScriptLoaded"]
    );

    const imageUrl = computed(() => {
      return require("@/assets/images/contact/header-image.png");
    });

    const requestLocations = () => {
      validateSearchInput();

      if (!searchError.value) {
        // %PAGE_TRACKING% "search: partner"

        store.dispatch("search/getLocation", {
          query: search.value,
          radius: 50,
          domainId: domainId.value,
        });
      }
    };

    const validateSearchInput = () => {
      searchError.value = search.value.length < 3;
    };

    const { trackContentContact } = useTrackingPage();

    watch(
      () => [activeLanguage.value, tsLoaded.value],
      ([language, loaded]) => {
        if (language?.isoCode && loaded) {
          trackContentContact();
        }
      }
    );

    const localization = computed(
      () => store.getters["localization/localization"]
    );

    onMounted(() => {
      if (activeLanguage.value.isoCode && tsLoaded.value) {
        trackContentContact();
      }
    });

    return {
      localization,
      requestLocations,
      searchError,
      locations,
      imageUrl,
    };
  },
  components: {
    Dealer,
    SearchInput,
    Icon,
  },
};
</script>
<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.contact-search-btn {
  @include breakpoint-to(mq3) {
    width: 100%;
  }
  @include breakpoint-from(mq4) {
    margin-left: 20px;
    width: 300px;
    height: 54px;
    margin-bottom: 0;
  }
}

.result-container {
  display: flex;
  flex-wrap: wrap;

  @include breakpoint-from(mq3) {
    justify-content: space-between;
  }
}

.search-input {
  @include breakpoint-from(mq4) {
    width: 500px;
  }
}

.content-text {
  @include breakpoint-from(mq3) {
    padding-right: 20%;
  }
  @include breakpoint-from(mq4) {
    padding-right: 30%;
  }
}

.search-form {
  @include breakpoint-from(mq4) {
    display: flex;
    align-items: center;
  }
}

.teaser {
  position: relative;
  width: 100%;
  min-height: 175px;
  color: var(--wb-white);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-color: #b1b0b5;
  background-size: cover;

  @include breakpoint-from(mq2) {
    min-height: 35vh;
  }

  @include breakpoint-from(mq3) {
    min-height: 500px;
  }

  &__content {
    padding: 5% 0 0 30px;

    @include breakpoint-from(mq3) {
      padding-left: 0;
      width: 85%;
      margin: auto;
    }
  }

  &__titles {
    font-size: 1.625rem;
    text-shadow: 1px 1px 1px rgba(51, 51, 51, 0.2);

    @include breakpoint-from(mq3) {
      font-size: 2.2rem;
      line-height: 2.5rem;
    }

    @include breakpoint-from(mq4) {
      font-size: 2.6rem;
      line-height: 3rem;
    }

    @include breakpoint-from(mq5) {
      font-size: 3rem;
      line-height: 3.5rem;
    }

    @include breakpoint-from(mq6) {
      font-size: 3.5rem;
      line-height: 4rem;
    }
  }

  &__title {
    display: block;
  }
}
</style>

<template>
  <div class="reservation">
    <h2 class="wb-type-heading-m">
      <span v-if="editMode">
        {{ localization?.checkoutPage?.summaryHeadline }}
      </span>
      <span v-else>
        {{ localization?.checkoutPage?.reservationConfirmTitle }}
      </span>
    </h2>
    <p v-if="editMode" class="reservation__subline">
      {{ localization?.checkoutPage?.summaryText }}
    </p>
    <p v-else class="reservation__subline">
      {{ localization?.checkoutPage?.reservationNumber }} {{ reservationId }}
    </p>

    <div class="reservation__data wb-margin-top-s">
      <div v-if="personalData.length" class="reservation__item">
        <h3 class="reservation__item__title">
          {{ localization?.checkoutPage?.summaryCustomerData }}
        </h3>
        <div
          v-if="editMode"
          class="reservation__item__edit"
          @click="editPage('personal')"
        >
          <Icon name="edit" :size="12" :width="8" :height="12" />
        </div>
        <p
          class="reservation__item__text wb-margin-bottom-s"
          v-html="getContactData()"
        ></p>

        <h3 class="reservation__item__title">
          {{ localization?.checkoutPage?.summaryAddress }}
        </h3>
        <p
          class="reservation__item__text wb-margin-bottom-s"
          v-html="getAddressData()"
        ></p>
      </div>

      <div
        v-if="additionalFields.length"
        :class="{ reservation__item: showReasonForRent() }"
      >
        <h3 class="reservation__item__title wb-padding-top-s">
          {{
            localization?.checkoutPage?.summaryAdditionalInformationAndDocuments
          }}
        </h3>
        <div
          v-if="editMode"
          class="reservation__item__edit"
          @click="editPage('addition')"
        >
          <Icon name="edit" :size="12" :width="8" :height="12" />
        </div>
        <p
          class="reservation__item__text wb-padding-bottom-s"
          v-for="additionalField in additionalFields"
          :key="additionalField.type"
        >
          <span v-if="additionalField.type === 'MINIMUM_AGE'">
            <Icon
              class="confirm-notification__icon"
              v-if="additionalField.value"
              name="checkmark-circle"
              :size="28"
              :paths="2"
            />
            <span
              v-if="localization?.checkoutFieldsAsMap[additionalField.type]"
            >
              &nbsp;{{
                localization?.checkoutFieldsAsMap[additionalField.type]?.label
              }}
            </span>
          </span>
          <span v-else>
            <span
              v-if="localization?.checkoutFieldsAsMap[additionalField.type]"
            >
              {{
                localization?.checkoutFieldsAsMap[additionalField.type]?.label
              }}:
            </span>
            {{ additionalField.value }}
          </span>
        </p>
      </div>

      <div v-if="rentalData.length && showReasonForRent()">
        <h3 class="reservation__item__title wb-padding-top-s">
          {{ localization?.checkoutPage?.summaryReasonForRent }}
        </h3>
        <div
          v-if="editMode"
          class="reservation__item__edit"
          @click="editPage('rental')"
        >
          <Icon name="edit" :size="12" :width="8" :height="12" />
        </div>
        <p class="reservation__item__text">
          {{ getReasonForRent() }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { useRouter } from "vue-router";
import {
  addressDataString,
  contactDataString,
  reasonForRentString,
  stepNames,
} from "@/utilities/checkout";
import Icon from "@/components/Icon";

export default {
  name: "ReservationData",
  props: {
    localization: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const personalData = computed(() => store.getters["checkout/personalData"]);
    const additionalFields = computed(
      () => store.getters["checkout/additionalFields"]
    );
    const rentalData = computed(() => store.getters["checkout/rentalData"]);
    const localizationFields = computed(
      () => store.getters["localization/checkoutFields"]
    );

    const showReasonForRent = () => {
      const reason = rentalData.value.find(
        (data) => data.type === "REASON_FOR_RENT"
      );
      return !!(reason && reason.value !== "");
    };

    const getContactData = () =>
      contactDataString(personalData.value, localizationFields.value);

    const getAddressData = () => addressDataString(personalData.value);

    const getReasonForRent = () =>
      reasonForRentString(rentalData.value, localizationFields.value);

    const router = useRouter();
    const steps = computed(() => store.getters["checkout/steps"]);

    const editPage = (name) => {
      let step;

      switch (name) {
        case "personal":
          step = 1;
          break;
        case "addition":
          step = 2;
          break;
        case "rental":
          const addition = steps.value.find(
            (curStep) => curStep.name === stepNames.additionalFields
          );
          if (addition?.name) {
            step = 3;
          } else {
            step = 2;
          }
          break;
        default:
          break;
      }

      router.push({ name: "checkout", query: { step } });
    };

    return {
      personalData,
      additionalFields,
      rentalData,
      getContactData,
      getAddressData,
      getReasonForRent,
      showReasonForRent,
      editPage,
      localizationFields,
      reservationId: computed(() => store.getters["checkout/reservationId"]),
    };
  },
  components: {
    Icon,
  },
};
</script>
<style lang="scss" scoped>
@import "~@workbench/core/dist/css/extensions/spacing";
@import "~@workbench/core/dist/scss/utility/_typography";

.reservation {
  &__subline {
    color: var(--wb-grey-60);
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__data {
    padding: 20px 0;
    border-top: 1px solid rgba(40, 40, 40, 0.3);
  }

  &__item {
    border-bottom: 1px solid rgba(40, 40, 40, 0.3);
  }

  &__item__title {
    @include _wb-font-text-regular;
    font-weight: bold;
    font-size: 1.125rem;
    display: inline-block;
    padding-bottom: 15px;
  }

  &__item__edit {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 10px;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: var(--wb-blue-45);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    color: var(--wb-white);
  }
}
</style>

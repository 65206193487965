<template>
  <div class="mb-rent-partner">
    <div class="mb-rent-partner__flex-container">
      <div ref="partnerRef" class="mb-rent-partner__map">
        <HereMap
          v-if="mapConfig?.type.toLowerCase() === 'here' && mapIsReady"
          :locations="mapParams.params.locations"
          :selection="mapParams.params.selection"
          :zoom="11"
          :mapConfig="mapParams.params.mapConfig"
          :mapWidth="mapParams.params.mapWidth"
          :mapHeight="mapParams.params.mapHeight"
          :isDesktop="false"
        />
        <GoogleMapComponent
          v-if="mapConfig?.type.toLowerCase() === 'googlemaps' && mapIsReady"
          :locations="mapParams.params.locations"
          :selection="mapParams.params.selection"
          :zoom="11"
          :mapConfig="mapParams.params.mapConfig"
          :mapWidth="mapParams.params.mapWidth"
          :mapHeight="mapParams.params.mapHeight"
          isPartnerView
        />
      </div>
      <div class="mb-rent-partner__address">
        <p class="mb-rent-partner__text wb-type-copy-strong">
          {{ localization?.pickUpLocation }}:
        </p>
        <p class="mb-rent-partner__text wb-type-copy">
          {{ addressData.name }}
        </p>
        <p class="mb-rent-partner__text wb-type-copy">
          {{ addressData.street }}
        </p>
        <p class="mb-rent-partner__text wb-type-copy space-below">
          {{ addressData.zipCode }}
          {{ addressData.city }}
        </p>
        <p class="mb-rent-partner__text wb-type-copy">
          {{ localization?.tel }}:
          {{ location.outlet?.communicationData?.phone }}
        </p>
        <p class="mb-rent-partner__text wb-type-copy">
          {{ localization?.email }}:
          {{ location.outlet?.communicationData?.email }}
        </p>
        <p class="mb-rent-partner__text wb-type-copy-strong space-above">
          {{ localization?.openingHours }}
        </p>
        <div class="mb-rent-partner__text wb-type-copy space-below">
          <p
            v-for="item in openingHours.list"
            :key="item.from"
            class="mb-rent-partner__text"
          >
            {{ item.from }}: {{ item.openingHours }}
          </p>
        </div>
        <Icon name="chevron-right" :size="12" />
        <BaseLink
          class="mb-rent-partner__text mb-rent-partner__imprint"
          link-type="standalone"
          :text="localization?.imprint"
          :url="location.outlet?.communicationData?.url"
        ></BaseLink>
        <br />
        <br />
        <Icon
          v-if="location.outlet?.communicationData?.dataPrivacyPolicyUrl"
          name="chevron-right"
          :size="12"
        />
        <BaseLink
          v-if="location.outlet?.communicationData?.dataPrivacyPolicyUrl"
          class="mb-rent-partner__text mb-rent-partner__imprint"
          link-type="standalone"
          :text="localization?.dataPrivacyPolicy"
          :url="location.outlet?.communicationData?.dataPrivacyPolicyUrl"
        ></BaseLink>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import {
  getFormatedOpeningHours,
  getIntlWeekDay,
} from "@/utilities/openingHours";
import HereMap from "@/components/Map/HereMap";
import Icon from "@/components/Icon";
import BaseLink from "@/components/BaseLink";
import GoogleMapComponent from "@/components/Map/GoogleMapComponent";

export default {
  name: "MBRentPartner",
  props: ["location", "localization", "timeLabel"],

  setup(props) {
    const store = useStore();
    const isMobile = computed(() => store.getters["localization/isMobile"]);
    const localization = computed(() => props["localization"]);
    const location = computed(() => props["location"]);
    const openingHours = reactive({ list: null });
    const isoCode = computed(() => store.getters["localization/isoCode"]);

    const mapConfig = computed(() => store.getters["marketConfig/mapConfig"]);
    const language = computed(
      () => store.getters["localization/activeLanguage"]
    );
    const addressData = computed(() => {
      const { latinAddress, name } = props.location?.outlet;

      if (language.value?.useOriginalOutletAddress) {
        const { originalAddress, originalName } = props.location?.outlet;

        return {
          name: originalName || name,
          street: originalAddress?.street || latinAddress.street,
          zipCode: originalAddress?.zipCode || latinAddress.zipCode,
          city: originalAddress?.city || latinAddress.city,
        };
      }

      return {
        name,
        street: latinAddress.street,
        zipCode: latinAddress.zipCode,
        city: latinAddress.city,
      };
    });

    const createOpeningHours = () => {
      const localizationOpeningHours = {
        closed: localization.value.closed,
        days: {
          mo: getIntlWeekDay("MO", isoCode.value),
          tu: getIntlWeekDay("TU", isoCode.value),
          we: getIntlWeekDay("WE", isoCode.value),
          th: getIntlWeekDay("TH", isoCode.value),
          fr: getIntlWeekDay("FR", isoCode.value),
          sa: getIntlWeekDay("SA", isoCode.value),
          su: getIntlWeekDay("SU", isoCode.value),
        },
        timeLabel: props.timeLabel,
      };
      openingHours.list = getFormatedOpeningHours(
        location.value?.outlet?.openingHours,
        localizationOpeningHours
      );
    };
    const selection = {
      selected: { value: new Set([location.value]) },
      numerSelected: 1,
    };
    const mapParams = reactive({
      params: null,
    });

    const partnerRef = ref(null);
    const mapIsReady = ref(false);
    const mapScale = ref(1);

    const scale = () => {
      if (isMobile) {
        mapScale.value = 1;
      } else {
        mapScale.value = 0.48;
      }
    };
    scale();

    watch(
      () => partnerRef.value,
      (partnerContainer) => {
        if (partnerContainer) {
          const ratio = 400 / 600;

          const altDivHeight = partnerContainer.clientWidth * ratio;
          const altDivWidth = partnerContainer.clientWidth;

          scale();
          mapParams.params = {
            locations: [location.value],
            selection: selection,
            zoom: 8,
            mapConfig: mapConfig.value,
            mapWidth: altDivWidth * mapScale.value,
            mapHeight: altDivHeight * mapScale.value,
            isDesktop: false,
          };
          mapIsReady.value = true;
        }
      }
    );
    const resizeEvent = () => {
      scale();
      const partnerContainer = partnerRef.value;
      const ratio = 400 / 600;
      const altDivHeight = partnerContainer.clientWidth * ratio;
      const altDivWidth = partnerContainer.clientWidth;
      mapParams.params.mapWidth = altDivWidth * mapScale.value;
      mapParams.params.mapHeight = altDivHeight * mapScale.value;
    };
    onMounted(() => {
      createOpeningHours();
      window.addEventListener("resize", resizeEvent);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", resizeEvent);
    });

    watch(
      () => localization.value,
      () => {
        createOpeningHours();
      }
    );
    return {
      addressData,
      mapParams,
      openingHours,
      partnerRef,
      mapIsReady,
      mapConfig,
    };
  },
  components: {
    GoogleMapComponent,
    BaseLink,
    Icon,
    HereMap,
  },
};
</script>
<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.mb-rent-partner {
  &__flex-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint-from(mq3) {
      flex-direction: row;
    }
  }

  &__map {
    padding: 6px 0 25px 0;
    @include breakpoint-from(mq3) {
      width: 48%;
    }
  }

  &__address {
    @include breakpoint-from(mq3) {
      width: 44%;
    }
  }

  &__imprint {
    font-weight: normal !important;
  }
}
</style>

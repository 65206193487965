import axios from "axios";
import mockPaymentRedirectReservation from "../../public/mocks/de/payment/paymentRedirectReservation.json";

// mockUrl depends on VUE_APP_DOMAIN
const mockUrl = process.env.VUE_APP_DOMAIN
  ? `/mocks/${process.env.VUE_APP_DOMAIN}/`
  : "/mocks/de/";

// api
export const api =
  process.env.VUE_APP_BACKEND_URL ??
  "https://dev-europe-cms.mbrent.mercedes-benz.com/api";

let development = false;
if (process.env.NODE_ENV === "development") {
  development = true;
}
const frontendUrl = development
  ? process.env.VUE_APP_FRONTEND_URL
  : window.location.origin;

export const apiClient = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "",
  },
});

export const OBP_ACCESS_TOKEN = "obpAccessToken";
export const OBP_BASIC_AUTH_TOKEN = "obpBasicAuthToken";

apiClient.interceptors.request.use((config) => {
  const accessToken = sessionStorage.getItem(OBP_ACCESS_TOKEN);
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  const basicAuthToken = sessionStorage.getItem(OBP_BASIC_AUTH_TOKEN);
  if (basicAuthToken) {
    config.headers.Authorization = `Basic ${basicAuthToken}`;
  }
  return config;
});

export default {
  authLogin() {
    const domain = window.location.origin;
    return apiClient.get(`${api}/auth/login?postLoginRedirectUrl=${domain}`);
  },
  getAccessToken(code) {
    const domain = window.location.origin;
    return apiClient.get(
      `${api}/auth/accesstoken?code=${code}&postLoginRedirectUrl=${domain}`
    );
  },
  authLogout() {
    const domain = window.location.origin;
    sessionStorage.removeItem(OBP_ACCESS_TOKEN);
    return apiClient.get(`${api}/auth/logout?postLogoutRedirectUrl=${domain}`);
  },
  getMockPreAuthData() {
    const domain = window.location.origin;
    return apiClient.get(`${mockUrl}configs/pre-auth-data.json?url=${domain}`);
  },
  getPreAuthData() {
    return apiClient.get(`${api}/domain/pre-auth-data?url=${frontendUrl}`);
  },
  getMockConfig(domainId) {
    return apiClient.get(
      `${mockUrl}configs/marketConfig.json?domainId=${domainId}`
    );
  },
  getConfig(domainId) {
    return apiClient.get(`${api}/domain/${domainId}/config`);
  },
  getMockLocalization(params) {
    const { isoCode } = params;
    return apiClient.get(`${mockUrl}configs/localization_${isoCode}.json`);
  },
  getMockModelTypes() {
    return apiClient.get(`${mockUrl}vehicles/modelTypes.json`);
  },
  getLocalization(params) {
    const { isoCode, domainId } = params;
    return apiClient.get(`${api}/domain/${domainId}/localization/${isoCode}`);
  },
  getModelTypes(params) {
    const { isoCode, domainId } = params;
    return apiClient.get(
      `${api}/domain/${domainId}/vehicle/${isoCode}/modelTypes`
    );
  },
  getMockSuggestion() {
    return apiClient.get(`${mockUrl}search/suggest.json`);
  },
  getSuggestion(params) {
    const { query, domainId } = params;
    return apiClient.get(
      `${api}/domain/${domainId}/city/suggest?searchTerm=${query}`
    );
  },
  getMockCity(params) {
    const { latitude, longitude } = params;
    return apiClient.get(
      `${mockUrl}search/suggest_city.json?lat=${latitude}&long=${longitude}`
    );
  },
  getCity(params) {
    const { latitude, longitude, domainId } = params;
    return apiClient.get(
      `${api}/domain/${domainId}/city/name?lat=${latitude}&long=${longitude}`
    );
  },
  getLocation(params) {
    const { query, radius, domainId } = params;
    return apiClient.get(
      `${api}/domain/${domainId}/stations/${query}/${radius}`
    );
  },
  getMockLocation(params) {
    const { query, radius } = params;

    if (radius <= 50) {
      return delayRequest(
        apiClient.get(
          `${mockUrl}search/locations.json?query=${query}&radius=${radius}`
        ),
        100
      );
    }
    return delayRequest(
      apiClient.get(
        `${mockUrl}search/locations_50.json?query=${query}&radius=${radius}`
      ),
      100
    );
  },
  getMainOutletStructure(params) {
    const { domainId, gsId } = params;
    return apiClient.get(
      `${api}/domain/${domainId}/main-outlet-structure/${gsId}`
    );
  },
  getMockMainOutletStructure() {
    return apiClient.get(`${mockUrl}search/main_outlet_structure.json`);
  },
  getVehicles(request) {
    return apiClient.post(
      `${api}/domain/${request.domainId}/vehicle/${request.isoCode}/search`,
      request
    );
  },
  getModelSelectionVehicles(request, domainId, isoCode) {
    return apiClient.post(
      `${api}/domain/${domainId}/vehicle/${isoCode}/modelsearch`,
      request
    );
  },
  getMockReservationDetails() {
    return mockPaymentRedirectReservation;
  },
  finalizeReservation(domainId, reservationId, email, isoCode) {
    return apiClient.get(
      `${api}/domain/${domainId}/vehicle/${isoCode}/book/${reservationId}?customerEmail=${email}`
    );
  },
  verifyPayment(reservationId, email) {
    return apiClient.get(
      `${api}/verifyPayment/${reservationId}/email/${email}`
    );
  },
  getMockReservation() {
    return apiClient.get(`${mockUrl}booking/getReservationResult.json`);
  },
  getReservation(domainId, reservationId, email, isoCode) {
    return apiClient.get(
      `${api}/domain/${domainId}/vehicle/${isoCode}/reservations/${reservationId}?customerEmail=${email}`
    );
  },
  cancelMockReservation() {
    return apiClient.get(`${mockUrl}booking/cancelReservation.json`);
  },
  cancelReservation(domainId, reservationId, email, isoCode) {
    return apiClient.post(
      `${api}/domain/${domainId}/vehicle/${isoCode}/cancel/${reservationId}?customerEmail=${email}`,
      {
        domainId,
        reservationId,
        isoCode,
        email,
      }
    );
  },
  getMockVehicles(request) {
    return delayRequest(
      apiClient.get(`${mockUrl}search/vehicles.json`, request),
      100
    );
  },
  requestMockBooking(request) {
    return delayRequest(
      apiClient.get(`${mockUrl}search/book.json`, request),
      500
    );
  },
  requestBooking(request) {
    return apiClient.post(
      `${api}/domain/${request.domainId}/vehicle/book`,
      request
    );
  },
  requestMockBookingPayment(request) {
    return delayRequest(
      apiClient.get(`${mockUrl}booking/paymentLinkResult.json`, request),
      500
    );
  },
  requestBookingPayment(request) {
    return apiClient.post(
      `${api}/domain/${request.domainId}/vehicle/reserve`,
      request
    );
  },
  requestVehicle(request, domainId) {
    return apiClient.post(`${api}/domain/${domainId}/vehicle/request`, request);
  },
  validateCaptcha(request) {
    return apiClient.post(`${api}/image/captcha/validate`, request);
  },
  getMockCaptcha() {
    return apiClient.get(`${mockUrl}configs/captcha.json`);
  },
  get(param) {
    return apiClient.get(`${api}${param}`);
  },
  requestAll(calls) {
    return axios.all(calls);
  },
  postRequest(param) {
    return apiClient.post(`${api}${param}`);
  },
  getModelTypesForRent(domainId, languageCode) {
    return apiClient.get(
      `${api}/domain/${domainId}/vehicle/${languageCode}/rentalRequestModels`
    );
  },
  getMockAdditionalServices() {
    return delayRequest(
      apiClient.get(`${mockUrl}vehicles/additionalServices.json`),
      100
    );
  },
  getAdditionalServices(domainId, isoCode, request) {
    return apiClient.post(
      `${api}/domain/${domainId}/vehicle/${isoCode}/additionalServices`,
      request
    );
  },
  mockVehiclePriceUpdate(domainId, isoCode, request) {
    return delayRequest(
      apiClient.get(`${mockUrl}vehicles/vehiclePriceUpdate.json`, {
        domainId,
        isoCode,
        ...request,
      }),
      1000
    );
  },
  vehiclePriceUpdate(domainId, isoCode, request) {
    return apiClient.post(
      `${api}/domain/${domainId}/vehicle/${isoCode}/vehiclePriceUpdate`,
      request
    );
  },
  postDsaReport(request, domainId) {
    return apiClient.post(`${api}/domain/${domainId}/dsa/dsaReport`, request);
  },
  getEnergyConsumptionLabel(domainId, isoCode, vin) {
    return apiClient.get(
      `${api}/domain/${domainId}/ecl/${isoCode}/label?vin=${vin}`
    );
  },
};

function delayRequest(request, delay) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(request);
    }, delay);
  });
}

<template>
  <div class="checkout" v-if="selectedVehicle">
    <ConfirmNotification
      class="wb-margin-top-s"
      v-if="localization?.checkoutPage?.reservationSuccessText"
      :headline="localization?.checkoutPage?.reservationSuccessHeadline"
      :message="localization?.checkoutPage?.reservationSuccessText"
      :delay="200"
      :useCallToAction="oneDocActive && showScrollButton"
      :ctaText="localization.oneDoc.confirmationBoxText"
      :buttonText="localization.oneDoc.confirmationButtonText"
      @ctaClicked="scrollToOneDoc"
    />

    <ModelPriceDateVehicle
      class="content-width"
      :vehicle="selectedVehicle"
      :localization="localization"
      :additionalServices="additionalServicesText"
    />

    <div class="checkout__container content-width">
      <div class="checkout__content wb-type-copy">
        <Summary :localization="localization" view="confirm" />
      </div>
      <div id="oneDoC" v-if="oneDocActive">
        <OneDocIframe
          @iframeClosed="removeScrollButton"
          @iframeOpen="renderJoinButton"
        />
      </div>
      <div class="checkout__actions row-3" :class="{ fixed: scrolling }">
        <div class="checkout__actions__inner">
          <button
            @click="redirectToHomeOrDealer"
            type="button"
            class="checkout__actions__button wb-button wb-button--secondary wb-button--medium"
          >
            <Icon name="chevron-left" :size="12" :width="18" :height="12" />
            {{ localization?.resultPage?.overview?.btnBackToHome }}
          </button>
        </div>
      </div>
      <div
        class="checkout__actions__placeholder"
        :class="{ visible: scrolling }"
      ></div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onBeforeUnmount, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ViewportObserver } from "@workbench/core";
import ModelPriceDateVehicle from "@/components/ModelPriceDateVehicle";
import Icon from "@/components/Icon";
import Summary from "@/components/Checkout/Steps/Summary";
import ConfirmNotification from "@/components/Notification/ConfirmNotification";
import useTrackingPage from "@/compositions/useTrackingPage";
import useHomeRedirect from "@/compositions/useHomeRedirect";
import { getAdditionalServicesAsText } from "@/utilities/additionalServices";
import OneDocIframe from "@/components/OneDoc/OneDocIframe.vue";

export default {
  name: "Confirm",
  setup() {
    const store = useStore();
    const router = useRouter();
    const localization = computed(
      () => store.getters["localization/localization"]
    );
    const selectedVehicle = computed(
      () => store.getters["vehicles/selectedVehicle"]
    );
    const oneDocActive = store.getters["marketConfig/oneDocActive"];
    // redirect if no data
    if (!Object.keys(localization.value).length) {
      router.push({ name: "home" });
      return;
    }

    // scrolling
    const scrolling = ref(false);
    const scrollObserver = ref(null);
    const { trackCheckoutReservationConfirmation } = useTrackingPage();
    onMounted(() => {
      scrollObserver.value = new ViewportObserver({
        element: document.querySelector("footer"),
        viewportEnterFn: () => {
          scrolling.value = false;
        },
        viewportLeaveFn: () => {
          scrolling.value = true;
        },
      });

      trackCheckoutReservationConfirmation();
    });

    onBeforeUnmount(() => {
      scrollObserver.value.disconnect();
      store.dispatch("checkout/resetError");
      store.dispatch("checkout/resetReservation");
    });

    const { redirectToHomeOrDealer } = useHomeRedirect();

    const additionalServicesText = ref(null);
    const selectedAdditionalServices = computed(
      () => store.getters["vehicles/selectedAdditionalServices"]
    );

    additionalServicesText.value = getAdditionalServicesAsText(
      selectedAdditionalServices.value
    );
    watch(
      () => selectedAdditionalServices.value,
      () => {
        additionalServicesText.value = getAdditionalServicesAsText(
          selectedAdditionalServices.value
        );
      }
    );

    const scrollToOneDoc = () => {
      const element = document.getElementById("oneDoC");
      const top = element.getBoundingClientRect().top;
      window.scrollTo({ top, behavior: "smooth" });
    };

    // This variable is set when the OneDoC SPA sent a message that the Iframe should be closed
    const confirmedClosedIFrame = ref(false);

    const showScrollButton = ref(false);

    /** When the SPA signals that the Iframe should be closed, this method is called. We disable 'showScrollButton' to remove the Join button.
     *  Also confirmedClosedIFrame is set to prevent that the button will be rendered after the setTimeout of renderJoinButton()  */
    const removeScrollButton = () => {
      showScrollButton.value = false;
      confirmedClosedIFrame.value = true;
    };

    /**  We wait for 1 Sec after the Iframe was loaded for OneDoc to tell the FE if the IFrame should be closed.. otherwise the join button will appear.
     *   When the SPA sends a close-message wihtin the 1sec duration of the setTimeout command, the join button will not be rendered.
     */
    const renderJoinButton = () => {
      setTimeout(function () {
        if (!confirmedClosedIFrame.value) {
          showScrollButton.value = true;
        }
      }, 1000);
    };

    return {
      localization,
      selectedVehicle,
      scrolling,
      redirectToHomeOrDealer,
      additionalServicesText,
      scrollToOneDoc,
      oneDocActive,
      removeScrollButton,
      showScrollButton,
      renderJoinButton,
    };
  },
  components: {
    OneDocIframe,
    ConfirmNotification,
    ModelPriceDateVehicle,
    Icon,
    Summary,
  },
};
</script>

<style lang="scss">
@import "@/styles/components/checkout";
</style>

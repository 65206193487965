import { formatDisplayDate } from "@/utilities/date";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

const useDateValidation = function (localization) {
  const store = useStore();
  const storeDate = computed(() => store.getters["search/selectedDate"]);
  const date = ref(null);
  const success = ref(false);
  const dateFormat = computed(() => store.getters["localization/dateFormat"]);
  const isoCode = computed(() => store.getters["localization/isoCode"]);
  const dateLabel = ref(null);

  const setDateValue = (from, to) => {
    if (from && to) {
      date.value = `${from} - ${to}`;
      success.value = true;
    } else {
      success.value = false;
    }
    if (from && !to) {
      date.value = `${from} - `;
    }
    if (!from && !to) {
      date.value = null;
    }
  };

  const updateLabel = (curDate) => {
    if (!curDate?.from) {
      dateLabel.value = localization()?.pickUpDate;
    }
    if (curDate?.from) {
      dateLabel.value = localization()?.returnDate;
    }
    if (curDate?.from && curDate?.to) {
      dateLabel.value = localization()?.pickUpAndReturnDate;
    }
  };

  const updateDate = (curDate, format) => {
    if (curDate?.from || curDate?.to) {
      setDateValue(
        formatDisplayDate(curDate?.from, isoCode.value, format),
        formatDisplayDate(curDate?.to, isoCode.value, format)
      );
    } else {
      setDateValue(null, null);
    }
    updateLabel(curDate);
  };

  // re-format date when storeDate changes (clicked on Calendar)
  watch(
    () => storeDate.value,
    (curDate) => updateDate(curDate, dateFormat.value),
    {
      immediate: true,
    }
  );

  // update input label and date when localization changes
  watch(
    () => localization(),
    () => {
      updateLabel(storeDate.value);
      updateDate(storeDate.value, dateFormat.value);
    }
  );

  // re-format date when dateFormat changes (language changes)
  watch(
    () => dateFormat.value,
    (curFormat) => updateDate(storeDate.value, curFormat)
  );

  // watch locationSelected to clear date if changed
  const locationSelected = computed(
    () => store.getters["search/locationSelected"]
  );
  watch(
    () => locationSelected.value,
    () => {
      store.dispatch("search/updateDate", {
        from: null,
        to: null,
      });
    }
  );

  return {
    date,
    success,
    dateFormat,
    dateLabel,
  };
};

export default useDateValidation;

import { useStore } from "vuex";
import { computed, ref, watch } from "vue";

const useModelSelection = function () {
  const store = useStore();
  const domainId = computed(() => store.getters["marketConfig/domainId"]);
  const isoCode = computed(() => store.getters["localization/isoCode"]);
  const modelTypesLoading = ref(true);

  const modelSelectionFeatureActive = computed(
    () => store.getters["marketConfig/modelSelection"]
  );

  const modelTypesForModelSelection = computed(
    () => store.getters["vehicles/modelTypesForModelSelection"]
  );

  const modelTypesReloadRequired = computed(
    () => store.getters["vehicles/modelTypesReloadRequired"]
  );

  // Activate gui elements for model selection only if feature is active and
  // at least one model is configured for feature
  const modelSelectionActive = computed(() => {
    return (
      modelSelectionFeatureActive.value &&
      modelTypesForModelSelection.value.length > 0
    );
  });

  const readyToFetchModels = computed(() => {
    return !!(isoCode.value && domainId.value);
  });

  const fetchModelTypes = async () => {
    try {
      modelTypesLoading.value = true;
      await store.dispatch("vehicles/getModelTypes", {
        domainId: domainId.value,
        isoCode: isoCode.value,
      });
    } finally {
      modelTypesLoading.value = false;
    }
  };

  // attributes filter
  const filteredTypes = ref([]);
  const filter = ref(new Set());
  const attributes = ref([]);

  const setAttributes = () => {
    const allAttributes = new Set();
    modelTypesForModelSelection.value
      .filter(
        (type) =>
          type.globalModelClassAttributes &&
          type.globalModelClassAttributes.length
      )
      .forEach(({ globalModelClassAttributes }) => {
        globalModelClassAttributes.forEach((attribute) =>
          allAttributes.add(attribute)
        );
      });

    attributes.value = Array.from(allAttributes);
    return !!attributes.value.length;
  };

  const toggleSelectedAttrs = (selectedAttrs) => {
    filter.value = new Set([...selectedAttrs]);
    filterModelTypes();
  };

  const hasAttribute = (attrs) =>
    !!attrs.find((attr) => filter.value.has(attr));

  const filterModelTypes = () => {
    if (!filter.value.size) {
      filteredTypes.value = modelTypesForModelSelection.value;
      return;
    }
    filteredTypes.value = modelTypesForModelSelection.value.filter(
      (type) =>
        type.globalModelClassAttributes &&
        type.globalModelClassAttributes.length &&
        hasAttribute(type.globalModelClassAttributes)
    );
  };

  watch(attributes, (attrs) => {
    if (attrs) {
      filteredTypes.value = modelTypesForModelSelection.value;
    }
  });

  watch(
    () => modelTypesForModelSelection.value,
    () => {
      if (modelTypesForModelSelection.value?.length) {
        setAttributes();
      }
    },
    {
      immediate: true,
    }
  );

  return {
    modelSelectionActive,
    modelSelectionFeatureActive,
    readyToFetchModels,
    fetchModelTypes,
    modelTypesLoading,
    modelTypesForModelSelection,
    filteredTypes,
    toggleSelectedAttrs,
    attributes,
    modelTypesReloadRequired,
  };
};

export default useModelSelection;

<template>
  <div
    class="vehicle-offer wb-slider__item wb-grid-col-mq1-12 wb-grid-col-mq3-6 wb-grid-col-mq4-4"
  >
    <div class="vehicle-offer__inner">
      <div class="vehicle-offer__top">
        <div class="vehicle-offer__distance">
          <Icon name="mercedes-pin" :paths="2" :size="24" :width="26" />

          <span class="vehicle-offer__distance-text wb-type-copy">
            {{ city }}
          </span>
        </div>
        <h3 class="vehicle-offer__name wb-type-heading-m">
          <span>{{ vehicle?.name }}</span>
        </h3>
        <h4 class="vehicle-offer__model wb-type-copy-strong">
          {{ vehicle?.modelType?.modelClassName }}
        </h4>
        <div class="vehicle-offer__container">
          <div class="vehicle-offer__price wb-type-copy">
            <RentalPeriodDisplay
              :localization="localization"
              :vehicle="vehicle"
            />

            /
            <span class="vehicle-offer__price__tag">
              {{ priceTag }}
            </span>
          </div>
        </div>
      </div>
      <div class="vehicle-offer__center">
        <div
          class="vehicle-offer__image"
          @click="isGalleryOpen = !isGalleryOpen"
        >
          <div class="vehicle-offer__image-icon">
            <Icon name="search" :size="16" :width="13" :height="16" />
          </div>
          <img v-lazyload :data-url="getImagePath" :alt="vehicle?.name" />
        </div>
        <div class="vehicle-offer__equipments">
          <VehicleEquipment :equipment="equipment" />
        </div>
        <button
          @click="onSelectVehicle"
          type="submit"
          class="vehicle-offer__submit wb-button wb-button--primary wb-button--medium"
        >
          <Icon name="chevron-right" :size="12" :width="18" :height="12" />
          {{ localization.resultPage?.overview?.btnConfirmSelection }}
        </button>
      </div>
    </div>
  </div>

  <VehicleGallery
    :vehicle="vehicle"
    @close="isGalleryOpen = false"
    v-if="isGalleryOpen"
  />
</template>

<script>
import VehicleEquipment from "@/components/Vehicle/VehicleEquipment";
import VehicleGallery from "@/components/Vehicle/VehicleGallery";
import RentalPeriodDisplay from "@/components/RentalPeriodDisplay";
import { computed, ref } from "vue";
import { createEquipment } from "@/utilities/vehicle";
import { getPriceTag } from "@/utilities/price";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Icon from "@/components/Icon";
import useImage from "@/compositions/useImage";

export default {
  name: "VehicleOffer",
  props: {
    vehicle: Object,
    localization: Object,
    isModelSelection: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { getModelImageUrl } = useImage();
    const isoCode = computed(() => store.getters["localization/isoCode"]);
    const priceTag = getPriceTag(
      props.vehicle.pricingDetails?.totalGrossPrice,
      props.vehicle.pricingDetails?.currencyCode,
      isoCode.value
    );
    const language = computed(
      () => store.getters["localization/activeLanguage"]
    );
    const city = computed(() => {
      if (language.value?.useOriginalOutletAddress) {
        return props.vehicle.originalCity;
      }
      return props.vehicle.city;
    });
    const isGalleryOpen = ref(false);
    const getImagePath = computed(() => {
      if (props.vehicle.vehicleImages.exterior20ImageUrl) {
        return (
          props.vehicle.vehicleImages.exterior20ImageUrl +
          "&crop=400,200,1700,900&width=500&height=350"
        );
      } else {
        return getModelImageUrl(props.vehicle.modelType.modelTypeId, "BIG");
      }
    });
    const equipment = computed(() =>
      createEquipment(props.localization, props.vehicle, true)
    );

    const onSelectVehicle = () => {
      store.dispatch("vehicles/setSelectedVehicle", props.vehicle);
      router.push({
        name: "selectedOffer",
        query: { isModelSelection: props.isModelSelection },
      });
    };

    const vehicleDistance = computed(() =>
      new Intl.NumberFormat(isoCode.value).format(props.vehicle?.distance)
    );

    return {
      city,
      getImagePath,
      priceTag,
      equipment,
      isGalleryOpen,
      onSelectVehicle,
      vehicleDistance,
    };
  },

  components: {
    VehicleEquipment,
    VehicleGallery,
    RentalPeriodDisplay,
    Icon,
  },
};
</script>

<style lang="scss">
@import "@/styles/components/vehicleOffer";
</style>

<template>
  <wb-modal
    ref="filterModal"
    class="filter-modal"
    mode="info"
    close-on-backdrop-click
    close-on-escape-key
    scroll-lock
  >
    <div class="filter-modal__inner">
      <wb-modal-head class="filter-modal__head">
        <h2 class="filter-modal__headline wb-type-heading-m">
          {{ vehiclesCount }} {{ localization?.overview?.offersTitle }}
        </h2>
        <Icon
          name="close"
          :size="20"
          class="filter-modal__close"
          @click="resetLastFilterOnClose"
        />
      </wb-modal-head>
      <wb-modal-content class="filter-modal__content">
        <p class="filter-modal__text">{{ localization?.filter?.filterBy }}:</p>

        <FilterTabs
          :localization="localization?.filter"
          :filters="filters"
          :active="active"
          @selected="filterSelected($event)"
        />
        <transition name="fade" mode="out-in">
          <component
            v-bind:is="active"
            :localization="localization"
            :name="active"
          ></component>
        </transition>
      </wb-modal-content>
      <wb-modal-footer class="filter-modal__footer">
        <button
          @click="clearFilter"
          class="filter-modal__footer-button wb-button wb-button--secondary wb-button--large"
        >
          {{ localization?.filter?.btnResetAllFilter }}
        </button>
        <button
          @click="closeModal"
          :disabled="vehiclesCount === 0"
          class="filter-modal__footer-button wb-button wb-button--primary wb-button--large"
        >
          <Icon
            name="chevron-right"
            :size="12"
            :width="8"
            :height="12"
            class="icon wb-button__icon"
          />
          {{ localization?.filter?.btnConfirmFilter }}
        </button>
      </wb-modal-footer>
    </div>
  </wb-modal>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import FilterTabs from "@/components/Vehicle/Filter/FilterTabs";
import Price from "@/components/Vehicle/Filter/Price";
import Age from "@/components/Vehicle/Filter/Age";
import Model from "@/components/Vehicle/Filter/Model";
import Equipment from "@/components/Vehicle/Filter/Equipment";
import Seats from "@/components/Vehicle/Filter/Seats";
import Icon from "@/components/Icon";
import useTrackingPage from "@/compositions/useTrackingPage";

export default {
  props: ["localization"],
  setup(_, { emit }) {
    const store = useStore();
    const filters = computed(() => store.getters["marketConfig/activeFilters"]);
    const activeFilters = computed(
      () => store.getters["vehicles/activeFilters"]
    );
    const filterModal = ref(null);
    const active = ref(null);
    const { trackResultFilter, trackResultOverview } = useTrackingPage();

    const filterSelected = (name) => {
      active.value = name;
    };

    const closeModal = () => {
      emit("close");

      trackResultOverview();
    };

    const cachedFilter = computed(() => store.getters["vehicles/cachedFilter"]);
    const resetLastFilterOnClose = () => {
      store.dispatch("vehicles/resetToCachedFilter", cachedFilter.value);
      closeModal();
    };

    const saveLastFilter = () => {
      store.dispatch("vehicles/setCachedFilter");
    };

    const expandActiveFilter = () => {
      if (
        activeFilters.value.size > 0 &&
        !(
          activeFilters.value.has("model") ||
          activeFilters.value.has("attribute")
        )
      ) {
        active.value = activeFilters.value.keys().next().value;
      } else {
        active.value = "model";
      }
    };

    onMounted(() => {
      saveLastFilter();
      expandActiveFilter();
      filterModal.value.addEventListener("wbclose", resetLastFilterOnClose);

      trackResultFilter();
    });

    const clearFilter = () => {
      active.value = "model";
      store.dispatch("vehicles/clearFilter");
    };

    const filteredVehicles = computed(
      () => store.getters["vehicles/filteredVehicles"]
    );
    const vehiclesCount = computed(() => {
      return filteredVehicles.value?.length;
    });

    return {
      filterModal,
      closeModal,
      active,
      filterSelected,
      filters,
      clearFilter,
      resetLastFilterOnClose,
      vehiclesCount,
    };
  },
  components: {
    FilterTabs,
    Price,
    Age,
    Model,
    Equipment,
    Seats,
    Icon,
  },
};
</script>

<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.filter-modal {
  animation: delayBackground 0.5s;

  .wb-modal__outer {
    @include breakpoint-from(mq3) {
      width: 85% !important;
      max-width: 890px;
      height: 98% !important;
    }
  }

  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .wb-modal__head {
    display: none;
  }

  &__head {
    color: var(--wb-grey-20);
    padding: 20px;

    @include breakpoint-from(mq3) {
      padding: 20px 30px;
    }
  }

  &__headline {
    padding-right: 30px;

    @include breakpoint-from(mq3) {
      padding-top: 40px;
    }
  }

  &__close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    color: var(--wb-grey-20);
  }

  &__content {
    flex: 1 0 50%;
    padding: 20px !important;

    @include breakpoint-from(mq3) {
      padding: 20px 30px !important;
    }
  }

  &__text {
    color: var(--wb-grey-20);
  }

  &__footer {
    padding: 5px 20px 20px;

    @include breakpoint-from(mq3) {
      justify-content: flex-end;
      padding: 10px 40px 30px;
    }

    &-button {
      width: 90%;
      margin: 0 auto;

      @include breakpoint-from(mq3) {
        width: auto;
        margin: 0;
      }
    }

    .icon {
      width: 25px;
      height: 25px;
    }
  }
}
</style>

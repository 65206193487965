import ApiService from "@/services/ApiService";

// initial state
const configState = () => ({
  reservationDetails: {},
  reservationCancelled: false,
  error: false,
  errorCancellationPeriodOver: false,
  errorAlreadyCancelled: false,
  loading: false,
});

// mutations
const mutations = {
  SET_RESERVATION_DETAILS(state, data) {
    state.reservationDetails = data;
  },
  SET_CANCELLATION_ERROR(state, error) {
    state.error = error;
  },
  SET_CANCELLATION_PERIOD_OVER_ERROR(state, error) {
    state.errorCancellationPeriodOver = error;
  },
  SET_CANCELLATION_ALREADY_CANCELLED_ERROR(state, error) {
    state.errorAlreadyCancelled = error;
  },
  SET_RESERVATION_CANCELLED(state, cancelled) {
    state.reservationCancelled = cancelled;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

// actions
const actions = {
  async getReservationDetails(
    { rootGetters, commit },
    { domainId, reservationId, email, isoCode }
  ) {
    commit("SET_LOADING", true);

    try {
      const mockMode = rootGetters["marketConfig/mockMode"];
      const reservationDetails = mockMode
        ? await ApiService.getMockReservation()
        : await ApiService.getReservation(
            domainId,
            reservationId,
            email,
            isoCode
          );

      if (reservationDetails.status === 200) {
        commit("SET_RESERVATION_DETAILS", reservationDetails.data);
        commit(
          "SET_CANCELLATION_PERIOD_OVER_ERROR",
          reservationDetails.data.rentalPeriod.bookingPeriodState !== "FUTURE"
        );
        commit(
          "SET_CANCELLATION_ALREADY_CANCELLED_ERROR",
          reservationDetails.data.cancelled
        );
      } else {
        commit("SET_CANCELLATION_ERROR", true);
      }
    } catch (error) {
      commit("SET_CANCELLATION_ERROR", true);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async cancelReservation(
    { rootGetters, commit },
    { domainId, reservationId, email, isoCode }
  ) {
    commit("SET_LOADING", true);

    try {
      const mockMode = rootGetters["marketConfig/mockMode"];
      const confirmCancel = mockMode
        ? await ApiService.cancelMockReservation()
        : await ApiService.cancelReservation(
            domainId,
            reservationId,
            email,
            isoCode
          );

      if (confirmCancel.status === 200 || confirmCancel.status === 204) {
        commit("SET_RESERVATION_CANCELLED", true);
      } else {
        commit("SET_CANCELLATION_ERROR", true);
      }
    } catch (e) {
      commit("SET_CANCELLATION_ERROR", true);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  setCancellationError({ commit }, error) {
    commit("SET_CANCELLATION_ERROR", error);
  },
};

// getters
const getters = {
  reservationDetails: (state) => {
    return state.reservationDetails;
  },
  reservationCancelled: (state) => {
    return state.reservationCancelled;
  },
  error: (state) => {
    return state.error;
  },
  errorCancellationPeriodOver: (state) => {
    return state.errorCancellationPeriodOver;
  },
  errorAlreadyCancelled: (state) => {
    return state.errorAlreadyCancelled;
  },
  loading: (state) => {
    return state.loading;
  },
};

export default {
  namespaced: true,
  state: configState,
  getters,
  actions,
  mutations,
};

import { CONFIGURED_HOSTS } from "@/assets/hosts";
import userCentricsConfig from "@/assets/userCentrics/config";

export default {
  initUserCentricsConfig(host) {
    switch (host) {
      case CONFIGURED_HOSTS.localhost:
      case CONFIGURED_HOSTS.de.dev:
      case CONFIGURED_HOSTS.de.int:
      case CONFIGURED_HOSTS.de.preProd:
      case CONFIGURED_HOSTS.de.prod:
        this.addUserCentricsScript("de");
        break;
      case CONFIGURED_HOSTS.ch.dev:
      case CONFIGURED_HOSTS.ch.int:
      case CONFIGURED_HOSTS.ch.preProd:
      case CONFIGURED_HOSTS.ch.prod:
        this.addUserCentricsScript("ch");
        break;
      case CONFIGURED_HOSTS.at.dev:
      case CONFIGURED_HOSTS.at.int:
      case CONFIGURED_HOSTS.at.preProd:
      case CONFIGURED_HOSTS.at.prod:
        this.addUserCentricsScript("at");
        break;
      default:
        return undefined;
    }
  },

  addUserCentricsScript(userCentricsDomain) {
    let script = document.createElement("script");
    script.setAttribute("id", userCentricsConfig.id);
    script.setAttribute("src", userCentricsConfig.src);
    script.setAttribute(
      "data-settings-id",
      userCentricsConfig.domainIds[userCentricsDomain]
    );
    document.getElementsByTagName("head")[0].appendChild(script);
  },
};

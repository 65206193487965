import { api } from "@/services/ApiService";

export default {
  getImageUrl(params) {
    const { imageId, isLandingPage } = params;
    return `${api}/image/${imageId}?landingpage=${isLandingPage}`;
  },
  getModelImageUrl(params) {
    const { modelTypeId, size, domainId } = params;
    return `${api}/image/modeltype/${modelTypeId}?size=${size}&domainId=${domainId}`;
  },
  getVehicleTypeImageUrl(vehicleTypeId) {
    return `${api}/image/vehicletype/${vehicleTypeId}`;
  },
};

<template>
  <teleport to="#teleport">
    <wb-modal
      ref="modal"
      mode="info"
      close-on-backdrop-click
      close-on-escape-key
      scroll-lock
      active-level="1"
      class="vehicle-gallery"
    >
      <wb-modal-head class="vehicle-gallery__head">
        <h2 class="vehicle-gallery__name wb-type-heading-m">
          {{ vehicle.name }}
        </h2>
        <h3 class="vehicle-gallery__model wb-type-copy-strong">
          {{ vehicle.modelType?.modelClassName }}
        </h3>
        <div @click="closeModal" class="vehicle-gallery__close">
          <Icon :size="12" name="close" />
        </div>
      </wb-modal-head>
      <wb-modal-content class="vehicle-gallery__content">
        <img
          :src="currentImage"
          :alt="vehicle.name"
          class="vehicle-gallery__image"
        />

        <div
          v-if="images.length && images.length > 1"
          class="vehicle-gallery__thumbs"
        >
          <img
            v-for="(image, index) in images"
            :key="image"
            class="vehicle-gallery__thumbs-image"
            :class="{ active: isActiveImage(index) }"
            :src="image"
            :alt="vehicle.name"
            @click="switchImage(index)"
            onerror="this.remove()"
          />
        </div>
        <div
          class="vehicle-gallery__arrow vehicle-gallery__next"
          :class="{ white: isWhite, disabled: nextDisabled }"
          @click="nextImage"
        >
          <Icon name="chevron-right" />
        </div>
        <div
          class="vehicle-gallery__arrow vehicle-gallery__prev"
          :class="{ white: isWhite, disabled: prevDisabled }"
          @click="prevImage"
        >
          <Icon name="chevron-left" />
        </div>
      </wb-modal-content>
    </wb-modal>
  </teleport>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import Icon from "@/components/Icon";
import useImage from "@/compositions/useImage";
import useTrackingPage from "@/compositions/useTrackingPage";

export default {
  name: "VehicleGallery",
  props: ["vehicle"],
  emits: ["close"],
  setup(props, { emit }) {
    const vehicle = props.vehicle;
    const images = ref([]);
    const modal = ref(null);
    const currentImage = ref(null);
    const { trackResultOverview, trackResultVehicleImages } = useTrackingPage();

    const closeModal = () => {
      emit("close");

      trackResultOverview();
    };
    const { getModelImageUrl } = useImage();

    const collectImages = () => {
      if (!vehicle.vehicleImages?.exterior20ImageUrl) {
        return [getModelImageUrl(vehicle.modelType.modelTypeId, "BIG")];
      } else {
        return [
          vehicle.vehicleImages?.exterior20ImageUrl,
          vehicle.vehicleImages?.exterior80ImageUrl,
          vehicle.vehicleImages?.exterior140ImageUrl,
          vehicle.vehicleImages?.interior1ImageUrl,
          vehicle.vehicleImages?.interior2ImageUrl,
        ];
      }
    };

    onMounted(() => {
      if (modal.value) {
        modal.value.addEventListener("wbclose", closeModal);
      }
      images.value = collectImages().filter((imageUrl) => imageUrl !== null);

      currentImage.value = images.value[0];

      trackResultVehicleImages();
    });

    const switchImage = (index) => {
      currentImage.value = images.value[index];
    };

    const isActiveImage = (index) => {
      return currentImage.value === images.value[index];
    };

    const isWhite = computed(() => {
      if (currentImage.value) {
        return currentImage.value.includes("POV=BI");
      }
      return false;
    });

    const nextDisabled = computed(
      () =>
        images.value.length === 1 ||
        images.value.indexOf(currentImage.value) === images.value.length - 1
    );

    const prevDisabled = computed(
      () =>
        images.value.length === 1 ||
        images.value.indexOf(currentImage.value) === 0
    );

    const nextImage = () => {
      if (images.value.length === 1) return;

      const index = images.value.indexOf(currentImage.value);
      if (index !== images.value.length - 1) {
        currentImage.value = images.value[index + 1];
      }
    };

    const prevImage = () => {
      if (images.value.length === 1) return;

      const index = images.value.indexOf(currentImage.value);
      if (index !== 0) {
        currentImage.value = images.value[index - 1];
      }
    };

    return {
      modal,
      images,
      closeModal,
      currentImage,
      switchImage,
      isActiveImage,
      isWhite,
      nextImage,
      prevImage,
      nextDisabled,
      prevDisabled,
    };
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss">
@import "@/styles/components/vehicleGallery";
</style>

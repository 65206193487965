<template>
  <LoadingScreen
    v-if="isLoading"
    :loadingText="localization?.mainPage?.search?.loadingText"
  />
  <div v-else class="vehicles">
    <SearchPageSummary
      class="vehicles__summary"
      :searchLocalization="localization?.resultPage?.overview"
    />
    <VehicleOfferList
      class="vehicles__offers"
      :localization="localization"
      :vehicles="groupedVehicles"
    />

    <div
      v-if="isInquiryActive && hasVBSOutlets"
      class="vehicles__requestheader"
    >
      <h1 class="wb-type-heading-m">
        {{ localization?.vehicleRequestPage?.linkHeader }}
      </h1>
    </div>
    <div class="vehicles__actions">
      <button
        @click="vehicleRequest"
        type="button"
        class="vehicles__actions__button wb-button wb-button--secondary wb-button--medium wb-button--theme-dark"
        v-if="isInquiryActive && hasVBSOutlets"
      >
        <Icon name="chevron-right" :size="12" :width="18" :height="12" />
        {{ localization?.vehicleRequestPage?.linkTitle }}
      </button>
      <button
        @click="redirectToHomeOrDealer"
        type="button"
        class="vehicles__actions__button wb-button wb-button--primary wb-button--medium"
      >
        <Icon name="chevron-right" :size="12" :width="18" :height="12" />
        {{ localization?.resultPage?.overview?.btnBackToHome }}
      </button>
    </div>
    <ModalMessage
      v-if="directSearchError"
      :localization="{
        headline: localization?.resultPage?.errorDirectSearch?.header,
        message: localization?.resultPage?.errorDirectSearch?.description,
        btnText: localization?.resultPage?.errorDirectSearch?.button,
      }"
    />
  </div>
</template>

<script>
import { computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import LoadingScreen from "@/components/LoadingScreen";
import SearchPageSummary from "@/components/Search/SearchPageSummary";
import VehicleOfferList from "@/components/Vehicle/VehicleOfferList";
import Icon from "@/components/Icon";
import useTrackingPage from "@/compositions/useTrackingPage";
import { scrollToTop } from "@/utilities/scroll";
import useHomeRedirect from "@/compositions/useHomeRedirect";
import ModalMessage from "@/components/Notification/ModalMessage";
import useTrackingError from "@/compositions/useTrackingError";

export default {
  name: "Vehicles",
  props: {
    target: {
      type: String,
      default: "",
    },
    activeFilter: {
      type: String,
      default: "",
    },
    directSearchError: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const isLoading = computed(() => store.getters["vehicles/isLoading"]);
    const request = computed(() => store.getters["search/request"]);
    const groupedVehicles = computed(
      () => store.getters["vehicles/groupedVehicles"]
    );

    const sort = computed(() => store.getters["vehicles/sort"]);
    const activeFilters = computed(
      () => store.getters["vehicles/activeFilters"]
    );

    const isInquiryActive = computed(
      () => store.getters["marketConfig/inquiryMode"]
    );
    const hasVBSOutlets = computed(() => store.getters["search/hasVBSOutlets"]);
    const { redirectToHomeOrDealer } = useHomeRedirect();

    const vehicleRequest = () => {
      router.push({ name: "vehicleRequest" });
      scrollToTop();
    };

    // re-fetch vehicles if activeFilters updated
    watch(
      () => activeFilters.value,
      (filters) => {
        store.dispatch("vehicles/filterVehicles", filters);
      }
    );

    // re-fetch vehicles if sorting changes
    watch(
      () => sort.value,
      () => {
        store.dispatch("vehicles/sortVehicles", sort.value);
      }
    );

    // onInit fetch vehicles if request exists else redirect to home
    const isoCode = computed(() => store.getters["localization/isoCode"]);
    watch(
      () => request.value,
      () => {
        // if coming from redirect, do nothing
        if (props.target === "directSearch") return;
        if (request.value) {
          if (!Object.keys(groupedVehicles.value).length) {
            store.dispatch("vehicles/getVehicles", {
              ...request.value,
              isoCode: isoCode.value,
            });
          }
        } else {
          router.push({ name: "home" });
        }
      },
      {
        immediate: true,
      }
    );

    const { trackResultOverview } = useTrackingPage();

    watch(
      () => isLoading.value,
      () => {
        // Tracking when the vehicle-offers finished loading
        trackResultOverview();

        // check if has activeFilters & filter vehicles
        if (
          Object.keys(groupedVehicles.value).length &&
          activeFilters.value.size
        ) {
          store.dispatch("vehicles/filterVehicles", activeFilters.value);
        }
      }
    );

    const directSearchError = props["directSearchError"];
    const localization = computed(
      () => store.getters["localization/localization"]
    );
    if (directSearchError) {
      const { trackErrorVehicles } = useTrackingError();
      trackErrorVehicles(
        localization?.value.resultPage?.errorDirectSearch?.description
      );
    }
    // Tracking when loading is complete but user returns to this view bc of any reason (click "back" etc)
    onMounted(() => {
      if (!isLoading.value) {
        trackResultOverview();
      }
      store.dispatch("vehicles/setModelSelectionIsOrigin", false);
    });

    return {
      isLoading,
      localization,
      groupedVehicles,
      redirectToHomeOrDealer,
      vehicleRequest,
      isInquiryActive,
      hasVBSOutlets,
    };
  },
  components: {
    LoadingScreen,
    SearchPageSummary,
    VehicleOfferList,
    Icon,
    ModalMessage,
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.vehicles {
  background: var(--wb-black);

  @include breakpoint-from(mq3) {
    padding-top: 30px;
  }

  &__summary {
    padding-bottom: 30px;

    @include breakpoint-from(mq3) {
      margin: 0 7.5%;
    }
  }

  &__offers {
    padding-bottom: 30px;
  }

  &__requestheader {
    padding-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;

    @include breakpoint-from(mq3) {
      margin: 0 7.5%;
    }
  }

  &__actions {
    padding-bottom: 50px;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include breakpoint-from(mq3) {
      margin: 0 7.5%;
      flex-direction: row;
      justify-content: flex-start;
    }

    &__button {
      width: 100%;

      &:first-child {
        margin-bottom: 15px;
      }

      @include breakpoint-from(mq3) {
        flex: 0 0 45%;
        width: 45%;

        &:first-child {
          margin-right: 5%;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>

<template>
  <div class="filter-tabs">
    <div
      class="filter-tabs__tab wb-type-copy-secondary"
      :class="{ active: filterIsActive(name) || name === active }"
      v-for="name in filters"
      :key="name"
      @click="$emit('selected', name)"
    >
      <div class="filter-tabs__icon">
        <Icon
          :name="filterIcon(name)"
          :size="iconSize(name)"
          :width="iconWidth(name)"
          :height="iconHeight(name)"
          :class="name"
        />
      </div>

      {{ filterName(name, localization) }}
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { filterName } from "@/utilities/filter";
import Icon from "@/components/Icon";

export default {
  name: "FilterTabs",
  props: ["localization", "filters", "active"],
  setup() {
    const store = useStore();
    const activeFilters = computed(
      () => store.getters["vehicles/activeFilters"]
    );

    const filterIcon = (name) => {
      switch (name) {
        case "price":
          return "euro";
        case "age":
          return "user";
        case "model":
          return "van";
        case "seats":
          return "seat";
        default:
          return name;
      }
    };

    const iconSize = (name) => {
      if (name === "model") {
        return 15;
      }
      return 24;
    };

    const iconWidth = (name) => {
      if (name === "model") {
        return 40;
      }
      return 24;
    };

    const iconHeight = (name) => {
      if (name === "model") {
        return 16;
      }
      return 24;
    };

    const filterIsActive = (name) => {
      return activeFilters.value.get(name);
    };

    return {
      filterIcon,
      filterName,
      filterIsActive,
      iconSize,
      iconWidth,
      iconHeight,
    };
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.filter-tabs {
  margin: 10px 0 40px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  &__tab {
    position: relative;
    flex: 0 0 30%;
    width: 30%;
    max-width: 130px;
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 7px 10px;
    border: 1px solid var(--wb-grey-45);
    color: var(--wb-grey-20);
    text-align: center;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    &:nth-child(3) {
      margin-right: 0;

      @include breakpoint-from(mq2) {
        margin-right: 15px;
      }
    }

    &.active {
      color: var(--wb-blue-45);
      border-color: var(--wb-blue-45);
    }

    @include breakpoint-from(mq3) {
      &:hover {
        color: var(--wb-blue-45);
        border-color: var(--wb-blue-45);
      }
    }
  }

  &__icon {
    .model {
      width: 40px;
      transform: scaleX(-1);
    }
  }

  &__close {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 25px;
    height: 25px;
  }
}
</style>

const MilliSecInOneDay = 86400000;
const MilliSecInOneHour = 3600000;

export function calcIsEqualOrLessThanOneHour(rentalPeriodInMilliSec) {
  return rentalPeriodInMilliSec <= MilliSecInOneHour;
}

export function calcIsLessThanOneDay(rentalPeriodInMilliSec) {
  return rentalPeriodInMilliSec < MilliSecInOneDay;
}

export function calcRentalPeriodHours(rentalPeriodInMilliSec) {
  return calcIsEqualOrLessThanOneHour(rentalPeriodInMilliSec)
    ? 1
    : rentalPeriodInMilliSec / MilliSecInOneHour;
}

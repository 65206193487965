<template>
  <div class="home">
    <LoadingScreen v-if="!localization?.mainPage" />
    <!-- This v-else is needed because otherwise all three trackingpoints will be visible at initial load of the page. -->
    <div v-else>
      <div class="home__teaser-search">
        <TopTeaser :content="localization?.mainPage?.contentTopTeaser" />
        <SearchArea
          :title="localization.mainPage?.search?.location?.title"
          :searchBtn="localization.mainPage?.search?.btnSearch"
        />
      </div>

      <hr
        class="home__spacer"
        v-if="!isMobile && modelSelectionFeatureActive"
      />
      <div class="content-width">
        <ModelSelection />
      </div>
      <hr class="home__spacer" />
      <div ref="modelTilesRef"></div>
      <div class="content-width">
        <div class="home__content-box">
          <div class="home__content-box-inline">
            <div class="home__content-box__item">
              <ContentBox
                :localization="localization?.mainPage?.contentBoxes?.[0]"
              />
            </div>
            <hr class="home__spacer" v-if="isMobile" />
            <div class="home__content-box__item">
              <ContentBox
                :localization="localization?.mainPage?.contentBoxes?.[1]"
              />
            </div>
          </div>
          <hr class="home__spacer" />
          <div
            class="home__content-box__item"
            v-if="mainPageBigContentBoxActive && isMobile"
          >
            <ContentBox
              :localization="localization?.mainPage?.contentBoxes?.[2]"
            />
          </div>
          <ContentBoxBig
            v-if="mainPageBigContentBoxActive && !isMobile"
            :localization="localization?.mainPage?.contentBoxes?.[2]"
          />
          <div ref="contentBoxesRef"></div>
          <hr class="home__spacer" v-if="mainPageBigContentBoxActive" />
          <div>
            <Benefits
              v-if="localization?.mainPage?.benefits"
              :benefits="localization.mainPage.benefits"
            />
            <div ref="benefitsRef"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, onMounted, onUnmounted, ref, onUpdated } from "vue";
import { useStore } from "vuex";
import TopTeaser from "@/components/Home/TopTeaser";
import SearchArea from "@/components/Search/SearchArea";
import LoadingScreen from "@/components/LoadingScreen";
import useTrackingPage from "@/compositions/useTrackingPage";
import ContentBox from "@/components/Home/ContentBox";
import Benefits from "@/components/Benefits";
import { ViewportObserver } from "@workbench/core";
import ContentBoxBig from "@/components/Home/ContentBoxBig";
import ModelSelection from "@/components/ModelSelection/ModelSelection";
import { scrollToTop } from "@/utilities/scroll";
import useModelSelection from "@/compositions/useModelSelection";

export default {
  name: "Home",
  setup() {
    const store = useStore();
    const modelTilesObserver = ref(null);
    const benefitsObserver = ref(null);
    const contentBoxesObserver = ref(null);
    const localization = computed(
      () => store.getters["localization/localization"]
    );
    const marketConfigPreAuthData = computed(
      () => store.getters["marketConfig/preAuthData"]
    );

    const activeLanguage = computed(
      () => store.getters["localization/activeLanguage"]
    );
    const { modelSelectionFeatureActive } = useModelSelection();

    if (computed(() => store.getters["mainOutlet/gsId"]).value) {
      store.dispatch("mainOutlet/resetMainOutlet");
    }

    const {
      trackHomeStart,
      trackHomePromotionTiles,
      trackHomeContent,
      trackHomeModelTiles,
    } = useTrackingPage();

    const tsLoaded = computed(
      () => store.getters["marketConfig/trackingScriptLoaded"]
    );
    let contentBoxesTracked = false;
    const contentBoxesRef = ref(null);
    let benefitsTracked = false;
    const benefitsRef = ref(null);
    let homeStartTracked = false;
    let modelTilesTracked = false;
    const modelTilesRef = ref(null);

    watch(
      () => [
        activeLanguage.value,
        tsLoaded.value,
        marketConfigPreAuthData.value,
      ],
      ([language, loaded, marketConfig]) => {
        triggerHomeTracking(language, loaded, marketConfig);
      }
    );

    const selectedDate = computed(() => store.getters["search/selectedDate"]);
    const selectedTime = computed(() => store.getters["search/selectedTime"]);
    const searchComplete = computed(() => {
      return !!(
        selectedDate.value?.from &&
        selectedDate.value?.to &&
        selectedTime.value?.from &&
        selectedTime.value?.to
      );
    });

    const triggerHomeTracking = (language, loaded, marketConfig) => {
      if (
        language?.isoCode &&
        loaded &&
        !marketConfig.maintenancePage &&
        marketConfig.domainActive
      ) {
        if (!homeStartTracked && !searchComplete.value) {
          trackHomeStart();
          homeStartTracked = true;
        }
        if (isInViewport(contentBoxesRef.value) && !contentBoxesTracked) {
          contentBoxesTracked = true;
          setTimeout(function () {
            trackHomeContent();
          }, 750);
        }
        if (isInViewport(benefitsRef.value) && !benefitsTracked) {
          benefitsTracked = true;
          setTimeout(function () {
            trackHomePromotionTiles();
          }, 1500);
        }
        if (isInViewport(modelTilesRef.value) && !modelTilesTracked) {
          modelTilesTracked = true;
          setTimeout(function () {
            trackHomeModelTiles();
          }, 750);
        }
      }
    };

    const trackModelTiles = () => {
      if (
        !modelTilesTracked &&
        activeLanguage.value.isoCode &&
        tsLoaded.value
      ) {
        trackHomeModelTiles();
        modelTilesTracked = true;
      }
    };

    const observeModelTiles = () => {
      if (modelTilesRef.value) {
        modelTilesObserver.value = new ViewportObserver({
          element: modelTilesRef.value,
          viewportEnterFn: () => trackModelTiles(),
        });
      }
    };

    const trackBenefits = () => {
      if (!benefitsTracked && activeLanguage.value.isoCode && tsLoaded.value) {
        trackHomePromotionTiles();
        benefitsTracked = true;
      }
    };

    const observeBenefits = () => {
      if (benefitsRef.value) {
        benefitsObserver.value = new ViewportObserver({
          element: benefitsRef.value,
          viewportEnterFn: () => trackBenefits(),
        });
      }
    };

    const trackcontentBoxes = () => {
      if (
        !contentBoxesTracked &&
        activeLanguage.value.isoCode &&
        tsLoaded.value
      ) {
        trackHomeContent();
        contentBoxesTracked = true;
      }
    };

    const observeContentBoxes = () => {
      if (contentBoxesRef.value && tsLoaded && activeLanguage.value) {
        contentBoxesObserver.value = new ViewportObserver({
          element: contentBoxesRef.value,
          viewportEnterFn: () => trackcontentBoxes(),
        });
      }
    };
    const isInViewport = (element) => {
      if (element) {
        const rect = element.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
        );
      }
      return false;
    };

    onMounted(() => {
      scrollToTop();
      observeBenefits();
      observeContentBoxes();
      observeModelTiles();
      benefitsTracked = false;
      contentBoxesTracked = false;
      homeStartTracked = false;
      triggerHomeTracking(
        activeLanguage.value,
        tsLoaded.value,
        marketConfigPreAuthData.value
      );
    });

    onUpdated(() => {
      observeModelTiles();
      observeBenefits();
      observeContentBoxes();
    });

    onUnmounted(() => {
      if (modelTilesObserver.value) {
        modelTilesObserver.value.disconnect();
      }
      if (benefitsObserver.value) {
        benefitsObserver.value.disconnect();
      }
      if (contentBoxesObserver.value) {
        contentBoxesObserver.value.disconnect();
      }
    });

    return {
      localization,
      marketConfigPreAuthData,
      modelTilesRef,
      benefitsRef,
      contentBoxesRef,
      modelSelectionFeatureActive,
      mainPageBigContentBoxActive: computed(
        () => store.getters["marketConfig/mainPageBigContentBoxActive"]
      ),
      isMobile: computed(() => store.getters["localization/isMobile"]),
    };
  },
  components: {
    TopTeaser,
    SearchArea,
    Benefits,
    LoadingScreen,
    ContentBox,
    ContentBoxBig,
    ModelSelection,
  },
};
</script>
<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";
@import "~@workbench/core/dist/css/extensions/spacing";

.home {
  &__teaser-search {
    display: flex;
    flex-direction: column-reverse;
    @include breakpoint-from(mq3) {
      flex-direction: column;
    }
  }

  &__content-box {
    width: 100%;

    &-inline {
      @include breakpoint-from(mq3) {
        display: flex;
      }
    }

    &__item {
      @include breakpoint-from(mq3) {
        width: calc(50% - 10px);

        &:nth-child(2) {
          margin-left: 20px;
        }
      }
    }
  }

  &__spacer {
    padding-top: 50px;
    border: none;
    margin: 0;

    @include breakpoint-from(mq3) {
      padding-top: 150px;
    }
  }

  &__button {
    color: var(--wb-white);
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
</style>

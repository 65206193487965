<template>
  <div class="footer-country-links">
    <p
      v-if="footer?.dropDownCountrySelection"
      class="footer-country-links__item wb-type-copy"
    >
      {{ footer?.dropDownCountrySelection }}
    </p>

    <wb-select-control class="footer-country-links__item" compact theme="dark">
      <wb-select>
        <label>{{ footer?.dropDownCountrySelection }}</label>
        <select v-model="countryLink" @change="redirect($event)">
          <option :value="null">
            {{ footer?.pleaseSelect }}
          </option>
          <option
            v-for="countryLink in footer?.countryLinks"
            :value="countryLink.url"
            :key="countryLink.label"
          >
            {{ countryLink.label }}
          </option>
        </select>
      </wb-select>
    </wb-select-control>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    footer: {
      type: [Object, undefined],
    },
    isMobile: {
      type: Boolean,
    },
  },
  setup(props) {
    const countryLink = ref(null);

    const redirect = (event) => {
      if (countryLink.value) {
        if (!props.isMobile) {
          window.open(event.target.value, "_blank");
        } else {
          window.location.href = event.target.value;
        }
      }
    };

    return {
      redirect,
      countryLink,
    };
  },
};
</script>

<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.footer-country-links {
  @include breakpoint-from(mq3) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }

  &__item {
    padding-bottom: 10px;

    @include breakpoint-from(mq3) {
      padding-bottom: 0;

      &:first-child {
        padding-right: 15px;
      }
    }
  }

  // overwrite workbench select
  wb-select-control wb-select {
    height: 40px;
  }
  wb-select-control wb-select select {
    height: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  wb-select-control > wb-select wb-icon {
    top: 0.6rem;
    width: 20px;
    height: 20px;
  }
}
</style>

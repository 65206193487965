<template>
  <LoadingScreen v-if="!dsaPage" />
  <template v-else>
    <transition name="slide-down">
      <ErrorMessage
        v-if="isError"
        :message="dsaPage.postErrorMessage"
        :homeButton="true"
        :buttonText="dsaPage.backToHomeLabel"
        @buttonClicked="redirectToHomeOrDealer()"
      />
      <wb-notification-host
        v-else-if="isError === false"
        position="bottom-center"
      >
        <wb-notification
          theme="dark"
          icon="bds/checkmark-circle/24"
          lifetime="10000"
          class="wb-margin-bottom-s"
          mode="success"
          hide-dismiss-button
        >
          <wb-notification-content>
            {{ dsaPage.postSuccessMessage }}
          </wb-notification-content>
        </wb-notification>
      </wb-notification-host>
    </transition>
    <form @submit.prevent="onFormSubmit">
      <MainTeaser
        v-if="imageUrl"
        :imageUrl="imageUrl"
        :headline="dsaPage?.mainTitle"
      />
      <section class="content-width wb-margin-top-l">
        <Introduction
          :headline="dsaPage?.topSectionTitle"
          :description="dsaPage?.topSectionDescription"
        />
        <div
          class="faq-list__itemContent richtext"
          v-html="
            dsaPage.contactDataDescription.replace(
              /\$\{email\}/g,
              dsaReportEmail
            )
          "
        />
        <br />
        <div class="container">
          <div class="input-wrapper">
            <wb-input-control>
              <wb-input>
                <label>
                  {{ localization?.checkoutFieldsAsMap?.FIRST_NAME?.label }}
                  <span>*</span></label
                >
                <input
                  type="text"
                  v-model="firstName"
                  id="firstName"
                  required
                />
              </wb-input>
            </wb-input-control>
          </div>
          <div class="input-wrapper">
            <wb-input-control>
              <wb-input>
                <label>
                  {{ localization?.checkoutFieldsAsMap?.LAST_NAME?.label }}
                  <span>*</span></label
                >
                <input type="text" v-model="lastName" id="lastName" required />
              </wb-input>
            </wb-input-control>
          </div>
        </div>
        <br />
        <div class="container">
          <div class="input-wrapper">
            <wb-input-control>
              <wb-input>
                <label>
                  {{ localization?.checkoutFieldsAsMap?.EMAIL?.label }}
                  <span>*</span></label
                >
                <input type="email" v-model="email" id="email" required />
              </wb-input>
            </wb-input-control>
          </div>
          <div class="input-wrapper"></div>
        </div>
        <br />
        <br />
        <div class="faq-accordion" theme="dark">
          <wb-accordion prevent-mobile-flyout theme="dark">
            <wb-accordion-item open="true">
              <wb-accordion-toggle>
                <h2>{{ dsaPage.generalRequestSection?.title }}</h2>
              </wb-accordion-toggle>
              <wb-accordion-section>
                <wb-accordion-mobile-header>Close</wb-accordion-mobile-header>
                <wb-accordion-content>
                  <div
                    class="richtext"
                    v-html="dsaPage.generalRequestSection?.description"
                  />
                  <br />
                  <wb-input-control>
                    <wb-input>
                      <label>{{ dsaPage.generalRequestSection?.label }}</label>
                      <textarea
                        v-model="generalRequest"
                        id="generalRequest"
                      ></textarea>
                    </wb-input>
                  </wb-input-control>
                </wb-accordion-content>
              </wb-accordion-section>
            </wb-accordion-item>
            <wb-accordion-item>
              <wb-accordion-toggle>
                <h2>
                  {{ dsaPage.reportTermsAndConditionsIssueSection?.title }}
                </h2>
              </wb-accordion-toggle>
              <wb-accordion-section>
                <wb-accordion-mobile-header>Close</wb-accordion-mobile-header>
                <wb-accordion-content>
                  <div
                    class="richtext"
                    v-html="
                      dsaPage.reportTermsAndConditionsIssueSection?.description
                    "
                  />
                  <br />
                  <wb-input-control>
                    <wb-input>
                      <label>{{
                        dsaPage.reportTermsAndConditionsIssueSection?.label
                      }}</label>
                      <textarea
                        v-model="reportTermsAndConditionsIssue"
                        id="reportTermsAndConditionsIssue"
                      ></textarea>
                    </wb-input>
                  </wb-input-control>
                </wb-accordion-content>
              </wb-accordion-section>
            </wb-accordion-item>
            <wb-accordion-item>
              <wb-accordion-toggle>
                <h2>{{ dsaPage.complaintSection?.title }}</h2>
              </wb-accordion-toggle>
              <wb-accordion-section>
                <wb-accordion-mobile-header>Close</wb-accordion-mobile-header>
                <wb-accordion-content>
                  <div
                    class="richtext"
                    v-html="dsaPage.complaintSection?.description"
                  />
                  <br />
                  <wb-input-control>
                    <wb-input>
                      <label>{{ dsaPage.complaintSection?.label }}</label>
                      <textarea v-model="complaint" id="complaint"></textarea>
                    </wb-input>
                  </wb-input-control>
                </wb-accordion-content>
              </wb-accordion-section>
            </wb-accordion-item>
            <wb-accordion-item>
              <wb-accordion-toggle>
                <h2>{{ dsaPage.reportDsaIssueSection?.title }}</h2>
              </wb-accordion-toggle>
              <wb-accordion-section>
                <wb-accordion-mobile-header>Close</wb-accordion-mobile-header>
                <wb-accordion-content>
                  <div
                    class="richtext"
                    v-html="dsaPage.reportDsaIssueSection?.description"
                  />
                  <br />
                  <wb-input-control>
                    <wb-input>
                      <label>{{ dsaPage.reportDsaIssueSection?.label }}</label>
                      <textarea
                        v-model="reportDsaIssue"
                        id="reportDsaIssue"
                      ></textarea>
                    </wb-input>
                  </wb-input-control>
                </wb-accordion-content>
              </wb-accordion-section>
            </wb-accordion-item>
            <wb-accordion-item>
              <wb-accordion-toggle>
                <h2>{{ dsaPage.reportOffenseSection?.title }}</h2>
              </wb-accordion-toggle>
              <wb-accordion-section>
                <wb-accordion-mobile-header>Close</wb-accordion-mobile-header>
                <wb-accordion-content>
                  <div
                    v-html="dsaPage.reportOffenseSection?.description"
                    class="provider-overlay__content__accordion richtext"
                  />
                  <div>
                    <br /><br />
                    <h3 class="wb-type-heading-xs">
                      {{ dsaPage.reportOffenseSection?.whenSection?.title }}
                    </h3>
                    <div
                      class="provider-overlay__content__accordion richtext"
                      v-html="
                        dsaPage.reportOffenseSection?.whenSection?.description
                      "
                    />
                    <br />
                    <wb-input-control>
                      <wb-input>
                        <label>{{
                          dsaPage.reportOffenseSection?.whenSection?.label
                        }}</label>
                        <textarea
                          v-model="reportWhen"
                          id="reportWhen"
                        ></textarea>
                      </wb-input>
                    </wb-input-control>
                  </div>
                  <div>
                    <br /><br />
                    <h3 class="wb-type-heading-xs">
                      {{ dsaPage.reportOffenseSection?.whereSection?.title }}
                    </h3>
                    <div
                      class="provider-overlay__content__accordion richtext"
                      v-html="
                        dsaPage.reportOffenseSection?.whereSection?.description
                      "
                    />
                    <br />
                    <wb-input-control>
                      <wb-input>
                        <label>{{
                          dsaPage.reportOffenseSection?.whereSection?.label
                        }}</label>
                        <textarea
                          v-model="reportWhere"
                          id="reportWhere"
                        ></textarea>
                      </wb-input>
                    </wb-input-control>
                  </div>
                  <div>
                    <br /><br />
                    <h3 class="wb-type-heading-xs">
                      {{ dsaPage.reportOffenseSection?.whatSection?.title }}
                    </h3>
                    <div
                      class="provider-overlay__content__accordion richtext"
                      v-html="
                        dsaPage.reportOffenseSection?.whatSection?.description
                      "
                    />
                    <br />
                    <wb-input-control>
                      <wb-input>
                        <label>{{
                          dsaPage.reportOffenseSection?.whatSection?.label
                        }}</label>
                        <textarea
                          v-model="reportWhat"
                          id="reportWhat"
                        ></textarea>
                      </wb-input>
                    </wb-input-control>
                  </div>
                  <div>
                    <br /><br />
                    <h3 class="wb-type-heading-xs">
                      {{ dsaPage.reportOffenseSection?.whoSection?.title }}
                    </h3>
                    <div
                      class="provider-overlay__content__accordion richtext"
                      v-html="
                        dsaPage.reportOffenseSection?.whoSection?.description
                      "
                    />
                    <br />
                    <wb-input-control>
                      <wb-input>
                        <label>{{
                          dsaPage.reportOffenseSection?.whoSection?.label
                        }}</label>
                        <textarea v-model="reportWho" id="reportWho"></textarea>
                      </wb-input>
                    </wb-input-control>
                  </div>
                </wb-accordion-content>
              </wb-accordion-section>
            </wb-accordion-item>
          </wb-accordion>
        </div>
        <br /><br />
        <div v-if="isCaptchaActive">
          <BaseInput
            type="text"
            name="Captcha"
            maxlength="4"
            :required="true"
            :data="{ label: captchaLocalization.placeholder }"
            :error="captchaError"
            v-model="captchaInput"
          >
            <h4 class="wb-type-copy">
              {{ captchaLocalization.label }}
            </h4>
          </BaseInput>
          <div class="wb-margin-vertical-xs captcha-container">
            <div v-if="captcha?.image">
              <img
                class="captcha-image"
                :src="'data:image/png;base64,' + captcha?.image"
                alt="captcha"
              />
            </div>
            <a class="wb-link--standalone" :onclick="updateCaptcha">
              <Icon name="reload" :size="12" :width="18" :height="12" />
              {{ captchaLocalization?.loadNewImage }}
            </a>
          </div>
        </div>
        <div
          class="faq-list__itemContent richtext"
          v-html="dsaPage.submitButtonDescription"
        />
        <div class="faq-list__itemContent richtext">
          <a
            href="/20240805_DSI_Produkt_DSA.pdf"
            target="_blank"
            rel="noopener noreferrer"
            >{{ dsaPage.dsaHintsLinkText }}</a
          >
          <br /><br />
        </div>
        <button
          :disabled="isLoading || !isFormValid"
          type="submit"
          class="vehicle-request__buttons__button checkout__actions__button checkout__actions__button--continue wb-button wb-button--primary wb-button--medium"
        >
          {{ dsaPage.submitButtonLabel }}
        </button>
      </section>
    </form>
  </template>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import useCaptcha from "@/compositions/useCaptcha";
import useHomeRedirect from "@/compositions/useHomeRedirect";

import ApiService from "@/services/ApiService";
import MainTeaser from "@/components/ContentPage/MainTeaser.vue";
import useImage from "@/compositions/useImage";
import Introduction from "@/components/ContentPage/Introduction.vue";
import BaseInput from "@/components/Form/BaseInput.vue";
import Icon from "@/components/Icon.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import ErrorMessage from "@/components/Notification/ErrorMessage.vue";

export default {
  name: "DsaReport",
  components: {
    ErrorMessage,
    MainTeaser,
    LoadingScreen,
    Icon,
    BaseInput,
    Introduction,
  },
  setup() {
    const store = useStore();
    const { redirectToHomeOrDealer } = useHomeRedirect();
    const { getImageUrl } = useImage();
    const domainId = computed(() => store.getters["marketConfig/domainId"]);
    const isMobile = computed(() => store.getters["localization/isMobile"]);
    const localization = computed(
      () => store.getters["localization/localization"]
    );
    const aboutUs = computed(() => store.getters["localization/aboutUs"]);
    const dsaPage = computed(
      () => store.getters["localization/digitalServicesActPage"]
    );
    const dsaReportEmail = computed(
      () => store.getters["marketConfig/dsaReportEmail"]
    );

    const captchaInput = ref(null);
    const captchaError = ref(null);
    const captchaValid = computed(() => store.getters["captcha/isValid"]);
    const captchaLocalization = computed(
      () => store.getters["localization/checkoutPage"].captcha
    );
    const isCaptchaActive = computed(
      () => store.getters["marketConfig/captcha"]
    );

    let captchaService;
    let captcha = ref(null);

    if (true) {
      captchaService = useCaptcha();
      captcha = computed(() => store.getters["captcha/captcha"]);
    }

    const updateCaptcha = () => {
      if (captchaService) {
        captchaService.refreshCaptcha();
        captcha = computed(() => store.getters["captcha/captcha"]);
      }
    };

    if (isCaptchaActive.value) {
      updateCaptcha(); // Ensure captcha is updated on component setup
    }

    const captchaData = computed(() => store.getters["captcha/captchaData"]);
    const captchaId = computed(() => store.getters["captcha/captcha"]);
    const captchaText = computed(() => store.getters["captcha/userInput"]);

    const imageUrl = computed(() => {
      let image = "";
      if (isMobile.value) {
        image = getImageUrl(aboutUs.value?.headerImageIdMobile, false);
      } else {
        image = getImageUrl(aboutUs.value?.headerImageIdDesktop, false);
      }
      return image;
    });

    watch(
      () => captchaInput.value,
      () => {
        store.dispatch("captcha/setUserInput", captchaInput.value);
        if (captchaInput.value.length === 4) {
          validateCaptcha();
        }
      }
    );

    watch(
      () => captchaValid.value,
      () => {
        if (captchaValid.value !== null) {
          captchaError.value = !captchaValid.value;
        }
      }
    );

    const validateCaptcha = () => {
      store.dispatch("captcha/validate", captchaData.value.validationData);
    };

    const email = ref("");
    const firstName = ref("");
    const lastName = ref("");
    const generalRequest = ref("");
    const complaint = ref("");
    const reportTermsAndConditionsIssue = ref("");
    const reportDsaIssue = ref("");
    const reportWhen = ref("");
    const reportWhere = ref("");
    const reportWhat = ref("");
    const reportWho = ref("");
    const isLoading = ref(false);
    const isError = ref(null);

    const isFormValid = computed(() => {
      return (
        firstName.value &&
        lastName.value &&
        email.value &&
        captchaInput.value &&
        captchaValid.value
      );
    });

    const resetForm = () => {
      captchaInput.value = "";
      captchaError.value = null;
      firstName.value = "";
      lastName.value = "";
      email.value = "";
      generalRequest.value = "";
      complaint.value = "";
      reportTermsAndConditionsIssue.value = "";
      reportDsaIssue.value = "";
      reportWhen.value = "";
      reportWhere.value = "";
      reportWhat.value = "";
      reportWho.value = "";
      updateCaptcha(); // Refresh captcha on form reset
    };

    const onFormSubmit = async () => {
      isLoading.value = true;
      isError.value = null;

      await validateCaptcha();

      const reportOffense = {
        reportWhen: reportWhen.value,
        reportWhere: reportWhere.value,
        reportWhat: reportWhat.value,
        reportWho: reportWho.value,
      };
      const requestBody = {
        isoCode: localization.value.isoCode,
        email: email.value,
        firstName: firstName.value,
        lastName: lastName.value,
        generalRequest: generalRequest.value,
        complaint: complaint.value,
        reportTermsAndConditionsIssue: reportTermsAndConditionsIssue.value,
        reportDsaIssue: reportDsaIssue.value,
        reportOffense: reportOffense,
        captchaId: captchaId.value.id,
        captchaText: captchaText.value,
      };

      try {
        await ApiService["postDsaReport"](requestBody, domainId.value);
        isError.value = false;
      } catch (e) {
        isError.value = true;
        updateCaptcha();
      } finally {
        isLoading.value = false;
        resetForm();
      }
    };

    return {
      localization,
      aboutUs,
      dsaPage,
      domainId,
      imageUrl,
      captcha,
      isCaptchaActive,
      updateCaptcha,
      captchaInput,
      captchaError,
      captchaLocalization,
      captchaId,
      captchaText,
      validateCaptcha,
      email,
      firstName,
      lastName,
      generalRequest,
      complaint,
      reportTermsAndConditionsIssue,
      reportDsaIssue,
      reportWhen,
      reportWhere,
      reportWhat,
      reportWho,
      isLoading,
      isError,
      isFormValid,
      onFormSubmit,
      redirectToHomeOrDealer,
      dsaReportEmail,
    };
  },
};
</script>
<style lang="scss">
@import "@/styles/components/faq";

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.input-wrapper {
  flex: 1 1 calc(50% - 10px);
  box-sizing: border-box;
}

.captcha-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  @include breakpoint-to(mq2) {
    flex-direction: column;
  }
}

.captcha-image {
  padding-right: 10px;
  padding-bottom: 10px;
}
</style>

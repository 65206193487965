<template>
  <wb-modal
    ref="providerModal"
    class="provider-overlay"
    mode="info"
    close-on-backdrop-click
    close-on-escape-key
    scroll-lock
  >
    <wb-modal-head class="provider-overlay__head">
      <Icon
        name="close"
        :size="20"
        class="provider-overlay__close"
        @click="closeModal"
      />
    </wb-modal-head>
    <wb-modal-content
      class="provider-overlay__content"
      v-if="localization?.providerOverlay"
    >
      <wb-accordion id="content">
        <wb-accordion-item
          id="provider"
          :open="preselectedTabAsync === 'provider'"
        >
          <wb-accordion-toggle>
            {{ localization?.providerOverlay?.provider?.title }}
          </wb-accordion-toggle>
          <wb-accordion-section>
            <wb-accordion-mobile-header>
              {{ localization?.checkoutPage?.btnBackBreadcrumb }}
            </wb-accordion-mobile-header>
            <wb-accordion-content>
              <div
                v-html="localization?.providerOverlay?.provider?.content"
                class="provider-overlay__content__accordion richtext"
              />
            </wb-accordion-content>
          </wb-accordion-section>
        </wb-accordion-item>
        <wb-accordion-item
          id="dataPrivacy"
          :open="preselectedTabAsync === 'dataPrivacy'"
        >
          <wb-accordion-toggle>
            {{ localization?.providerOverlay?.dataPrivacy?.title }}
          </wb-accordion-toggle>
          <wb-accordion-section>
            <wb-accordion-mobile-header>
              {{ localization?.checkoutPage?.btnBackBreadcrumb }}
            </wb-accordion-mobile-header>
            <wb-accordion-content>
              <div
                v-html="localization?.providerOverlay?.dataPrivacy?.content"
                class="provider-overlay__content__accordion richtext"
              />
            </wb-accordion-content>
          </wb-accordion-section>
        </wb-accordion-item>
        <wb-accordion-item
          id="cookies"
          :open="preselectedTabAsync === 'cookies'"
        >
          <wb-accordion-toggle>
            {{ localization?.providerOverlay?.cookies?.title }}
          </wb-accordion-toggle>
          <wb-accordion-section>
            <wb-accordion-mobile-header>
              {{ localization?.checkoutPage?.btnBackBreadcrumb }}
            </wb-accordion-mobile-header>
            <wb-accordion-content>
              <div
                v-html="localization?.providerOverlay?.cookies?.content"
                class="provider-overlay__content__accordion richtext"
              />
            </wb-accordion-content>
          </wb-accordion-section>
        </wb-accordion-item>
        <wb-accordion-item
          id="legalPreconditions"
          :open="preselectedTabAsync === 'legalPreconditions'"
        >
          <wb-accordion-toggle>
            {{ localization?.providerOverlay?.legalPreconditions?.title }}
          </wb-accordion-toggle>
          <wb-accordion-section>
            <wb-accordion-mobile-header>
              {{ localization?.checkoutPage?.btnBackBreadcrumb }}
            </wb-accordion-mobile-header>
            <wb-accordion-content>
              <div
                v-html="
                  localization?.providerOverlay?.legalPreconditions?.content
                "
                class="provider-overlay__content__accordion richtext"
              />
            </wb-accordion-content>
          </wb-accordion-section>
        </wb-accordion-item>
        <wb-accordion-item
          id="termsAndConditions"
          :open="preselectedTabAsync === 'termsAndConditions'"
        >
          <wb-accordion-toggle>
            {{ localization?.providerOverlay?.termsAndConditions?.title }}
          </wb-accordion-toggle>
          <wb-accordion-section>
            <wb-accordion-mobile-header>
              {{ localization?.checkoutPage?.btnBackBreadcrumb }}
            </wb-accordion-mobile-header>
            <wb-accordion-content>
              <div
                v-html="
                  localization?.providerOverlay?.termsAndConditions?.content
                "
                class="provider-overlay__content__accordion richtext"
              />
            </wb-accordion-content>
          </wb-accordion-section>
        </wb-accordion-item>
        <wb-accordion-item
          id="legalNotice"
          :open="preselectedTabAsync === 'legalNotice'"
        >
          <wb-accordion-toggle>
            {{ localization?.providerOverlay?.legalNotice?.title }}
          </wb-accordion-toggle>
          <wb-accordion-section>
            <wb-accordion-mobile-header>
              {{ localization?.checkoutPage?.btnBackBreadcrumb }}
            </wb-accordion-mobile-header>
            <wb-accordion-content>
              <div
                v-html="localization?.providerOverlay?.legalNotice?.content"
                class="provider-overlay__content__accordion richtext"
              />
              <div
                v-html="thirdPartyLicenses"
                class="provider-overlay__content__accordion richtext"
              />
            </wb-accordion-content>
          </wb-accordion-section>
        </wb-accordion-item>
      </wb-accordion>
    </wb-modal-content>
  </wb-modal>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import useTrackingPage from "@/compositions/useTrackingPage";
import Icon from "@/components/Icon";
import licenseFile from "!raw-loader!@/assets/legal/licenses.txt";
import { parseLicense } from "@/utilities/license";

export default {
  name: "Provider",
  components: { Icon },
  props: {
    preselectedTab: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const providerModal = ref(null);
    const { trackMetaLegalLayer } = useTrackingPage();
    const thirdPartyLicenses = parseLicense(licenseFile);
    const isMobile = computed(() => store.getters["localization/isMobile"]);
    const preselectedTabAsync = ref("");

    const localization = computed(
      () => store.getters["localization/localization"]
    );

    const activeLanguage = computed(
      () => store.getters["localization/activeLanguage"]
    );
    const tsLoaded = computed(
      () => store.getters["marketConfig/trackingScriptLoaded"]
    );

    watch(
      () => [activeLanguage.value, tsLoaded.value],
      ([language, loaded]) => {
        if (language?.isoCode && loaded) {
          trackMetaLegalLayer();
        }
      }
    );

    onMounted(() => {
      providerModal.value.addEventListener("wbclose", closeModal);

      if (activeLanguage.value.isoCode && tsLoaded.value) {
        trackMetaLegalLayer();
      }

      setTimeout(() => {
        preselectedTabAsync.value = props.preselectedTab;
      }, 300); // otherwise the back-button is not sticky
      if (!isMobile.value) {
        setTimeout(() => {
          if (props.preselectedTab) {
            const scrollAnchor = document.getElementById(props.preselectedTab);
            if (scrollAnchor) {
              scrollAnchor.scrollIntoView();
            }
          }
        }, 700); // wait for accordion to be rendered and preselected tab to be opened. Otherwise scrolling won't work
      }
    });

    const route = useRoute();
    const router = useRouter();

    const closeModal = () => {
      emit("close");

      if (route.query.preselectedTab) {
        router.push({ name: "home" });
      }
    };

    return {
      providerModal,
      localization,
      closeModal,
      thirdPartyLicenses,
      preselectedTabAsync,
    };
  },
};
</script>

<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.provider-overlay {
  .wb-modal__outer {
    @include breakpoint-from(mq4) {
      width: 85% !important;
      height: 80% !important;
      max-width: 1000px;
    }

    @include breakpoint-from(mq6) {
      width: 85% !important;
      height: 80% !important;
      max-width: 1240px;
    }
  }

  &__head {
    color: var(--wb-black);
    padding: 20px;
  }

  &__close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    color: var(--wb-black);
  }

  &__content {
    text-align: left;

    &__accordion {
      padding-top: 15px;
    }
  }

  wb-accordion-item[open] {
    background-color: white !important;
  }

  wb-accordion-section {
    background-color: white !important;
  }
}
</style>

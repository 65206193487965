<template>
  <div class="additional-services__check-box__wrapper">
    <wb-checkbox-control
      class="wb-margin-bottom-xs additional-services__check-box"
      v-for="item in service.additionalServices"
      :key="item.serviceName"
      :data-test-id="'addtional-checkbox-' + item.id"
    >
      <div>
        <label class="additional-services__check-box__label">
          <input
            type="checkbox"
            v-model="selectedItems"
            :value="item.serviceName"
            @change="checkboxChanged"
          />
          {{ item.serviceName }}
        </label>
        <ToolTip
          style="margin-left: 15px"
          v-if="item.onlineDescription"
          :text="item.onlineDescription"
        ></ToolTip>
      </div>
      <div
        class="wb-type-heading-xs"
        :class="{ deactivated: !selectedItems.includes(item.serviceName) }"
      >
        {{ renderPrice(item) }}
      </div>
    </wb-checkbox-control>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import { getPriceTag } from "@/utilities/price";
import ToolTip from "@/components/ToolTip.vue";

export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
    currencyCode: {
      type: String,
      required: true,
    },
    isoCode: {
      type: String,
      required: true,
    },
    numberOfDays: {
      type: Number,
      required: true,
    },
    pricingDetails: {
      type: Object,
      required: true,
    },
    localization: {
      type: Object,
      required: true,
    },
  },
  emits: ["selectedItemsChanged"],
  setup(props, { emit }) {
    const selectedItems = ref([]);
    const priceMap = ref(new Map());
    const checkboxChanged = () => {
      const selectedServices = {};
      props["service"].additionalServices.filter((service) => {
        if (selectedItems.value.includes(service.serviceName)) {
          selectedServices[service.serviceName] = {
            service: service,
            quantity: 1,
          };
        }
      });
      emit(
        "selectedItemsChanged",
        props["service"].categoryName,
        JSON.parse(JSON.stringify(selectedServices))
      );
    };

    const renderPrice = ref((item) => {
      return item.dailyCharge
        ? priceMap.value.get(item.id) &&
          selectedItems.value.includes(item.serviceName)
          ? priceMap.value.get(item.id)
          : getPriceTag(item.price, props["currencyCode"], props["isoCode"]) +
            " / " +
            props["localization"].day
        : getPriceTag(item.price, props["currencyCode"], props["isoCode"]);
    });

    watch(
      () => props["pricingDetails"],
      () => {
        let selectedAdditionalServices = JSON.parse(
          JSON.stringify(props["pricingDetails"])
        ).selectedAdditionalServices;

        priceMap.value = new Map();

        selectedAdditionalServices.forEach((service) => {
          priceMap.value.set(
            service.additionalRentalServiceId,
            getPriceTag(
              service.amountIncludingTax,
              props["currencyCode"],
              props["isoCode"]
            )
          );
        });
      }
    );

    return {
      checkboxChanged,
      selectedItems,
      renderPrice,
    };
  },
  components: {
    ToolTip,
  },
};
</script>
<style lang="scss" scoped>
.additional-services__check-box {
  display: flex;
  justify-content: space-between;
  gap: 18px;

  &__label {
    flex: 0 0 68%;
    word-wrap: break-word;
  }

  &__wrapper {
    .deactivated {
      color: var(--wb-grey-65);
    }
  }
}
</style>

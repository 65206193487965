<template>
  <div v-if="localization" class="content-box">
    <h2 class="content-box__title wb-type-heading-m">
      {{ localization?.header }}
    </h2>
    <p class="content-box__text wb-type-copy" v-html="localization?.text" />
    <img
      v-if="isMobile"
      :src="getImageUrl(localization?.imageIdMobile, false)"
      :alt="localization?.text"
      class="content-box__img"
    />
    <img
      v-else
      :src="getImageUrl(localization?.imageIdDesktop, false)"
      :alt="localization?.text"
      class="content-box__img"
    />

    <button
      @click="openUrl(localization?.url)"
      class="wb-button wb-button--secondary wb-button--medium content-box__button"
    >
      <Icon
        class="content-box__button__color"
        name="chevron-right"
        :size="12"
        :width="18"
        :height="12"
      />
      <span class="content-box__button__color" v-if="localization?.button">
        {{ localization?.button }}
      </span>
    </button>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import useImage from "@/compositions/useImage";
import Icon from "@/components/Icon";

export default {
  name: "ContentBox",
  props: ["localization"],
  setup(props) {
    const store = useStore();
    const isMobile = computed(() => store.getters["localization/isMobile"]);

    const { getImageUrl } = useImage();

    return {
      isMobile,
      getImageUrl,
    };
  },

  methods: {
    openUrl: function (url) {
      window.open(url, "_blank");
    },
  },
  components: {
    Icon,
  },
};
</script>
<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  &__img {
    width: 100%;
    order: 1;

    @include breakpoint-from(mq3) {
      object-fit: cover;
      height: 200px;
      order: 3;
    }

    @include breakpoint-from(mq4) {
      object-fit: cover;
      height: 250px;
    }

    @include breakpoint-from(mq5) {
      height: 300px;
    }
  }

  &__title {
    margin-top: 20px;
    margin-bottom: 10px;
    order: 2;

    @include breakpoint-from(mq3) {
      order: 1;
    }
  }

  &__text {
    margin-bottom: 30px;
    order: 3;

    @include breakpoint-from(mq3) {
      order: 2;
      flex-grow: 1;
    }
  }

  &__button {
    margin-top: 20px;
    order: 4;

    @include breakpoint-from(mq3) {
      margin-top: 30px;
    }

    .content-box & {
      border-color: var(--wb-white);
    }

    &__color {
      color: var(--wb-white);
    }
  }
}
</style>

import {
  checkoutFields,
  extractFieldsForStep,
  stepNames,
} from "@/utilities/checkout";
import { computed, onBeforeMount, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { availableFieldsForRequestMode } from "@/components/RequestModeLongTerm/availableFields";

const useCheckoutFormValidation = function (
  possibleFields,
  stepName,
  stepNumber,
  activeField = "checkoutActive"
) {
  const store = useStore();
  const form = reactive({
    elements: [],
  });
  const salutationHint = ref(false);
  const fields =
    activeField === "requestModeActive"
      ? availableFieldsForRequestMode
      : computed(() => store.getters["marketConfig/fields"]);
  const route = useRoute();

  onBeforeMount(() => {
    setTimeout(() => {
      initFormElements();
    }, 200);
  });

  watch(
    () => route.query.step,
    () => {
      checkForErrors();
    }
  );

  watch(
    () => computed(() => store.getters["captcha/userInput"]).value,
    () => {
      checkForErrors();
    }
  );

  const localizationFields = computed(
    () => store.getters["localization/checkoutFields"]
  );
  const voucherEnabled = computed(
    () => store.getters["marketConfig/useVoucher"]
  );
  const initFormElements = () => {
    form.elements = extractFieldsForStep(
      possibleFields,
      JSON.parse(JSON.stringify(fields.value)),
      localizationFields.value,
      activeField,
      voucherEnabled.value
    );
    checkForErrors();
  };

  const getFieldData = (type) => localizationFields.value[type];

  const getRadioOptions = (type) => {
    const options = computed(() =>
      store.getters["localization/fieldOptions"](type)
    );
    return options.value?.valuesMap ?? {};
  };

  const getRadioSortedOptionKeys = (type) => {
    const options = computed(() =>
      store.getters["localization/fieldOptions"](type)
    );
    return options.value?.sortedMapKeys ?? Object.keys(getRadioOptions(type));
  };

  const validateInput = (index) => {
    if (!form.elements[index].required || !form.elements[index].value) {
      form.elements[index].error = null;
    }

    if (!form.elements[index].value && form.elements[index].required) {
      form.elements[index].error = true;
    }

    validateOptionalBusinessFields(index);

    if (form.elements[index].value && form.elements[index].regex) {
      form.elements[index].error = !form.elements[index].regex.test(
        form.elements[index].value
      );
    }
    checkForErrors();
  };

  const validateBirthday = (index) => {
    const now = new Date();
    // I am using a year of 365.25 days (0.25 because of leap years) which are 3.15576e+10 milliseconds (365.25 * 24 * 60 * 60 * 1000) respectively.
    const oneYear = 3.15576e10;
    // When Field is empty set error to null
    if (!form.elements[index].required || !form.elements[index].value) {
      form.elements[index].error = null;
    }

    // If field has a value, then check if regex matches
    if (form.elements[index].value && form.elements[index].regex) {
      form.elements[index].error = !form.elements[index].regex.test(
        form.elements[index].value
      );
    }
    //
    // // 18 years min
    // if (Math.floor(now - new Date(form.elements[index].value)) / oneYear < 18) {
    //   form.elements[index].error = true;
    // }

    checkForErrors();
  };

  const validateOptionalBusinessFields = (index) => {
    if (form.elements[index].type === checkoutFields.company) {
      const companyElement = form.elements[index];
      validateCompanyField(companyElement);
    }
  };

  const validateCompanyField = (companyElement) => {
    // company name is mandatory if the checkout field "profile type" is configured
    // and the value "business" is selected
    const profileTypeElement = findFormElementByType(
      checkoutFields.profileType
    );

    if (
      profileTypeElement &&
      profileTypeElement.value === "PROFILE_TYPE_VALUE_BUSINESS" &&
      !companyElement.value
    ) {
      companyElement.required = true;
    } else {
      companyElement.required = false;
    }
  };

  const radioCheckboxChanged = (index) => {
    updateSelectedProfileType(index);
    updateMarketingConsentCheckboxes(index);
    checkMandatoryCheckBox(form.elements[index]);
    checkForErrors();
  };

  const updateSelectedProfileType = (index) => {
    if (form.elements[index].type === checkoutFields.profileType) {
      store.dispatch(
        "checkout/setSelectedProfileType",
        form.elements[index].value
      );

      const companyElement = findFormElementByType(checkoutFields.company);

      if (companyElement) {
        validateCompanyField(companyElement);
      }
    }
  };

  const checkMandatoryCheckBox = (element) => {
    if (!element.value && element.required) {
      element.error = true;
    } else {
      element.error = false;
    }
  };

  const updateMarketingConsentCheckboxes = (index) => {
    const consentFieldTypeNames = [
      checkoutFields.promotionConsentPhone,
      checkoutFields.promotionConsentEmail,
      checkoutFields.promotionConsentSMS,
      checkoutFields.promotionConsentPost,
    ];
    const generalConsentElement = findFormElementByType(
      checkoutFields.promotionConsent
    );

    if (!generalConsentElement) {
      return;
    }

    // a) Activating or deactivating marketing consent should initially activate or deactivate all communication channels
    if (form.elements[index].type === checkoutFields.promotionConsent) {
      consentFieldTypeNames.forEach((typeName) => {
        const consentElement = findFormElementByType(typeName);

        if (consentElement) {
          consentElement.value = form.elements[index].value;
          checkMandatoryCheckBox(consentElement);
        }
      });
    }

    // b) Deselecting all marketing communication channels should deactivate the general consent
    if (
      consentFieldTypeNames.includes(form.elements[index].type) &&
      !form.elements[index].value &&
      generalConsentElement.value
    ) {
      let deactivateGeneralConsent = true;

      consentFieldTypeNames.forEach((typeName) => {
        const consentElement = findFormElementByType(typeName);

        if (consentElement && consentElement.value) {
          // at least one communication channel is still selected
          // so we do not need to deactivate the general consent
          deactivateGeneralConsent = false;
        }
      });

      if (deactivateGeneralConsent) {
        generalConsentElement.value = false;
        checkMandatoryCheckBox(generalConsentElement);
      }
    }

    // c) Activating a channel also activate the general consent (if not already selected)
    if (
      consentFieldTypeNames.includes(form.elements[index].type) &&
      form.elements[index].value &&
      !generalConsentElement.value
    ) {
      generalConsentElement.value = true;
      checkMandatoryCheckBox(generalConsentElement);
    }
  };

  const findFormElementByType = (typeName) => {
    return form.elements.find((element) => element.type === typeName);
  };

  function checkCaptcha(disabled) {
    const isCaptchaActive = computed(
      () => store.getters["marketConfig/captcha"]
    );
    const captchaInput = computed(() => store.getters["captcha/userInput"]);
    if (
      isCaptchaActive.value &&
      stepName === stepNames.rentalData &&
      captchaInput.value.length !== 4
    ) {
      disabled = true;
    }
    return disabled;
  }

  const areAllRequiredFilledExceptSalutation = (formElements) => {
    const salutationElement = formElements.find(
      (element) => element.type === checkoutFields.salutation
    );

    if (!salutationElement) {
      return false;
    }

    return !formElements.some(
      (element) =>
        (element.type === checkoutFields.salutation &&
          (element.value || !element.required)) ||
        (element.type !== checkoutFields.salutation &&
          element.required &&
          (!element.value || element.value === ""))
    );
  };

  const checkForErrors = () => {
    salutationHint.value = areAllRequiredFilledExceptSalutation(form.elements);

    if (stepNumber !== route.query.step) {
      return;
    }

    let disabled = false;
    form.elements.forEach((element) => {
      if (element.error) {
        disabled = true;
      }

      if (element.required && !element.value) {
        disabled = true;
      }
    });
    disabled = checkCaptcha(disabled);

    store.dispatch("checkout/setDisabled", disabled);
    store.dispatch("checkout/setStepDisabled", { disabled, stepName });
    store.dispatch("checkout/setFormData", {
      form: form.elements,
      stepName,
    });
  };

  return {
    form,
    salutationHint,
    validateInput,
    validateBirthday,
    radioCheckboxChanged,
    getFieldData,
    getRadioOptions,
    getRadioSortedOptionKeys,
  };
};

export default useCheckoutFormValidation;

<template>
  <wb-modal
    ref="shareLinkModal"
    class="share-link richtext"
    mode="info"
    close-on-backdrop-click
    close-on-escape-key
    scroll-lock
  >
    <wb-modal-head class="share-link__head">
      <div class="share-link__head__left wb-type-heading-m">
        <div class="share-link__head__left__wrapper">
          <div class="share-link__head__left__icon">
            <Icon name="copy" :size="20" class="share-link__share-icon" />
          </div>
          <div class="share-link__head__left__headline">
            {{ headline }}
          </div>
        </div>
        <div class="share-link__head__left__subheadline wb-type-copy">
          {{ subheadline }}
        </div>
      </div>

      <div class="share-link__head__right">
        <Icon
          name="close"
          :size="24"
          class="share-link__close"
          @click="closeModal"
        />
      </div>
    </wb-modal-head>
    <wb-modal-content class="share-link__content wb-type-copy">
      <div class="share-link__input-wrapper">
        <wb-input-control>
          <wb-input>
            <label>Link</label>
            <input type="text" :value="shareLink" readonly />
          </wb-input>
        </wb-input-control>
      </div>
      <div class="share-link__content__buttons">
        <button
          type="button"
          class="wb-button wb-button--secondary wb-button--large"
          @click="closeModal"
        >
          {{ cancelButton }}
        </button>

        <button
          type="button"
          class="wb-button wb-button--primary wb-button--large"
          @click="copyToClipboard"
        >
          <Icon :name="copied ? 'checkmark' : 'copy-clipboard'" :size="28" />
          <span class="share-link__content__buttons__right__text">
            {{ shareButton }}
          </span>
        </button>
      </div>
    </wb-modal-content>
  </wb-modal>
</template>
<script>
import Icon from "@/components/Icon.vue";
import useShareLink from "@/compositions/useShareLink";
import useTrackingLink from "@/compositions/useTrackingLink";
import { onMounted, ref } from "vue";

export default {
  name: "ShareLink",
  props: {
    headline: String,
    subheadline: String,
    shareButton: String,
    cancelButton: String,
    copiedNotification: String,
  },
  setup(props, { emit }) {
    const { createShareLink } = useShareLink();
    const shareLink = createShareLink();
    const shareLinkModal = ref(null);
    const copied = ref(false);

    const { trackCopyShareLink } = useTrackingLink();

    const closeModal = () => {
      emit("close");
    };
    const copyToClipboard = () => {
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(shareLink);
      trackCopyShareLink();
      switchIcons();
    };

    const switchIcons = () => {
      copied.value = true;
      setTimeout(function () {
        copied.value = false;
      }, 4000);
    };

    onMounted(() => {
      shareLinkModal.value.addEventListener("wbclose", closeModal);
    });
    return {
      copyToClipboard,
      closeModal,
      shareLink,
      shareLinkModal,
      copied,
      switchIcons,
    };
  },
  components: { Icon },
};
</script>
<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.share-link {
  .wb-modal__outer {
    width: 85% !important;
    max-width: 800px;
    height: auto !important;
    @include breakpoint-to(mq3) {
      width: 100% !important;
    }
  }

  &__share-icon {
    margin-bottom: 6px;
  }
  &__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--wb-spacing-xs) var(--wb-spacing-xs) 0 var(--wb-spacing-xs);
    color: var(--wb-black);
    &__left {
      &__wrapper {
        display: flex;
        flex-direction: row;
      }
      &__icon {
        padding-right: 10px;
      }
      &__subheadline {
        font-size: 1rem !important;
        line-height: 1.5rem !important;
      }
    }
  }

  &__content {
    padding: var(--wb-spacing-xs) !important;
    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: var(--wb-spacing-s);

      &__right__text {
        padding-left: 10px;
      }
    }
  }
  &__content::after {
    height: 0 !important;
  }
}
</style>

<template>
  <wb-modal class="error-modal" mode="prompt" scroll-lock>
    <wb-modal-content
      :headline="localization?.headline"
      class="error-modal__content"
    >
      <p v-html="localization?.message" class="error-modal__inner"></p>
    </wb-modal-content>
    <wb-modal-footer class="error-modal__footer">
      <button
        v-if="localization?.cancelBtnText"
        type="button"
        @click="closeModal"
        class="wb-button wb-button--secondary wb-button--large error-modal__button"
        data-test-id="modal-cancel"
      >
        {{ localization.cancelBtnText }}
      </button>
      <button
        @click="handleSubmit"
        type="button"
        class="wb-button wb-button--primary wb-button--large error-modal__button"
        data-test-id="modal-submit"
      >
        {{ localization?.btnText }}
      </button>
    </wb-modal-footer>
  </wb-modal>
</template>

<script>
import useHomeRedirect from "@/compositions/useHomeRedirect";

export default {
  name: "modalMessage",
  props: {
    localization: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { redirectToHomeOrDealer } = useHomeRedirect();

    const handleSubmit = () => {
      if (props.localization?.cancelBtnText) {
        emit("submitClicked");
      } else {
        redirectToHomeOrDealer();
      }
    };

    const closeModal = () => {
      emit("closeModal");
    };

    return { handleSubmit, closeModal };
  },
};
</script>

<style lang="scss" scoped>
.error-modal {
  &__inner {
    padding: 10px 0;
  }

  &__footer {
    display: flex;
    justify-content: flex-start;
  }
}
</style>

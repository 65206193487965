import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { ViewportObserver } from "@workbench/core";

const useActionsBarPosition = function () {
  const scrolling = ref(false);
  const topObserver = ref(null);
  const store = useStore();
  const vehicles = computed(() => store.getters["vehicles/filteredVehicles"]);

  const topEnter = () => {
    scrolling.value = false;
  };

  const topLeave = () => {
    var rectFilterBar = document
      .getElementsByClassName("vehicle-offer-list__actions")[0]
      .getBoundingClientRect();

    if (rectFilterBar.top < 0) {
      scrolling.value = true;
    }
  };

  watch(
    () => vehicles.value,
    (watchedVehicles) => {
      if (!watchedVehicles.length) {
        scrolling.value = false;
      }
    }
  );

  onMounted(() => {
    topObserver.value = new ViewportObserver({
      element: document.querySelector("#jsVehicleTitle"),
      rootMargin: "30px",
      viewportEnterFn: () => topEnter(),
      viewportLeaveFn: () => topLeave(),
    });
  });

  onUnmounted(() => {
    topObserver.value.disconnect();
  });

  return {
    scrolling,
  };
};

export default useActionsBarPosition;

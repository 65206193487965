<template>
  <div dir="ltr" class="content">
    <Header :isLoggedIn="isLoggedIn" @logout="authLogout" />

    <MaintenanceStatic v-if="marketConfigPreAuthData.domainActive === false" />
    <div v-else-if="marketConfigPreAuthData?.domainActive">
      <router-view
        v-if="!marketConfigPreAuthData.maintenancePage || isLoggedIn"
      />
      <Maintenance v-else />
    </div>

    <Footer />

    <!-- used for overlays, loading screens positions -->
    <div id="teleport"></div>

    <Teleport to="#mapUrl">{{ mapConfig?.url }}</Teleport>
    <Teleport to="#mapKey">{{ mapConfig?.apiKey }}</Teleport>

    <BasicAuthModal v-if="basicAuthRequired" />
  </div>
</template>

<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
import BasicAuthModal from "@/components/BasicAuthModal";
import useAuthentication from "@/compositions/useAuthentication";
import useTrackingInit from "@/compositions/useTrackingInit";
import MaintenanceStatic from "@/components/Maintenance/MaintenanceStatic";
import Maintenance from "@/components/Maintenance/Maintenance";
import useMediaQuery from "@/compositions/useMediaQuery";

export default {
  setup() {
    const store = useStore();

    useMediaQuery();

    const basicAuthRequired = computed(
      () => store.getters["marketConfig/basicAuthRequired"]
    );

    const marketConfigPreAuthData = computed(
      () => store.getters["marketConfig/preAuthData"]
    );

    const { authLogout, isLoggedIn } = useAuthentication();

    // load tracking script if in config set
    const trackingScriptUrl = computed(
      () => store.getters["marketConfig/trackingScriptUrl"]
    );
    const { loadTrackingScript } = useTrackingInit();
    watch(
      () => trackingScriptUrl.value,
      (url) => {
        if (url) {
          loadTrackingScript(url);
        }
      }
    );

    return {
      authLogout,
      isLoggedIn,
      basicAuthRequired,
      marketConfigPreAuthData,
      mapConfig: computed(() => store.getters["marketConfig/mapConfig"]),
    };
  },
  components: {
    Header,
    Footer,
    BasicAuthModal,
    MaintenanceStatic,
    Maintenance,
  },
};
</script>

<style lang="scss">
.content {
  max-width: 1440px;
  margin: auto;
}
</style>

<template>
  <wb-modal mode="prompt">
    <wb-modal-content
      :headline="localization?.vehicleRequestPage?.successTitle"
    >
      <div v-html="localization?.vehicleRequestPage?.successText" />
    </wb-modal-content>
    <wb-modal-footer>
      <!--      Spacer Div to keep the only button on right side-->
      <div></div>
      <form @submit.prevent="redirectToHomeOrDealer()">
        <button
          type="submit"
          class="wb-button wb-button--primary wb-button--large"
        >
          <Icon name="chevron-right" :size="12" :width="18" :height="12" />
          {{ localization?.resultPage?.overview?.btnBackToHome }}
        </button>
      </form>
    </wb-modal-footer>
  </wb-modal>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import Icon from "@/components/Icon";
import useTrackingPage from "@/compositions/useTrackingPage";
import useHomeRedirect from "@/compositions/useHomeRedirect";

export default {
  name: "VehicleRequestConfirmation",
  components: { Icon },
  setup() {
    const store = useStore();

    const localization = computed(
      () => store.getters["localization/localization"]
    );

    const { redirectToHomeOrDealer } = useHomeRedirect();
    const { trackRequestVehicleConfirmation } = useTrackingPage();

    onMounted(() => {
      trackRequestVehicleConfirmation();
    });

    return {
      localization,
      redirectToHomeOrDealer,
    };
  },
};
</script>

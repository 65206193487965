<template>
  <div class="additional-services__drop-down__wrapper">
    <div class="additional-services__drop-down__select-box">
      <wb-select-control class="additional-services__drop-down__select-control">
        <wb-select>
          <label></label>
          <select
            v-model="quantity"
            class="additional-services__drop-down__select"
          >
            <option selected="selected" value="0">0</option>
            <option v-for="i in service.maxQuantity" :key="i" :value="i">
              {{ i }}
            </option>
          </select>
        </wb-select>
      </wb-select-control>
      <span>
        {{ service.serviceName }}
        <ToolTip
          style="margin-left: 15px"
          v-if="service.onlineDescription"
          :text="service.onlineDescription"
        ></ToolTip>
      </span>
    </div>
    <div
      class="wb-type-heading-xs"
      :class="{
        deactivated: quantity == 0,
      }"
    >
      {{ price }}
    </div>
  </div>
</template>
<script>
import { watch, ref } from "vue";
import { getPriceTag } from "@/utilities/price";
import ToolTip from "@/components/ToolTip.vue";

export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
    currencyCode: {
      type: String,
      required: true,
    },
    isoCode: {
      type: String,
      required: true,
    },
    numberOfDays: {
      type: Number,
      required: true,
    },
    pricingDetails: {
      type: Object,
      required: true,
    },
    localization: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const quantity = ref(0);
    const active = ref(false);
    const service = JSON.parse(JSON.stringify(props["service"]));

    const price = ref("");

    const initPriceTag = () => {
      if (service.dailyCharge) {
        price.value =
          getPriceTag(service.price, props["currencyCode"], props["isoCode"]) +
          " / " +
          props["localization"].day;
      } else {
        price.value = getPriceTag(
          service.price,
          props["currencyCode"],
          props["isoCode"]
        );
      }
      active.value = false;
    };

    initPriceTag();

    watch(
      () => quantity.value,
      () => {
        emit(
          "quantityChanged",
          JSON.parse(JSON.stringify(props["service"])),
          quantity.value
        );
        if (quantity.value == 0) {
          initPriceTag();
        } else {
          active.value = true;
        }
      }
    );

    watch(
      () => props["pricingDetails"],
      () => {
        let selectedAdditionalServices = JSON.parse(
          JSON.stringify(props["pricingDetails"])
        ).selectedAdditionalServices;
        let currentService = JSON.parse(JSON.stringify(props["service"]));

        if (selectedAdditionalServices.length > 0) {
          let selectedAdditionalService = selectedAdditionalServices.find(
            (service) => service.additionalRentalServiceId === currentService.id
          );
          if (selectedAdditionalService) {
            price.value = getPriceTag(
              selectedAdditionalService.amountIncludingTax,
              props["currencyCode"],
              props["isoCode"]
            );
          }
        }
      }
    );

    return { quantity, price, active };
  },
  components: {
    ToolTip,
  },
};
</script>
<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.additional-services__drop-down {
  &__wrapper {
    display: flex;
    justify-content: space-between;

    .deactivated {
      color: var(--wb-grey-65);
    }
  }

  &__select-box {
    display: flex;
    justify-content: flex-start;
    gap: 18px;
    align-items: center;
    word-break: break-word;
    width: 68%;
  }

  // workbench styling overrides below
  &__select-control {
    min-width: 72px;
  }
  &__select {
    padding: 0.5rem 2.75rem 0.5rem 1rem !important;
  }
}
</style>

<template>
  <div
    class="provider_modal content-page-navigation"
    @mouseover="show = true"
    @mouseleave="show = false"
    v-if="contentPagesActive"
  >
    <span @click="isOpenContentModal = !isOpenContentModal">
      {{ contentPages?.header }}
    </span>
    <transition name="dropdown">
      <div v-if="show" class="content-page-navigation__link-container">
        <ul>
          <li class="content-page-navigation__link-container__link">
            <router-link
              :to="{ name: 'contentPage', params: { pageId: '1' } }"
              class="wb-type-button"
            >
              {{ contentPages.subHeader1 }}
            </router-link>
          </li>
          <li class="content-page-navigation__link-container__link">
            <router-link
              :to="{ name: 'contentPage', params: { pageId: '2' } }"
              class="wb-type-button"
            >
              {{ contentPages.subHeader2 }}
            </router-link>
          </li>
          <li class="content-page-navigation__link-container__link">
            <router-link
              :to="{ name: 'contentPage', params: { pageId: '3' } }"
              class="wb-type-button"
            >
              {{ contentPages.subHeader3 }}
            </router-link>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script setup>
import { ref } from "vue";

const props = defineProps(["contentPages", "contentPagesActive"]);
const show = ref(false);
</script>
<style lang="scss" scoped>
.provider_modal {
  cursor: pointer;
}
.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 0.7s ease;
}

.dropdown-enter-to,
.dropdown-leave-from {
  opacity: 1;
}

.content-page-navigation {
  padding-left: 25px;

  a {
    text-decoration: none;
  }

  ul {
    list-style-type: none;
    padding: 20px 0 20px 20px;
  }

  li {
    width: fit-content;
  }

  &__link-container {
    text-align: left;

    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);

    &__link {
      padding: 5px 0 10px 0;
    }
    &__link:hover,
    &__link:hover a {
      color: var(--wb-blue-50);
    }
    a {
      padding: 0 30px 5px 0;
      font-size: 0.85em;
      color: var(--wb-white);
      font-weight: normal;
    }
    a:hover {
      color: var(--wb-blue-50);
    }
  }
}
</style>

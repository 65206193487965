import ApiService from "@/services/ApiService";
import {
  addDistanceKeyAndCityToVehicles,
  getFilteredVehicles,
  getGroupedVehicles,
  sortVehicles,
  setVehicleTypesAndSort,
  createVehicleTypeMap,
  addOrderToVehicles,
  getGroupedVehiclesByAttribute,
} from "@/utilities/vehicle";
import { getAdditionalServicesIdAndQuantity } from "@/utilities/additionalServices";

// initial state
const vehiclesState = () => ({
  vehicles: {
    isLoading: false,
    allVehicles: [],
    matchingVehicles: [],
    filteredVehicles: [],
    filteredVehiclesExceptModel: [],
    filteredVehiclesExceptVehicleType: [],
    selectedVehicle: {},
    activeFilters: new Map(),
    cachedFilter: new Map(),
    sort: "price",
    modelTypes: [],
    modelTypesReloadRequired: false,
    modelTypeForVehicleRequest: null,
    modelTypesForLongTermRequest: [],
    activeModelId: null,
    selectedModelTypeForLongTermRequest: null,
    vehicleTypes: new Map(),
    modelSelectionIsOrigin: false,
    additionalServices: null,
    selectedAdditionalServices: {},
  },
});

//mutations
const mutations = {
  SET_LOADING(state, isLoading) {
    state.vehicles.isLoading = isLoading;
  },
  SET_VEHICLES(state, vehicles) {
    state.vehicles.allVehicles = vehicles;
  },
  SET_MATCHING_VEHICLES(state, vehicles) {
    state.vehicles.matchingVehicles = vehicles;
  },
  SET_FILTERED_VEHICLES(state, vehicles) {
    state.vehicles.filteredVehicles = vehicles;
  },
  SET_FILTERED_EXCEPT_MODEL(state, vehicles) {
    state.vehicles.filteredVehiclesExceptModel = vehicles;
  },
  SET_FILTERED_EXCEPT_VEHICLE_TYPE(state, vehicles) {
    state.vehicles.filteredVehiclesExceptVehicleType = vehicles;
  },
  SET_SELECTED_VEHICLE(state, vehicle) {
    state.vehicles.selectedVehicle = vehicle;
  },
  SET_SORTING(state, sort) {
    state.vehicles.sort = sort;
  },
  ADD_FILTER(state, filter) {
    const filters = new Map([...state.vehicles.activeFilters]);

    if (filters.has(filter.type)) {
      filters.delete(filter.type);
    }
    filters.set(filter.type, filter);
    state.vehicles.activeFilters = filters;
  },
  REMOVE_FILTER(state, name) {
    const filters = new Map([...state.vehicles.activeFilters]);
    filters.delete(name);
    state.vehicles.activeFilters = filters;
  },
  CLEAR_FILTER(state) {
    state.vehicles.activeFilters = new Map();
  },
  SET_CACHED_FILTER(state) {
    state.vehicles.cachedFilter = new Map([...state.vehicles.activeFilters]);
  },
  REPLACE_FILTER(state, cachedFilter) {
    state.vehicles.activeFilters = new Map([...cachedFilter]);
  },
  SET_MODEL_TYPES(state, modelTypes) {
    state.vehicles.modelTypes = modelTypes;
  },
  SET_MODEL_TYPES_RELOAD_REQUIRED(state, isRequired) {
    state.vehicles.modelTypesReloadRequired = isRequired;
  },
  SET_MODEL_TYPE_FOR_VEHICLE_REQUEST(state, modelTypeId) {
    state.vehicles.modelTypeForVehicleRequest = modelTypeId;
  },
  SET_VEHICLE_TYPES(state, vehicleTypes) {
    state.vehicles.vehicleTypes = vehicleTypes;
  },
  RESET_FILTERS(state) {
    state.vehicles.activeFilters = new Map();
    state.vehicles.cachedFilter = new Map();
  },
  SET_MODEL_SELECTION_IS_ORIGIN(state, boolean) {
    state.vehicles.modelSelectionIsOrigin = boolean;
  },
  SET_MODEL_TYPES_FOR_LONG_TERM_REQUEST(state, list) {
    state.vehicles.modelTypesForLongTermRequest = list;
  },
  SET_SELECTED_MODEL_TYPE_FOR_LONG_TERM_REQUEST(state, model) {
    state.vehicles.selectedModelTypeForLongTermRequest = model;
  },
  SET_ADDITIONAL_SERVICES(state, additionalServices) {
    state.vehicles.additionalServices = additionalServices;
  },
  CLEAR_ADDITIONAL_SERVICES(state) {
    state.vehicles.additionalServices = null;
  },
  UPDATE_SELECTED_ADDITIONAL_SERVICES_CATEGORY(
    state,
    { categoryName, service }
  ) {
    state.vehicles.selectedAdditionalServices[categoryName] = service;
  },
  DELETE_SELECTED_ADDITIONAL_SERVICES_CATEGORY(state, categoryName) {
    delete state.vehicles.selectedAdditionalServices[categoryName];
  },
  CLEAR_SELECTED_ADDITIONAL_SERVICES(state) {
    state.vehicles.selectedAdditionalServices = {};
    delete state.vehicles.selectedVehicle.pricingDetails
      .totalAdditionalServicesAmount;
    delete state.vehicles.selectedVehicle.pricingDetails.totalAmount;
    delete state.vehicles.selectedVehicle.pricingDetails.totalBaseRateAmount;
  },
  UPDATE_SELECTED_VEHICLE_TOTAL_PRICE(state, updatedPriceData) {
    state.vehicles.selectedVehicle.pricingDetails = {
      ...state.vehicles.selectedVehicle.pricingDetails,
      ...updatedPriceData,
    };
  },
  UPDATE_ACTIVE_MODEL_ID(state, modelId) {
    state.vehicles.activeModelId = modelId;
  },
  RESET_VOUCHER_STATUS_CODE(state) {
    state.vehicles.selectedVehicle.pricingDetails.validityStatusCode = null;
  },
};

//actions
const actions = {
  resetVoucherStatusCode({ commit }) {
    commit("RESET_VOUCHER_STATUS_CODE");
  },
  isLoading({ commit }, isLoading) {
    commit("SET_LOADING", isLoading);
  },
  setModelSelectionIsOrigin({ commit }, boolean) {
    commit("SET_MODEL_SELECTION_IS_ORIGIN", boolean);
  },
  async getVehicles({ state, commit, rootGetters }, request) {
    commit("SET_LOADING", true);

    const mockMode = rootGetters["marketConfig/mockMode"];
    try {
      const getVehicles = mockMode
        ? await ApiService.getMockVehicles(request)
        : await ApiService.getVehicles(request);

      switch (getVehicles.status) {
        case 200:
          const vehicles = addDistanceKeyAndCityToVehicles(
            getVehicles.data?.vehicles,
            rootGetters["search/locationSelected"]
          );
          const { sort } = state.vehicles;
          const orderList = getVehicles?.data?.vehicleTypeOrderList;
          const sortedVehicles = addOrderToVehicles(
            sortVehicles(vehicles, sort),
            orderList
          );
          const vehicleTypeMap = createVehicleTypeMap(vehicles);

          commit(
            "SET_VEHICLE_TYPES",
            setVehicleTypesAndSort(orderList, vehicleTypeMap)
          );
          commit("SET_VEHICLES", sortedVehicles);
          commit("SET_FILTERED_VEHICLES", sortedVehicles);
          break;
        case 204:
          throw new Error("API Succes But No Vehicles, noVehicles");
        default:
          throw new Error("API Success But Unhandled Response");
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async getModelSelectionVehicles(
    { state, commit, rootGetters },
    { request, domainId, isoCode }
  ) {
    commit("SET_LOADING", true);

    try {
      const getVehicles = await ApiService.getModelSelectionVehicles(
        request,
        domainId,
        isoCode
      );

      switch (getVehicles.status) {
        case 200:
          const orderList = getVehicles?.data?.vehicleTypeOrderList;
          const matchingVehicles = addDistanceKeyAndCityToVehicles(
            getVehicles.data?.matchingVehicles,
            getVehicles.data?.locations
          );
          const nonMatchingVehicles = addDistanceKeyAndCityToVehicles(
            getVehicles.data?.nonMatchingVehicles,
            getVehicles.data?.locations
          );
          const { sort } = state.vehicles;
          const sortedMatchingVehicles = addOrderToVehicles(
            sortVehicles(matchingVehicles, sort),
            orderList
          );
          const sortedNonMatchingVehicles = addOrderToVehicles(
            sortVehicles(nonMatchingVehicles, sort),
            orderList
          );

          commit(
            "search/SET_LOCATION",
            { locations: getVehicles.data?.locations },
            {
              root: true,
            }
          );

          commit(
            "search/SET_LOCATION_SELECTED",
            new Set(getVehicles.data?.locations),
            {
              root: true,
            }
          );

          const vehicleTypeMap = createVehicleTypeMap(nonMatchingVehicles);
          commit(
            "SET_VEHICLE_TYPES",
            setVehicleTypesAndSort(orderList, vehicleTypeMap)
          );
          commit("SET_MATCHING_VEHICLES", sortedMatchingVehicles);
          commit("SET_VEHICLES", sortedNonMatchingVehicles);
          commit("SET_FILTERED_VEHICLES", sortedNonMatchingVehicles);
          break;
        case 204:
          throw new Error("API Succes But No Model Selection Vehicles");
        default:
          throw new Error("API Success But Unhandled Response");
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async getAdditionalServices({ commit, rootGetters, getters }, domainId) {
    const mockMode = rootGetters["marketConfig/mockMode"];
    const isoCode = rootGetters["localization/isoCode"];
    const request = getters["getAdditionalServicesRequest"];
    try {
      const getAdditionalServices = mockMode
        ? await ApiService.getMockAdditionalServices()
        : await ApiService.getAdditionalServices(domainId, isoCode, request);

      switch (getAdditionalServices.status) {
        case 200:
          commit("SET_ADDITIONAL_SERVICES", getAdditionalServices.data);
          break;
        case 204:
          commit("SET_ADDITIONAL_SERVICES", []);
          break;
        default:
          throw new Error("API Success But Unhandled Response");
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  async vehiclePriceUpdate({ commit, rootGetters, getters }, domainId) {
    const mockMode = rootGetters["marketConfig/mockMode"];
    const isoCode = rootGetters["localization/isoCode"];
    const rentalData = rootGetters["checkout/rentalData"];
    const voucherCodeField = rentalData.find(
      (field) => field.type === "PROMOTION_CODE"
    );
    let request = {
      ...getters["getAdditionalServicesRequest"],
      vehicleId: getters["selectedVehicleRequestData"].vehicleId,
      selectedAdditionalServices: getAdditionalServicesIdAndQuantity(
        getters["selectedAdditionalServices"]
      ),
      tenantAlias: getters["selectedVehicleRequestData"].tenantAlias,
    };
    if (voucherCodeField) {
      request = { ...request, voucherCode: voucherCodeField.value };
    }
    try {
      commit("SET_LOADING", true);
      const updatedPrice = mockMode
        ? await ApiService.mockVehiclePriceUpdate(domainId, isoCode, request)
        : await ApiService.vehiclePriceUpdate(domainId, isoCode, request);
      commit("SET_LOADING", false);
      if (updatedPrice.status === 200) {
        commit("UPDATE_SELECTED_VEHICLE_TOTAL_PRICE", updatedPrice.data);
      } else {
        throw new Error("API Success But Unhandled Response");
      }
    } catch (e) {
      commit("SET_LOADING", false);
      throw new Error(e);
    }
  },
  clearVehicles({ commit }) {
    commit("SET_MATCHING_VEHICLES", []);
    commit("SET_VEHICLES", []);
    commit("SET_FILTERED_VEHICLES", []);
    commit("SET_LOADING", true);
  },
  setSorting({ commit }, sort) {
    commit("SET_SORTING", sort);
  },
  sortVehicles({ state, commit }, sort) {
    commit(
      "SET_VEHICLES",
      sortVehicles(JSON.parse(JSON.stringify(state.vehicles.allVehicles)), sort)
    );
    commit(
      "SET_FILTERED_VEHICLES",
      sortVehicles(
        JSON.parse(JSON.stringify(state.vehicles.filteredVehicles)),
        sort
      )
    );
  },
  addFilter({ commit }, filter) {
    commit("ADD_FILTER", filter);
  },
  addPriceFilter({ commit }, filter) {
    if (filter?.min && filter?.max) {
      commit("ADD_FILTER", filter);
    }
  },
  removeFilter({ commit }, name) {
    commit("REMOVE_FILTER", name);
  },
  clearFilter({ state, commit }) {
    commit("CLEAR_FILTER");
    commit("SET_FILTERED_VEHICLES", state.vehicles.allVehicles);
  },
  filterVehicles({ state, commit }, filters) {
    if (filters.size) {
      const filteredVehicles = getFilteredVehicles(
        state.vehicles.allVehicles,
        filters
      );
      commit("SET_FILTERED_VEHICLES", filteredVehicles);
      const filteredExceptModel = getFilteredVehicles(
        state.vehicles.allVehicles,
        filters,
        { excludeModel: true }
      );
      const filteredExceptVehicleType = getFilteredVehicles(
        state.vehicles.allVehicles,
        filters,
        { excludeVehicleType: true }
      );
      commit("SET_FILTERED_EXCEPT_MODEL", filteredExceptModel);
      commit("SET_FILTERED_EXCEPT_VEHICLE_TYPE", filteredExceptVehicleType);
    } else {
      commit("SET_FILTERED_VEHICLES", state.vehicles.allVehicles);
    }
  },
  setSelectedVehicle({ commit }, vehicle) {
    commit("SET_SELECTED_VEHICLE", vehicle);
    commit("CLEAR_ADDITIONAL_SERVICES");
    commit("CLEAR_SELECTED_ADDITIONAL_SERVICES");
  },
  setCachedFilter({ commit }) {
    commit("SET_CACHED_FILTER");
  },
  resetToCachedFilter({ commit }, cachedFilter) {
    commit("REPLACE_FILTER", cachedFilter);
  },
  resetFilters({ commit }) {
    commit("RESET_FILTERS");
  },
  async getModelTypes({ rootGetters, commit, state }, params) {
    const mockMode = rootGetters["marketConfig/mockMode"];
    // load vehicle types only once from the backend
    if (
      !state.vehicles.modelTypes.length ||
      state.vehicles.modelTypesReloadRequired
    ) {
      const getModelTypes = mockMode
        ? await ApiService.getMockModelTypes()
        : await ApiService.getModelTypes({
            isoCode: params.isoCode,
            domainId: params.domainId,
          });

      if (getModelTypes.status === 200) {
        const modelTypes = getModelTypes.data;
        commit("SET_MODEL_TYPES", modelTypes);
        commit("SET_MODEL_TYPES_RELOAD_REQUIRED", false);
      }
    }
  },
  setModelTypesReloadRequired({ commit }, isRequired) {
    commit("SET_MODEL_TYPES_RELOAD_REQUIRED", isRequired);
  },
  setModelTypeForVehicleRequest({ commit }, modelTypeId) {
    commit("SET_MODEL_TYPE_FOR_VEHICLE_REQUEST", modelTypeId);
  },

  async getModelTypesForRequestMode({ commit }, { domainId, isoCode }) {
    const response = await ApiService.getModelTypesForRent(domainId, isoCode);

    commit("SET_MODEL_TYPES_FOR_LONG_TERM_REQUEST", response.data);
  },

  setSelectedModelTypeForLongTermRequest({ commit }, model) {
    commit("SET_SELECTED_MODEL_TYPE_FOR_LONG_TERM_REQUEST", model);
  },

  updateSelectedAdditionalServices(
    { commit, getters },
    { categoryName, serviceWrapper }
  ) {
    if (
      Object.keys(serviceWrapper).length === 0 &&
      serviceWrapper.constructor === Object
    ) {
      commit("DELETE_SELECTED_ADDITIONAL_SERVICES_CATEGORY", categoryName);
    } else {
      commit("UPDATE_SELECTED_ADDITIONAL_SERVICES_CATEGORY", {
        categoryName,
        service: serviceWrapper,
      });
    }
  },
  clearSelectedAdditionalServices({ commit }) {
    commit("CLEAR_SELECTED_ADDITIONAL_SERVICES");
  },
  setActiveModelType({ commit }, modelId) {
    commit("UPDATE_ACTIVE_MODEL_ID", modelId);
  },
};

// getters
const vehiclesGetters = {
  voucherStatusCode: (state) => {
    return state.vehicles.selectedVehicle.pricingDetails.validityStatusCode;
  },
  isLoading: (state) => {
    return state.vehicles.isLoading;
  },
  sort: (state) => {
    return state.vehicles.sort;
  },
  allVehicles: (state) => {
    return state.vehicles?.allVehicles;
  },
  matchingVehicles: (state) => {
    return state.vehicles?.matchingVehicles;
  },
  filteredVehicles: (state) => {
    return state.vehicles?.filteredVehicles;
  },
  allGroupedVehicles: (_, getters) => {
    if (!getters.allVehicles) {
      return [];
    }
    return getGroupedVehicles(getters.allVehicles);
  },
  groupedVehicles: (_, getters) => {
    if (!getters.filteredVehicles) {
      return [];
    }
    return getGroupedVehicles(getters.filteredVehicles);
  },
  groupedVehiclesExModel: (state, getters) => {
    if (
      !state.vehicles.filteredVehiclesExceptModel.length ||
      !state.vehicles.activeFilters.get("model")
    ) {
      return getters.groupedVehicles;
    }
    return getGroupedVehicles(state.vehicles.filteredVehiclesExceptModel);
  },
  groupedVehiclesExVehicleType: (state, getters) => {
    if (!state.vehicles.filteredVehiclesExceptVehicleType.length) {
      return getters.groupedVehicles;
    }
    return getGroupedVehicles(state.vehicles.filteredVehiclesExceptVehicleType);
  },
  groupedVehiclesByAttribute: (_, getters) => {
    if (!getters.filteredVehicles) {
      return [];
    }
    return getGroupedVehiclesByAttribute(getters.filteredVehicles);
  },
  activeFilters: (state) => {
    return state.vehicles.activeFilters;
  },
  getFilter: (_, getters) => (key) => {
    return getters.activeFilters?.get(key);
  },
  minPrice: (_, getters) => {
    if (!getters.allVehicles) {
      return 0;
    }
    const vehicle = getters.allVehicles.reduce((prev, cur) =>
      prev?.pricingDetails?.totalGrossPrice <
      cur?.pricingDetails?.totalGrossPrice
        ? prev
        : cur
    );
    return Math.round(vehicle?.pricingDetails?.totalGrossPrice);
  },
  maxPrice: (_, getters) => {
    if (!getters.allVehicles) {
      return 0;
    }
    const vehicle = getters.allVehicles.reduce((prev, cur) =>
      prev?.pricingDetails?.totalGrossPrice >
      cur?.pricingDetails?.totalGrossPrice
        ? prev
        : cur
    );
    return Math.round(vehicle?.pricingDetails?.totalGrossPrice);
  },
  models: (_, { allGroupedVehicles }) => {
    if (!allGroupedVehicles) {
      return [];
    }
    const allModels = new Map();
    Object.entries(allGroupedVehicles).forEach(([, vehicles]) => {
      vehicles.forEach(({ modelType }) => {
        const model = {
          name: modelType?.modelClassName,
          short: modelType?.modelClassNameShort,
        };
        if (!allModels.has(model.short)) {
          allModels.set(model.short, model);
        }
      });
    });

    return Array.from(allModels).map(([, model]) => ({
      name: model.name,
      short: model.short,
      disabled: false,
    }));
  },
  equipments: (_, getters) => {
    if (!getters.allVehicles) {
      return new Set();
    }
    const allEquipments = new Set();
    getters.allVehicles.map((vehicle) => {
      vehicle?.equipments.map((equipment) => {
        allEquipments.add(equipment);
        return equipment;
      });
      return vehicle;
    });
    return allEquipments;
  },
  seats: (_, getters) => {
    if (!getters.allVehicles) {
      return [];
    }
    const allSeats = new Set();
    getters.allVehicles.map((vehicle) => {
      allSeats.add(vehicle?.seats);
      return vehicle;
    });
    return Array.from(allSeats).sort((a, b) => a - b);
  },
  selectedVehicle: (state) => {
    return state.vehicles.selectedVehicle;
  },
  pricingDetailsOfSelectedVehicle: (state) => {
    return state.vehicles.selectedVehicle.pricingDetails;
  },
  selectedVehicleRequestData: (state) => {
    const selectedVehicle = state.vehicles.selectedVehicle;
    const {
      vehicleId,
      outletId,
      mandantId,
      orderType,
      modelType,
      minAge,
      enginePowerKw,
      fuelType,
      minLicenceHoldingPeriodYears,
      name,
      equipments,
      registrationDate,
      tenantAlias,
    } = selectedVehicle;

    return {
      vehicleId,
      outletId,
      mandantId,
      orderType,
      modelTypeId: modelType.modelTypeId,
      minAge,
      enginePowerKw,
      fuelType,
      minLicenceHoldingPeriodYears,
      modelName: name,
      equipments,
      registrationDate,
      tenantAlias,
    };
  },
  currency: (_, getters) => {
    if (!getters.allVehicles) {
      return "EUR";
    }

    return getters.allVehicles[0]?.pricingDetails?.currencyCode;
  },
  cachedFilter: (state) => {
    return state.vehicles.cachedFilter;
  },
  modelTypes: (state) => {
    return state.vehicles.modelTypes;
  },
  modelTypesReloadRequired: (state) => {
    return state.vehicles.modelTypesReloadRequired;
  },
  modelTypeIdsInVBSVehicles: (state) => {
    const modelTypeIdsInVBSVehicles = [];

    if (!state.vehicles?.allVehicles) {
      return modelTypeIdsInVBSVehicles;
    }

    state.vehicles?.allVehicles.forEach((vehicleItem) => {
      // We only want VBS rentalPlatform
      if (vehicleItem.rentalPlatform === "VBS") {
        // add vbsModelBodyStyleId, vbsModelClassId
        modelTypeIdsInVBSVehicles.push({
          vbsModelBodyStyleId: vehicleItem.vbsModelBodyStyleId,
          vbsModelClassId: vehicleItem.vbsModelClassId,
        });
      }
    });

    return modelTypeIdsInVBSVehicles;
  },
  vehicleTypes: (state, getters, _, rootGetters) => {
    const allVehicleTypes = state.vehicles?.vehicleTypes;
    const availableTypes = new Map();
    if (!getters.allGroupedVehicles || !allVehicleTypes) {
      return new Map();
    }

    allVehicleTypes.forEach(({ name, order, globalVehicleType }, id) => {
      if (
        Object.keys(getters.allGroupedVehicles).find(
          (group) =>
            group.substring(0, group.length - 1) === name ||
            group.substring(0, group.length) === name
        ) !== undefined &&
        !availableTypes.has(name)
      ) {
        // add disabled, active values to vehicleTypes
        availableTypes.set(name, {
          id,
          name,
          order,
          globalVehicleType,
          disabled: false,
          active: getters
            .getFilter("model")
            ?.vehicleTypes?.includes(globalVehicleType)
            ? true
            : false,
        });
      }
    });
    return availableTypes;
  },
  modelTypesForModelSelection: (state) => {
    // Filter only relevant model types and sort
    return Object.entries(state.vehicles.modelTypes)
      .filter((item) => {
        return item[1].activatedForModelSelection;
      })
      .map((filteredItem) => {
        return filteredItem[1];
      })
      .sort(function (modelType1, modelType2) {
        if (
          modelType1 &&
          modelType1.weightForModelSelection &&
          modelType2 &&
          modelType2.weightForModelSelection
        ) {
          if (
            modelType1.weightForModelSelection <
            modelType2.weightForModelSelection
          ) {
            return -1;
          }
          if (
            modelType1.weightForModelSelection >
            modelType2.weightForModelSelection
          ) {
            return 1;
          }
        }
        return 0;
      });
  },
  modelSelectionIsOrigin: (state) => {
    return state.vehicles.modelSelectionIsOrigin;
  },
  modelTypesForLongTermRequest: (state) => {
    return state.vehicles.modelTypesForLongTermRequest;
  },
  selectedModelTypeForLongTermRequest: (state) => {
    return state.vehicles.selectedModelTypeForLongTermRequest;
  },
  additionalServices: (state) => {
    return state.vehicles.additionalServices;
  },
  useAdditionalServicesView: (_, getters, _rootState, rootGetters) => {
    if (!getters.additionalServices) {
      return false;
    }
    return (
      Object.keys(getters.additionalServices).length &&
      rootGetters["marketConfig/additionalServices"]
    );
  },
  selectedAdditionalServices: (state) => {
    return state.vehicles.selectedAdditionalServices;
  },
  getAdditionalServicesRequest: (_, getters, _rootState, rootGetters) => {
    return {
      rentalPeriod: rootGetters["search/rentalPeriod"],
      pricingCategory:
        getters["selectedVehicle"].pricingDetails.pricingCategory,
      outletId: getters["selectedVehicleRequestData"].outletId,
      tenantAlias: getters["selectedVehicleRequestData"].tenantAlias,
    };
  },
  activeModelId: (state) => state.vehicles.activeModelId,
  pricingCategory: (state, getters) =>
    getters["selectedVehicle"].pricingDetails.pricingCategory,
};

function makeState(override) {
  const state = vehiclesState();
  state.vehicles = { ...state.vehicles, ...override };
  return state;
}

export function createVehiclesModule(override) {
  return {
    namespaced: true,
    state: makeState(override),
    getters: vehiclesGetters,
    actions,
    mutations,
  };
}

export default {
  namespaced: true,
  state: vehiclesState,
  getters: vehiclesGetters,
  actions,
  mutations,
};

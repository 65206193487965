export function getAdditionalServicesAsText(additionalServices = {}) {
  let result = "";
  Object.keys(additionalServices).forEach((category) => {
    Object.keys(additionalServices[category]).forEach((service) => {
      if (result === "") {
        result = createItemForServicesText(
          additionalServices[category][service]
        );
      } else {
        result =
          `${result}, ` +
          createItemForServicesText(additionalServices[category][service]);
      }
    });
  });
  return result;
}

function createItemForServicesText(serviceObject) {
  return `${serviceObject.quantity}x ${serviceObject.service.serviceName}`;
}

/**
 *  returns array of object with id & quantity
 *  used for vehiclePriceUpdate & booking request
 */
export function getAdditionalServicesIdAndQuantity(additionalServices = {}) {
  const result = [];
  let index = 0;
  Object.keys(additionalServices).forEach((category) => {
    Object.keys(additionalServices[category]).forEach((service) => {
      result[index] = {
        id: additionalServices[category][service].service.id,
        quantity: additionalServices[category][service].quantity,
      };
      index++;
    });
  });

  return result;
}

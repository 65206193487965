<template>
  <LoadingScreen v-if="!contentPages" />
  <template v-else>
    <MainTeaser
      v-if="imageUrl"
      :imageUrl="imageUrl"
      :headline="contentPages[pageName]?.topTeaserTitle"
    />
    <section class="content-width wb-margin-top-l">
      <Introduction
        :headline="contentPages[pageName]?.headline"
        :description="contentPages[pageName]?.topText"
      />
      <template v-if="contentPages[pageName]?.section1?.imageId">
        <ImageAndText
          :option="imageAndTextOption(1)"
          class="wb-margin-top-l wb-margin-bottom-l"
        />
        <ImageAndText
          :option="imageAndTextOption(2, 'right')"
          class="wb-margin-bottom-l"
        />
        <ImageAndText
          :option="imageAndTextOption(3)"
          class="wb-margin-bottom-l"
        />
      </template>
      <ModelSelection :contentPage="pageId" />
      <div class="spacer"></div>
      <MoreTopics :topics="topics" :header="contentPages.moreTopicsHeader" />
    </section>
  </template>
</template>

<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import LoadingScreen from "@/components/LoadingScreen";
import MainTeaser from "@/components/ContentPage/MainTeaser";
import Introduction from "@/components/ContentPage/Introduction";
import ImageAndText from "@/components/ContentPage/ImageAndText";
import MoreTopics from "@/components/ContentPage/MoreTopics";
import useImage from "@/compositions/useImage";
import ModelSelection from "@/components/ModelSelection/ModelSelection";
import { createTopics } from "@/utilities/contentPages";

export default {
  name: "ContentPage",
  props: {
    pageId: String,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const contentPages = computed(
      () => store.getters["localization/contentPages"]
    );
    const aboutUs = computed(() => store.getters["localization/aboutUs"]);
    const useNewAboutUs = computed(
      () => store.getters["marketConfig/useNewAboutUs"]
    );
    const contentPagesActive = computed(
      () => store.getters["marketConfig/contentPages"]
    );

    const pageName = computed(() => `contentPage${props.pageId}`);

    const topics = computed(() =>
      createTopics(
        contentPages.value,
        aboutUs.value,
        pageName.value,
        useNewAboutUs.value
      )
    );

    watch(
      () => contentPagesActive.value,
      () => {
        if (!contentPagesActive.value) {
          router.push({ name: "home" });
        }
      }
    );

    const isMobile = computed(() => store.getters["localization/isMobile"]);
    const { getImageUrl, getContentImageUrl } = useImage();

    const imageUrl = computed(() => {
      let image = "";
      if (isMobile.value) {
        image = getImageUrl(
          contentPages.value[pageName.value]?.topTeaserImageIdMobile,
          false
        );
      } else {
        image = getImageUrl(
          contentPages.value[pageName.value]?.topTeaserImageIdDesktop,
          false
        );
      }
      return image;
    });

    const imageAndTextOption = (index, position) => {
      return {
        imageUrl: contentImg(index),
        headline: contentPages.value[pageName.value][`section${index}`]?.title,
        description:
          contentPages.value[pageName.value][`section${index}`]?.text,
        btnText:
          contentPages.value[pageName.value][`section${index}`]?.buttonText,
        btnLink:
          contentPages.value[pageName.value][`section${index}`]?.buttonUrl,
        imagePosition: position ? position : null,
      };
    };

    const contentImg = (index) => {
      if (contentPages.value[pageName.value]?.section1?.imageId) {
        const imageId =
          contentPages.value[pageName.value][`section${index}`]?.imageId;
        return getContentImageUrl(imageId);
      }
      return null;
    };

    return {
      contentPages,
      imageUrl,
      imageAndTextOption,
      contentImg,
      pageName,
      topics,
    };
  },
  components: {
    LoadingScreen,
    MainTeaser,
    Introduction,
    ImageAndText,
    MoreTopics,
    ModelSelection,
  },
};
</script>
<style lang="scss" scoped>
.spacer {
  height: 75px;
}
</style>

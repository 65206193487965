<template>
  <teleport to="#teleport">
    <div class="loading-screen">
      <div class="loading-screen__inner">
        <wb-spinner theme="dark" class="loading-screen__spinner"></wb-spinner>
        <p
          class="loading-screen__text wb-type-heading-m"
          v-html="loadingText"
        />
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "LoadingScreen",
  props: ["loadingText"],
};
</script>

<style lang="scss" scoped>
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--wb-black);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(46, 46, 46, 1) 50%,
    rgba(0, 0, 0, 1) 100%
  );
  z-index: 10;

  &__inner {
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
  }

  &__spinner {
    display: block;
    text-align: center;
    width: 60px;
    height: 60px;
    margin: 0 auto 25px;
  }
  &__text {
    text-align: center;
    color: var(--wb-white);
  }
}
</style>

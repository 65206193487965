<template>
  <div class="search-input jsSearchInput">
    <div class="search-input__loader" v-if="loading">
      <wb-spinner theme="dark"></wb-spinner>
    </div>
    <wb-input-control
      :theme="themeStyle"
      :success="
        searchValid && isSuggestSelected && !invalidLocation && !emptyLocation
      "
    >
      <wb-input>
        <label data-test-id="search-input-label">
          <span v-if="localization?.location?.inputPlaceholder">
            {{ localization?.location?.inputPlaceholder }}
          </span>
        </label>
        <input
          id="search-input"
          :class="{
            bright: themeStyle === 'bright',
            dark: themeStyle === 'dark',
          }"
          type="text"
          aria-labelledby="IdOfError"
          v-model="displayValue"
          @input="debounceListener"
          @focus="toggleSuggest()"
          @blur="inputFocused = false"
          ref="queryRef"
          :disabled="loading || !transitionComplete"
          autocomplete="off"
        />
        <Icon
          class="search-input__locate"
          name="locate-me"
          @click="getGeoLocation()"
          :class="{
            success: searchValid,
            error: geoError,
            active: geoLocationActive,
          }"
        />
      </wb-input>
      <wb-control-error v-if="invalidLocation" id="IdOfError">
        {{ localization?.location?.inputError }}
      </wb-control-error>
      <wb-control-error v-if="emptyLocation" id="emptyLocation">
        {{ noDataTextSubstituted }}
      </wb-control-error>
      <wb-control-error v-if="geoError">
        {{ localization?.location?.geolocationError }}
      </wb-control-error>
    </wb-input-control>
    <Suggest
      :inputFocused="inputFocused"
      :localization="localization"
      :query="debouncedValue"
      :themeStyle="themeStyle"
      @selected="suggestSelected($event)"
    />
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import useDebounce from "@/compositions/useDebounce";
import Suggest from "@/components/Search/Suggest";
import useClickEvent from "@/compositions/useClickEvent";
import Icon from "@/components/Icon";
import useTrackingError from "@/compositions/useTrackingError";

export default {
  props: {
    localization: {
      type: Object,
    },
    transitionComplete: {
      type: Boolean,
      default: true,
    },
    themeStyle: {
      type: String,
      default: "dark",
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const debounce = useDebounce(200);
    const geoLocation = computed(() => store.getters["search/geoLocation"]);
    const radiusNoLocationFound = computed(
      () => store.getters["search/radiusNoLocationFound"]
    );
    const loading = computed(() => store.getters["search/locationLoading"]);
    const queryRef = ref(null);
    const isSuggestSelected = ref(false);
    const noDataText = computed(() => props.localization?.location?.noData);
    const noDataTextSubstituted = ref(null);
    const inputFocused = ref(false);

    const suggestSelected = (suggest) => {
      queryRef.value.blur();
      debounce.debounceSelected(suggest);
      toggleSuggest(false);
      isSuggestSelected.value = true;
      emit("onSuggestSelect", suggest);
    };

    watch(
      () => geoLocation.value,
      (geo) => {
        if (geo) {
          suggestSelected(geo);
        }
      }
    );
    watch(
      () => radiusNoLocationFound.value,
      () => {
        noDataTextSubstituted.value = noDataText.value.replace(
          "{0}",
          radiusNoLocationFound.value
        );
      }
    );

    const suggestion = computed(() => store.getters["search/suggestion"]);
    const toggleSuggest = (open = true) => {
      if (open) {
        inputFocused.value = true;
        isSuggestSelected.value = false;
      } else {
        inputFocused.value = false;
      }

      if (debounce.displayValue.value.length <= 1) {
        return;
      }
      const updateSuggestion = "search/updateSuggestionOpen";

      if (debounce.displayValue.value.length > 1 && suggestion.value && open) {
        store.dispatch(updateSuggestion, true);
      } else {
        store.dispatch(updateSuggestion, false);
      }
    };

    // close suggest when clicked somewhere else
    useClickEvent({
      exclude: ["jsSearchInput", "jsSuggest"],
      fn: () => {
        toggleSuggest(false);
      },
    });

    const invalidLocation = computed(
      () => store.getters["search/invalidLocation"]
    );
    const emptyLocation = computed(() => store.getters["search/emptyLocation"]);

    const { trackErrorSearchInput } = useTrackingError();

    watch(
      () => invalidLocation.value,
      () => {
        if (invalidLocation.value) {
          trackErrorSearchInput(props["localization"].location?.inputError);
        }
      }
    );
    watch(
      () => emptyLocation.value,
      () => {
        if (emptyLocation.value) {
          trackErrorSearchInput(noDataTextSubstituted.value);
        }
      }
    );
    watch(
      () => debounce.geoError.value,
      () => {
        if (debounce.geoError.value) {
          trackErrorSearchInput(
            props["localization"].location?.geolocationError
          );
        }
      }
    );

    return {
      suggestSelected,
      invalidLocation,
      emptyLocation,
      isSuggestSelected,
      loading,
      toggleSuggest,
      queryRef,
      noDataTextSubstituted,
      inputFocused,
      ...debounce,
    };
  },
  components: {
    Suggest,
    Icon,
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.search-input {
  position: relative;

  &__loader {
    position: absolute;
    top: 22px;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint-from(mq3) {
      top: 12px;
    }
  }

  &__locate {
    cursor: pointer;
    visibility: visible;
    position: absolute;
    right: 1rem;
    top: 15px;

    &.success,
    &.error {
      right: 3rem;
    }

    &:hover,
    &.active {
      color: var(--wb-blue-65);
    }
  }

  #search-input {
    padding-right: 80px;
  }
  .bright {
    color: var(--wb-grey-20);
  }
  .dark {
    color: var(--wb-white);
  }
}
</style>

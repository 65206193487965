<template>
  <div class="promo-box wb-type-copy">
    <img
      class="promo-box__image"
      :src="getImageUrl(promotion.imageId, false)"
      :alt="promotion.text"
    />

    <template v-if="isMobile">
      <p class="promo-box__headline wb-type-copy-strong">
        {{ promotion.title }}
      </p>
      <div>
        <div v-html="promotion.text" class="promo-box__textbox__text" />
        <ToolTip
          :text="promotion.tooltip"
          @mouseenter="trackInfoItemPromotionBox"
        ></ToolTip>
      </div>
      <BaseLink :url="promotion.linkUrl" :text="promotion.linkText" />
    </template>

    <div v-else class="promo-box__textbox">
      <p class="promo-box__headline wb-type-copy-strong">
        {{ promotion.title }}
      </p>
      <div>
        <div v-html="promotion.text" class="promo-box__textbox__text" />
        <ToolTip
          :text="promotion.tooltip"
          @mouseenter="trackInfoItemPromotionBox"
        ></ToolTip>
      </div>
      <BaseLink :url="promotion.linkUrl" :text="promotion.linkText" />
    </div>
  </div>
</template>

<script>
import BaseLink from "@/components/BaseLink";
import ToolTip from "@/components/ToolTip";
import useImage from "@/compositions/useImage";
import useTrackingLink from "@/compositions/useTrackingLink";

export default {
  props: {
    isMobile: Boolean,
    promotion: {
      type: Object,
      required: true,
    },
  },
  components: {
    ToolTip,
    BaseLink,
  },
  setup() {
    const { getImageUrl } = useImage();
    const { trackInfoItemPromotionBox } = useTrackingLink();

    return { getImageUrl, trackInfoItemPromotionBox };
  },
};
</script>

<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.promo-box {
  color: var(--wb-grey-20);
  background-color: var(--wb-grey-90);
  margin: 0 -20px;
  padding: 30px 20px;
  display: flex;
  flex-wrap: wrap;

  @include breakpoint-from(mq3) {
    margin: 40px 0;
  }

  &__image {
    margin-right: 20px;
    width: 100px;
    object-fit: contain;
    object-position: top;

    @include breakpoint-from(mq3) {
      width: 180px;
      margin-right: 30px;
    }
  }

  &__textbox {
    flex: 1;

    &__text {
      padding-top: 20px;
    }
  }

  &__headline {
    font-size: 1.125rem !important;
    line-height: 1.85rem !important;
    flex: 1;

    @include breakpoint-from(mq3) {
      font-size: 1.25rem !important;
    }
  }

  &__text {
    margin: 20px 0;
    flex: 100%;
  }
}
</style>

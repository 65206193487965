<template>
  <div class="burger">
    <div class="burger__item" @click.prevent="toggle">
      <div class="menu" :class="{ active: !isNavOpen }">
        <Icon name="menu" :size="22" />
      </div>
      <div v-if="isNavOpen" class="burger__divider">&nbsp;</div>
      <div class="close" :class="{ active: isNavOpen }">
        <Icon name="close" />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import Icon from "@/components/Icon";

export default {
  setup() {
    const store = useStore();
    const toggle = () => store.dispatch("menu/toggleNav");
    return {
      toggle,
      isNavOpen: computed(() => store.getters["menu/isNavOpen"]),
    };
  },
  components: { Icon },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.burger {
  position: relative;
  text-align: right;
  align-self: center;
  margin-right: -12px;
  margin-left: auto;
  color: var(--wb-white);
  width: 50px;
  cursor: pointer;

  &__divider {
    position: relative;
    right: 10px;
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
  }

  @include breakpoint-from(mq3) {
    display: none;
  }

  &__item {
    display: block;
    width: 100%;
    height: 100%;
  }

  .menu,
  .close {
    position: absolute;
    top: 0;
    right: 15px;
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg) scale(0);
    opacity: 0;

    &.active {
      opacity: 1;
      transform: rotate(0deg) scale(1);
    }
  }
}
</style>

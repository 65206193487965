<template>
  <div class="location">
    <div class="location__inner">
      <div
        v-if="showLocation"
        class="location__wrapper"
        :class="{ location__row: !isMobile }"
      >
        <div class="location__inner">
          <h1
            v-if="!isComingFromLandingPage"
            class="location__title wb-type-heading-m"
          >
            {{ localization?.location?.searchTitle }}
          </h1>
          <h1 v-else class="location__title wb-type-heading-m">
            {{ mainOutletStructure.displayName }}
          </h1>
          <Radius
            :localization="localization"
            class="location__radius"
            @radiusChanged="radiusChanged($event)"
            v-if="!isComingFromLandingPage"
          />
          <MapContainer
            v-if="isMobile"
            :localization="localization"
            :locations="locations"
            :zoom="zoom"
            :mapConfig="mapConfig"
          />
          <LocationSelection
            v-if="locations?.length"
            :localization="localization"
            :locations="locations"
            :showDealerSelectAll="showDealerSelectAll"
          />
          <perfect-scrollbar
            ref="scrollbarRef"
            class="location__content__scroll"
            :options="{ scrollYMarginOffset: 10 }"
          >
            <div v-if="locations?.length">
              <LocationItem
                v-for="location in locations"
                :key="location.outletId"
                :location="location"
                :selection="selection"
                :localization="localization?.search"
              />
            </div>
            <div v-else class="location__loader">
              <wb-spinner v-if="!invalidLocation" theme="dark"></wb-spinner>
              <div v-else>
                <p class="wb-type-copy">
                  {{ localization?.location?.noData?.replace("{0}", radius) }}
                </p>
              </div>
            </div>
          </perfect-scrollbar>
          <div class="location__action">
            <button
              data-test-id="location-confirm"
              type="button"
              @click="confirm"
              :disabled="!selection.numberSelected.value"
              class="location__button wb-button wb-button--primary wb-button--medium"
            >
              <Icon name="chevron-right" :size="12" :width="18" :height="12" />
              {{ localization?.btnConfirm }}
            </button>
          </div>
        </div>
        <div class="location__map" ref="mapRef" v-if="!isMobile">
          <HereMap
            v-if="mapConfig.type.toLowerCase() === 'here'"
            :locations="locations"
            :zoom="zoom"
            :mapConfig="mapConfig"
            :mapWidth="mapWidth"
            :mapHeight="mapHeight"
            :selection="selection"
          />
          <GoogleMapComponent
            v-if="mapConfig.type.toLowerCase() === 'googlemaps'"
            :locations="locations"
            :zoom="zoom"
            :mapConfig="mapConfig"
            :mapWidth="mapWidth"
            :mapHeight="mapHeight"
            :selection="selection"
          />
        </div>
      </div>
      <div v-else>
        <div v-if="locationLoading" class="location__loader">
          <wb-spinner
            class="location__loader__spinner"
            theme="dark"
          ></wb-spinner>
        </div>
        <div v-else class="location__empty">
          <p class="wb-type-copy">{{ localization?.location?.noData }}</p>
        </div>
      </div>
      <div class="location__close" @click="close">
        <Icon name="close" :size="20" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import useLocationSelection from "@/compositions/useLocationSelection";
import Radius from "@/components/Location/Radius";
import LocationSelection from "@/components/Location/LocationSelection";
import LocationItem from "@/components/Location/LocationItem";
import MapContainer from "@/components/Map/MapContainer";
import HereMap from "@/components/Map/HereMap";
import GoogleMapComponent from "@/components/Map/GoogleMapComponent";
import useMap from "@/compositions/useMap";
import useScrollbarEvent from "@/compositions/useScrollbarEvent";
import Icon from "@/components/Icon";
import useTrackingPage from "@/compositions/useTrackingPage";
import useTrackingError from "@/compositions/useTrackingError";

export default {
  name: "LocationOverlay",
  props: ["localization", "transitionComplete", "target"],
  setup(props, { emit }) {
    const store = useStore();
    const query = computed(() => store.getters["search/query"]);
    const selection = useLocationSelection();
    const locationLoading = computed(
      () => store.getters["search/locationLoading"]
    );
    const locations = computed(() => store.getters["search/locations"]);
    const emptyLocation = computed(() => store.getters["search/emptyLocation"]);
    const invalidLocation = computed(
      () => store.getters["search/invalidLocation"]
    );
    const isComingFromLandingPage = computed(
      () => store.getters["mainOutlet/isComingFromLandingPage"]
    );
    const mainOutletStructure = computed(
      () => store.getters["mainOutlet/mainOutletStructure"]
    );

    const showDealerSelectAll = computed(
      () => store.getters["marketConfig/showDealerSelectAll"]
    );

    const singleSelectionDealer = computed(
      () => store.getters["marketConfig/singleSelectionDealer"]
    );

    const { trackSearchPartner, trackHomeStart, trackResultOverview } =
      useTrackingPage();

    const confirm = () => {
      store.dispatch("search/updateLocationOverlayOpen", false);
      emit("close", true);
    };

    // select all locations per default, except if single selection is activated
    watch(
      () => locations.value,
      (curLocations) => {
        if (!isComingFromLandingPage.value) {
          if (curLocations?.length) {
            selection.clear();
            if (singleSelectionDealer?.value) {
              selection.toggle(curLocations[0]);
            } else {
              selection.addMultiple(curLocations);
            }
          }
        }
      },
      {
        immediate: true,
      }
    );

    const domainId = computed(() => store.getters["localization/domainId"]);
    const radiusChanged = (value) => {
      selection.clear();
      store.dispatch("search/updateLocation", {
        query: query.value,
        radius: value,
        domainId: domainId.value,
      });
    };

    const close = () => {
      store.dispatch("search/updateLocationOverlayOpen", false);
      emit("close", false);

      if (props.target === "home") {
        store.dispatch("search/resetSearchState");
        trackHomeStart();
      }

      if (props.target === "vehicles") {
        trackResultOverview();
      }
    };

    const showLocation = computed(
      () => !!(!locationLoading.value && !emptyLocation.value)
    );
    const emptyPartnerError = computed(
      () => !locationLoading.value && emptyLocation.value
    );
    const { trackErrorLocationOverlay } = useTrackingError();

    watch(
      () => emptyPartnerError.value,
      () => {
        trackErrorLocationOverlay(props["localization"].location?.noData);
      }
    );

    onMounted(() => {
      document.documentElement.classList.add("scroll-lock");
      trackSearchPartner();
    });

    onUnmounted(() => {
      document.documentElement.classList.remove("scroll-lock");
    });

    return {
      mainOutletStructure,
      isComingFromLandingPage,
      locationLoading,
      selection,
      radiusChanged,
      close,
      confirm,
      isMobile: computed(() => store.getters["localization/isMobile"]),
      location,
      locations,
      showLocation,
      invalidLocation,
      radius: computed(() => store.getters["search/radius"]),
      ...useMap(() => props.transitionComplete),
      ...useScrollbarEvent(() => locations.value),
      showDealerSelectAll,
    };
  },
  components: {
    Radius,
    LocationSelection,
    LocationItem,
    MapContainer,
    HereMap,
    GoogleMapComponent,
    PerfectScrollbar,
    Icon,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/locationOverlay";
</style>

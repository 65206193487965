import ApiService from "@/services/ApiService";
// import mockLocalization from "@/../public/mocks/configs/localization.json";

// initial state
const localizationState = () => ({
  localization: {},
  activeLanguage: {},
  activeMediaQuery: null,
  isMobile: false,
  loading: false,
});

// mutations
const mutations = {
  SET_LOCALIZATION(state, localization) {
    state.localization = localization;
  },
  SET_ACTIVE_LANGUAGE(state, language) {
    state.activeLanguage = language;
  },
  SET_ACTIVE_MEDIA_QUERY(state, mediaQuery) {
    state.activeMediaQuery = mediaQuery;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
};

// actions
const localizationActions = {
  async getLocalization({ rootGetters, commit }, params) {
    commit("SET_LOADING", true);
    const mockMode = rootGetters["marketConfig/mockMode"];

    try {
      const getLocalization = mockMode
        ? await ApiService.getMockLocalization({
            isoCode: params.language.isoCode,
            domainId: params.domainId,
          })
        : await ApiService.getLocalization({
            isoCode: params.language.isoCode,
            domainId: params.domainId,
          });

      if (getLocalization.status === 200) {
        const localization = getLocalization.data;
        commit("SET_LOCALIZATION", localization);
        commit("SET_ACTIVE_LANGUAGE", params.language);
        commit("SET_LOADING", false);
      }
    } catch (e) {
      commit("SET_LOADING", false);
      // console.warn("error in getLocalization ApiService", e);
    }
  },
  updateMediaQuery({ commit }, mediaQuery) {
    commit("SET_ACTIVE_MEDIA_QUERY", mediaQuery);
  },
};

// getters
const localizationGetters = {
  localization: (state) => {
    return state.localization;
  },
  header: (state) => {
    return state.localization.header;
  },
  contentPages: (state) => {
    return state.localization.contentPages;
  },
  aboutUs: (state) => {
    return state.localization.aboutUsPage;
  },
  digitalServicesActPage: (state) => {
    return state.localization.digitalServicesActPage;
  },
  footer: (state) => {
    return state.localization.footer;
  },
  search: (state) => {
    return state.localization?.mainPage?.search;
  },
  modelSelection: (state) => {
    return state.localization?.mainPage?.modelSelection;
  },
  activeLanguage: (state) => {
    return state.activeLanguage;
  },
  domainId: (state) => {
    return state.localization?.domainId;
  },
  activeMediaQuery: (state) => {
    return state.activeMediaQuery;
  },
  isMobile: (_, getters) => {
    if (!getters.activeMediaQuery) return false;

    return !!(
      getters.activeMediaQuery === "mq1" || getters.activeMediaQuery === "mq2"
    );
  },
  dateFormat: (state) => {
    return state.localization?.dateFormat;
  },
  isoCode: (_, getters) => {
    if (!getters.activeLanguage) return "de-DE";
    return getters.activeLanguage?.isoCode;
  },
  loading: (state) => {
    return state.loading;
  },
  fieldOptions: (state) => (type) => {
    return state.localization?.checkoutFieldsAsMap[type];
  },
  checkoutFields: (state) => {
    return state.localization?.checkoutFieldsAsMap;
  },
  checkoutPage: (state) => {
    return state.localization.checkoutPage;
  },
  requestModePage: (state) => {
    return state.localization?.requestModePage;
  },
};

function makeState(override) {
  return { ...localizationState(), ...override };
}

export function createLocalizationModule(override) {
  return {
    namespaced: true,
    state: makeState(override),
    getters: localizationGetters,
    actions: localizationActions,
    mutations,
  };
}

export default {
  namespaced: true,
  state: localizationState,
  getters: localizationGetters,
  actions: localizationActions,
  mutations,
};

<template>
  <LoadingScreen
    v-if="!error"
    :loadingText="
      localization?.checkoutPage?.loadingTextReservationAfterPayment
    "
  />
  <transition name="slide-down">
    <ErrorMessage
      v-if="error"
      :message="localization?.checkoutPage?.reservationPaymentErrorMessage"
    />
  </transition>
</template>
<script>
import LoadingScreen from "@/components/LoadingScreen";
import ErrorMessage from "@/components/Notification/ErrorMessage";
import useTrackingError from "@/compositions/useTrackingError";
import useGetAndStoreReservation from "@/compositions/useGetAndStoreReservation";
import { computed, watch, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "PaymentRedirect",
  props: {
    reservationId: {
      type: String,
    },
    email: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore();
    const localization = computed(
      () => store.getters["localization/localization"]
    );
    const domainId = computed(() => store.getters["marketConfig/domainId"]);
    const paymentError = computed(
      () => store.getters["paymentRedirect/paymentError"]
    );
    const error = ref(false);
    const router = useRouter();
    const finishedLoadingDetails = computed(
      () => store.getters["checkout/finishedLoadingReservationDetails"]
    );
    const activeLanguage = computed(
      () => store.getters["localization/activeLanguage"]
    );

    const { getReservationDetails } = useGetAndStoreReservation();

    watch(
      () => activeLanguage.value,
      () => {
        if (activeLanguage.value?.isoCode) {
          getReservationDetails(
            props["reservationId"],
            props["email"],
            activeLanguage.value?.isoCode,
            domainId.value
          );
        }
      }
    );

    watch(
      () => [
        localization.value,
        finishedLoadingDetails.value,
        paymentError.value,
      ],
      () => {
        if (localization.value.checkoutPage && finishedLoadingDetails.value) {
          router.push({
            name: "confirm",
          });
        }
        if (localization.value.checkoutPage && paymentError.value) {
          error.value = true;
        }
      }
    );
    const { trackErrorPaymentRedirect } = useTrackingError();

    watch(
      () => error.value,
      () => {
        trackErrorPaymentRedirect(
          localization?.value.checkoutPage?.reservationPaymentErrorMessage
        );
      }
    );

    return { error, localization };
  },
  components: {
    LoadingScreen,
    ErrorMessage,
  },
};
</script>

<template>
  <wb-modal
    ref="cookieLayerModal"
    class="cookie-layer-overlay"
    mode="info"
    close-on-backdrop-click
    close-on-escape-key
    scroll-lock
  >
    <wb-modal-head class="cookie-layer-overlay__head">
      <Icon
        name="close"
        :size="20"
        class="cookie-layer-overlay__close"
        @click="closeModal"
      />
    </wb-modal-head>
    <wb-modal-content
      class="cookie-layer-overlay__content"
      v-if="localization?.cookiesText"
    >
      <p v-html="localization?.cookiesText?.content"></p>
      <div class="cookie-layer-overlay__link">
        <a class="wb-link--standalone" :onclick="modalProviderClicked">
          <Icon name="chevron-right" :size="12" :width="18" :height="12" />
          {{ localization?.cookiesText?.linkText }}
        </a>
      </div>
      <button
        class="wb-button wb-button--primary wb-button--medium cookie-layer-overlay__button"
        @click="closeModal"
      >
        {{ localization?.cookiesText?.buttonText }}
      </button>
    </wb-modal-content>
  </wb-modal>
  <transition name="Slide">
    <Provider
      v-if="openModalProvider"
      @close="openModalProvider = false"
      preselectedTab="cookies"
    />
  </transition>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Icon from "@/components/Icon";
import Provider from "@/views/Provider";

export default {
  name: "CookieLayer",
  components: { Icon, Provider },
  setup(props, { emit }) {
    const store = useStore();
    const cookieLayerModal = ref(null);

    const localization = computed(
      () => store.getters["localization/localization"]
    );

    onMounted(() => {
      cookieLayerModal.value.addEventListener("wbclose", closeModal);
    });

    const router = useRouter();

    const closeModal = () => {
      emit("close");
      router.push({ name: "home" });
    };

    const openModalProvider = ref(false);

    const modalProviderClicked = () => {
      openModalProvider.value = true;
    };

    return {
      cookieLayerModal,
      localization,
      closeModal,
      openModalProvider,
      modalProviderClicked,
    };
  },
};
</script>

<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.cookie-layer-overlay {
  .wb-modal {
    align-items: flex-start;
  }

  .wb-modal__outer {
    height: 50% !important;

    @include breakpoint-from(mq2) {
      width: 85% !important;
      height: 30% !important;
    }

    @include breakpoint-from(mq4) {
      width: 85% !important;
      height: 30% !important;
      max-width: 1000px;
    }

    @include breakpoint-from(mq6) {
      width: 85% !important;
      height: 30% !important;
      max-width: 1240px;
    }
  }

  &__head {
    color: var(--wb-black);
    padding: 20px;
  }

  &__close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    color: var(--wb-black);
  }

  &__content {
    display: flex;
    flex-direction: column;
    text-align: left;

    &__accordion {
      padding-top: 15px;
    }
  }

  &__link {
    margin-top: 30px;
    cursor: pointer;
  }

  &__button {
    align-self: flex-end;
    width: 180px;
    margin: 20px 10px 20px 0;
  }
}
</style>

<template>
  <div class="checkout">
    <transition name="slide-down">
      <ErrorMessage
        v-if="error"
        :message="
          errorMessage
            ? errorMessage
            : localization?.checkoutPage?.reservationErrorMessage
        "
        :homeButton="errorMessage ? true : false"
        :buttonText="localization?.checkoutPage?.btnRequestNewSearch"
        @buttonClicked="errorMsgButtonClicked()"
      />
    </transition>

    <ModelPriceDateVehicle
      v-if="selectedVehicle"
      class="content-width"
      :vehicle="selectedVehicle"
      :localization="localization"
      :additionalServices="additionalServicesText"
      id="modelPrice"
    />

    <section class="content-width">
      <Breadcrumb
        :active="activeStep"
        :configCheckout="configCheckout"
        @stepClicked="changeStep"
        id="breadcrumb"
      />

      <div class="checkout__container">
        <form
          @submit.prevent="onFormSubmit($event)"
          class="checkout__wrapper"
          :class="{ 'full-width': lastStep }"
        >
          <div class="checkout__content wb-type-copy">
            <transition name="fade" mode="out-in">
              <keep-alive>
                <component
                  v-bind:is="activeComponent"
                  :localization="localization"
                  :name="activeComponent"
                ></component>
              </keep-alive>
            </transition>
          </div>

          <div
            class="checkout__actions"
            :class="{ 'row-3': lastStep, fixed: scrolling }"
          >
            <div class="checkout__actions__inner">
              <button
                v-if="lastStep && paymentOptionalFlag"
                :disabled="disabled || loading"
                type="button"
                @click="requestBookingService(false)"
                class="checkout__actions__button checkout__actions__button--withoutPayment wb-button wb-button--primary wb-button--medium"
              >
                <wb-spinner
                  v-if="loading"
                  theme="dark"
                  class="btn-spinner"
                ></wb-spinner>
                <Icon
                  v-else
                  name="chevron-right"
                  :size="12"
                  :width="18"
                  :height="12"
                />
                {{
                  localization?.checkoutPage
                    ?.btnSubmitReservationWithoutOptionalPayment
                }}
              </button>
              <button
                :disabled="disabled || loading"
                type="submit"
                class="checkout__actions__button checkout__actions__button--continue wb-button wb-button--primary wb-button--medium"
              >
                <wb-spinner
                  v-if="loading"
                  theme="dark"
                  class="btn-spinner"
                ></wb-spinner>
                <Icon
                  v-else
                  name="chevron-right"
                  :size="12"
                  :width="18"
                  :height="12"
                />
                {{ continueText }}
              </button>
              <button
                :disabled="loading"
                @click="back"
                type="button"
                class="checkout__actions__button checkout__actions__button--back wb-button wb-button--secondary wb-button--medium"
              >
                <Icon name="chevron-left" :size="12" :width="18" :height="12" />
                {{ backText }}
              </button>
              <button
                :disabled="loading"
                v-if="lastStep && !paymentOptionalFlag"
                @click="redirectToHomeOrDealer"
                type="button"
                class="checkout__actions__button checkout__actions__button--home wb-button wb-button--secondary wb-button--medium"
              >
                <Icon name="chevron-left" :size="12" :width="18" :height="12" />
                {{ localization?.checkoutPage?.btnRequestNewSearch }}
              </button>
            </div>
          </div>
          <div
            class="fixed-buttons__placeholder"
            :class="{ visible: scrolling }"
          ></div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Breadcrumb from "@/components/Checkout/Breadcrumb";
import Icon from "@/components/Icon";
import PersonalData from "@/components/Checkout/Steps/PersonalData";
import AdditionalFields from "@/components/Checkout/Steps/AdditionalFields";
import RentalData from "@/components/Checkout/Steps/RentalData";
import Summary from "@/components/Checkout/Steps/Summary";
import useCheckoutStep from "@/compositions/useCheckoutStep";
import useHomeRedirect from "@/compositions/useHomeRedirect";
import ModelPriceDateVehicle from "@/components/ModelPriceDateVehicle";
import ErrorMessage from "@/components/Notification/ErrorMessage";
import useTrackingError from "@/compositions/useTrackingError";
import { stepNames } from "@/utilities/checkout";
import {
  getAdditionalServicesAsText,
  getAdditionalServicesIdAndQuantity,
} from "@/utilities/additionalServices";
import useStickyButtons from "@/compositions/useStickyButtons";

export default {
  name: "Checkout",
  setup() {
    const store = useStore();
    const router = useRouter();
    const localization = computed(
      () => store.getters["localization/localization"]
    );
    const selectedVehicle = computed(
      () => store.getters["vehicles/selectedVehicle"]
    );

    const { redirectToHomeOrDealer } = useHomeRedirect();
    // redirect if no data
    if (!Object.keys(localization.value).length) {
      redirectToHomeOrDealer();
      return {};
    }

    const additionalServicesText = ref(null);
    const selectedAdditionalServices = computed(
      () => store.getters["vehicles/selectedAdditionalServices"]
    );

    additionalServicesText.value = getAdditionalServicesAsText(
      selectedAdditionalServices.value
    );
    watch(
      () => selectedAdditionalServices.value,
      () => {
        additionalServicesText.value = getAdditionalServicesAsText(
          selectedAdditionalServices.value
        );
      }
    );

    const {
      activeStep,
      nextStep,
      changeStep,
      activeComponent,
      back,
      lastStep,
      getSelectedLocation,
    } = useCheckoutStep();

    const captchaData = computed(() => store.getters["captcha/captchaData"]);

    function validateCaptcha() {
      store.dispatch("captcha/validate", captchaData.value.validationData);
    }

    watch(
      () => captchaData.value.isValid,
      () => {
        if (captchaData.value.isValid) {
          nextStep();
        }
      }
    );

    function errorMsgButtonClicked() {
      redirectToHomeOrDealer();
    }
    const domainId = computed(() => store.getters["localization/domainId"]);

    const isCaptchaActive = computed(
      () => store.getters["marketConfig/captcha"]
    );
    const disabled = computed(() => store.getters["checkout/disabled"]);
    const onFormSubmit = async () => {
      if (!disabled.value) {
        if (lastStep.value) {
          requestBookingService(
            (isPaymentRequired() || isPaymentOptional()) &&
              !isPaymentFullyPaidByVoucher()
          );
        } else {
          if (
            activeComponent.value === stepNames.rentalData &&
            isCaptchaActive.value
          ) {
            validateCaptcha();
          } else {
            nextStep();
          }
        }
      }
    };

    const requestBookingService = (withPayment) => {
      const selectedVehicleData = computed(
        () => store.getters["vehicles/selectedVehicleRequestData"]
      );
      const getPricingCategory = computed(
        () => store.getters["vehicles/pricingCategory"]
      );
      const checkoutFields = computed(
        () => store.getters["checkout/checkoutFields"]
      );
      const rentalPeriod = computed(() => store.getters["search/rentalPeriod"]);
      const domainId = computed(() => store.getters["localization/domainId"]);
      const isoCode = computed(() => store.getters["localization/isoCode"]);
      const captchaId = computed(() => store.getters["captcha/captcha"]);
      const captchaText = computed(() => store.getters["captcha/userInput"]);
      const additionalServicesForBooking = getAdditionalServicesIdAndQuantity(
        selectedAdditionalServices.value
      );
      const request = {
        domainId: domainId.value,
        isoCode: isoCode.value,
        vehicleData: selectedVehicleData.value,
        checkoutFields: checkoutFields.value,
        rentalPeriod: rentalPeriod.value,
        captchaId: captchaId.value.id,
        captchaText: captchaText.value,
        pricingCategory: getPricingCategory.value,
      };
      if (Object.keys(selectedAdditionalServices.value).length) {
        request["additionalServices"] = additionalServicesForBooking;
      }
      if (withPayment) {
        store.dispatch("checkout/requestBookingPayment", request);
      } else {
        store.dispatch("checkout/requestBooking", request);
      }

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const loading = computed(() => store.getters["checkout/loading"]);
    const reservationId = computed(
      () => store.getters["checkout/reservationId"]
    );
    const paymentLink = computed(() => store.getters["checkout/paymentLink"]);

    // for booking without payment: route to confirmation page after reservation id has been received
    watch(
      () => [loading.value, reservationId.value],
      ([isLoading, id]) => {
        if (!isLoading && id) {
          router.push({ name: "confirm" });
        }
      }
    );

    // for booking with payment: redirect to MPay after payment link has been received
    watch(
      () => [loading.value, paymentLink.value],
      ([isLoading, link]) => {
        if (!isLoading && link) {
          window.location.href = link;
        }
      }
    );
    const continueText = computed(() => {
      if (lastStep.value) {
        if (
          (isPaymentRequired() || isPaymentOptional()) &&
          !isPaymentFullyPaidByVoucher()
        ) {
          return localization.value?.checkoutPage
            ?.btnSubmitReservationWithPayment;
        }
        return localization.value?.checkoutPage?.btnSubmitReservation;
      }
      return localization.value?.checkoutPage?.btnContinue;
    });

    const backText = computed(() => {
      if (lastStep.value) {
        return localization.value?.checkoutPage?.btnBackToVehicleSearch;
      }
      return localization.value?.checkoutPage?.btnBackBreadcrumb;
    });

    // fixed buttons
    const { scrolling, setScrollObserver, resetScrollObserver } =
      useStickyButtons(false);

    watch(
      () => lastStep.value,
      () => {
        if (lastStep.value) {
          setScrollObserver();
        } else {
          resetScrollObserver();
        }
      }
    );

    const { trackErrorCheckout } = useTrackingError();

    const error = computed(() => store.getters["checkout/error"]);
    const errorMessage = computed(() => store.getters["checkout/errorMessage"]);
    watch(
      () => error.value,
      () => {
        const msg = errorMessage.value
          ? errorMessage.value
          : localization?.value.checkoutPage?.reservationErrorMessage;
        trackErrorCheckout(msg, activeComponent.value);
      }
    );

    onBeforeUnmount(() => {
      store.dispatch("checkout/resetError");
    });

    const location = ref(null);

    // determine payment flow depending on information from rental station
    const paymentOptionalFlag = ref(false);

    onBeforeMount(() => {
      location.value = getSelectedLocation();
      paymentOptionalFlag.value = isPaymentOptional();
    });

    const isPaymentRequired = () => {
      return location.value?.outlet?.paymentMode === "REQUIRED";
    };

    const isPaymentOptional = () => {
      return location.value?.outlet?.paymentMode === "OPTIONAL";
    };

    const isPaymentFullyPaidByVoucher = () => {
      const voucherStatusCode = store.getters["vehicles/voucherStatusCode"];
      const pricing = store.getters["vehicles/pricingDetailsOfSelectedVehicle"];

      if (
        voucherStatusCode === 1 &&
        pricing?.validityStatusCode === 1 &&
        pricing?.totalAmount === 0
      ) {
        return true;
      }
      return false;
    };

    return {
      localization,
      selectedVehicle,
      activeStep,
      changeStep,
      disabled,
      configCheckout: computed(() => store.getters["marketConfig/checkout"]),
      activeComponent,
      onFormSubmit,
      back,
      redirectToHomeOrDealer,
      lastStep,
      continueText,
      backText,
      scrolling,
      loading,
      error,
      errorMessage,
      paymentOptionalFlag,
      requestBookingService,
      errorMsgButtonClicked,
      additionalServicesText,
    };
  },
  components: {
    ModelPriceDateVehicle,
    Breadcrumb,
    Icon,
    PersonalData,
    AdditionalFields,
    RentalData,
    Summary,
    ErrorMessage,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/checkout";
</style>

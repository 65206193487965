import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

export default function (timeoutCount = 300) {
  const store = useStore();
  const displayValue = ref("");
  const debouncedValue = ref("");
  const geoError = ref(null);
  let timeoutRef = null;
  const query = computed(() => store.getters["search/query"]);

  const debounceListener = (e) => {
    if (timeoutRef !== null) {
      clearTimeout(timeoutRef);
    }

    if (e.target.value.length <= 2) {
      store.dispatch("search/updateSuggestionOpen", false);
      store.dispatch("search/clearSuggestion");
    }

    displayValue.value = e.target.value;
    geoError.value = false;

    timeoutRef = setTimeout(() => {
      dispatchStore(e.target.value);
    }, timeoutCount);
  };

  const dispatchStore = (searchInput) => {
    debouncedValue.value = searchInput;
    store.dispatch("search/updateQuery", searchInput);
    store.dispatch("search/updateSuggestionSelected", false);
    store.dispatch("search/clearLocation");
    store.dispatch("search/clearCity");
    store.dispatch("search/resetEmptyLocation");
  };

  const debounceSelected = (suggestion) => {
    displayValue.value = suggestion;

    if (query.value.toLowerCase() !== suggestion.toLowerCase()) {
      store.dispatch("search/updateQuery", suggestion);
    }
    geoError.value = false;

    if (timeoutRef !== null) {
      clearTimeout(timeoutRef);
    }

    timeoutRef = setTimeout(() => {
      debouncedValue.value = suggestion;
    }, timeoutCount);
  };

  const searchValid = computed(() => {
    return displayValue.value.length > 2;
  });

  onMounted(() => {
    if (query.value?.length) {
      displayValue.value = query.value;
      debouncedValue.value = query.value;
    }
  });

  watch(
    () => query.value,
    (value) => {
      if (value === "") {
        displayValue.value = value;
      }
    }
  );

  const domainId = computed(() => store.getters["marketConfig/domainId"]);
  const getGeoLocation = () => {
    store.dispatch("search/updateLocationLoading", true);

    if (navigator.geolocation) {
      store.dispatch("search/clearSuggestion");
      store.dispatch("search/setSearchType", "localization function");
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        store.dispatch("search/getCity", {
          latitude,
          longitude,
          domainId: domainId.value,
        });
      }, geoLocationErrorHandler);
    } else {
      geoLocationErrorHandler();
    }
  };

  const geoLocationErrorHandler = () => {
    store.dispatch("search/updateLocationLoading", false);
    geoError.value = true;
  };

  const city = computed(() => store.getters["search/geoLocation"]);
  const geoLocationActive = computed(() => {
    return city.value && city.value === displayValue.value;
  });

  return {
    debouncedValue,
    displayValue,
    debounceListener,
    debounceSelected,
    searchValid,
    getGeoLocation,
    geoError,
    geoLocationActive,
  };
}

<template>
  <div class="dealer">
    <LoadingScreen
      v-if="!localization?.mainPage || !mainOutletStructure?.displayName"
    />
    <div v-else>
      <section
        :style="{
          backgroundImage: `url(${getImageUrl(
            mainOutletStructure.imageId,
            true
          )})`,
          backgroundPosition: `center`,
        }"
        class="teaser"
      ></section>
      <section class="content-width">
        <div class="wb-margin-top-m"></div>
        <h3 class="wb-type-heading-l wb-margin-bottom-s">
          {{ localization?.landingPage?.welcomeTitle }}
        </h3>
        <div class="wb-type-copy-strong wb-margin-bottom-s">
          {{ displayName }}
        </div>
        <div
          class="wb-type-copy wb-margin-bottom-m"
          v-html="localization?.landingPage?.welcomeText"
        ></div>
      </section>
      <div id="startOfSearchArea" />
      <SearchArea
        :title="localization.landingPage?.dateSelectLabel"
        :searchBtn="localization.landingPage?.searchBtn"
        :mainOutlet="mainOutletStructure"
        target="landing"
      />
      <section class="content-width">
        <div class="dealer__content-box">
          <div class="dealer__content-box__item wb-margin-bottom-l">
            <ContentBox
              :localization="localization?.mainPage?.contentBoxes?.[0]"
            />
          </div>
          <div class="dealer__content-box__item wb-margin-bottom-l">
            <ContentBox
              :localization="localization?.mainPage?.contentBoxes?.[1]"
            />
          </div>
          <div ref="contentBoxesRef"></div>
        </div>
        <div class="wb-margin-vertical-l">
          <div class="wb-margin-top-l"></div>
          <h3 class="wb-type-heading-l wb-margin-bottom-xs">
            {{ localization?.landingPage?.locationsTitle }}
          </h3>
          <div class="outlet-list-container">
            <div
              v-for="outlet of mainOutletStructure.outlets"
              :key="outlet.outletId"
              class="wb-margin-top-m outlet-container"
            >
              <div class="wb-padding-top-xxs wb-padding-end-s">
                <Icon
                  name="mercedes-pin"
                  :width="48"
                  :height="48"
                  :size="48"
                  class="pin"
                  :paths="2"
                />
              </div>
              <div class="wb-type-copy">
                {{ outlet.name }}<br />
                {{ outlet.latinAddress.street }}<br />
                {{
                  outlet.latinAddress.zipCode + " " + outlet.latinAddress.city
                }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, onUpdated, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import LoadingScreen from "@/components/LoadingScreen";
import useTrackingPage from "@/compositions/useTrackingPage";
import ContentBox from "@/components/Home/ContentBox";
import { ViewportObserver } from "@workbench/core";
import useImage from "@/compositions/useImage";
import SearchArea from "@/components/Search/SearchArea";
import Icon from "@/components/Icon";

export default {
  name: "DealerLanding",
  props: {
    partnerId: null,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const contentBoxesObserver = ref(null);
    const localization = computed(
      () => store.getters["localization/localization"]
    );
    const marketConfigPreAuthData = computed(
      () => store.getters["marketConfig/preAuthData"]
    );
    const domainId = computed(() => store.getters["localization/domainId"]);
    const mainOutletStructure = computed(
      () => store.getters["mainOutlet/mainOutletStructure"]
    );
    const { getImageUrl } = useImage();

    const selectedRentalPeriod = computed(
      () => store.getters["search/rentalPeriod"]
    );

    watch(
      () => marketConfigPreAuthData.value,
      (preAuth) => {
        if (!preAuth?.landingPage) {
          router.push({ name: "home" });
        }
      }
    );

    watch(
      () => domainId.value,
      (value) => {
        if (value) {
          store.dispatch("mainOutlet/getMainOutletStructure", {
            domainId: value,
            gsId: props.partnerId,
          });
        }
      },
      {
        immediate: true,
      }
    );

    watch(
      () => computed(() => store.getters["marketConfig/mockMode"]).value,
      () =>
        store.dispatch("mainOutlet/getMainOutletStructure", {
          domainId: 61,
          gsId: props.partnerId,
        })
    );

    const error = computed(() => store.getters["mainOutlet/loadingFailed"]);
    watch(
      () => error.value,
      (hasError) => {
        if (hasError) {
          router.push({
            name: "home",
          });
        }
      }
    );

    const { trackHomePromotionTiles, trackHomeDealerLandingPage } =
      useTrackingPage();
    const activeLanguage = computed(
      () => store.getters["localization/activeLanguage"]
    );
    const tsLoaded = computed(
      () => store.getters["marketConfig/trackingScriptLoaded"]
    );
    let contentBoxesTracked = false;
    const contentBoxesRef = ref(null);

    watch(
      () => [
        activeLanguage.value,
        tsLoaded.value,
        marketConfigPreAuthData.value,
      ],
      ([language, loaded, marketConfig]) => {
        triggerDealerLandingTracking(language, loaded, marketConfig);
      }
    );

    const isInViewport = (element) => {
      if (element) {
        const rect = element.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
        );
      }
      return false;
    };

    const trackcontentBoxes = () => {
      if (
        !contentBoxesTracked &&
        activeLanguage.value.isoCode &&
        tsLoaded.value
      ) {
        trackHomePromotionTiles();
        contentBoxesTracked = true;
      }
    };

    const observeContentBoxes = () => {
      if (contentBoxesRef.value && tsLoaded && activeLanguage.value) {
        contentBoxesObserver.value = new ViewportObserver({
          element: contentBoxesRef.value,
          viewportEnterFn: () => trackcontentBoxes(),
        });
      }
    };
    let dealerLandingStartTracked = false;

    const triggerDealerLandingTracking = (language, loaded, marketConfig) => {
      if (
        language?.isoCode &&
        loaded &&
        !marketConfig.maintenancePage &&
        marketConfig.domainActive
      ) {
        if (!dealerLandingStartTracked) {
          trackHomeDealerLandingPage();
          dealerLandingStartTracked = true;
        }
        if (isInViewport(contentBoxesRef.value) && !contentBoxesTracked) {
          contentBoxesTracked = true;
          setTimeout(function () {
            trackHomePromotionTiles();
          }, 750);
        }
      }
    };
    onUpdated(() => {
      observeContentBoxes();
    });

    onUnmounted(() => {
      if (contentBoxesObserver.value) {
        contentBoxesObserver.value.disconnect();
      }
    });

    onMounted(() => {
      // scroll to search area in case there already was a date selection made
      // e.g. when editing search parameters in result view
      if (selectedRentalPeriod.value.fromDate) {
        const searchAreaScrollAnchor =
          document.getElementById("startOfSearchArea");

        if (searchAreaScrollAnchor) {
          const top = searchAreaScrollAnchor.offsetTop;
          window.scrollTo(0, top);
        }
      }
      observeContentBoxes();
      contentBoxesTracked = false;
      dealerLandingStartTracked = false;
      triggerDealerLandingTracking(
        activeLanguage.value,
        tsLoaded.value,
        marketConfigPreAuthData.value
      );
    });

    const displayName = computed(() => {
      if (activeLanguage.value.useOriginalOutletAddress) {
        return (
          mainOutletStructure.value.outlets[0]?.originalName ||
          mainOutletStructure.value.displayName
        );
      }
      return mainOutletStructure.value.displayName;
    });

    return {
      localization,
      marketConfigPreAuthData,
      contentBoxesRef,
      getImageUrl,
      mainOutletStructure,
      displayName,
    };
  },
  components: {
    Icon,
    SearchArea,
    LoadingScreen,
    ContentBox,
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/components/dealerLanding";
</style>

import { computed } from "vue";
import { useStore } from "vuex";
import {
  removeAdobeLaunchKeyCharacters,
  getContractPartnerType,
} from "@/utilities/tracking";

const useProductTracking = function () {
  const store = useStore();
  const selectedVehicle = computed(
    () => store.getters["vehicles/selectedVehicle"]
  );

  const selected = computed(() => store.getters["search/locationSelected"]);

  const convertedSelectedVehicle = JSON.parse(
    JSON.stringify(selectedVehicle.value)
  );
  const selectedPartner = Array.from(selected.value).filter((partner) => {
    const myPartner = JSON.parse(JSON.stringify(partner));
    if (myPartner.outlet?.outletId === convertedSelectedVehicle?.outletId) {
      return true;
    }
    return false;
  });
  const convertedSelectedPartner = JSON.parse(
    JSON.stringify(selectedPartner)
  )[0];

  const getProductTracking = (purchaseType) => {
    return [
      {
        productInfo: {
          productID: selectedVehicle.value.vehicleVin,
          productName: removeAdobeLaunchKeyCharacters(
            `${selectedVehicle.value.modelType.modelClassName} ${selectedVehicle.value.modelType.vehicleTypeSingular} ${selectedVehicle.value.name}`
          ),
        },
        category: {
          primaryCategory: removeAdobeLaunchKeyCharacters(
            selectedVehicle.value.modelType.modelClassName
          ),
          subCategory1: removeAdobeLaunchKeyCharacters(
            `${selectedVehicle.value.modelType.modelClassName} ${selectedVehicle.value.modelType.vehicleTypeSingular}`
          ),
          subCategory2: removeAdobeLaunchKeyCharacters(
            selectedVehicle.value.name
          ),
          productType: removeAdobeLaunchKeyCharacters(
            selectedVehicle.value.modelType.vehicleTypeSingular
          ),
        },
        attributes: {
          purchaseType: purchaseType,
          contractPartner: {
            type: getContractPartnerType(
              selectedVehicle.value.contractualPartner
            ),
            name: removeAdobeLaunchKeyCharacters(
              convertedSelectedPartner.outlet.name
            ),
            address: removeAdobeLaunchKeyCharacters(
              convertedSelectedPartner.outlet.latinAddress.street
            ),
            zipCode: removeAdobeLaunchKeyCharacters(
              convertedSelectedPartner.outlet.latinAddress.zipCode
            ),
            city: removeAdobeLaunchKeyCharacters(
              convertedSelectedPartner.outlet.latinAddress.city
            ),
          },
        },
      },
    ];
  };

  return { getProductTracking };
};

export default useProductTracking;

import { reactive } from "vue";

const useDragContentSlider = function () {
  const slider = reactive({
    element: null,
    startX: null,
    scrollLeft: null,
    isDown: false,
  });

  const mouseDown = (event, selector = null) => {
    slider.element = document.querySelector(`#${selector} .wb-slider__inner`);
    slider.isDown = true;
    if (slider.element) {
      slider.startX = event.pageX - slider.element.offsetLeft;
      slider.scrollLeft = slider.element.scrollLeft;
    }
  };

  const mouseUp = () => {
    slider.isDown = false;
  };

  const mouseMove = (event) => {
    if (!slider.isDown) {
      return;
    }
    event.preventDefault();
    const x = event.pageX - slider.element.offsetLeft;
    const walk = x - slider.startX;
    slider.element.scrollLeft = slider.scrollLeft - walk;
  };

  return { mouseDown, mouseUp, mouseMove };
};

export default useDragContentSlider;

const useErrorTrackingBuilder = function () {
  const buildErrorTracking = (messagetext, type = "system error") => {
    return {
      event: {
        eventInfo: {
          eventName: "error",
          eventAction: "error message",
          type: type,
        },
        attributes: {
          messagetext: messagetext,
        },
      },
    };
  };

  return { buildErrorTracking };
};

export default useErrorTrackingBuilder;

import { computed } from "vue";
import { useStore } from "vuex";
import { paramNames } from "@/components/Vehicle/constants";

const useShareLink = function () {
  const store = useStore();

  const createShareLink = () => {
    const baseUrl = window.location.origin;
    let url = baseUrl + "/direct-search";

    url = addSearchParameter(url);
    url = addFilterParameter(url);
    return url;
  };

  const addSearchParameter = (url) => {
    const from = store.getters["search/selectedDate"].from;
    const to = store.getters["search/selectedDate"].to;
    const fromTime = store.getters["search/selectedTime"].from;
    const toTime = store.getters["search/selectedTime"].to;
    const isDealerLandingPage =
      store.getters["mainOutlet/isComingFromLandingPage"];

    const fromParameter = "?" + createSearchParam(paramNames.from, from);
    const toParameter = "&" + createSearchParam(paramNames.to, to);
    const fromTimeParameter =
      "&" + createSearchParam(paramNames.timeFrom, fromTime);
    const toTimeParameter = "&" + createSearchParam(paramNames.timeTo, toTime);

    url += fromParameter + toParameter + fromTimeParameter + toTimeParameter;

    if (isDealerLandingPage) {
      // Direct search based on partner ID
      const gsId = store.getters["mainOutlet/gsId"];
      const partnerIdParameter =
        "&" + createSearchParam(paramNames.partnerId, gsId);
      url += partnerIdParameter;
    } else {
      // Standard search based on radius and location
      const location = store.getters["search/query"];
      const radius = store.getters["search/radius"];

      const locationParameter =
        "&" + createSearchParam(paramNames.location, location);
      const radiusParameter =
        "&" + createSearchParam(paramNames.radius, radius);

      url += locationParameter + radiusParameter;
    }

    return url;
  };

  const addFilterParameter = (url) => {
    const activeFilters = computed(
      () => store.getters["vehicles/activeFilters"]
    );

    if (
      activeFilters.value.size &&
      activeFilters.value.get("model")?.models?.length
    ) {
      const classFilterParam = createClassFilterParam(
        paramNames.classFilter,
        activeFilters.value
      );
      url += classFilterParam;
    }
    if (
      activeFilters.value.size &&
      activeFilters.value.get("model")?.vehicleTypes?.length
    ) {
      const vehicleTypeParam = createVehicleTypeParam(
        paramNames.vehicleTypeFilter,
        activeFilters.value
      );
      url += vehicleTypeParam;
    }
    if (
      activeFilters.value.size &&
      activeFilters.value.get("attribute")?.attribute?.length
    ) {
      const brandFilterParam = createBrandFilterParam(
        paramNames.brandFilter,
        activeFilters.value
      );
      url += brandFilterParam;
    }
    return url;
  };

  const createSearchParam = (key, value) => {
    let result;
    if (typeof value === "string" || value instanceof String) {
      result = `${key}=${value.replace(/[:\-]/g, "")}`;
    } else {
      result = `${key}=${value}`;
    }
    return result;
  };

  const createClassFilterParam = (classFilter, activeFilters) => {
    const model = activeFilters.get("model");
    const result = model.models.join();
    return `&${classFilter}=${result}`;
  };

  const createVehicleTypeParam = (vehicleFilter, activeFilters) => {
    const vehicleType = activeFilters.get("model");
    const result = vehicleType.vehicleTypes.join();
    return `&${vehicleFilter}=${result}`;
  };

  const createBrandFilterParam = (brandFilter, activeFilters) => {
    const brand = activeFilters.get("attribute");
    const result = brand.attribute.map((attr) => attr.name);
    return `&${brandFilter}=${result}`;
  };

  return {
    createShareLink,
  };
};

export default useShareLink;

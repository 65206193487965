<template>
  <div
    class="timepicker"
    @mouseleave="stopScrolling(false)"
    ref="timepickerRef"
  >
    <div class="timepicker__container">
      <div class="timepicker__item">
        <wb-input-control :theme="themeStyle" :success="success.from">
          <wb-input class="timepicker__input-container jsTimeInput">
            <label>
              <span v-if="localization?.pickUpTime">
                {{ localization?.pickUpTime }}
              </span>
            </label>
            <input
              class="timepicker__input"
              type="text"
              placeholder="00:00"
              v-model="timeFrom"
              @focus="
                focus(1);
                stopScrolling(true);
              "
              ref="timeFromRef"
              :disabled="disabled"
              readonly
            />
          </wb-input>
        </wb-input-control>
        <transition name="slide-down" @after-enter="scrollTo">
          <Times
            v-if="isOpen && mode === 1"
            :mode="mode"
            :themeStyle="themeStyle"
            @closeOverlay="closeOverlay"
            @timeSelected="timeSelected($event)"
            @mouseenter="stopScrolling(true)"
          />
        </transition>
      </div>
      <div class="timepicker__item">
        <wb-input-control :theme="themeStyle" :success="success.to">
          <wb-input class="timepicker__input-container jsTimeInput">
            <label>
              <span v-if="localization?.returnTime">
                {{ localization?.returnTime }}
              </span>
            </label>
            <input
              class="timepicker__input"
              type="text"
              placeholder="00:00"
              v-model="timeTo"
              @focus="
                focus(2);
                stopScrolling(true);
              "
              ref="timeToRef"
              :disabled="disabled"
              readonly
            />
          </wb-input>
        </wb-input-control>
        <transition name="slide-down" @after-enter="scrollTo">
          <Times
            v-if="isOpen && mode === 2"
            :mode="mode"
            :themeStyle="themeStyle"
            @closeOverlay="closeOverlay"
            @timeSelected="timeSelected($event)"
            @mouseenter="stopScrolling(true)"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import useTimeValidation from "@/compositions/useTimeValidation";
import Times from "@/components/Timepicker/Times";
import useTrackingPage from "@/compositions/useTrackingPage";
import { getOffsetTop } from "@/utilities/scroll";

export default {
  props: {
    disabled: Boolean,
    localization: Object,
    calendarLeft: Boolean,
    themeStyle: {
      type: String,
      default: "dark",
    },
  },
  setup(props) {
    const timeValidation = useTimeValidation();
    const { trackSearchTimePicker } = useTrackingPage();

    watch(
      () => props.calendarLeft,
      (afterCalendarLeave) => {
        if (afterCalendarLeave) {
          timeValidation.timeFromRef.value.focus();
          stopScrolling(true);
          trackSearchTimePicker();
        }
      }
    );

    const store = useStore();
    const isMobile = computed(() => store.getters["localization/isMobile"]);
    const timepickerRef = ref(null);
    const scrollTo = () => {
      if (isMobile.value) {
        window.scrollTo({
          top: getOffsetTop(timepickerRef.value),
          behavior: "smooth",
        });
      }
    };

    const stopScrolling = (active) => {
      if (active) {
        document.body.style.height = "100%";
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.height = "auto";
        document.body.style.overflowY = "auto";
      }
    };

    watch(
      () => timeValidation.isOpen.value,
      () => {
        if (!timeValidation.isOpen.value) {
          stopScrolling(false);
        }
      }
    );

    return {
      stopScrolling,
      ...timeValidation,
      scrollTo,
      timepickerRef,
    };
  },
  components: {
    Times,
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.timepicker {
  padding: 10px 0;

  @include breakpoint-from(mq3) {
    padding: 0;
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    flex: 0 0 47%;
    width: 47%;
  }

  &__input {
    padding-right: 0 !important;
  }

  // overwrite workbench style
  wb-input-control[theme="dark"]:not([disabled]):not([readonly])
    > wb-input:hover {
    background-color: var(--wb-grey-20);
  }
  wb-input-control[theme="bright"]:not([disabled]):not([readonly])
    > wb-input:hover {
    background-color: var(--wb-grey-95);
  }
  wb-input-control[theme="bright"] &__input-container,
  &__input-container[readonly] {
    background-color: var(--wb-grey-95);
  }

  &__input-container,
  &__input-container[readonly] {
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: var(--wb-grey-20);
    border-color: var(--wb-grey-70) !important;
  }
}
</style>

import { computed, ref } from "vue";
import { useStore } from "vuex";
import { addMinutes, format, isAfter, parseISO } from "date-fns";
import {
  addTime,
  beforeTime,
  getDayChar,
  inTimeRange,
  newDateWithTime,
  subtractTime,
} from "@/utilities/time";
import { isInDateRange } from "@/utilities/date";

const useTimesRange = function () {
  const store = useStore();
  const times = ref([]);
  const selectedDate = computed(() => store.getters["search/selectedDate"]);
  const selectedTime = computed(() => store.getters["search/selectedTime"]);
  const locationSelected = computed(
    () => store.getters["search/locationSelected"]
  );
  const sameDay =
    selectedDate.value?.from === selectedDate.value?.to ? true : false;

  const initTimes = (mode) => {
    // mode 1 = from-time-picker. mode 2 = to-time-picker
    const from = selectedDate.value?.from;
    const to = selectedDate.value?.to;

    let min;
    let max;
    if (mode === 1) {
      min = getTimes(from, "min", mode);
      max = getTimes(from, "max", mode);
    } else {
      min = getTimes(to, "min", mode);
      max = getTimes(to, "max", mode);
    }
    const [minHour, minMinute] = min.minMax.split(":");
    const [maxHour, maxMinute] = max.minMax.split(":");
    const maxTime = newDateWithTime(maxHour, maxMinute);

    let time = newDateWithTime(minHour, minMinute);
    while (!isAfter(time, maxTime)) {
      const formatTime = format(time, "HH:mm");
      let selected = false;
      if (mode === 1) {
        selected = selectedTime.value?.from === formatTime;
      } else {
        selected = selectedTime.value?.to === formatTime;
      }
      times.value.push({
        value: formatTime,
        selected,
        active: inTimeRange(min, time),
      });
      time = addMinutes(time, 30);
    }
  };

  const minFromTime = computed(() => store.getters["search/minFromTime"]);
  const minFromDate = computed(() => store.getters["search/minDate"]);
  const blockedMinutesOpening = computed(
    () => store.getters["marketConfig/blockedMinutesOpening"]
  );
  const blockedMinutesClosing = computed(
    () => store.getters["marketConfig/blockedMinutesClosing"]
  );

  function getSpecialOpeningIfPresent(location, stringDate) {
    let opening;
    const date = parseISO(stringDate);
    if (location.outlet.specialOpeningHours.length > 0) {
      const special = location.outlet.specialOpeningHours.find(
        (specialOpening) => {
          const startDate = parseISO(specialOpening.startDate);
          const endDate = parseISO(specialOpening.endDate);
          return isInDateRange(date, startDate, endDate);
        }
      );
      if (special) {
        opening = special.dayOpeningHours.find(
          (dayOpening) => dayOpening?.day === getDayChar(stringDate)
        );
      }
    }
    return opening;
  }

  function setRangeTimesArray(rangeTimes, i, time) {
    if (!rangeTimes[i]?.begin) {
      rangeTimes.push(time);
    }
    if (rangeTimes[i]?.begin && beforeTime(rangeTimes[i]?.begin, time?.begin)) {
      rangeTimes[i].begin = time.begin;
    }
    if (rangeTimes[i]?.end && !beforeTime(rangeTimes[i]?.end, time?.end)) {
      rangeTimes[i].end = time.end;
    }
  }

  function excludeBlockedMinutes(minOrMax, mode, minMax) {
    if (
      minOrMax === "min" &&
      mode === 1 &&
      selectedDate.value?.from !== minFromDate.value
    ) {
      minMax = addTime(minMax, blockedMinutesOpening.value);
    }
    if (
      minOrMax === "min" &&
      mode === 2 &&
      selectedDate.value?.from !== selectedDate.value?.to
    ) {
      minMax = addTime(minMax, blockedMinutesOpening.value);
    }
    if (minOrMax === "max") {
      minMax = subtractTime(minMax, blockedMinutesClosing.value);
    }
    return minMax;
  }

  function excludeLastHour(max) {
    return subtractTime(max, 60);
  }

  function getMinTime(minMax, time, mode) {
    if (beforeTime(minMax, time.begin)) {
      minMax = time.begin;
    }

    // if minFromDate same as selectedDate.from, set minMax to minFromTime
    if (mode === 1 && minFromDate.value === selectedDate.value?.from) {
      minMax = minFromTime.value;
    }

    // if pickup&return same, minMax should start at least 1h after selectedTime.value?.from
    if (
      mode === 2 &&
      selectedTime.value?.from &&
      selectedDate.value?.from === selectedDate.value?.to
    ) {
      minMax = addTime(selectedTime.value?.from, 60);
    }

    return minMax;
  }

  function setMinMaxIfNotPresent(minMax, minOrMax, time) {
    if (!minMax) {
      minMax = minOrMax === "min" ? time?.begin : time?.end;
    }
    return minMax;
  }

  const getTimes = (stringDate, minOrMax, mode) => {
    let minMax = "";
    let rangeTimes = [];

    [...locationSelected.value].forEach((location) => {
      let opening = getSpecialOpeningIfPresent(location, stringDate);

      if (!opening) {
        opening = location?.outlet?.openingHours.find(
          (dayOpening) => dayOpening?.day === getDayChar(stringDate)
        );
      }

      if (!rangeTimes.length) {
        // remove Proxy reactivity so it can be modified!
        rangeTimes = JSON.parse(JSON.stringify(opening?.times));
      }

      opening?.times.forEach((time, i) => {
        setRangeTimesArray(rangeTimes, i, time);

        // set min or max string
        minMax = setMinMaxIfNotPresent(minMax, minOrMax, time);
        if (minOrMax === "min") {
          minMax = getMinTime(minMax, time, mode);
        } else {
          if (!beforeTime(minMax, time.end)) {
            minMax = time.end;
          }
        }
      });
    });
    // add/subtract blockedMinutesOpening/blockedMinutesClosing to minMax
    minMax = excludeBlockedMinutes(minOrMax, mode, minMax);
    if (sameDay && mode === 1 && minOrMax === "max") {
      minMax = excludeLastHour(minMax);
    }
    return {
      minMax,
      rangeTimes,
    };
  };

  return {
    initTimes,
    times,
    selectedTime,
  };
};

export default useTimesRange;

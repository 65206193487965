// initial state
const menuState = () => ({
  isNavOpen: false,
});

// mutations
const menuMutations = {
  SET_IS_NAV_OPEN(state, isNavOpen) {
    state.isNavOpen = isNavOpen;
  },
  TOGGLE_NAV(state) {
    state.isNavOpen = !state.isNavOpen;
  },
};

// actions
const menuActions = {
  setIsNavOpen({ commit }, isNavOpen) {
    commit("SET_IS_NAV_OPEN", isNavOpen);
  },
  toggleNav({ commit }) {
    commit("TOGGLE_NAV");
  },
};

// getters
const menuGetters = {
  isNavOpen: (state) => {
    return state.isNavOpen;
  },
};

export default {
  namespaced: true,
  state: menuState,
  getters: menuGetters,
  actions: menuActions,
  mutations: menuMutations,
};

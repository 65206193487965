<template>
  <div class="vehicle-equipment wb-type-copy-secondary">
    <div v-if="displayMobile">
      <div class="vehicle-equipment__row">
        <div
          class="vehicle-equipment__item"
          :class="{ disabled: !equipment.kw }"
        >
          <Icon name="engine" :size="18" />
          <p class="vehicle-equipment__text">
            <span v-if="equipment.kw">{{ equipment.kw }}</span>
            <span v-else> — </span>
          </p>
          <p v-if="equipment?.ps">{{ equipment.ps }}</p>
        </div>
        <div
          class="vehicle-equipment__item"
          :class="{ disabled: !equipment.fuelType }"
        >
          <Icon name="fuel" :size="22" />
          <p class="vehicle-equipment__text">
            <span v-if="equipment.fuelType">{{ equipment.fuelType }}</span>
            <span v-else> — </span>
          </p>
        </div>
        <div class="vehicle-equipment__item">
          <Icon name="gear" :size="22" />
          <p class="vehicle-equipment__text">
            {{ equipment.transition }}
          </p>
        </div>
        <div
          class="vehicle-equipment__item"
          v-bind:class="{ disabled: !equipment.seats?.included }"
        >
          <Icon name="seat" :size="22" />
          <p class="vehicle-equipment__text">{{ equipment.seats?.text }}</p>
        </div>
      </div>
      <hr class="vehicle-equipment__divider" />
      <div class="vehicle-equipment__row">
        <div
          class="vehicle-equipment__item"
          v-bind:class="{ disabled: !equipment.navigation?.included }"
        >
          <Icon name="trip-end" :size="22" />
          <p class="vehicle-equipment__text">
            {{ equipment.navigation?.text }}
          </p>
        </div>
        <div
          class="vehicle-equipment__item"
          v-bind:class="{ disabled: !equipment.fourMatic?.included }"
        >
          <Icon name="equipment" :size="22" />
          <p class="vehicle-equipment__text">{{ equipment.fourMatic?.text }}</p>
        </div>
        <div
          class="vehicle-equipment__item"
          v-bind:class="{ disabled: !equipment.airCondition?.included }"
        >
          <Icon name="refrigerated" :size="22" />
          <p class="vehicle-equipment__text">
            {{ equipment.airCondition?.text }}
          </p>
        </div>
        <div
          v-bind:class="{ disabled: !equipment.trailerHitch?.included }"
          class="vehicle-equipment__item"
        >
          <Icon name="trailer-hitch" :size="22" />
          <p class="vehicle-equipment__text">
            {{ equipment.trailerHitch?.text }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="!displayMobile">
      <div class="vehicle-equipment__row">
        <div
          class="vehicle-equipment__item"
          :class="{ disabled: !equipment.kw }"
        >
          <Icon name="engine" :size="28" />
          <p class="vehicle-equipment__text">
            <span v-if="equipment.kw">{{ equipment.kw }}</span>
            <span v-else> — </span>
          </p>
          <p v-if="equipment?.ps">{{ equipment.ps }}</p>
        </div>
        <div
          class="vehicle-equipment__item"
          :class="{ disabled: !equipment.fuelType }"
        >
          <Icon name="fuel" :size="32" />
          <p class="vehicle-equipment__text">
            <span v-if="equipment.fuelType">{{ equipment.fuelType }}</span>
            <span v-else> — </span>
          </p>
        </div>
        <div class="vehicle-equipment__item">
          <Icon name="gear" :size="32" />
          <p class="vehicle-equipment__text">
            {{ equipment.transition }}
          </p>
        </div>
        <div
          class="vehicle-equipment__item"
          v-bind:class="{ disabled: !equipment.seats?.included }"
        >
          <Icon name="seat" :size="32" />
          <p class="vehicle-equipment__text">{{ equipment.seats?.text }}</p>
        </div>
        <div
          class="vehicle-equipment__item"
          v-bind:class="{ disabled: !equipment.navigation?.included }"
        >
          <Icon name="trip-end" :size="32" />
          <p class="vehicle-equipment__text">
            {{ equipment.navigation?.text }}
          </p>
        </div>
        <div
          class="vehicle-equipment__item"
          v-bind:class="{ disabled: !equipment.fourMatic?.included }"
        >
          <Icon name="equipment" :size="32" />
          <p class="vehicle-equipment__text">{{ equipment.fourMatic?.text }}</p>
        </div>
        <div
          class="vehicle-equipment__item"
          v-bind:class="{ disabled: !equipment.airCondition?.included }"
        >
          <Icon name="refrigerated" :size="32" />
          <p class="vehicle-equipment__text">
            {{ equipment.airCondition?.text }}
          </p>
        </div>
        <div
          v-bind:class="{ disabled: !equipment.trailerHitch?.included }"
          class="vehicle-equipment__item"
        >
          <Icon name="trailer-hitch" :size="32" />
          <p class="vehicle-equipment__text">
            {{ equipment.trailerHitch?.text }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "@/components/Icon";
import { useStore } from "vuex";
import { computed, watch, ref } from "vue";
export default {
  name: "VehicleEquipment",
  props: ["equipment"],
  setup(props) {
    const store = useStore();
    const isMobile = computed(() => store.getters["localization/isMobile"]);
    const displayMobile = ref(isMobile.value);
    const calcDisplayMobile = () => {
      displayMobile.value = isMobile.value;
      if (props.equipment.forceMobile) {
        displayMobile.value = true;
      }
    };
    calcDisplayMobile();
    watch(
      () => isMobile.value,
      () => {
        calcDisplayMobile();
      }
    );
    return { displayMobile };
  },
  components: {
    Icon,
  },
};
</script>
<style lang="scss" scoped>
.vehicle-equipment {
  padding: 20px 0;
  &__row {
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
  &__item {
    width: 25%;
    padding: 5px 5px 5px 5px;
    font-size: 0.875rem;
    line-height: 1.2rem;
    min-height: 85px;
  }
  &__text {
    padding-top: 17px;
  }
  &__divider {
    border-top: 1px solid var(--wb-grey-85);
    border-width: 1px 0 0 0;
    margin: 20px 0 20px 0;
  }
}
.disabled {
  color: var(--wb-grey-70);
}
</style>

// Semicolon and comma are not allowed in AdobeLaunchTracking-Fields
export function removeAdobeLaunchKeyCharacters(string) {
  if (string) {
    return string
      .replace(new RegExp(";", "g"), "")
      .replace(new RegExp(",", "g"), "");
  } else {
    return string;
  }
}

export function getContractPartnerType(isContractualPartner) {
  return isContractualPartner ? "Vertragspartner" : "Niederlassung";
}

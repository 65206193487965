<template>
  <div clas="filter-items">
    <p class="filter-items__label wb-type-copy-strong">
      {{ localization?.filter?.yourAge }}
    </p>
    <div class="filter-items__tags">
      <TagItem
        v-for="age in ages"
        :key="age.name"
        :tag="age"
        :active="ageActive(age)"
        @toggle="toggle($event)"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { filterName } from "@/utilities/filter";
import TagItem from "@/components/Vehicle/Filter/TagItem";

export default {
  name: "age",
  props: ["localization", "name"],
  setup(props) {
    const store = useStore();
    const ageFilter = computed(() =>
      store.getters["vehicles/getFilter"]("age")
    );

    const ages = [
      { value: "18-20", name: props.localization?.filter["18-20"] },
      { value: "21-24", name: props.localization?.filter["21-24"] },
      { value: "25+", name: props.localization?.filter["25+"] },
    ];

    const ageActive = (age) => ageFilter.value?.age?.value === age.value;

    const toggle = (event) => {
      const { tag, mode } = event;
      if (mode === "add") {
        store.dispatch("vehicles/addFilter", {
          type: "age",
          age: tag,
        });
      } else {
        store.dispatch("vehicles/removeFilter", "age");
      }
    };

    return {
      filterName,
      ages,
      ageActive,
      toggle,
      ageFilter,
    };
  },
  components: {
    TagItem,
  },
};
</script>

<style lang="scss">
.filter-items {
  &__label {
    color: var(--wb-grey-20);
    margin-bottom: 15px;
  }
}
</style>

import { useStore } from "vuex";

const useTrackingInit = function () {
  const store = useStore();

  const loadTrackingScript = (url) => {
    const trackingScript = document.querySelector(`script[src='${url}']`);

    // add tag only once
    if (!trackingScript) {
      const script = document.createElement("script");
      script.setAttribute("src", url);
      script.onload = () => {
        store.dispatch("marketConfig/trackingScriptLoaded", true);
      };
      document.getElementsByTagName("head")[0].appendChild(script);
    }
  };

  return { loadTrackingScript };
};

export default useTrackingInit;

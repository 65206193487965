<template>
  <div class="additional-services__radio-buttons__wrapper">
    <form>
      <wb-radio-control
        class="wb-margin-bottom-xs additional-services__radio-buttons"
      >
        <label class="additional-services__radio-buttons__label">
          <input
            type="radio"
            name="transmission"
            value=""
            v-model="selectedServiceName"
          />
          {{ localization.additionalServicesRadioButtonNoSelectionText }}
        </label>
      </wb-radio-control>
      <wb-radio-control
        class="wb-margin-bottom-xs additional-services__radio-buttons"
        v-for="item in service.additionalServices"
        :key="item.serviceName"
      >
        <label class="additional-services__radio-buttons__label">
          <input
            type="radio"
            name="transmission"
            :value="item.serviceName"
            v-model="selectedServiceName"
          />
          {{ item.serviceName }}
          <ToolTip
            style="margin-left: 15px"
            v-if="item.onlineDescription"
            :text="item.onlineDescription"
          ></ToolTip>
        </label>

        <div
          class="wb-type-heading-xs"
          :class="{ deactivated: selectedServiceName !== item.serviceName }"
        >
          {{ renderPrice(item) }}
        </div>
      </wb-radio-control>
    </form>
  </div>
</template>
<script>
import { watch, ref } from "vue";
import { getPriceTag } from "@/utilities/price";
import ToolTip from "@/components/ToolTip.vue";

export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
    currencyCode: {
      type: String,
      required: true,
    },
    isoCode: {
      type: String,
      required: true,
    },
    numberOfDays: {
      type: Number,
      required: true,
    },
    localization: {
      type: Object,
      required: true,
    },
    pricingDetails: {
      type: Object,
      required: true,
    },
  },
  emits: ["selectedItemChanged"],
  setup(props, { emit }) {
    const selectedServiceName = ref("");

    const priceMap = ref(new Map());

    watch(
      () => selectedServiceName.value,
      () => {
        const selectedService = props["service"].additionalServices.find(
          (service) => {
            if (selectedServiceName.value === service.serviceName) {
              return service;
            }
          }
        );
        const selectedServiceObject = {};
        if (selectedService) {
          selectedServiceObject[selectedService.serviceName] = {
            service: selectedService,
            quantity: 1,
          };
        }

        emit(
          "selectedItemChanged",
          props["service"].categoryName,
          JSON.parse(JSON.stringify(selectedServiceObject))
        );
      }
    );

    const renderPrice = ref((item) => {
      return item.dailyCharge
        ? priceMap.value.get(item.id) &&
          selectedServiceName.value === item.serviceName
          ? priceMap.value.get(item.id)
          : getPriceTag(item.price, props["currencyCode"], props["isoCode"]) +
            " / " +
            props["localization"].day
        : getPriceTag(item.price, props["currencyCode"], props["isoCode"]);
    });

    watch(
      () => props["pricingDetails"],
      () => {
        let selectedAdditionalServices = JSON.parse(
          JSON.stringify(props["pricingDetails"])
        ).selectedAdditionalServices;

        priceMap.value = new Map();

        selectedAdditionalServices.forEach((service) => {
          priceMap.value.set(
            service.additionalRentalServiceId,
            getPriceTag(
              service.amountIncludingTax,
              props["currencyCode"],
              props["isoCode"]
            )
          );
        });
      }
    );

    return { selectedServiceName, renderPrice };
  },
  components: {
    ToolTip,
  },
};
</script>
<style lang="scss" scoped>
.additional-services__radio-buttons {
  display: flex;
  justify-content: space-between;
  gap: 18px;

  &__label {
    flex: 0 0 fit-content;
    word-wrap: break-word;
    display: inline-block;

    -webkit-font-smoothing: antialiased;
    color: var(--wb-grey-20);
    cursor: pointer;
    font: inherit;
    font-family: var(--wb-font-text);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    position: relative;
  }

  &__wrapper {
    .deactivated {
      color: var(--wb-grey-65);
    }
  }
}
</style>

<template>
  <LoadingScreen v-if="loading" />
  <ErrorMessage
    v-if="error"
    :message="localization?.cancellationPage?.cancellationErrorText"
  />
  <ErrorMessage
    v-if="errorCancellationPeriodOver"
    :message="
      localization?.cancellationPage
        ?.cancellationErrorCancellationPeriodOverText
    "
  />
  <ErrorMessage
    v-if="errorAlreadyCancelled"
    :message="
      localization?.cancellationPage?.cancellationErrorAlreadyCancelledText
    "
  />
  <div class="checkout" v-if="showReservationDetails">
    <CancelReservation
      v-if="!reservationCancelled"
      :localization="localization"
      :additionalServicesBooked="showAdditionalServicesNote"
      @cancelReservation="toggleModal"
    />
    <ConfirmNotification
      v-else
      :headline="localization?.cancellationPage?.confirmationText"
      :delay="200"
    />
    <ModelPriceDateVehicle
      class="content-width"
      :vehicle="selectedVehicle"
      :localization="localization"
    />
    <div class="checkout__container content-width">
      <div class="checkout__content wb-type-copy">
        <Summary :localization="localization" view="confirm" />
      </div>

      <div class="checkout__actions row-3">
        <div class="checkout__actions__inner">
          <button
            @click="redirectToHome"
            type="button"
            class="checkout__actions__button wb-button wb-button--secondary wb-button--medium"
          >
            <Icon name="chevron-left" :size="12" :width="18" :height="12" />
            {{ localization?.resultPage?.overview?.btnBackToHome }}
          </button>
        </div>
      </div>
      <div class="checkout__actions__placeholder"></div>
    </div>
  </div>
  <ModalMessage
    v-if="modalOpen"
    :localization="{
      headline: localization?.cancellationPage?.modalTitle,
      message: localization?.cancellationPage?.modalText,
      btnText: localization?.cancellationPage?.modalConfirmBtnLabel,
      cancelBtnText: localization?.cancellationPage?.modalNoBtnLabel,
    }"
    @submitClicked="requestCancellation"
    @closeModal="toggleModal(false)"
  />
</template>

<script>
import { computed, watch, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ModelPriceDateVehicle from "@/components/ModelPriceDateVehicle";
import Icon from "@/components/Icon";
import Summary from "@/components/Checkout/Steps/Summary";
import LoadingScreen from "@/components/LoadingScreen";
import CancelReservation from "@/components/Checkout/CancelReservation";
import ModalMessage from "@/components/Notification/ModalMessage";
import ConfirmNotification from "@/components/Notification/ConfirmNotification";
import ErrorMessage from "@/components/Notification/ErrorMessage";
import useTrackingError from "@/compositions/useTrackingError";
import useGetAndStoreReservation from "@/compositions/useGetAndStoreReservation";
import useTrackingPage from "@/compositions/useTrackingPage";

export default {
  name: "CancellationPage",
  props: {
    reservationId: {
      type: String,
    },
    email: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const localization = computed(
      () => store.getters["localization/localization"]
    );
    const selectedVehicle = computed(
      () => store.getters["vehicles/selectedVehicle"]
    );
    const activeLanguage = computed(
      () => store.getters["localization/activeLanguage"]
    );
    const reservationDetails = computed(
      () => store.getters["cancellationPage/reservationDetails"]
    );

    const { getReservationDetails } = useGetAndStoreReservation("cancellation");
    const { trackCancellationOverview, trackCancellationConfirmation } =
      useTrackingPage();
    const domainId = computed(() => store.getters["marketConfig/domainId"]);

    const redirectToHome = () => {
      router.push("/");
      store.dispatch("search/resetSearchState");
    };

    const showAdditionalServicesNote = ref(false);
    watch(
      () => reservationDetails.value,
      () => {
        if (reservationDetails.value.additionalServicesCategories?.length) {
          showAdditionalServicesNote.value = true;
        }
      }
    );

    watch(
      () => activeLanguage.value,
      () => {
        if (activeLanguage.value.isoCode) {
          if (!props.reservationId || !props.email) {
            redirectToHome();
            return;
          }
          getReservationDetails(
            props.reservationId,
            props.email,
            activeLanguage.value.isoCode,
            domainId.value
          );
          trackCancellationOverview();
        }
      }
    );

    const modalOpen = ref(false);
    const toggleModal = (open = true) => (modalOpen.value = open);

    const requestCancellation = async () => {
      toggleModal(false);
      await store.dispatch("cancellationPage/cancelReservation", {
        domainId: domainId.value,
        reservationId: props.reservationId,
        email: props.email,
        isoCode: activeLanguage.value.isoCode,
      });
      trackCancellationConfirmation();
    };

    const loading = computed(() => store.getters["cancellationPage/loading"]);
    const error = computed(() => store.getters["cancellationPage/error"]);
    const errorCancellationPeriodOver = computed(
      () => store.getters["cancellationPage/errorCancellationPeriodOver"]
    );
    const errorAlreadyCancelled = computed(
      () => store.getters["cancellationPage/errorAlreadyCancelled"]
    );
    const finishedLoadingDetails = computed(
      () => store.getters["checkout/finishedLoadingReservationDetails"]
    );
    const reservationCancelled = computed(
      () => store.getters["cancellationPage/reservationCancelled"]
    );

    const { trackErrorCancellationOverview } = useTrackingError();

    watch(
      () => error.value,
      () => {
        trackErrorCancellationOverview(
          localization?.value.cancellationPage?.cancellationErrorText
        );
      }
    );

    watch(
      () => errorCancellationPeriodOver.value,
      () => {
        trackErrorCancellationOverview(
          localization?.value.cancellationPage
            ?.cancellationErrorCancellationPeriodOverText
        );
      }
    );

    watch(
      () => errorAlreadyCancelled.value,
      () => {
        trackErrorCancellationOverview(
          localization?.value.cancellationPage
            ?.cancellationErrorAlreadyCancelledText
        );
      }
    );

    const showReservationDetails = computed(() => {
      return (
        selectedVehicle.value &&
        !loading.value &&
        !error.value &&
        !errorCancellationPeriodOver.value &&
        !errorAlreadyCancelled.value &&
        finishedLoadingDetails.value
      );
    });

    return {
      showAdditionalServicesNote,
      localization,
      selectedVehicle,
      redirectToHome,
      showReservationDetails,
      finishedLoadingDetails,
      loading,
      error,
      errorCancellationPeriodOver,
      errorAlreadyCancelled,
      toggleModal,
      modalOpen,
      requestCancellation,
      reservationCancelled,
    };
  },
  components: {
    Icon,
    ModelPriceDateVehicle,
    Summary,
    LoadingScreen,
    CancelReservation,
    ModalMessage,
    ConfirmNotification,
    ErrorMessage,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/checkout";
</style>

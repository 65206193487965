<template>
  <div v-if="modelSelectionFeatureActive" id="model-selection">
    <h2 id="model_slider_heading" class="content-box__title wb-type-heading-m">
      {{ localization?.mainPage?.modelSelection?.title }}
    </h2>
    <div
      v-if="modelTypesLoading"
      class="wb-skeleton"
      style="width: 100%; height: 350px"
    ></div>
    <div v-else style="overflow: hidden">
      <wb-slider
        theme="dark"
        id="model-slider"
        ref="sliderRef"
        class="model-selection__slider"
        show-arrow-navigation
        :initialItem="initialSlide"
        show-dot-navigation
        @mousedown="mouseDown($event, 'model-slider')"
        @mouseup="mouseUp"
        @mousemove="mouseMove($event)"
      >
        <ModelOffer
          v-for="modelType in modelSelectionList"
          :key="modelType.modelTypeId"
          :modelType="modelType"
          :localization="localization"
          :active="isActive(modelType.modelTypeId)"
          @toggle="setModelActive($event)"
        />
      </wb-slider>
    </div>
    <button
      v-if="modelSelectionActive"
      class="wb-button wb-button--secondary wb-button--medium model-selection__button"
      @click="goToModelSelection"
    >
      <Icon name="chevron-right" :size="12" :width="18" :height="12" />
      {{ localization.mainPage?.modelSelection?.showAllVehicles }}
    </button>
  </div>
</template>

<script setup>
import Icon from "@/components/Icon";
import ModelOffer from "@/components/ModelSelection/ModelOffer";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import { scrollToTop } from "@/utilities/scroll";
import useModelSelection from "@/compositions/useModelSelection";
import useDragContentSlider from "@/compositions/useDragContentSlider";

const props = defineProps(["contentPage"]);

const store = useStore();
const activeModelId = computed(() => store.getters["vehicles/activeModelId"]);
const initialSlide = ref(1);

const setModelActive = (modelId) => {
  store.dispatch("vehicles/setActiveModelType", modelId);
};

const isActive = (modelId) => {
  return modelId === activeModelId.value;
};
const sliderRef = ref(null);

const localization = computed(() => store.getters["localization/localization"]);

const modelTypesForModelSelection = computed(
  () => store.getters["vehicles/modelTypesForModelSelection"]
);

const modelSelectionList = ref([]);

// set initialSlide if activeModelId is set
watch(
  [
    () => props["contentPage"],
    () => modelTypesForModelSelection.value,
    () => sliderRef.value,
  ],
  ([contentPage, modelTypes, slider]) => {
    if (props["contentPage"] && slider) {
      modelSelectionList.value = sortByContentPagePrio(modelTypes, contentPage);
      slider.scrollToIndex(0);
    } else {
      modelSelectionList.value = modelTypes;
    }

    if (modelTypes && activeModelId.value) {
      modelTypes.forEach((type, index) => {
        if (type.modelTypeId === activeModelId.value) {
          initialSlide.value = index + 1;
        }
      });
    }
  },
  {
    immediate: true,
  }
);

onMounted(() => {
  if (activeModelId.value && store.getters["search/locationSelected"].size) {
    // scrollTo model-selection with a delay
    setTimeout(() => {
      document.getElementById("model-selection").scrollIntoView();
    }, 1);
  }
});

const sortByContentPagePrio = (modelTypes, contentPage) => {
  modelTypes.sort(function (modelType1, modelType2) {
    if (modelType1 && modelType2) {
      if (modelType1[`activatedForContentPage${contentPage}`]) {
        return -1;
      }
      if (
        modelType1.weightForModelSelection < modelType2.weightForModelSelection
      ) {
        return -1;
      }
      if (
        modelType1.weightForModelSelection > modelType2.weightForModelSelection
      ) {
        return 1;
      }
    }
    return 0;
  });
  return modelTypes;
};

const {
  modelSelectionActive,
  modelSelectionFeatureActive,
  readyToFetchModels,
  fetchModelTypes,
  modelTypesLoading,
  modelTypesReloadRequired,
} = useModelSelection();

const goToModelSelection = () => {
  router.push({
    name: "modelSelection",
  });
  scrollToTop();
};

watch(
  () => modelTypesLoading.value,
  () => {
    modelSelectionList.value = sortByContentPagePrio(
      modelTypesForModelSelection.value,
      props["contentPage"]
    );
  }
);

watch(
  [() => readyToFetchModels.value, () => modelTypesReloadRequired.value],
  ([ready, isRequired]) => {
    if (ready || isRequired) {
      fetchModelTypes();
    }
  },
  {
    immediate: true,
  }
);

const { mouseDown, mouseMove, mouseUp } = useDragContentSlider();
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.model-selection {
  &__slider {
    position: relative;
    width: calc(100% + 25px);
    left: -25px;
    padding-top: 20px;
    margin-bottom: 20px;

    :deep(.wb-content-slider__content:before) {
      content: none;
    }

    :deep(.wb-content-slider__content) {
      overflow-y: visible;
    }

    :deep(.wb-content-slider__arrow--left) {
      display: block;
      left: 10px !important;

      @include breakpoint-from(mq3) {
        left: 1% !important;
      }
    }

    :deep(.wb-content-slider__arrow--right) {
      display: block;
      right: 10px !important;

      @include breakpoint-from(mq3) {
        right: 1% !important;
      }
    }

    :deep(wb-dot-navigation) {
      margin-top: 10px !important;
    }
  }

  &__spinner {
    display: block;
    height: 60px;
    width: 60px;
    margin: auto;
  }

  &__button {
    color: var(--wb-white);

    @include breakpoint-to(mq2) {
      width: 100%;
    }
  }
}
</style>

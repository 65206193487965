import { isBefore, format } from "date-fns";

export function checkOriginalAddressFields(location) {
  var locations = location.locations.map((locationItem) => {
    if (!locationItem.outlet.originalName) {
      locationItem.outlet.originalName = locationItem.outlet.name;
    }
    if (!locationItem.outlet.originalAddress) {
      locationItem.outlet.originalAddress = {};
      locationItem.outlet.originalAddress.city =
        locationItem.outlet.latinAddress.city;
      locationItem.outlet.originalAddress.street =
        locationItem.outlet.latinAddress.street;
      locationItem.outlet.originalAddress.zipCode =
        locationItem.outlet.latinAddress.zipCode;
    }

    return locationItem;
  });
  location.locations = locations;

  return location;
}

export function getMinTimeFromLocationSelected(locationSelected) {
  const tmp = getSortedMinDates(locationSelected);
  return format(tmp[0], "HH:mm");
}

export function getMinDateFromLocationSelected(locationSelected) {
  const tmp = getSortedMinDates(locationSelected);
  return format(tmp[0], "yyyy-MM-dd");
}

function getSortedMinDates(locationSelected) {
  const result = [...locationSelected].map((location) => {
    const minDate = new Date();
    const [year, month, day] = location.outlet.minFromDate.split("-");
    const [hour, minute] = location.outlet.minFromTime.split(":");

    minDate.setFullYear(year);
    minDate.setDate(day);
    minDate.setMonth(month - 1);
    minDate.setHours(hour);
    minDate.setMinutes(minute);
    minDate.setSeconds(0);
    return minDate;
  });
  result.sort((a, b) => {
    if (isBefore(a, b)) {
      return -1;
    } else {
      return 1;
    }
  });
  return result;
}

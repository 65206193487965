import { computed } from "vue";
import { useStore } from "vuex";
import {
  removeAdobeLaunchKeyCharacters,
  getContractPartnerType,
} from "@/utilities/tracking";
import {
  getFormatedDateForTracking,
  getDaysBetweenTwoDates,
  getFormattedDateAndTime,
} from "@/utilities/date";
import { reasonForRentString } from "@/utilities/checkout";

const useTransactionTracking = function () {
  const store = useStore();

  const generalConfig = computed(() => store.getters["marketConfig/general"]);
  const rentalData = computed(() => store.getters["checkout/rentalData"]);
  const selectedDate = computed(() => store.getters["search/selectedDate"]);
  const selectedTime = computed(() => store.getters["search/selectedTime"]);
  const selected = computed(() => store.getters["search/locationSelected"]);
  const localizationFields = computed(
    () => store.getters["localization/checkoutFields"]
  );

  const getTransactionReservationTracking = () => {
    const reservationId = computed(
      () => store.getters["checkout/reservationId"]
    );
    const selectedVehicle = computed(
      () => store.getters["vehicles/selectedVehicle"]
    );
    const convertedSelectedVehicle = JSON.parse(
      JSON.stringify(selectedVehicle.value)
    );
    const selectedPartner = Array.from(selected.value).filter((partner) => {
      const myPartner = JSON.parse(JSON.stringify(partner));
      if (myPartner.outlet.outletId === convertedSelectedVehicle.outletId) {
        return true;
      }
      return false;
    });
    const convertedSelectedPartner = JSON.parse(
      JSON.stringify(selectedPartner)
    )[0];

    const reasonForRent = reasonForRentString(
      rentalData.value,
      localizationFields.value
    );
    return {
      transactionID: reservationId.value,
      purchaseID: reservationId.value,
      attributes: {
        currentDate: getFormatedDateForTracking(new Date(), false),
        purchaseType: "reservation",
      },
      item: [
        {
          productInfo: {
            productID: selectedVehicle.value.vehicleVin,
            productName: removeAdobeLaunchKeyCharacters(
              `${selectedVehicle.value.modelType.modelClassName} ${selectedVehicle.value.modelType.vehicleTypeSingular} ${selectedVehicle.value.name}`
            ),
          },
          category: {
            primaryCategory: removeAdobeLaunchKeyCharacters(
              selectedVehicle.value.modelType.modelClassName
            ),
            subCategory1: removeAdobeLaunchKeyCharacters(
              `${selectedVehicle.value.modelType.modelClassName} ${selectedVehicle.value.modelType.vehicleTypeSingular}`
            ),
            subCategory2: removeAdobeLaunchKeyCharacters(
              selectedVehicle.value.name
            ),
            productType: removeAdobeLaunchKeyCharacters(
              selectedVehicle.value.modelType.vehicleTypeSingular
            ),
          },
          price: {
            basePrice: Number(
              selectedVehicle.value.pricingDetails.totalGrossPrice /
                ((generalConfig.value.vat + 100) / 100)
            ).toFixed(2),
            currency: selectedVehicle.value.pricingDetails.currencyCode,
            taxRate: generalConfig.value.vat,
            priceWithTax: Number(
              selectedVehicle.value.pricingDetails.totalGrossPrice
            ).toFixed(2),
          },
          attributes: {
            contractPartner: {
              type: getContractPartnerType(
                selectedVehicle.value.contractualPartner
              ),
              name: removeAdobeLaunchKeyCharacters(
                convertedSelectedPartner.outlet.name
              ),
              address: removeAdobeLaunchKeyCharacters(
                convertedSelectedPartner.outlet.latinAddress.street
              ),
              zipCode: removeAdobeLaunchKeyCharacters(
                convertedSelectedPartner.outlet.latinAddress.zipCode
              ),
              city: removeAdobeLaunchKeyCharacters(
                convertedSelectedPartner.outlet.latinAddress.city
              ),
            },
            termOfLease: {
              startDate: getFormattedDateAndTime(
                new Date(selectedDate.value.from),
                selectedTime.value.from
              ),
              endDate: getFormattedDateAndTime(
                new Date(selectedDate.value.to),
                selectedTime.value.to
              ),
              days: selectedVehicle.value.pricingDetails.numberOfDays,
            },
            leadTime: getDaysBetweenTwoDates(
              new Date(selectedDate.value.from),
              new Date()
            ),
            reasonOfRent: reasonForRent,
          },
        },
      ],
    };
  };

  const getTransactionRequestTracking = () => {
    const selectedLocation = computed(
      () => store.getters["search/locationForVehicleRequest"]
    );
    const selectedModelTypeId = computed(
      () => store.getters["vehicles/modelTypeForVehicleRequest"]
    );
    const modelTypesAll = computed(() => store.getters["vehicles/modelTypes"]);

    const selectedPartner = Array.from(selected.value).filter((partner) => {
      const myPartner = JSON.parse(JSON.stringify(partner));
      if (myPartner.outlet.outletId === selectedLocation.value) {
        return true;
      }
      return false;
    });
    const convertedSelectedPartner = JSON.parse(
      JSON.stringify(selectedPartner)
    )[0];
    const selectedModelType = modelTypesAll.value.filter((modelType) => {
      const currentModelType = JSON.parse(JSON.stringify(modelType));
      if (currentModelType.modelTypeId === selectedModelTypeId.value) {
        return true;
      }
      return false;
    })[0];

    const modelTypeLTR = computed(
      () => store.getters["vehicles/selectedModelTypeForLongTermRequest"]
    );
    const productNameString = `${modelTypeLTR.value.modelClassName} ${modelTypeLTR.value.modelBodyStyleName}`;
    const primaryCategoryString = `${modelTypeLTR.value.modelClassName}`;
    const subCategory1String = `${modelTypeLTR.value.modelClassName} ${modelTypeLTR.value.modelBodyStyleName}`;
    const productTypeString = `${modelTypeLTR.value.modelBodyStyleName}`;

    const reasonForRent = reasonForRentString(
      rentalData.value,
      localizationFields.value
    );

    return {
      transactionID: "request",
      purchaseID: "request",
      attributes: {
        currentDate: getFormatedDateForTracking(new Date(), false),
        purchaseType: "request",
      },
      item: [
        {
          productInfo: {
            productID: "request",
            productName: removeAdobeLaunchKeyCharacters(productNameString),
          },
          category: {
            primaryCategory: removeAdobeLaunchKeyCharacters(
              primaryCategoryString
            ),
            subCategory1: removeAdobeLaunchKeyCharacters(subCategory1String),
            subCategory2: "request",
            productType: removeAdobeLaunchKeyCharacters(productTypeString),
          },
          price: {
            basePrice: "",
            currency: "",
            taxRate: "",
            priceWithTax: "",
          },
          attributes: {
            contractPartner: {
              type: getContractPartnerType(
                convertedSelectedPartner.outlet.contractualPartner
              ),
              name: removeAdobeLaunchKeyCharacters(
                convertedSelectedPartner.outlet.name
              ),
              address: removeAdobeLaunchKeyCharacters(
                convertedSelectedPartner.outlet.latinAddress.street
              ),
              zipCode: removeAdobeLaunchKeyCharacters(
                convertedSelectedPartner.outlet.latinAddress.zipCode
              ),
              city: removeAdobeLaunchKeyCharacters(
                convertedSelectedPartner.outlet.latinAddress.city
              ),
            },
            termOfLease: {
              startDate: getFormattedDateAndTime(
                new Date(selectedDate.value.from),
                selectedTime.value.from
              ),
              endDate: getFormattedDateAndTime(
                new Date(selectedDate.value.to),
                selectedTime.value.to
              ),
              days: getDaysBetweenTwoDates(
                new Date(selectedDate.value.to),
                new Date(selectedDate.value.from)
              ),
            },
            leadTime: getDaysBetweenTwoDates(
              new Date(selectedDate.value.from),
              new Date()
            ),
            reasonOfRent: reasonForRent,
          },
        },
      ],
    };
  };

  return { getTransactionReservationTracking, getTransactionRequestTracking };
};

export default useTransactionTracking;

<template>
  <iframe
    v-if="!config.error && !config.success && config.src && config.showSPA"
    :class="{ visible: config.height > 100 }"
    class="iframe"
    :src="config.src"
    ref="iframeRef"
    @load="sendPostMessage"
    title="OneDoc"
  ></iframe>

  <div v-if="config.showFeedback" class="onedoc-success">
    <h3 class="onedoc-success__headline wb-type-heading-m">
      {{ localization.oneDoc.successHeadline }}
    </h3>
    <p class="onedoc-success__text wb-type-copy">
      {{ localization.oneDoc.successText }}
    </p>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed, onBeforeMount, reactive, ref } from "vue";
import { bindEvent, convertDataToJsonObj } from "@/utilities/oneDoc";
import oneDocService from "@/services/OneDocService";

const emit = defineEmits(["iframeClosed", "iframeOpen"]);
const store = useStore();
const personalData = computed(() => store.getters["checkout/personalData"]);
const language = computed(() => store.getters["localization/activeLanguage"]);
const localization = computed(() => store.getters["localization/localization"]);

const legalEntity = oneDocService.getLegalEntity(window.location.host);
const systemName = oneDocService.getSystemName();

const spaFinishedCallback = function (resultData) {
  if (resultData) {
    config.showSPA = false;
    emit("iframeClosed");
  }
};

// Added 50px height to remove scrollbar in SPA. Better Fix possible?
const setIframeHeight = (height) => {
  if (height && height > 100) {
    config.height = height + 50;
    iframeRef.value.style.height = `${height + 50}px`;
  }
};
const config = reactive({
  src: oneDocService.getSPAUrl(window.location.host),
  height: 0,
  error: false,
  success: false,
  sendCustomerDataToSpa: true,
  customerData: null,
  showSPA: true,
  showFeedback: false,
});
const iframeRef = ref(null);

onBeforeMount(() => {
  if (personalData.value) {
    config.customerData = convertDataToJsonObj(
      personalData.value,
      language.value,
      legalEntity,
      systemName
    );

    bindEvent(window, "message", function (e) {
      // check received message
      if (e && e.data && typeof e.data === "string") {
        const start = e.data.indexOf("(");
        const end = e.data.lastIndexOf(")");

        if (start === -1 || end === -1) {
          return;
        }

        const method = e.data.substring(0, start);
        const payload = JSON.parse(e.data.substring(start + 1, end));
        switch (method) {
          case "spaCompletedCallback":
            if (e.data.includes("CONSENT_SAVED_DATA_UPSERT")) {
              config.showFeedback = true;
            }
            config.success = true;
            spaFinishedCallback(payload);
            break;
          case "spaErrorCallback":
            config.error = true;
            spaFinishedCallback(payload);
            break;
          case "spaSkippedCallback":
            config.sendCustomerDataToSpa = false;
            spaFinishedCallback(payload);
            break;
          case "setIframeHeight":
            setIframeHeight(payload);
            break;
          default:
        }
      }
    });
  }
});

const sendPostMessage = () => {
  if (config.sendCustomerDataToSpa) {
    emit("iframeOpen");
    // the allowed targetOrigin should be defined in your Firewall/WebServer/Gateway/Application to avoid security problem.
    // For example, targetOrigin can be 'https://docms.i.daimler.com/oneDoCSPA' or '*' (* means that all URLs are allowed)
    iframeRef.value.contentWindow.postMessage(
      config.customerData,
      oneDocService.getSPAUrl(window.location.host)
    );
    config.sendCustomerDataToSpa = false;
  }
};
</script>

<style lang="scss" scoped>
.iframe {
  width: 100%;
  height: 0;
  visibility: hidden;
  border-width: 0;

  &.visible {
    visibility: visible;
  }
}

.onedoc-success {
  margin-bottom: 20px;
  color: var(--wb-grey-20);
}
</style>

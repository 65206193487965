<template>
  <div class="here-map" ref="mapContainer"></div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import "here-js-api/scripts/mapsjs-core";
import "here-js-api/scripts/mapsjs-service";
import "here-js-api/scripts/mapsjs-ui";
import "here-js-api/scripts/mapsjs-mapevents";

export default {
  props: {
    locations: Object,
    selection: Object,
    zoom: Number,
    mapConfig: Object,
    mapWidth: Number,
    mapHeight: Number,
    isDesktop: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    const activeLanguage = computed(
      () => store.getters["localization/activeLanguage"]
    );
    const mockMode = store.getters["marketConfig/mockMode"];
    const mapContainer = ref(null);
    if (mockMode) {
      return { mapContainer };
    }

    const platform = ref(null);
    const map = reactive({
      object: null,
    });
    const ui = reactive({
      object: null,
    });

    const desktopInit = ref(true);
    watch(
      () => props.mapWidth,
      (mapWidth) => {
        if (desktopInit.value && props.isDesktop) {
          desktopInit.value = false;
          initializeHereMap();
        }
        if (mapWidth) {
          mapContainer.value.style.width = `${props.mapWidth}px`;
          mapContainer.value.style.height = `${props.mapHeight}px`;
          map.object.getViewPort().resize();
        }
      }
    );

    onMounted(() => {
      if (!props.isDesktop) {
        initializeHereMap();
      }
    });

    const initializeHereMap = () => {
      if (mockMode) {
        return;
      }
      platform.value = new window.H.service.Platform({
        apikey: props.mapConfig?.apiKey,
      });
      // rendering map
      mapContainer.value.style.width = `${props.mapWidth}px`;
      mapContainer.value.style.height = `${props.mapHeight}px`;

      const H = window.H;
      // Obtain the default map types from the platform object
      const maptypes = platform.value.createDefaultLayers({
        lg: activeLanguage.value?.mapsLanguage ?? "en",
      });

      delete maptypes.vector.normal.traffic;
      delete maptypes.vector.normal.trafficincidents;

      // Instantiate (and display) a map object:
      const myMap = new H.Map(mapContainer.value, maptypes.vector.normal.map, {
        zoom: props.zoom,
        pixelRatio: window.devicePixelRatio || 1,
      });
      // copy myMap to use it in component
      map.object = myMap;

      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(myMap));

      // add UI
      const myUI = H.ui.UI.createDefault(myMap, maptypes);

      const mapSettingsOverlay = myUI
        .getControl("mapsettings")
        .getChildren()[1];

      mapSettingsOverlay.removeChild(mapSettingsOverlay.getChildren()[2]); // removes container with traffic and incidents options
      mapSettingsOverlay.removeChild(mapSettingsOverlay.getChildren()[1]); // removes now useless separator

      // copy myUI to use it in component
      ui.object = myUI;

      addLocationMarkers(myMap, myUI);

      // End rendering the initial map
    };

    const addLocationMarkers = (map, ui) => {
      if (!props.locations) return;

      const markers = props.locations.map((location) => {
        const locationActive = isLocationSelected(location);
        const marker = new H.map.Marker(
          {
            lat: location?.outlet?.lat,
            lng: location?.outlet?.lng,
          },
          { icon: setMarkerIcon(locationActive) }
        );
        marker.setData(setLocationData(location));
        return marker;
      });
      const container = new H.map.Group({
        objects: markers,
      });

      markers.map((marker, index) => {
        marker.addEventListener("tap", (evt) => {
          props.selection?.toggle(props.locations[index]);

          container.removeAll(markers);
          map.removeObject(container);
          addLocationMarkers(map, ui);
        });
        marker.addEventListener("pointerenter", (evt) => {
          const bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
            content: evt.target.getData(),
          });
          ui.addBubble(bubble);
        });
        marker.addEventListener("pointerleave", (evt) => {
          ui.getBubbles().forEach((bub) => ui.removeBubble(bub));
        });
      });

      map.addObject(container);

      // get geo bounding box for the group and set it to the map
      if (container.getBoundingBox()) {
        map.getViewModel().setLookAtData({
          bounds: container.getBoundingBox(),
        });
        map.setZoom(props.zoom);
      }
    };

    const setLocationData = (location) => {
      return activeLanguage.value?.useOriginalOutletAddress
        ? `${location?.outlet?.name}, ${location?.outlet?.originalAddress?.street}`
        : `${location?.outlet?.name}, ${location?.outlet?.latinAddress?.street}`;
    };

    const isLocationSelected = (location) => {
      return props.selection.selected.value.has(location);
    };

    watch(
      () => [props.selection?.selected.value, props.locations],
      ([curSelection, curLocations]) => {
        // remove all markers on change to re-init markers
        if (curSelection || curLocations) {
          reInitMarkers();
        }
      }
    );

    const reInitMarkers = () => {
      if (props.locations) {
        const group = map.object.getObjects();
        map.object.removeObjects(group);
        addLocationMarkers(map.object, ui.object);
      }
    };

    const setMarkerIcon = (active = false) => {
      const type = active ? "selected" : "default";
      return new H.map.Icon(
        require(`@/assets/images/location/pin-${type}.svg`),
        {
          size: { w: 50, h: 50 },
        }
      );
    };

    return {
      mapContainer,
    };
  },
};
</script>

<style lang="scss">
.here-map {
  height: 100%;
  text-align: center;
  background-color: #ccc;

  // overwrite here style
  .H_ib_body {
    bottom: 50px;
    background-color: var(--wb-grey-15);
    box-shadow: none;
  }

  .H_ib_tail {
    bottom: 55px;
  }

  .H_ib_close {
    display: none;
  }

  .H_ib_content {
    margin: 7px;
    font-size: 12px;
    line-height: 16px;
    color: var(--wb-white);
  }

  .H_ib.H_ib_top .H_ib_tail::after {
    border-color: var(--wb-grey-15) transparent;
  }
}
</style>

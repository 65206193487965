<template>
  <div class="attributes-filter wb-margin-bottom-m">
    <p class="wb-margin-bottom-xs wb-type-copy">{{ filterByText }}</p>
    <div
      @click="attributeClicked(attribute)"
      :class="{ active: isActive(attribute) }"
      class="attributes-filter__item wb-type-copy"
      v-for="attribute in attributes"
      :key="attribute"
    >
      {{ attribute }}
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

const emit = defineEmits(["selectedAttrs"]);
const props = defineProps({
  attributes: {
    type: Array,
    required: true,
  },
  filterByText: {
    type: String,
    required: true,
  },
});
const activeAttributes = ref(new Set());

const attributeClicked = (attribute) => {
  activeAttributes.value.has(attribute)
    ? activeAttributes.value.delete(attribute)
    : activeAttributes.value.add(attribute);

  emit("selectedAttrs", activeAttributes.value);
};

const isActive = computed(
  () => (attribute) => activeAttributes.value.has(attribute)
);
</script>

<style lang="scss" scoped>
.attributes-filter {
  padding: 20px;
  background: var(--wb-grey-20);

  &__item {
    display: inline-block;
    padding: 7px 15px;
    margin: 0 12px 15px 0;
    color: var(--wb-white);
    border: 1px solid var(--wb-grey-45);
    font-size: 0.875rem;
    line-height: 1rem;
    border-radius: 50px;
    cursor: pointer;

    &.active {
      color: var(--wb-blue-45);
      border-color: var(--wb-blue-45);
    }
  }
}
</style>

import { computed } from "vue";
import { useStore } from "vuex";
import usePageTracking from "@/compositions/tracking/usePageTracking";
import useTransactionTracking from "@/compositions/tracking/useTransactionTracking";
import useProductTracking from "./tracking/useProductTracking";

const useTrackingPage = function () {
  const store = useStore();
  const {
    getBasicPageTracking,
    getSearchPageTracking,
    getSearchDatePickerPageTracking,
    getFilterPageTracking,
  } = usePageTracking();

  const { getTransactionReservationTracking, getTransactionRequestTracking } =
    useTransactionTracking();

  const { getProductTracking } = useProductTracking();

  const tsLoaded = computed(
    () => store.getters["marketConfig/trackingScriptLoaded"]
  );

  const fillPageDataAndTrack = (newDigitalData) => {
    if (tsLoaded.value) {
      digitalData = newDigitalData; // update global variable that is defined in index.html
      trackEvent("mbrent_pageload");
    }
  };

  const trackEvent = (eventName) => {
    // uncomment for debugging and tracking-bootcamp
    //console.log("page: ", digitalData.page.pageInfo.pageName);
    //console.log("digitalData: ", digitalData);
    _satellite.track(eventName);
  };

  const trackHomeStart = () => {
    const page = getBasicPageTracking("home: start", "home");
    fillPageDataAndTrack({ page });
  };

  const trackHomePromotionTiles = () => {
    const page = getBasicPageTracking("home: promotion tiles", "home");
    fillPageDataAndTrack({ page });
  };

  const trackHomeContent = () => {
    const page = getBasicPageTracking("home: content", "home");
    fillPageDataAndTrack({ page });
  };

  const trackHomeDealerLandingPage = () => {
    const page = getBasicPageTracking("home: dealer landing page", "home");
    fillPageDataAndTrack({ page });
  };

  const trackHomeModelTiles = () => {
    const page = getBasicPageTracking("home: model tiles", "home");
    fillPageDataAndTrack({ page });
  };

  const trackModelSearchOverview = () => {
    const page = getBasicPageTracking("model: overview", "home");
    fillPageDataAndTrack({ page });
  };

  const trackSearchPartner = () => {
    const page = getSearchPageTracking("search: partner", "search");
    fillPageDataAndTrack({ page });
  };

  const trackSearchDatePicker = () => {
    const page = getSearchDatePickerPageTracking(
      "search: date picker",
      "search"
    );
    fillPageDataAndTrack({ page });
  };

  const trackSearchTimePicker = () => {
    const page = getBasicPageTracking("search: time picker", "search");
    fillPageDataAndTrack({ page });
  };

  const trackSearchComplete = () => {
    const page = getBasicPageTracking("search: complete", "search");
    fillPageDataAndTrack({ page });
  };

  const trackResultVehicleImages = () => {
    const page = getBasicPageTracking("result: vehicle images", "result");
    fillPageDataAndTrack({ page });
  };

  const trackResultFilter = () => {
    const page = getBasicPageTracking("result: filter", "result");
    fillPageDataAndTrack({ page });
  };

  const trackResultOverview = () => {
    const page = getFilterPageTracking("result: overview", "result");
    fillPageDataAndTrack({ page });
  };

  const trackProductVehicleDetails = () => {
    const page = getBasicPageTracking("product: vehicle details", "product");
    const product = getProductTracking("reservation");
    fillPageDataAndTrack({ page, product });
  };

  const trackCheckoutAdditionalServices = () => {
    const page = getBasicPageTracking(
      "product: additional services",
      "product"
    );
    const product = getProductTracking("reservation");
    fillPageDataAndTrack({ page, product });
  };

  const trackCheckoutContactData = () => {
    const page = getBasicPageTracking("checkout: contact data", "checkout");
    const product = getProductTracking("reservation");
    fillPageDataAndTrack({ page, product });
  };

  const trackCheckoutWishesAndConditions = () => {
    const page = getBasicPageTracking(
      "checkout: wishes and conditions",
      "checkout"
    );
    const product = getProductTracking("reservation");
    fillPageDataAndTrack({ page, product });
  };

  const trackCheckoutAdditionalInfo = () => {
    const page = getBasicPageTracking("checkout: additional info", "checkout");
    const product = getProductTracking("reservation");
    fillPageDataAndTrack({ page, product });
  };

  const trackCheckoutSummary = () => {
    const page = getBasicPageTracking("checkout: summary", "checkout");
    const product = getProductTracking("reservation");
    fillPageDataAndTrack({ page, product });
  };

  const trackCheckoutReservationConfirmation = () => {
    const page = getBasicPageTracking(
      "checkout: reservation confirmation",
      "checkout"
    );
    const transaction = getTransactionReservationTracking();
    fillPageDataAndTrack({ page, transaction });
  };

  const trackRequestVehicleForm = () => {
    const page = getBasicPageTracking("request: vehicle form", "request");
    fillPageDataAndTrack({ page });
  };

  const trackRequestVehicleConfirmation = () => {
    const page = getBasicPageTracking(
      "request: vehicle confirmation",
      "request"
    );
    const transaction = getTransactionRequestTracking();
    fillPageDataAndTrack({ page, transaction });
  };

  const trackContentAboutUs = () => {
    const page = getBasicPageTracking("content: about us", "content");
    fillPageDataAndTrack({ page });
  };

  const trackContentContact = () => {
    const page = getBasicPageTracking("content: contact", "content");
    fillPageDataAndTrack({ page });
  };

  const trackContentFaq = () => {
    const page = getBasicPageTracking("content: faq", "content");
    fillPageDataAndTrack({ page });
  };

  const trackMetaMaintenance = () => {
    const page = getBasicPageTracking("meta: maintenance", "meta");
    fillPageDataAndTrack({ page });
  };

  const trackMetaLegalLayer = () => {
    const page = getBasicPageTracking("meta: legal layer", "meta");
    fillPageDataAndTrack({ page });
  };

  const trackCancellationOverview = () => {
    const page = getBasicPageTracking("cancelation: overview", "cancelation");
    fillPageDataAndTrack({ page });
  };

  const trackCancellationConfirmation = () => {
    const page = getBasicPageTracking(
      "cancelation: confirmation",
      "cancelation"
    );
    fillPageDataAndTrack({ page });
  };

  const trackDirectSearch = () => {
    const page = getBasicPageTracking("search: direct search", "search");
    fillPageDataAndTrack({ page });
  };

  return {
    trackHomeStart,
    trackHomePromotionTiles,
    trackHomeContent,
    trackHomeDealerLandingPage,
    trackHomeModelTiles,
    trackModelSearchOverview,
    trackSearchPartner,
    trackSearchDatePicker,
    trackSearchTimePicker,
    trackSearchComplete,
    trackResultVehicleImages,
    trackResultFilter,
    trackResultOverview,
    trackProductVehicleDetails,
    trackCheckoutAdditionalServices,
    trackCheckoutContactData,
    trackCheckoutWishesAndConditions,
    trackCheckoutAdditionalInfo,
    trackCheckoutSummary,
    trackCheckoutReservationConfirmation,
    trackRequestVehicleForm,
    trackRequestVehicleConfirmation,
    trackContentAboutUs,
    trackContentContact,
    trackContentFaq,
    trackMetaMaintenance,
    trackMetaLegalLayer,
    trackCancellationOverview,
    trackCancellationConfirmation,
    trackDirectSearch,
  };
};

export default useTrackingPage;

import { checkoutFields } from "@/utilities/checkout";

// add event listener which can also support for IE8+
export const bindEvent = (element, eventName, eventHandler) => {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent("on" + eventName, eventHandler);
  }
};

export const getArrayIndex = (personalData, type) =>
  personalData.findIndex((data) => data.type === type);

// mapping gender MR / MRS / MS / MX / COMPANY
export const matchSalutationConstant = (salutation) => {
  switch (salutation) {
    case "SALUTATION_VALUE_MALE":
      return "MR";
    case "SALUTATION_VALUE_FEMALE":
      return "MS";
    case "SALUTATION_VALUE_COMPANY":
      return "COMPANY";
    default:
      return salutation;
  }
};

export const convertDataToJsonObj = (
  personalData,
  language,
  legalEntity,
  systemName
) => {
  let jsonObj = "spaCustomerDataMessage({";

  // market
  const market = language.isoCode ? language.isoCode.slice(-2) : null;
  if (market) {
    jsonObj += `"m":"${market}",`;
  }

  // legal entity
  jsonObj += `"le":"${legalEntity}",`;

  // country
  const country = language.isoCode ? language.isoCode.slice(-2) : null;
  if (country) {
    jsonObj += `"country":"${country}",`;
  }

  // isoCode
  const isoCode = language.isoCode;
  if (isoCode) {
    jsonObj += `"l":"${isoCode}",`;
  }

  // location
  const location = language.isoCode;
  if (location) {
    jsonObj += `"loc":"${location}",`;
  }

  // salutation
  const salutation = matchSalutationConstant(
    personalData[getArrayIndex(personalData, checkoutFields.salutation)]?.value
  );
  if (salutation) {
    jsonObj += `"cs":"${salutation}",`;
  }

  // lastname
  const lastName =
    personalData[getArrayIndex(personalData, checkoutFields.lastName)]?.value;
  if (lastName) {
    jsonObj += `"pnl":"${lastName}",`;
  }

  // firstName
  const firstName =
    personalData[getArrayIndex(personalData, checkoutFields.firstName)]?.value;
  if (firstName) {
    jsonObj += `"pnf":"${firstName}",`;
  }

  // street
  const street =
    personalData[getArrayIndex(personalData, checkoutFields.street)]?.value;
  if (street) {
    jsonObj += `"as":"${street}",`;
  }

  // houseNumber
  const houseNumber =
    personalData[getArrayIndex(personalData, checkoutFields.houseNumber)]
      ?.value;
  if (houseNumber) {
    jsonObj += `"ah":"${houseNumber}",`;
  }

  // postCode
  const postCode =
    personalData[getArrayIndex(personalData, checkoutFields.postCode)]?.value;
  if (postCode) {
    jsonObj += `"azip":"${postCode}",`;
  }

  // city
  const city =
    personalData[getArrayIndex(personalData, checkoutFields.city)]?.value;
  if (city) {
    jsonObj += `"al":"${city}",`;
  }

  // email
  const email =
    personalData[getArrayIndex(personalData, checkoutFields.email)]?.value;
  if (email) {
    jsonObj += `"ea":"${email}",`;
  }

  // phone
  const phone =
    personalData[getArrayIndex(personalData, checkoutFields.phoneNumber)]
      ?.value;
  if (phone) {
    jsonObj += `"tn":"${phone}",`;
    // phone type
    jsonObj += '"tt":"M",';
  }

  // vari, purp, s
  jsonObj += `"vari":"ONLINE-OPT_IN-TIER2","purp":"Direct Marketing","s":"${systemName}"`;

  // closing tag
  jsonObj += "})";

  return jsonObj;
};

<template>
  <div
    class="times jsTimes"
    :class="{ dark: themeStyle === 'dark', bright: themeStyle === 'bright' }"
  >
    <perfect-scrollbar class="times__scroll" ref="scroll">
      <div
        class="times__item wb-type-copy jsTimeItem"
        :data-time="time.value"
        v-for="time in times"
        :key="time"
        @click="timeSelected(time)"
        :class="{
          disabled: !time.active,
          selected: isSelected(time.value),
        }"
      >
        {{ time.value }}
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { watch, ref } from "vue";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import useTimesRange from "@/compositions/useTimesRange";
import useClickEvent from "@/compositions/useClickEvent";
import useLockScroll from "@/compositions/useLockScroll";

export default {
  props: {
    mode: {
      type: [Number, null],
    },
    refElement: HTMLElement,
    themeStyle: {
      type: String,
      default: "dark",
    },
  },
  setup(props, { emit }) {
    const timesRange = useTimesRange();
    const scroll = ref(null);
    const refElement = props.refElement;

    const scrollToElement = () => {
      if (scroll.value) {
        const selected = document.querySelector(".jsTimeItem.selected");
        if (selected) {
          // @ts-ignore
          setScrollTop(selected.offsetTop);
        } else {
          setScrollTop(0);
        }
      }
    };

    const setScrollTop = (top) => {
      scroll.value.$el.scrollTop = top;
    };

    // re-init times on mode change or init
    watch(
      () => props.mode,
      () => {
        timesRange.times.value = [];
        timesRange.initTimes(props.mode);
        // wait till times component transition is finished
        setTimeout(() => {
          scrollToElement();
        }, 250);
      },
      {
        immediate: true,
      }
    );

    const timeSelected = (time) => {
      if (time.active) {
        emit("timeSelected", { time: time.value, mode: props.mode });
      }
    };

    const isSelected = (time) => {
      if (props.mode === 1) {
        return time === timesRange.selectedTime.value?.from;
      } else {
        return time === timesRange.selectedTime.value?.to;
      }
    };

    // close this component if clicked somewhere else
    useClickEvent({
      exclude: ["jsTimes", "jsTimeInput"],
      fn: () => {
        emit("closeOverlay");
      },
    });

    return {
      ...timesRange,
      timeSelected,
      isSelected,
      scroll,
      ...useLockScroll(refElement),
    };
  },
  components: {
    PerfectScrollbar,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/times";
</style>

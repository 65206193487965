<template>
  <div class="price">
    <p class="price__label filter-items__label wb-type-copy-strong">
      {{ filterName(name, localization?.filter) }}
    </p>
    <wb-range-control class="price__range-control">
      <label>&nbsp;</label>
      <input
        @change="minChanged"
        name="min"
        type="range"
        :value="priceLow"
        :min="min"
        :max="max"
      />
      <input
        @change="maxChanged"
        name="max"
        type="range"
        :value="priceHigh"
        :min="min"
        :max="max"
      />
    </wb-range-control>
    <div class="price__output">
      <div class="price__output__item">
        {{ getPriceTag(min, currency, isoCode) }}
      </div>
      <div class="price__output__item">
        {{ getPriceTag(max, currency, isoCode) }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { filterName } from "@/utilities/filter";
import { getPriceTag } from "@/utilities/price";

export default {
  name: "price",
  props: ["localization", "name"],
  setup() {
    const store = useStore();
    const min = computed(() => store.getters["vehicles/minPrice"]);
    const max = computed(() => store.getters["vehicles/maxPrice"]);
    const priceLow = ref(null);
    const priceHigh = ref(null);
    const priceFilter = computed(() =>
      store.getters["vehicles/getFilter"]("price")
    );

    const initialValue = () => {
      if (priceFilter.value?.min && priceFilter.value?.max) {
        priceLow.value = priceFilter.value.min;
        priceHigh.value = priceFilter.value.max;
      } else {
        priceLow.value = min.value;
        priceHigh.value = max.value;
      }
    };

    onMounted(() => {
      initialValue();
    });

    watch(
      () => priceFilter.value,
      (filter) => {
        if (!filter?.type) {
          // bug in workbench reseting value, have to force set to 0, 9999
          priceLow.value = 0;
          priceHigh.value = 9999;
        }
      }
    );

    const minChanged = (event) => {
      store.dispatch("vehicles/addPriceFilter", {
        type: "price",
        min: parseInt(event.target.value),
        max: priceFilter.value?.max ?? max.value,
      });
    };

    const maxChanged = (event) => {
      store.dispatch("vehicles/addPriceFilter", {
        type: "price",
        min: priceFilter.value?.min ?? min.value,
        max: parseInt(event.target.value),
      });
    };

    return {
      filterName,
      min,
      max,
      priceLow,
      priceHigh,
      minChanged,
      maxChanged,
      getPriceTag,
      currency: computed(() => store.getters["vehicles/currency"]),
      isoCode: computed(() => store.getters["localization/isoCode"]),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.price {
  width: 100%;

  @include breakpoint-from(mq3) {
    width: 50%;
  }

  &__output {
    display: flex;
    justify-content: space-between;
  }

  &__range-control {
    padding: 0 10px;

    label {
      display: none;
    }
  }

  output {
    display: none;
  }
}
</style>

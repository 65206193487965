<template>
  <div class="map wb-type-copy">
    <Icon name="map" :size="20" class="map__icon" @click="open = !open" />
    <span
      data-test-id="map-container-button"
      class="map__text"
      @click="openMap"
    >
      {{ localization?.showMap }}
    </span>

    <div v-if="open">
      <wb-modal
        ref="modal"
        mode="info"
        close-on-backdrop-click
        close-on-escape-key
        scroll-lock
        active-level="1"
      >
        <wb-modal-head>
          <div class="wb-modal__header">
            <div class="wb-type-copy">
              {{ localization?.location?.searchTitle }}
            </div>
            <Icon name="close" @click="cancel()" />
          </div>
        </wb-modal-head>
        <wb-modal-content>
          <HereMap
            v-if="mapConfig?.type.toLowerCase() === 'here'"
            :locations="locations"
            :zoom="zoom"
            :mapConfig="mapConfig"
            :mapWidth="mapWidth"
            :mapHeight="mapHeight"
            :selection="selection"
            :isDesktop="false"
          />
          <GoogleMapComponent
            v-else-if="mapConfig?.type.toLowerCase() === 'googlemaps'"
            :locations="locations"
            :zoom="zoom"
            :mapConfig="mapConfig"
            :mapWidth="mapWidth"
            :mapHeight="mapHeight"
            :selection="selection"
            :isDesktop="false"
          />
        </wb-modal-content>
        <wb-modal-footer>
          <button
            type="button"
            class="wb-button wb-button--primary wb-button--medium"
            @click="open = false"
          >
            <Icon name="chevron-right" :size="12" :width="18" :height="12" />
            {{ localization?.btnConfirm }}
          </button>
        </wb-modal-footer>
      </wb-modal>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, onUnmounted } from "vue";
import HereMap from "@/components/Map/HereMap";
import GoogleMapComponent from "@/components/Map/GoogleMapComponent";
import useLocationSelection from "@/compositions/useLocationSelection";
import useTrackingLink from "@/compositions/useTrackingLink";
import Icon from "@/components/Icon";

export default {
  name: "MapContainer",
  props: ["localization", "locations", "zoom", "mapConfig"],
  setup() {
    const open = ref(false);
    const modal = ref(null);
    const selection = useLocationSelection();

    const selectionCache = ref(null);

    const cancel = () => {
      selection.clear();
      selection.addMultiple(selectionCache.value);

      open.value = false;
    };

    const { trackNavOpenMap } = useTrackingLink();
    const openMap = () => {
      open.value = !open.value;
      trackNavOpenMap();
    };

    watch(open, (curOpen, prevOpen) => {
      if (curOpen && curOpen !== prevOpen) {
        setTimeout(() => {
          modal.value.addEventListener("wbclose", () => {
            open.value = false;
          });
        }, 10);
        selectionCache.value = selection.selected.value;
      }
    });

    const mapWidth = ref(0);
    const mapHeight = ref(0);

    onMounted(() => {
      // update mapWidth on resize
      window.addEventListener("resize", resizeEvent);
      resizeEvent();
    });

    const resizeEvent = () => {
      mapWidth.value = document.documentElement.clientWidth * 0.85;
      mapHeight.value = document.documentElement.clientHeight * 0.85 - 70;
    };

    onUnmounted(() => {
      window.removeEventListener("resize", resizeEvent);
    });

    return {
      openMap,
      open,
      modal,
      selection,
      mapWidth,
      mapHeight,
      cancel,
    };
  },
  components: {
    GoogleMapComponent,
    HereMap,
    Icon,
  },
};
</script>

<style lang="scss">
@import "@/styles/components/mapContainer";
</style>

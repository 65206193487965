<template>
  <div class="selected-offer-loading">
    <LoadingScreen
      v-if="loading"
      :loadingText="localization?.checkoutPage?.loadingText"
    />
  </div>
  <div class="selected-offer__container">
    <ModelPriceDateVehicle
      class="content-width"
      :vehicle="selectedVehicle"
      :localization="localization"
    />
    <div class="selected-offer content-width" v-if="location">
      <div class="selected-offer__content">
        <SelectedOfferAccordion
          :localization="localization"
          :location="location"
          :vehicle="selectedVehicle"
        />
        <InfoBox
          :title="localization?.resultPage?.selectedOfferAccordion?.noteTitle"
          :text="localization?.resultPage?.selectedOfferAccordion?.noteText"
        />

        <div
          class="selected-offer__button-container wb-margin-top-m"
          :class="{
            'fixed-buttons': scrolling,
          }"
        >
          <div class="selected-offer__button-wrapper">
            <button
              data-test-id="btn-continue-checkout"
              type="submit"
              @click="goToCheckout"
              class="selected-offer__button wb-button wb-button--primary wb-button--medium wb-type-copy"
              :disabled="!continueButtonActive"
            >
              <wb-spinner
                v-if="!continueButtonActive"
                theme="dark"
                class="btn-spinner"
              ></wb-spinner>
              <wb-icon
                v-else
                class="wb-button__icon"
                aria-hidden="true"
                name="chevron-right"
              >
              </wb-icon>
              {{ localization.checkoutPage?.btnContinue }}
            </button>
          </div>
          <div class="selected-offer__button-wrapper">
            <button
              @click="goToVehicleOffers"
              type="submit"
              class="selected-offer__button wb-button wb-button--secondary wb-button--medium wb-type-copy"
            >
              <wb-icon
                class="wb-button__icon"
                aria-hidden="true"
                name="chevron-left"
              >
              </wb-icon>
              {{ localization.checkoutPage?.btnBackToVehicleSearch }}
            </button>
          </div>
          <div class="selected-offer__button-wrapper">
            <button
              @click="redirectToHomeOrDealer"
              type="submit"
              class="selected-offer__button wb-button wb-button--secondary wb-button--medium wb-type-copy"
            >
              <wb-icon
                class="wb-button__icon"
                aria-hidden="true"
                name="chevron-left"
              >
              </wb-icon>
              {{ localization.checkoutPage?.btnRequestNewSearch }}
            </button>
          </div>
        </div>
        <div
          class="fixed-buttons__placeholder"
          :class="{ visible: scrolling }"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import LoadingScreen from "@/components/LoadingScreen";
import SelectedOfferAccordion from "@/components/SelectedOfferAccordion";
import { useRouter } from "vue-router";
import ModelPriceDateVehicle from "@/components/ModelPriceDateVehicle";
import { scrollToTop } from "@/utilities/scroll";
import useTrackingPage from "@/compositions/useTrackingPage";
import InfoBox from "@/components/InfoBox";
import useHomeRedirect from "@/compositions/useHomeRedirect";
import useStickyButtons from "@/compositions/useStickyButtons";

export default {
  name: "SelectedOffer",
  props: {
    isModelSelection: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const loading = ref(false);
    const continueButtonActive = ref(false);
    const isMobile = computed(() => store.getters["localization/isMobile"]);
    const additionalServicesActive =
      store.getters["marketConfig/additionalServices"];
    const useAdditionalServicesView = computed(
      () => store.getters["vehicles/useAdditionalServicesView"]
    );
    const domainId = computed(() => store.getters["localization/domainId"]);

    const checkForAdditionalServices = async () => {
      if (additionalServicesActive) {
        try {
          await store.dispatch(
            "vehicles/getAdditionalServices",
            domainId.value
          );
        } catch (e) {
          // on error additionalServicesStep will be skipped
        } finally {
          continueButtonActive.value = true;
        }
      } else {
        continueButtonActive.value = true;
      }
    };
    checkForAdditionalServices();

    const selectedVehicle = computed(
      () => store.getters["vehicles/selectedVehicle"]
    );

    const locationSet = computed(() => store.getters["search/location"]);
    const localization = computed(
      () => store.getters["localization/localization"]
    );

    const { redirectToHomeOrDealer } = useHomeRedirect();

    const getLocation = (locationList, vehicle) => {
      let resultLocation = null;
      locationList.map((item) => {
        if (item?.outlet?.outletId === vehicle.outletId) {
          resultLocation = item;
        }
      });
      return resultLocation;
    };

    const location = ref(null);

    const { trackProductVehicleDetails } = useTrackingPage();

    onMounted(() => {
      scrollToTop();
      initLocation();
      trackProductVehicleDetails();
    });

    const initLocation = () => {
      if (!locationSet.value?.locations) {
        redirectToHomeOrDealer();
        return;
      }
      location.value = getLocation(
        locationSet.value.locations,
        selectedVehicle.value
      );
    };

    const goToVehicleOffers = () => {
      if (props["isModelSelection"]) {
        router.push({ name: "modelSelectionResult" });
      } else {
        router.push({ name: "vehicles" });
      }
      scrollToTop();
    };
    const goToCheckout = () => {
      if (useAdditionalServicesView.value) {
        router.push({ name: "additionalServices" });
      } else {
        router.push({ name: "checkout", query: { step: 1 } });
      }
      scrollToTop();
    };
    const topImageLoaded = () => {
      loading.value = false;
    };
    const topImageError = () => {
      loading.value = false;
    };

    // fixed buttons
    const { scrolling } = useStickyButtons();

    return {
      isMobile,
      topImageError,
      topImageLoaded,
      localization,
      selectedVehicle,
      loading,
      goToVehicleOffers,
      redirectToHomeOrDealer,
      scrolling,
      location,
      goToCheckout,
      continueButtonActive,
    };
  },

  components: {
    LoadingScreen,
    ModelPriceDateVehicle,
    SelectedOfferAccordion,
    InfoBox,
  },
};
</script>
<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";
@import "~@workbench/core/dist/css/extensions/spacing";

.selected-offer {
  padding-top: 4% !important;
  background-color: white !important;
  color: var(--wb-grey-20);

  &__container {
    background-color: var(--wb-white);
  }

  &__vehicle-overview {
    display: flex;
    flex-direction: column-reverse;
    @include breakpoint-from(mq3) {
      flex-direction: row;
    }

    &__image {
      @include breakpoint-from(mq3) {
        width: 66%;
      }
    }

    &__model-price-date {
      margin-top: 5%;
      @include breakpoint-from(mq3) {
        width: 33%;
        margin-top: 0;
      }

      &__wrapper {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }

  &__vehicle-image {
    position: relative;
    margin-top: -30%;
  }

  &__vehicle-image img {
    display: block;
    margin: 0 auto 0 auto;
  }

  &__button {
    width: 100%;
    margin-bottom: var(--wb-spacing-xs);

    @include breakpoint-from(mq3) {
      margin-bottom: 0;
    }
  }

  &__button-wrapper {
    @include breakpoint-from(mq3) {
      width: 31%;
    }
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5;
    padding-bottom: 50px;

    @include breakpoint-from(mq3) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
}
</style>

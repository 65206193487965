<template>
  <transition name="slide-down">
    <ErrorMessage
      v-if="error"
      :message="localization?.checkoutPage?.reservationErrorMessage"
      :homeButton="true"
      :buttonText="localization?.checkoutPage?.btnRequestNewSearch"
      @buttonClicked="redirectToHomeOrDealer()"
    />
  </transition>
  <div class="vehicle-request">
    <div class="vehicle-request__content-wrapper">
      <h1 class="vehicle-request__headline wb-type-heading-l wb-margin-top-l">
        {{ localization?.vehicleRequestPage?.headerTitle }}
      </h1>
      <p class="vehicle-request__text wb-type-copy">
        {{ localization?.vehicleRequestPage?.headerText }}
      </p>
      <div class="vehicle-request__rent-period">
        <RentalPeriod :localization="localization" />
        <div
          v-if="modelTypesForShortTermRequest.length"
          class="vehicle-request__model-type-selection"
        >
          <div
            class="vehicle-request__model-type-selection__image"
            v-if="
              selectedModelData.modelClassName && selectedModelData.modelImage
            "
          >
            <p
              class="vehicle-request__model-type-selection__image__text wb-type-heading-m"
            >
              {{ selectedModelData.modelClassName }}
            </p>
            <img :src="selectedModelData.modelImage" alt="modelType" />
          </div>
          <ModelSelection
            :localization="localization"
            :modelTypes="modelTypesForShortTermRequest"
            @modelSelectionChanged="setSelectedModelType"
          />
        </div>
        <div v-else class="vehicle-request__model-type-selection__skeleton">
          <div style="display: flex; flex-direction: column">
            <wb-skeleton
              style="width: 50%; height: 30px; margin-bottom: 15px"
            ></wb-skeleton>
            <wb-skeleton
              style="width: 100%; height: 80px; margin-bottom: 10px"
            ></wb-skeleton>
          </div>
        </div>
      </div>
      <div class="vehicle-request__location-selection">
        <LocationSelection />
      </div>
      <div class="wb-type-copy">
        <PersonalData
          :localization="localization"
          activeField="requestModeActive"
        />
        <RentalData
          :localization="localization"
          activeField="requestModeActive"
        />
      </div>
      <div class="vehicle-request__buttons">
        <button
          @click="returnToVehicleOffers()"
          type="submit"
          class="vehicle-request__buttons__button checkout__actions__button checkout__actions__button--continue wb-button wb-button--secondary wb-button--medium"
        >
          {{ localization?.vehicleRequestPage?.backButtonLabel }}
        </button>
        <button
          @click="onFormSubmit($event)"
          :disabled="
            disabledPersonalData ||
            disabledRentalData ||
            disabledAdditionalFieldsData ||
            !locationForVehicleRequest ||
            !modelTypeSelected ||
            isLoading
          "
          type="submit"
          class="vehicle-request__buttons__button checkout__actions__button checkout__actions__button--continue wb-button wb-button--primary wb-button--medium"
        >
          {{ localization?.vehicleRequestPage?.submitButtonLabel }}
        </button>
      </div>
    </div>
  </div>
  <transition name="Slide">
    <VehicleRequestConfirmation
      v-if="openRequestConfirmation"
      :useLTR="false"
    />
  </transition>
</template>
<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { scrollToTop } from "@/utilities/scroll";
import PersonalData from "@/components/Checkout/Steps/PersonalData";
import RentalData from "@/components/Checkout/Steps/RentalData";
import LocationSelection from "@/components/VehicleRequest/LocationSelection";
import ModelSelection from "@/components/VehicleRequest/ModelSelection";
import RentalPeriod from "@/components/VehicleRequest/RentalPeriod";
import VehicleRequestConfirmation from "@/views/VehicleRequestConfirmation";
import useRequestModeData from "@/compositions/useRequestModeData";
import useHomeRedirect from "@/compositions/useHomeRedirect";
import ErrorMessage from "@/components/Notification/ErrorMessage.vue";
import useTrackingError from "@/compositions/useTrackingError";

export default {
  name: "VehicleRequest",
  setup() {
    const store = useStore();
    const localization = computed(
      () => store.getters["localization/localization"]
    );
    const { redirectToHomeOrDealer } = useHomeRedirect();
    const {
      locationForVehicleRequest,
      disabledRentalData,
      disabledPersonalData,
      disabledAdditionalFieldsData,
      onFormSubmit,
      openRequestConfirmation,
      isLoading,
      error,
      modelTypesForShortTermRequest,
      selectedModelData,
      modelTypeSelected,
      setSelectedModelType,
    } = useRequestModeData();

    const { trackErrorVehicleRequest } = useTrackingError();

    watch(
      () => error.value,
      () => {
        trackErrorVehicleRequest(
          localization?.value.checkoutPage?.reservationErrorMessage
        );
      }
    );

    const returnToModelSelectionResults = computed(
      () => store.getters["vehicles/modelSelectionIsOrigin"]
    );
    const router = useRouter();
    const returnToVehicleOffers = () => {
      if (returnToModelSelectionResults.value) {
        router.push({
          name: "modelSelectionResult",
        });
      } else {
        router.push({
          name: "vehicles",
        });
      }
      scrollToTop();
    };

    return {
      returnToVehicleOffers,
      onFormSubmit,
      redirectToHomeOrDealer,
      localization,
      disabledRentalData,
      disabledPersonalData,
      disabledAdditionalFieldsData,
      openRequestConfirmation,
      locationForVehicleRequest,
      isLoading,
      error,
      modelTypesForShortTermRequest,
      modelTypeSelected,
      selectedModelData,
      setSelectedModelType,
    };
  },
  components: {
    ModelSelection,
    LocationSelection,
    RentalPeriod,
    PersonalData,
    RentalData,
    VehicleRequestConfirmation,
    ErrorMessage,
  },
};
</script>
<style lang="scss">
@import "@/styles/views/vehicleRequest";
</style>

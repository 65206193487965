<template>
  <div class="selected-offer-accordion">
    <wb-accordion prevent-mobile-flyout>
      <wb-accordion-item open>
        <wb-accordion-toggle>
          {{ localization.resultPage.selectedOfferAccordion?.equipmentTitle }}
          <ToolTip
            @mouseenter="trackInfoItemEquipment"
            @click="$event.stopPropagation()"
            :text="
              localization.resultPage.selectedOfferAccordion?.equipmentTooltip
            "
          ></ToolTip>
        </wb-accordion-toggle>
        <wb-accordion-section>
          <wb-accordion-mobile-header>Close</wb-accordion-mobile-header>
          <wb-accordion-content>
            <VehicleEquipment :equipment="equipment" />
          </wb-accordion-content>
        </wb-accordion-section>
      </wb-accordion-item>
    </wb-accordion>
    <wb-accordion prevent-mobile-flyout v-if="additionalEquipment.length > 0">
      <wb-accordion-item open>
        <wb-accordion-toggle>
          {{
            localization.resultPage.selectedOfferAccordion
              ?.additionalEquipmentTitle
          }}
          <ToolTip
            @mouseenter="trackInfoItemEquipment"
            @click="$event.stopPropagation()"
            :text="
              localization.resultPage.selectedOfferAccordion
                ?.additionalEquipmentTooltip
            "
          ></ToolTip>
        </wb-accordion-toggle>
        <wb-accordion-section>
          <wb-accordion-mobile-header>Close</wb-accordion-mobile-header>
          <wb-accordion-content>
            <VehicleAdditionalEquipment
              :additionalEquipment="additionalEquipment"
            />
          </wb-accordion-content>
        </wb-accordion-section>
      </wb-accordion-item>
    </wb-accordion>
    <wb-accordion prevent-mobile-flyout v-if="eclEnabled">
      <wb-accordion-item open>
        <wb-accordion-toggle>
          {{ localization.resultPage.selectedOfferAccordion?.eclTitle }}
        </wb-accordion-toggle>
        <wb-accordion-section>
          <wb-accordion-mobile-header>Close</wb-accordion-mobile-header>
          <wb-accordion-content>
            <EnergyConsumptionLabel></EnergyConsumptionLabel>
          </wb-accordion-content>
        </wb-accordion-section>
      </wb-accordion-item>
    </wb-accordion>
    <wb-accordion prevent-mobile-flyout>
      <wb-accordion-item open>
        <wb-accordion-toggle>
          <p>
            {{
              localization.resultPage.selectedOfferAccordion?.conditionsTitle
            }}
          </p>
        </wb-accordion-toggle>
        <wb-accordion-section>
          <wb-accordion-mobile-header>Close</wb-accordion-mobile-header>
          <wb-accordion-content>
            <RentConditions
              :localization="localization.resultPage"
              :vehicle="vehicle"
            />
          </wb-accordion-content>
        </wb-accordion-section>
      </wb-accordion-item>
    </wb-accordion>
    <wb-accordion prevent-mobile-flyout>
      <wb-accordion-item open>
        <wb-accordion-toggle>
          <p>
            {{ localization.resultPage.selectedOfferAccordion?.partnerTitle }}
          </p>
        </wb-accordion-toggle>
        <wb-accordion-section>
          <wb-accordion-mobile-header>Close</wb-accordion-mobile-header>
          <wb-accordion-content>
            <MBRentPartner
              :localization="localization?.resultPage?.partner"
              :timeLabel="localization?.timeLabel"
              :location="location"
            />
          </wb-accordion-content>
        </wb-accordion-section>
      </wb-accordion-item>
    </wb-accordion>
  </div>
</template>
<script>
import { computed } from "vue";
import {
  createEquipment,
  createAdditionalEquipment,
} from "@/utilities/vehicle";
import MBRentPartner from "@/components/MBRentPartner";
import RentConditions from "@/components/RentConditions";
import VehicleEquipment from "@/components/Vehicle/VehicleEquipment";
import VehicleAdditionalEquipment from "@/components/Vehicle/VehicleAdditionalEquipment";
import ToolTip from "@/components/ToolTip";
import useTrackingLink from "@/compositions/useTrackingLink";
import EnergyConsumptionLabel from "@/components/EnergyConsumptionLabel/EnergyConsumptionLabel.vue";
import { useStore } from "vuex";

export default {
  name: "SeletedOfferAccordion",
  props: ["localization", "location", "vehicle"],

  setup(props) {
    const vehicle = computed(() => props["vehicle"]);
    const localization = computed(() => props["localization"]);
    const { trackInfoItemEquipment } = useTrackingLink();
    const equipment = computed(() =>
      createEquipment(localization.value, vehicle.value)
    );
    const additionalEquipment = computed(() =>
      createAdditionalEquipment(vehicle.value.equipments, localization.value)
    );
    const store = useStore();
    const eclEnabled = computed(() => store.getters["marketConfig/eclEnabled"]);
    return {
      equipment,
      additionalEquipment,
      trackInfoItemEquipment,
      eclEnabled,
    };
  },
  components: {
    EnergyConsumptionLabel,
    ToolTip,
    MBRentPartner,
    RentConditions,
    VehicleEquipment,
    VehicleAdditionalEquipment,
  },
};
</script>
<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_typography";
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.selected-offer-accordion {
  wb-accordion {
    border-top: 0;
  }

  wb-accordion-content {
    padding: var(--wb-spacing-xs) 0 var(--wb-spacing-xs) 0;
  }

  .wb-accordion__toggle-icon {
    background-color: var(--wb-grey-20) !important;
    border-radius: 50%;
    color: white;
    width: 36px;
    height: 36px;
    padding: 7px;
  }

  .wb-accordion__toggle {
    background-color: white !important;
    border: 0;
    border-bottom: 1px solid var(--wb-grey-85);
    border-bottom-color: var(--wb-grey-85) !important;
  }

  .wb-accordion__toggle > div {
    padding: var(--wb-spacing-xs) 0 var(--wb-spacing-xs) 0 !important;
    @include wb-type-heading-m;
  }

  wb-accordion-item[open] {
    background-color: white !important;
  }

  wb-accordion-item[open] wb-accordion-section {
    border-bottom: 0;
  }

  wb-accordion-section {
    background-color: white !important;
  }

  .wb-accordion__toggle:focus:not(:disabled) {
    outline: none;
  }
}
</style>

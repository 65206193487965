export function createTopics(contentPages, aboutUs, path, useNewAboutUs) {
  const result = [];
  let item;
  if (path !== "contentPage1") {
    item = addButtonTextAndUrlId(
      { ...contentPages.contentPage1 },
      "1",
      contentPages.moreTopicsButton
    );
    result.push(item);
  }
  if (path !== "contentPage2") {
    item = addButtonTextAndUrlId(
      { ...contentPages.contentPage2 },
      "2",
      contentPages.moreTopicsButton
    );
    result.push(item);
  }
  if (path !== "contentPage3") {
    item = addButtonTextAndUrlId(
      { ...contentPages.contentPage3 },
      "3",
      contentPages.moreTopicsButton
    );
    result.push(item);
  }
  if (path !== "about-us" && useNewAboutUs) {
    item = {
      topTeaserTitle: aboutUs.aboutUsContentSection.header,
      topText: aboutUs.aboutUsContentSection.topText,
      topTeaserImageIdMobile: aboutUs.headerImageIdMobile,
      moreTopicsButtonText: contentPages.moreTopicsButton,
      urlId: "aboutUs",
    };
    result.push(item);
  }
  return result;
}

function addButtonTextAndUrlId(content, urlId, buttonText) {
  content.urlId = urlId;
  content.moreTopicsButtonText = buttonText;
  return content;
}

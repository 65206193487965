<template>
  <div class="model-price-date-vehicle__vehicle-overview">
    <div class="model-price-date-vehicle__vehicle-overview__image">
      <img :src="getImagePath" alt="vehicle-image" />
    </div>
    <div class="model-price-date-vehicle__vehicle-overview__model-price-date">
      <div
        class="model-price-date-vehicle__vehicle-overview__model-price-date__wrapper"
      >
        <ModelPriceDate
          :localization="localization"
          :vehicle="vehicle"
          :additionalServices="additionalServices"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import ModelPriceDate from "@/components/ModelPriceDate";
import useImage from "@/compositions/useImage";

export default {
  props: ["vehicle", "localization", "additionalServices"],
  setup(props) {
    const { getModelImageUrl } = useImage();
    const getImagePath = computed(() => {
      if (props.vehicle.vehicleImages.exterior20ImageUrl) {
        return (
          props.vehicle.vehicleImages.exterior20ImageUrl +
          "&crop=500,100,1800,900&width=788&height=398"
        );
      } else {
        return getModelImageUrl(props.vehicle.modelType.modelTypeId, "BIG");
      }
    });

    return {
      getImagePath,
    };
  },
  components: {
    ModelPriceDate,
  },
};
</script>
<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.model-price-date-vehicle__vehicle-overview {
  display: flex;
  flex-direction: column-reverse;
  color: var(--wb-grey-20);
  background-color: var(--wb-white);
  padding-top: 30px !important;

  @include breakpoint-to(mq2) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @include breakpoint-from(mq3) {
    flex-direction: row;
  }

  &__image {
    @include breakpoint-from(mq3) {
      width: 60%;
    }
  }

  &__model-price-date {
    @include breakpoint-from(mq3) {
      width: 40%;
    }

    &__wrapper {
      @include breakpoint-from(mq3) {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }
}
</style>

<template>
  <p class="vehicle-request__rent-period__subheadline wb-type-copy-strong">
    {{ localization?.vehicleRequestPage?.rentalPeriodTitle }}
  </p>
  <div class="vehicle-request__time">
    <DateTimeFromTo :localization="localization" />
  </div>
</template>

<script>
import DateTimeFromTo from "@/components/DateTimeFromTo";

export default {
  props: ["localization"],
  setup() {
    return {};
  },
  components: {
    DateTimeFromTo,
  },
};
</script>

<style lang="scss" scoped></style>

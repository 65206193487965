import ApiService from "@/services/ApiService";

// initial state
const mainOutletState = () => ({
  mainOutletStructure: {
    displayName: null,
    addressMainOutlet: null,
    imageId: null,
    outlets: [],
  },
  gsId: null,
  isComingFromLandingPage: false,
  loadingFailed: false,
});

// mutations
const mutations = {
  SET_MAIN_OUTLET_STRUCTURE(state, mainOutletStructure) {
    state.mainOutletStructure = mainOutletStructure;
  },
  SET_GS_ID(state, gsId) {
    state.gsId = gsId;
  },
  RESET_STATE(state) {
    state.mainOutletStructure = {
      displayName: null,
      addressMainOutlet: null,
      imageId: null,
      outlets: [],
    };
    state.gsId = null;
    state.isComingFromLandingPage = false;
  },
  SET_IS_COMING_FROM_LANDING_PAGE(state, value) {
    state.isComingFromLandingPage = value;
  },
  SET_LOADING_FAILED(state, value) {
    state.loadingFailed = value;
  },
};

// actions
const actions = {
  async getMainOutletStructure({ commit, rootGetters }, { domainId, gsId }) {
    const mockMode = rootGetters["marketConfig/mockMode"];

    try {
      const getMainOutletStructure = mockMode
        ? await ApiService.getMockMainOutletStructure()
        : await ApiService.getMainOutletStructure({
            domainId,
            gsId,
          });

      commit("SET_GS_ID", gsId);

      if (getMainOutletStructure.status === 200) {
        commit("SET_MAIN_OUTLET_STRUCTURE", getMainOutletStructure.data);
        commit("SET_IS_COMING_FROM_LANDING_PAGE", true);
      } else {
        commit("SET_LOADING_FAILED", true);
      }
    } catch (e) {
      commit("SET_LOADING_FAILED", true);
      throw new Error(e);
    }
  },
  resetMainOutlet({ commit }) {
    commit("RESET_STATE");
  },
};

// getters
const getters = {
  mainOutletStructure: (state) => {
    return state.mainOutletStructure;
  },
  gsId: (state) => {
    return state.gsId;
  },
  isComingFromLandingPage: (state) => {
    return state.isComingFromLandingPage;
  },
  loadingFailed: (state) => {
    return state.loadingFailed;
  },
};

function makeState(override) {
  return { ...mainOutletState(), ...override };
}

export function createMainOutletModule(override) {
  return {
    namespaced: true,
    state: makeState(override),
    getters,
    actions,
    mutations,
  };
}

export default {
  namespaced: true,
  state: mainOutletState,
  getters,
  actions,
  mutations,
};

import { createRouter, createWebHistory } from "vue-router";
import MetaService from "@/services/MetaService";
import Home from "@/views/Home";
import Faq from "@/views/Faq";
import About from "@/views/About";
import AboutUs from "@/views/AboutUs";
import Vehicles from "@/views/Vehicles";
import SelectedOffer from "@/views/SelectedOffer";
import AdditionalServices from "@/views/AdditionalServices";
import Checkout from "@/views/Checkout";
import Confirm from "@/views/Confirm";
import Contact from "@/views/Contact";
import VehicleRequest from "@/views/VehicleRequest";
import Provider from "@/views/Provider";
import DealerLanding from "@/views/DealerLanding";
import ModelSelection from "@/views/ModelSelection";
import ModelSelectionResult from "@/views/ModelSelectionResult";
import PaymentRedirect from "@/views/PaymentRedirect";
import DirectSearch from "@/views/DirectSearch";
import RequestModeLongTerm from "@/views/RequestModeLongTerm";
import CancellationPage from "@/views/CancellationPage";
import ContentPage from "@/views/ContentPage.vue";
import DigitalServicesAct from "@/views/DigitalServicesAct.vue";

export const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    beforeEnter: () => {
      MetaService.initMetaData(window.location.host, "home");
    },
  },
  {
    path: "/vehicles",
    name: "vehicles",
    props: true,
    component: Vehicles,
  },
  {
    path: "/vehicle-request",
    name: "vehicleRequest",
    component: VehicleRequest,
  },
  {
    path: "/selected-offer",
    name: "selectedOffer",
    component: SelectedOffer,
    props: (route) => ({
      isModelSelection: route.query.isModelSelection === "true",
    }),
  },
  {
    path: "/additional-services",
    name: "additionalServices",
    component: AdditionalServices,
  },
  {
    path: "/checkout",
    name: "checkout",
    component: Checkout,
  },
  {
    path: "/confirm",
    name: "confirm",
    component: Confirm,
  },
  {
    path: "/faq",
    name: "faq",
    component: Faq,
    beforeEnter: () => {
      MetaService.initMetaData(window.location.host, "faq");
    },
  },
  {
    path: "/about",
    name: "about",
    component: About,
    beforeEnter: () => {
      MetaService.initMetaData(window.location.host, "about");
    },
  },
  {
    path: "/about-us",
    name: "aboutUs",
    component: AboutUs,
    beforeEnter: () => {
      MetaService.initMetaData(window.location.host, "about");
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    beforeEnter: () => {
      MetaService.initMetaData(window.location.host, "contact");
    },
  },
  {
    path: "/digitalservicesact",
    name: "digitalservicesact",
    component: DigitalServicesAct,
    beforeEnter: () => {
      MetaService.initMetaData(window.location.host, "digitalservicesact");
    },
  },
  {
    path: "/provider",
    name: "provider",
    component: Provider,
    props: (route) => ({ preselectedTab: route.query.preselectedTab }),
  },
  {
    path: "/model-selection",
    name: "modelSelection",
    component: ModelSelection,
  },
  {
    path: "/model-selection-result",
    name: "modelSelectionResult",
    component: ModelSelectionResult,
  },
  {
    path: "/dealer",
    name: "dealer",
    component: DealerLanding,
    props: (route) => ({ partnerId: route.query.partnerId }),
    beforeEnter: () => {
      MetaService.initMetaData(window.location.host, "dealer");
    },
  },
  {
    path: "/payment-redirect",
    name: "paymentRedirect",
    component: PaymentRedirect,
    props: (route) => ({
      reservationId: route.query.reservationId,
      email: route.query.email,
    }),
  },
  {
    path: "/direct-search",
    name: "directSearch",
    component: DirectSearch,
    props: (route) => {
      // get all query params and encodeURI to sanitize possible injections
      const queryParams = {};
      Object.entries(route.query).forEach(([key, value]) => {
        Object.assign(queryParams, {
          [encodeURI(key)]: encodeURI(value),
        });
      });
      return queryParams;
    },
  },
  {
    path: "/request-long-term",
    name: "requestModeLongTerm",
    component: RequestModeLongTerm,
  },
  {
    path: "/cancellationPage",
    name: "cancellationPage",
    component: CancellationPage,
    props: (route) => ({
      reservationId: route.query.reservationId,
      email: route.query.email,
    }),
  },
  {
    path: "/content/:pageId",
    name: "contentPage",
    component: ContentPage,
    props: true,
  },
  {
    path: "/:catchAll(.*)",
    name: "default",
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  // main redirect
  if (to.path === "/main.xhtml") {
    return "/";
  }

  // contact redirect
  if (to.query?.contactPage) {
    return "/contact";
  }
  if (to.path === "/contactPage.xhtml") {
    return "/contact";
  }

  // faq redirect
  if (to.path === "/faqPage.xhtml") {
    return "/faq";
  }

  // about us redirect
  if (to.path === "/aboutUs.xhtml") {
    return "/about";
  }

  // provider redirects to sub-pages
  switch (to.query?.legalNotice) {
    case "0":
      return "/provider?preselectedTab=provider";
    case "1":
      return "/provider?preselectedTab=dataPrivacy";
    case "2":
      return "/provider?preselectedTab=cookies";
    case "3":
      return "/provider?preselectedTab=legalPreconditions";
    case "4":
      return "/provider?preselectedTab=termsAndConditions";
    case "5":
      return "/provider?preselectedTab=legalNotice";
    default:
      break;
  }

  // dealer redirect
  if ((to.path === "/" || to.path === "/main.xhtml") && to.query?.partnerId) {
    return `/dealer?partnerId=${to.query.partnerId}`;
  }
  if (to.path === "/dealer" && !to.query?.partnerId) {
    return "/";
  }
  return true;
});

export default router;

import { scrollToTop } from "@/utilities/scroll";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const useHomeRedirect = function () {
  const route = useRoute();
  const router = useRouter();
  const store = useStore();
  const outletId = computed(() => store.getters["mainOutlet/gsId"]);

  function redirectToHomeOrDealer() {
    store.dispatch("search/resetDateAndTime");
    store.dispatch("checkout/resetCheckoutState");
    const goToDealerPage = () => {
      router.push({
        name: "dealer",
        query: {
          partnerId: outletId.value,
          locale: route.query.locale,
        },
      });
    };

    const goToHomeScreen = () => {
      store.dispatch("search/resetSearchState");
      router.push({
        name: "home",
        query: {
          locale: route.query.locale,
        },
      });
    };

    if (route.query?.partnerId || outletId.value) {
      goToDealerPage();
    } else {
      goToHomeScreen();
    }
    scrollToTop();
  }

  return {
    redirectToHomeOrDealer,
  };
};

export default useHomeRedirect;

<template>
  <wb-checkbox-control class="wb-margin-bottom-xs">
    <label>
      <input
        v-bind="$attrs"
        type="checkbox"
        :checked="modelValue"
        @change="checkboxChanged"
        class="field"
      />
      <span v-if="data.label">
        <span v-if="splitLabel && splitLabel.length === 3">
          {{ splitLabel[0] }}
          <span class="wb-link--inline" @click="linkClicked($event, 'one')">
            {{ splitLabel[1] }}
          </span>
          {{ splitLabel[2] }}
        </span>
        <span v-else-if="splitLabel && splitLabel.length === 5">
          {{ splitLabel[0] }}
          <span class="wb-link--inline" @click="linkClicked($event, 'one')">
            {{ splitLabel[1] }}
          </span>
          {{ splitLabel[2] }}
          <span class="wb-link--inline" @click="linkClicked($event, 'two')">
            {{ splitLabel[3] }}
          </span>
          {{ splitLabel[4] }}
        </span>
        <span v-else>
          {{ data.label }}
        </span>
        <span v-if="required">*</span>
      </span>
    </label>
  </wb-checkbox-control>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const checkboxChanged = (event) => {
      emit("update:modelValue", event.target.checked);
      emit("changed");
    };

    const linkClicked = (event, value) => {
      if (props.link) {
        event.preventDefault();
        emit("linkClick", value);
      }
    };

    let splitLabel;
    if (props.link) {
      splitLabel = props.data.label.split(new RegExp("[}{]"));
    }

    return {
      checkboxChanged,
      linkClicked,
      splitLabel,
    };
  },
};
</script>

export function getPriceTag(price, currencyCode, isoCode) {
  if (price === null) {
    return "-";
  }

  return new Intl.NumberFormat(isoCode, {
    style: "currency",
    currency: currencyCode,
  }).format(price);
}

<template>
  <div class="search_page_summary wb-type-copy">
    <div v-if="!isComingFromLandingPage" class="search_page_summary__item">
      <span class="wb-type-copy-strong">
        {{ searchLocalization?.labelPickUpLocation }}:
      </span>
      <span class="search_page_summary__location" @click="redirect">
        {{ location }}
      </span>
    </div>
    <div v-else class="location__container">
      <div
        class="search_page_summary__item wb-type-copy-strong location__name"
        @click="openLocationOverlay"
      >
        {{ mainOutletStructure.displayName }}
      </div>
      <div
        class="search_page_summary__edit edit_btn"
        @click="openLocationOverlay"
      >
        <button
          type="button"
          aria-label="Close Button"
          class="wb-round-button wb-round-button--medium"
        >
          <Icon name="edit" :size="18" :width="12" :height="22" />
        </button>
      </div>
    </div>
    <div
      class="search_page_summary__item search_page_summary__spacer"
      :class="{
        search_page_summary__dealer_landing: isComingFromLandingPage,
      }"
    ></div>
    <div
      class="times__container"
      :class="{
        times__container__dealer_landing: isComingFromLandingPage,
      }"
    >
      <div class="times__container__inner">
        <div class="search_page_summary__item search_page_summary__item--date">
          <span class="wb-type-copy-strong">
            {{ searchLocalization?.labelPickUp }}:
          </span>
          <span class="search_page_summary__date_time"
            >{{ fromDate }}, {{ time?.from }}</span
          >
        </div>
        <div class="search_page_summary__item search_page_summary__item--date">
          <span class="wb-type-copy-strong">
            {{ searchLocalization?.labelReturn }}:
          </span>
          <span class="search_page_summary__date_time"
            >{{ toDate }}, {{ time?.to }}</span
          >
        </div>
      </div>
      <div
        class="search_page_summary__edit edit_btn"
        :class="{
          search_page_summary__edit_center: !isComingFromLandingPage,
        }"
        @click="redirect"
      >
        <button
          type="button"
          aria-label="Close Button"
          class="wb-round-button wb-round-button--medium"
        >
          <Icon name="edit" :size="18" :width="12" :height="22" />
        </button>
      </div>
    </div>

    <transition
      name="slide-overlay"
      @after-enter="transitionComplete = true"
      @after-leave="redoSearch"
    >
      <LocationOverlay
        v-if="locationOpen"
        :transitionComplete="transitionComplete"
        :localization="localization"
        @close="hasChanges"
        target="vehicles"
      />
    </transition>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { formatDisplayDate } from "@/utilities/date";
import Icon from "@/components/Icon";
import LocationOverlay from "@/components/Location/LocationOverlay";

export default {
  name: "SearchPageSummary",
  props: ["searchLocalization"],

  setup() {
    const store = useStore();
    const router = useRouter();
    const location = computed(() => store.getters["search/query"]);
    const date = computed(() => store.getters["search/selectedDate"]);
    const time = computed(() => store.getters["search/selectedTime"]);
    const mainOutletStructure = computed(
      () => store.getters["mainOutlet/mainOutletStructure"]
    );
    const gsId = computed(() => store.getters["mainOutlet/gsId"]);
    const isComingFromLandingPage = computed(
      () => store.getters["mainOutlet/isComingFromLandingPage"]
    );
    const redirect = () => {
      if (!isComingFromLandingPage.value) {
        router.push({ name: "home" });
      } else {
        router.push({
          name: "dealer",
          query: { partnerId: gsId.value },
        });
      }
    };

    const requiredDateFormat = computed(
      () => store.getters["localization/dateFormat"]
    );
    const isoCode = computed(() => store.getters["localization/isoCode"]);

    const fromDate = computed(() =>
      formatDisplayDate(
        date.value?.from,
        isoCode.value,
        requiredDateFormat.value
      )
    );
    const toDate = computed(() =>
      formatDisplayDate(date.value?.to, isoCode.value, requiredDateFormat.value)
    );

    const locationOpen = computed(
      () => store.getters["search/locationOverlayOpen"]
    );
    const openLocationOverlay = () => {
      store.dispatch("search/updateLocationOverlayOpen", true);
    };

    const redo = ref(true);
    const hasChanges = (changed) => {
      redo.value = changed;
    };
    const redoSearch = () => {
      if (redo.value) {
        const rentalPeriod = computed(
          () => store.getters["search/rentalPeriod"]
        );
        const domainId = computed(() => store.getters["marketConfig/domainId"]);
        const outlets = [];
        const selectedOutlets = computed(
          () => store.getters["search/locationSelected"]
        );
        selectedOutlets.value.forEach((outlet) => {
          outlets.push({ gsId: outlet.outlet.outletId });
        });
        const request = {
          domainId: domainId.value,
          outlets: outlets,
          rentalPeriod: rentalPeriod.value,
        };

        store.dispatch("vehicles/clearVehicles");
        store.dispatch("search/setSearchRequest", request);
      }
    };

    return {
      redirect,
      location,
      fromDate,
      toDate,
      time,
      mainOutletStructure,
      isComingFromLandingPage,
      locationOpen,
      openLocationOverlay,
      redoSearch,
      hasChanges,
      transitionComplete: ref(true),
      localization: computed(() => store.getters["localization/search"]),
    };
  },
  components: {
    Icon,
    LocationOverlay,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/searchPageSummary";
</style>

<template>
  <div
    v-if="inputFocused"
    class="suggest jsSuggest wb-type-copy"
    :class="{ dark: themeStyle === 'dark', bright: themeStyle === 'bright' }"
  >
    <ul class="suggest__container" data-test-id="suggest-container">
      <li class="suggest__item" @mousedown="debounce.getGeoLocation()">
        {{ localization?.location?.useLocation }}
      </li>
      <template v-if="suggestionOpen">
        <li
          @mousedown="suggestionSelected(suggest)"
          class="suggest__item dynamic"
          :class="{ selected: preSelected === suggest }"
          v-for="suggest in suggestion"
          v-html="highlightSuggestion(suggest)"
          :key="suggest"
          data-test-id="suggest-items"
        />
      </template>
    </ul>
    <div class="suggest__loader" v-if="loading">
      <wb-spinner theme="dark"></wb-spinner>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useKeydown } from "@/compositions/useKeydown";
import useDebounce from "@/compositions/useDebounce";

export default {
  name: "Suggest",
  props: {
    localization: {
      type: Object,
    },
    query: {
      type: String,
    },
    themeStyle: {
      type: String,
      default: "dark",
    },
    inputFocused: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const domainId = computed(() => store.getters["marketConfig/domainId"]);
    const updateSuggestionAfterRetrieving = ref(false);

    // dispatch suggestion if query changes
    watch(
      () => props.query,
      (propsQuery) => {
        if (propsQuery.length > 1) {
          if (!store.getters["search/suggestionSelected"]) {
            store.dispatch("search/getSuggestion", {
              query: propsQuery,
              domainId: domainId.value,
            });
          }
        } else {
          store.dispatch("search/clearSuggestion");
        }
      }
    );

    // on click/enter suggestion - update store
    const suggestionSelected = (suggest) => {
      updateSuggestionStore(suggest, "auto suggest list");
    };

    // suggestion keyboard events
    const suggestion = computed(() => store.getters["search/suggestion"]);
    const preSelected = ref("");
    const selectionIndex = ref(0);
    useKeydown([
      {
        key: "Escape",
        fn: () => {
          emit("selected", props.query);
        },
      },
      {
        key: "Enter",
        fn: () => {
          let searchType = "manually";
          if (preSelected.value) {
            searchType = "auto suggest list";
          }
          if (preSelected.value || suggestion.value[0]) {
            updateSuggestionAfterRetrieving.value = false;
            const selectedSuggestion = preSelected.value
              ? preSelected.value
              : suggestion.value[0];
            updateSuggestionStore(selectedSuggestion, searchType);
          } else {
            updateSuggestionAfterRetrieving.value = true;
          }
        },
      },
      {
        key: "ArrowDown", // Down for IE/Edge
        fn: () => {
          if (
            preSelected.value &&
            selectionIndex.value < suggestion.value.length - 1
          ) {
            selectionIndex.value++;
            preSelected.value = suggestion.value[selectionIndex.value];
          }
          if (!preSelected.value) {
            preSelected.value = suggestion.value[0];
          }
        },
      },
      {
        key: "ArrowUp", // Up for IE/Edge
        fn: () => {
          if (preSelected.value && selectionIndex.value > 0) {
            selectionIndex.value--;
            preSelected.value = suggestion.value[selectionIndex.value];
          }
          if (!preSelected.value) {
            preSelected.value = suggestion.value[0];
          }
        },
      },
    ]);

    watch(
      () => suggestion.value,
      () => {
        if (suggestion.value.length && updateSuggestionAfterRetrieving.value) {
          updateSuggestionStore(suggestion.value[0], "manually");
        }
      }
    );

    const suggestionInvalid = computed(
      () => store.getters["search/suggestionInvalid"]
    );
    watch(
      () => [suggestionInvalid.value, updateSuggestionAfterRetrieving.value],
      ([invalid, suggestionUpdated]) => {
        if (invalid && suggestionUpdated) {
          updateSuggestionStore(props.query, "manually");
        }
      }
    );

    const updateSuggestionStore = (suggest, type) => {
      emit("selected", suggest);
      store.dispatch("search/setSearchType", type);
    };

    // higlight suggestion query in suggestion list
    const highlightSuggestion = (suggestName) => {
      return suggestName.replace(
        new RegExp(escapeRegex(props.query), "gi"),
        (match) => {
          return '<span class="highlight">' + match + "</span>";
        }
      );
    };
    const escapeRegex = (string) => {
      return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    };

    return {
      suggestion,
      suggestionSelected,
      highlightSuggestion,
      preSelected,
      invalidLocation: computed(() => store.getters["search/invalidLocation"]),
      loading: computed(() => store.getters["search/loading"]),
      suggestionOpen: computed(() => store.getters["search/suggestionOpen"]),
      debounce: useDebounce(),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/suggest";
</style>

<template>
  <div class="language-switcher" v-if="allLanguages?.length > 1">
    <div
      class="language-switcher__items"
      v-for="(language, index) in allLanguages"
      :key="language.id"
      @click="updateLanguage(language)"
    >
      <span
        class="language-switcher__text"
        :class="{ active: language.isoCode === activeLanguage.isoCode }"
      >
        {{ language.displayName }}
      </span>
      <span v-if="notLastElement(index)">&nbsp;|&nbsp;</span>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import {
  updateHtmlLanguage,
  updateUserCentricsLanguage,
} from "@/utilities/language";

export default {
  name: "LanguageSwitcher",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const allLanguages = computed(
      () => store.getters["marketConfig/languages"]
    );
    const activeLanguage = computed(
      () => store.getters["localization/activeLanguage"]
    );

    const domainId = computed(() => store.getters["marketConfig/domainId"]);
    const updateLanguage = async (language) => {
      store.dispatch("localization/getLocalization", {
        language,
        domainId: domainId.value,
      });
      setTimeout(
        () => store.dispatch("vehicles/setModelTypesReloadRequired", true),
        1000
      );

      await router.push({
        name: route.name,
        query: { ...route.query, locale: language.isoCode },
      });

      // update html & usercentrics language
      updateUserCentricsLanguage(language.isoCode);
      updateHtmlLanguage(language.isoCode);
    };

    const notLastElement = (index) => index < allLanguages.value.length - 1;

    return {
      allLanguages,
      activeLanguage,
      updateLanguage,
      notLastElement,
    };
  },
};
</script>

<style lang="scss" scoped>
.language-switcher {
  cursor: pointer;
  height: 100%;
  padding-left: 15px;
  display: flex;
  align-items: center;

  &__text {
    &.active {
      font-weight: bold;
    }
  }
}
</style>

<template>
  <section
    class="image"
    :style="{
      backgroundImage: `url(${imageUrl})`,
    }"
  ></section>
  <section class="content-width information__width">
    <div class="information__itemHeader">Maintenance Work</div>
    <div class="information__itemContent">
      We are performing some maintenance and improvements at the moment. We
      apologize for the inconvenience. We will be back online shortly.
    </div>
  </section>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  setup() {
    const store = useStore();
    const isMobile = computed(() => store.getters["localization/isMobile"]);

    const imageUrl = computed(() => {
      let image = "";
      if (isMobile.value) {
        image = require("@/assets/images/maintenance_image_mobile.jpg");
      } else {
        image = require("@/assets/images/maintenance_image_desktop.jpg");
      }
      return image;
    });

    return {
      imageUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_typography";
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.image {
  position: relative;
  width: 100%;
  min-height: 300px;
  background-position: 90% 0;
  background-repeat: no-repeat;
  background-color: #b1b0b5;
  background-size: cover;

  @include breakpoint-from(mq2) {
    min-height: 35vh;
  }

  @include breakpoint-from(mq3) {
    min-height: 500px;
  }
}

.information {
  &__itemHeader {
    @include wb-type-heading-l;
    padding-top: 7px;
  }

  &__width {
    @include breakpoint-to(mq2) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &__itemContent {
    font-family: "MBCorpo Text", "DaimlerCS-Regular", sans-serif;
    font-style: normal;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: normal;
    padding-top: 18px;
    padding-bottom: 30px;
  }
}
</style>

import ApiService from "@/services/ApiService";
import {
  checkOriginalAddressFields,
  getMinTimeFromLocationSelected,
  getMinDateFromLocationSelected,
} from "@/utilities/location";

// initial state
const searchState = () => ({
  search: {
    query: "",
    prevQuery: "",
    locationOverlayOpen: false,
    suggestionSelected: false,
    suggestionOpen: false,
    suggestion: [],
    invalidSuggestion: null,
    locationMinRadius: null,
    location: {},
    emptyLocation: null,
    invalidLocation: null,
    locationSelected: new Set(),
    locationSelectedMinDateTime: null,
    locationForVehicleRequest: null,
    locationLoading: false,
    holiday: [],
    radius: null,
    radiusNoLocationFound: null,
    datePickerOpen: false,
    geoLocation: null,
    date: {
      from: null,
      to: null,
    },
    blockedDates: [],
    timePickerOpen: false,
    time: {
      from: null,
      to: null,
    },
    searchType: null,
    searchRequest: null,
    loading: false,
  },
});

// mutations
const mutations = {
  SET_QUERY(state, query) {
    state.search.query = query;
  },
  SET_PREV_QUERY(state, query) {
    state.search.prevQuery = query;
  },
  UPDATE_SUGGESTION_SELECTED(state, selected) {
    state.search.suggestionSelected = selected;
  },
  SET_SUGGESTION(state, suggestion) {
    state.search.suggestion = suggestion;
  },
  SET_INVALID_SUGGESTION(state, invalidSuggestion) {
    state.search.invalidSuggestion = invalidSuggestion;
  },
  SET_LOCATION(state, locations) {
    state.search.location = locations;
  },
  SET_LOCATIONS(state, locations) {
    state.search.location.locations = locations;
  },
  EMPTY_LOCATION(state, empty) {
    state.search.emptyLocation = empty;
  },
  INVALID_LOCATION(state, invalid) {
    state.search.invalidLocation = invalid;
  },
  CLEAR_SUGGESTION(state) {
    state.search.suggestion = [];
  },
  RESET_SUGGESTION(state) {
    state.search.suggestion = [];
    state.search.suggestionOpen = false;
    state.search.invalidSuggestion = null;
  },
  UPDATE_SUGGESTION_OPEN(state, open) {
    state.search.suggestionOpen = open;
  },
  UPDATE_LOCATION_OVERLAY_OPEN(state, open) {
    state.search.locationOverlayOpen = open;
  },
  UPDATE_LOADING(state, loading) {
    state.search.loading = loading;
  },
  UPDATE_LOCATION_LOADING(state, loading) {
    state.search.locationLoading = loading;
  },
  UPDATE_RADIUS(state, radius) {
    state.search.radius = radius;
  },
  UPDATE_RADIUS_NO_LOCATION_FOUND(state, radius) {
    state.search.radiusNoLocationFound = radius;
  },
  UPDATE_LOCATION_MIN_RADIUS(state, radius) {
    state.search.locationMinRadius = radius;
  },
  UPDATE_GEO_LOCATION(state, city) {
    state.search.geoLocation = city;
  },
  CLEAR_GEO_LOCATION(state) {
    state.search.geoLocation = null;
  },
  SET_LOCATION_SELECTED(state, locations) {
    state.search.locationSelected = locations;
  },
  ADD_LOCATION_SELECTION(state, location) {
    const selected = new Set([...state.search.locationSelected]);
    selected.add(location);
    state.search.locationSelected = selected;
  },
  REMOVE_LOCATION_SELECTION(state, location) {
    const selected = new Set([...state.search.locationSelected]);
    selected.delete(location);
    state.search.locationSelected = selected;
  },
  CLEAR_LOCATION_SELECTION(state) {
    const selected = new Set([...state.search.locationSelected]);
    selected.clear();
    state.search.locationSelected = selected;
  },
  SET_LOCATION_FOR_VEHICLE_REQUEST(state, location) {
    state.search.locationForVehicleRequest = location;
  },
  SET_DATE(state, date) {
    state.search.date = date;
  },
  SET_BLOCKED_DATES(state, blockedDates) {
    state.search.blockedDates = blockedDates;
  },
  SET_TIME_FROM(state, from) {
    state.search.time = {
      ...state.search.time,
      from,
    };
  },
  SET_TIME_TO(state, to) {
    state.search.time = {
      ...state.search.time,
      to,
    };
  },
  SET_DATE_PICKER_OPEN(state, open) {
    state.search.datePickerOpen = open;
  },
  SET_TIME_PICKER_OPEN(state, open) {
    state.search.timePickerOpen = open;
  },
  SET_SEARCH_REQUEST(state, request) {
    state.search.searchRequest = request;
  },
  SET_SEARCH_TYPE(state, type) {
    state.search.searchType = type;
  },
  RESET_SEARCH_STATE(state) {
    Object.assign(state, searchState());
  },
  RESET_DATE_AND_TIME(state) {
    state.search.date = { from: null, to: null };
    state.search.time = { from: null, to: null };
  },
};

// actions
const actions = {
  updateQuery({ state, commit }, query) {
    commit("SET_QUERY", query);

    if (state.search.prevQuery !== query) {
      commit("CLEAR_LOCATION_SELECTION");
    }
  },
  clearSuggestion({ commit }) {
    commit("CLEAR_SUGGESTION");
  },
  updateSuggestionSelected({ commit }, selected) {
    commit("UPDATE_SUGGESTION_SELECTED", selected);
  },
  async getSuggestion({ rootGetters, commit }, params) {
    commit("INVALID_LOCATION", false);
    commit("UPDATE_LOADING", true);
    commit("CLEAR_SUGGESTION");
    commit("UPDATE_SUGGESTION_OPEN", true);

    const mockMode = rootGetters["marketConfig/mockMode"];

    try {
      const getSuggestion = mockMode
        ? await ApiService.getMockSuggestion()
        : await ApiService.getSuggestion(params);

      switch (getSuggestion.status) {
        case 200:
          const suggestion = getSuggestion.data;
          commit("SET_SUGGESTION", suggestion);
          break;
        case 204:
          commit("SET_INVALID_SUGGESTION", true);
          break;
        default:
          commit("SET_INVALID_SUGGESTION", true);
          throw new Error("API Success But Unhandled Response");
      }
    } catch (e) {
      commit("SET_INVALID_SUGGESTION", true);
      throw new Error(e);
    } finally {
      commit("UPDATE_LOADING", false);
    }
  },
  updateSuggestionOpen({ commit }, open) {
    commit("UPDATE_SUGGESTION_OPEN", open);
  },
  updateLocationOverlayOpen({ commit }, open) {
    commit("UPDATE_LOCATION_OVERLAY_OPEN", open);
  },
  async getLocation(
    { rootGetters, state, commit },
    { query, radius, domainId, isSearchArea = true }
  ) {
    if (isSearchArea) {
      commit("UPDATE_LOCATION_LOADING", true);
    }

    // clear location, date, time
    commit("SET_DATE", {
      from: null,
      to: null,
    });
    commit("SET_TIME_FROM", null);
    commit("SET_TIME_TO", null);

    if (
      state.search.prevQuery === query &&
      state.search.location?.locations?.length
    ) {
      commit("UPDATE_LOCATION_LOADING", false);
      return;
    }
    commit("SET_LOCATION", []);
    const mockMode = rootGetters["marketConfig/mockMode"];
    let location;

    try {
      const getLocation = mockMode
        ? await ApiService.getMockLocation({ query, radius })
        : await ApiService.getLocation({
            query,
            radius,
            domainId,
          });
      location = getLocation.data;
      // Check original address and name: when the fields are empty use latin address values instead.
      // This is needed because original address fields are all optional in GSSN but must not be empty when original displaymode is used.
      if (location?.locations?.length) {
        location = checkOriginalAddressFields(location);
      }

      switch (getLocation.status) {
        case 200:
          commit("SET_LOCATION", location);
          commit("UPDATE_RADIUS", location.radius);
          // set minRadius to BE Response radius if from searchArea call
          if (isSearchArea) {
            commit("UPDATE_LOCATION_MIN_RADIUS", location.radius);
          }
          commit("EMPTY_LOCATION", false);
          commit("INVALID_LOCATION", false);
          break;

        case 204:
          throw new Error("API Success But No Location");

        case 206:
          commit("SET_LOCATION", location);
          commit("UPDATE_RADIUS_NO_LOCATION_FOUND", location.radius);
          commit("EMPTY_LOCATION", true);
          commit("INVALID_LOCATION", false);
          throw new Error("API Success But No Stations In Location Radius");

        default:
          commit("EMPTY_LOCATION", true);
          throw new Error("API Success But Unhandled Response");
      }
    } catch (error) {
      if (!location) {
        commit("INVALID_LOCATION", true);
      }
      throw new Error(error);
    } finally {
      commit("UPDATE_LOCATION_LOADING", false);
    }
  },
  async getCity({ rootGetters, commit }, params) {
    const mockMode = rootGetters["marketConfig/mockMode"];

    try {
      const result = mockMode
        ? await ApiService.getMockCity(params)
        : await ApiService.getCity(params);

      if (result.status === 200) {
        commit("UPDATE_LOADING", false);
        const city = result.data?.city;
        commit("UPDATE_GEO_LOCATION", city);
      }
    } catch (e) {
      // console.warn("error in getLocation ApiService", e);
    }
  },
  resetEmptyLocation({ commit }) {
    commit("EMPTY_LOCATION", false);
  },
  clearCity({ commit }) {
    commit("CLEAR_GEO_LOCATION");
  },
  async updateLocation({ commit, dispatch }, { query, radius, domainId }) {
    commit("SET_PREV_QUERY", "");

    dispatch("getLocation", {
      query,
      radius,
      domainId,
      isSearchArea: false,
    });
  },
  clearLocation({ commit }) {
    commit("SET_LOCATION", []);
  },
  setLocationForVehicleRequest({ commit }, location) {
    commit("SET_LOCATION_FOR_VEHICLE_REQUEST", location);
  },
  setLocation({ commit }, location) {
    commit("SET_LOCATION", location);
  },
  updateLoading({ commit }, loading) {
    commit("UPDATE_LOADING", loading);
  },
  updateLocationLoading({ commit }, loading) {
    commit("UPDATE_LOCATION_LOADING", loading);
  },
  resetSuggestion({ commit }) {
    commit("RESET_SUGGESTION");
  },
  updateRadius({ commit }, radius) {
    commit("UPDATE_RADIUS", radius);
  },
  toggleLocationSelection({ commit, state }, location) {
    if (state.search.locationSelected.has(location)) {
      commit("REMOVE_LOCATION_SELECTION", location);
    } else {
      commit("ADD_LOCATION_SELECTION", location);
    }
  },
  clearLocationSelection({ commit }) {
    commit("CLEAR_LOCATION_SELECTION");
  },
  addMultipleLocationSelection({ commit }, newLocations) {
    newLocations.forEach((location) => {
      commit("ADD_LOCATION_SELECTION", location);
    });
  },
  updateTimeFrom({ commit }, from) {
    commit("SET_TIME_FROM", from);
  },
  updateTimeTo({ commit }, to) {
    commit("SET_TIME_TO", to);
  },
  setDate({ commit }, date) {
    commit("SET_DATE", date);
  },
  updateDate({ commit }, date) {
    commit("SET_DATE", date);

    // clear time
    commit("SET_TIME_FROM", null);
    commit("SET_TIME_TO", null);
  },
  updateBlockedDates({ commit }, blockedDates) {
    commit("SET_BLOCKED_DATES", blockedDates);
  },
  updateDatePickerOpen({ commit }, open) {
    commit("SET_DATE_PICKER_OPEN", open);
  },
  updateTimePickerOpen({ commit }, open) {
    commit("SET_TIME_PICKER_OPEN", open);
  },
  setSearchRequest({ commit }, request) {
    commit("SET_SEARCH_REQUEST", request);
  },
  setSearchType({ commit }, type) {
    commit("SET_SEARCH_TYPE", type);
  },
  resetSearchState({ dispatch, commit }) {
    commit("RESET_SEARCH_STATE");
    dispatch("vehicles/resetFilters", undefined, { root: true });
  },
  resetDateAndTime({ commit }) {
    commit("RESET_DATE_AND_TIME");
  },
  updateLocations({ commit }, locations) {
    commit("SET_LOCATIONS", locations);
  },
  updateSelectedLocations({ commit }, location) {
    commit("SET_LOCATION_SELECTED", [location]);
  },
};

// getters
const getters = {
  search: (state) => {
    return state.search;
  },
  query: (state) => {
    return state.search.query;
  },
  suggestion: (state) => {
    return state.search.suggestion;
  },
  suggestionSelected: (state) => {
    return state.search.suggestionSelected;
  },
  suggestionOpen: (state) => {
    return state.search.suggestionOpen;
  },
  suggestionInvalid: (state) => {
    return state.search.invalidSuggestion;
  },
  locationOverlayOpen: (state) => {
    return state.search.locationOverlayOpen;
  },
  location: (state) => {
    return state.search.location;
  },
  invalidLocation: (state) => {
    return state.search.invalidLocation;
  },
  locations: (state) => {
    return state.search.location?.locations ?? [];
  },
  emptyLocation: (state) => {
    return state.search?.emptyLocation;
  },
  holiday: (state) => {
    return state.search.holiday;
  },
  loading: (state) => {
    return state.search.loading;
  },
  radius: (state) => {
    return state.search.radius;
  },
  radiusNoLocationFound: (state) => {
    return state.search.radiusNoLocationFound;
  },
  locationMinRadius: (state) => {
    return state.search.locationMinRadius;
  },
  geoLocation: (state) => {
    return state.search.geoLocation;
  },
  locationSelected: (state) => {
    return state.search.locationSelected;
  },
  locationSelectedOnlyVBS: (state) => {
    return [...state.search.locationSelected].filter(
      (location) => location.outlet?.rentPlatform === "VBS"
    );
  },
  hasVBSOutlets: (_, getters) => !!getters.locationSelectedOnlyVBS?.length,
  locationForVehicleRequest: (state) => {
    return state.search.locationForVehicleRequest;
  },
  locationLoading: (state) => {
    return state.search.locationLoading;
  },
  locationAvailableDates: (state) => {
    return state.search.location?.availableDates;
  },
  selectedDate: (state) => {
    return state.search.date;
  },
  blockedDates: (state) => {
    return state.search.blockedDates;
  },
  selectedTime: (state) => {
    return state.search.time;
  },
  minFromTime: (state) => {
    if (state.search?.locationSelected.size > 0) {
      return getMinTimeFromLocationSelected(state.search?.locationSelected);
    } else {
      return state.search?.location?.minFromTime;
    }
  },
  minDate: (state) => {
    if (state.search?.locationSelected.size > 0) {
      return getMinDateFromLocationSelected(state.search?.locationSelected);
    } else {
      return state.search?.location?.minFromDate;
    }
  },
  maxFromDate: (state) => {
    return state.search?.location?.maxFromDate;
  },
  maxToDate: (state) => {
    return state.search?.location?.maxToDate;
  },
  datePickerOpen: (state) => {
    return state.search?.datePickerOpen;
  },
  timePickerOpen: (state) => {
    return state.search?.timePickerOpen;
  },
  request: (state) => {
    return state.search?.searchRequest;
  },
  rentalPeriod: (state) => {
    return {
      fromDate: state.search?.date?.from,
      fromTime: state.search?.time?.from,
      toDate: state.search?.date?.to,
      toTime: state.search?.time?.to,
    };
  },
  searchType: (state) => {
    return state.search.searchType;
  },
};

function makeState(override) {
  const state = searchState();
  state.search = { ...state.search, ...override };
  return state;
}

export function createSearchModule(override) {
  return {
    namespaced: true,
    state: makeState(override),
    getters,
    actions,
    mutations,
  };
}

export default {
  namespaced: true,
  state: searchState,
  getters,
  actions,
  mutations,
};

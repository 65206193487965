import { watch, computed, ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { scrollToTop } from "@/utilities/scroll";
import {
  hasFieldsForStep,
  additionalFieldsPossibleFields,
} from "@/utilities/checkout";
import useTrackingPage from "@/compositions/useTrackingPage";
import ApiService from "@/services/ApiService";

const useRequestModeData = function () {
  const store = useStore();
  const router = useRouter();
  const domainId = computed(() => store.getters["marketConfig/domainId"]);
  const isoCode = computed(() => store.getters["localization/isoCode"]);
  const rentalPeriod = computed(() => store.getters["search/rentalPeriod"]);
  const fields = computed(() => store.getters["marketConfig/fields"]);
  const isCaptchaActive = computed(() => store.getters["marketConfig/captcha"]);
  const captcha = computed(() => store.getters["captcha/captcha"]);
  const captchaUserInput = computed(() => store.getters["captcha/userInput"]);
  const captchaData = computed(() => store.getters["captcha/captchaData"]);
  const localization = computed(
    () => store.getters["localization/localization"]
  );
  const selectedModelData = reactive({
    modelImage: null,
    modelClassName: null,
    modelType: null,
  });
  const modelTypeSelected = ref(false);

  const setSelectedModelType = (model) => {
    if (model) {
      selectedModelData.modelImage = model.image;
      selectedModelData.modelClassName = model.modelClassName;
      selectedModelData.modelType = model;
      store.dispatch("vehicles/setSelectedModelTypeForLongTermRequest", model);
      modelTypeSelected.value = true;
    } else {
      modelTypeSelected.value = false;
    }
  };

  const hasAdditionalFields = computed(() => {
    if (!fields.value) {
      return false;
    }

    return hasFieldsForStep(
      additionalFieldsPossibleFields,
      JSON.parse(JSON.stringify(fields.value)),
      "inquiryActive"
    );
  });

  const { trackRequestVehicleForm } = useTrackingPage();

  onMounted(() => {
    // Page reload with re-initialized store data shall route back to home
    if (!rentalPeriod.value?.fromDate) {
      router.push({
        name: "home",
      });
      scrollToTop();
    }

    trackRequestVehicleForm();
  });

  const locationForVehicleRequest = computed(
    () => store.getters["search/locationForVehicleRequest"]
  );
  const checkoutFields = computed(
    () => store.getters["checkout/checkoutFields"]
  );
  const openRequestConfirmation = ref(false);
  const isLoading = ref(false);
  const error = ref(false);

  const onFormSubmit = async (request) => {
    if (isCaptchaActive.value) {
      await store.dispatch(
        "captcha/validate",
        captchaData.value.validationData
      );
    }

    let requestBody = {
      isoCode: localization.value.isoCode,
      outletId: locationForVehicleRequest.value,
      vehicleModel: {
        modelBodyStyleId: selectedModelData.modelType.modelBodyStyleId,
        modelClassId: selectedModelData.modelType.modelClassId,
      },
      rentalPeriod: rentalPeriod.value,
      inquiryFields: checkoutFields.value,
    };

    if (isCaptchaActive.value && captchaData.value.isValid) {
      requestBody = {
        ...requestBody,
        captchaId: captcha.value.id,
        captchaText: captchaUserInput.value,
      };
    }

    if (
      (isCaptchaActive.value && captchaData.value.isValid) ||
      !isCaptchaActive.value
    ) {
      isLoading.value = true;

      try {
        await ApiService["requestVehicle"](requestBody, domainId.value);
        openRequestConfirmation.value = true;
      } catch (e) {
        error.value = true;
      } finally {
        isLoading.value = false;
      }
    }
  };

  watch(
    () => isoCode.value,
    () => {
      if (isoCode.value) {
        store.dispatch("vehicles/getModelTypesForRequestMode", {
          domainId: domainId.value,
          isoCode: isoCode.value,
        });
      }
    },
    {
      immediate: true,
    }
  );

  const modelTypesForLongTermRequest = computed(
    () => store.getters["vehicles/modelTypesForLongTermRequest"]
  );
  const modelTypeIdsInVBSVehicles = computed(
    () => store.getters["vehicles/modelTypeIdsInVBSVehicles"]
  );
  const modelTypesForShortTermRequest = computed(() => {
    // We only want to display model types that are not already
    // contained in the vehicle search results!
    return modelTypesForLongTermRequest.value
      .filter((item) => {
        let existsInSearch = false;
        modelTypeIdsInVBSVehicles.value.forEach((vbsVehicle) => {
          if (
            (vbsVehicle.vbsModelBodyStyleId === item.modelBodyStyleId &&
              vbsVehicle.vbsModelClassId === item.modelClassId) ||
            item.image === null
          ) {
            existsInSearch = true;
          }
        });
        return !existsInSearch;
      })
      .sort(function (modelType1, modelType2) {
        if (
          modelType1 &&
          modelType1.modelClassName &&
          modelType2 &&
          modelType2.modelClassName
        ) {
          const name1 = modelType1.modelClassName.toUpperCase();
          const name2 = modelType2.modelClassName.toUpperCase();

          if (name1 < name2) {
            return -1;
          }
          if (name1 > name2) {
            return 1;
          }
        }

        return 0;
      });
  });

  return {
    locationForVehicleRequest,
    modelTypesForLongTermRequest,
    modelTypesForShortTermRequest,
    selectedModelTypeForLongTermRequest: computed(
      () => store.getters["vehicles/selectedModelTypeForLongTermRequest"]
    ),
    disabledRentalData: computed(
      () => store.getters["checkout/disabledRentalData"]
    ),
    disabledPersonalData: computed(
      () => store.getters["checkout/disabledPersonalData"]
    ),
    disabledAdditionalFieldsData: computed(
      () => store.getters["checkout/disabledAdditionalFieldsData"]
    ),
    hasAdditionalFields,
    rentalPeriod,
    onFormSubmit,
    openRequestConfirmation,
    isLoading,
    error,
    setSelectedModelType,
    selectedModelData,
    modelTypeSelected,
  };
};

export default useRequestModeData;

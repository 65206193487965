import ApiService from "@/services/ApiService";

// initial state
const configState = () => ({
  reservationDetails: {},
  paymentError: false,
});

// mutations
const mutations = {
  SET_RESERVATION_DETAILS(state, data) {
    state.reservationDetails = data;
  },
  SET_PAYMENT_ERROR(state, error) {
    state.paymentError = error;
  },
};

// actions
const actions = {
  async getReservationDetails(
    { state, commit },
    { domainId, reservationId, email, isoCode }
  ) {
    try {
      const reservationDetails = state.mockMode
        ? await ApiService.getMockPreAuthData()
        : await ApiService.finalizeReservation(
            domainId,
            reservationId,
            email,
            isoCode
          );

      if (reservationDetails.status === 200) {
        commit("SET_RESERVATION_DETAILS", reservationDetails.data);
      } else {
        commit("SET_PAYMENT_ERROR", true);
      }
    } catch (error) {
      //when a 401 happens here, BASIC auth is activated on the REST API
      if (error.message.includes("401")) {
        commit("SET_BASIC_AUTH", true);
      }
      commit("SET_PAYMENT_ERROR", true);
    }
  },
  setPaymentError({ commit }, error) {
    commit("SET_PAYMENT_ERROR", error);
  },
};

// getters
const getters = {
  reservationDetails: (state) => {
    return state.reservationDetails;
  },
  hasAdditionalServicesCategories: (state) => {
    return !!state.reservationDetails?.additionalServicesCategories?.length;
  },
  paymentError: (state) => {
    return state.paymentError;
  },
};

function makeState(override) {
  return { ...configState(), ...override };
}

export function createPaymentRedirectModule(override) {
  return {
    namespaced: true,
    state: makeState(override),
    getters,
    actions,
    mutations,
  };
}

export default {
  namespaced: true,
  state: configState,
  getters,
  actions,
  mutations,
};

let lazyObserver = null;

const LazyLoadDirective = {
  mounted: (el) => {
    function loadImage() {
      if (el && el.style.display !== "none") {
        el.addEventListener("load", () => {
          setTimeout(() => el.classList.add("loaded"), 100);
        });
        el.src = el.dataset.url;
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    }

    function createObserver() {
      const options = {
        root: null,
        threshold: 0,
      };
      lazyObserver = new IntersectionObserver(handleIntersect, options);
      lazyObserver.observe(el);
    }
    // fallback
    if (window["IntersectionObserver"]) {
      createObserver();
    } else {
      loadImage();
    }
  },
  unmounted: () => {
    lazyObserver.disconnect();
  },
};

export default LazyLoadDirective;

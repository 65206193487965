<template>
  <div>
    <footer class="footer">
      <div
        class="footer__inner wb-grid-container"
        v-if="marketConfigPreAuthData?.domainActive"
      >
        <div
          class="footer__upper"
          v-if="!marketConfigPreAuthData?.maintenancePage"
        >
          <div class="footer__top">
            <Icon
              class="footer__top__icon"
              name="chevron-up"
              :size="14"
              :width="24"
              @click="scrollTop"
            />
          </div>
          <div class="footer__center" id="jsFooterCenter">
            <div class="footer__center__item">
              <span
                v-if="footer?.headerFooterText"
                class="footer__center__headline wb-type-heading-m"
              >
                {{ footer?.headerFooterText }}
              </span>
            </div>
            <div class="footer__center__item footer__center__item__right">
              <FooterCountryLinks :footer="footer" :isMobile="isMobile" />
            </div>
          </div>
        </div>
        <div class="footer__bottom">
          <FooterMenu v-if="footer" :footer="footer" />
          <div class="footer__copyright wb-type-copy-secondary">
            <p v-if="footer?.copyRight">
              &copy; {{ new Date().getFullYear() }} {{ footer?.copyRight }}
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import FooterCountryLinks from "@/components/Footer/FooterCountryLinks";
import FooterMenu from "@/components/Footer/FooterMenu";
import Icon from "@/components/Icon";

export default {
  setup() {
    const store = useStore();
    const marketConfigPreAuthData = computed(
      () => store.getters["marketConfig/preAuthData"]
    );
    const isMobile = computed(() => store.getters["localization/isMobile"]);

    const scrollTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    return {
      isMobile,
      footer: computed(() => store.getters["localization/footer"]),
      scrollTop,
      marketConfigPreAuthData,
    };
  },
  components: {
    FooterCountryLinks,
    FooterMenu,
    Icon,
  },
};
</script>
<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.footer {
  width: 100%;
  min-height: 300px;
  background: var(--wb-black);
  color: var(--wb-white);

  &__inner {
    @include breakpoint-from(mq3) {
      margin: 0 auto;
      padding: 30px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__upper {
    width: 100%;
  }

  &__top {
    display: block;
    padding: 20px 0;
    margin: auto;
    text-align: right;

    &__icon {
      cursor: pointer;
    }
  }

  &__home {
    color: var(--wb-blue-45);
    text-decoration: none;
  }

  &__center {
    text-align: center;
    padding: 30px 0 20px;
    @include breakpoint-from(mq3) {
      flex: 0 0 100%;
      width: 100%;
      order: 3;
      border-top: 1px solid var(--wb-grey-20);
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px 0;
    }

    &__item {
      padding-bottom: 10px;
      @include breakpoint-from(mq3) {
        flex: 1 0 45%;
        width: 45%;
        align-self: center;
        padding-bottom: 0;
      }

      &__right {
        @include breakpoint-from(mq3) {
          text-align: right;
          flex: 1 0 55%;
          width: 55%;
        }
      }
    }

    .footer__center__headline {
      display: block;
      font-size: 1.4rem;
      @include breakpoint-from(mq3) {
        text-align: left;
      }
    }
  }

  &__bottom {
    border-top: 1px solid var(--wb-grey-20);
    @include breakpoint-from(mq3) {
      flex: 0 0 100%;
      width: 100%;
      order: 4;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__copyright {
    text-align: center;
    width: 100%;
    margin: auto;
    padding-bottom: 20px;
    color: var(--wb-grey-60);
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    @include breakpoint-from(mq3) {
      text-align: left;
      padding: 20px 0 0 0;
      margin: 0;
      order: 1;
    }
  }
}
</style>

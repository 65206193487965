<template>
  <div class="location-selection">
    <h3 class="location-selection__headline wb-type-copy-strong">
      {{ localization?.vehicleRequestPage?.locationSelectionTitle }}
    </h3>
    <div class="location-selection__location__list">
      <div role="radiogroup">
        <form>
          <wb-radio-control
            v-for="item in locationList"
            :key="item.outlet.outletId"
            class="location-selection__location__item wb-margin-bottom-xs"
          >
            <label>
              <input
                type="radio"
                :name="item.outlet.name"
                :value="item.outlet.outletId"
                v-model="locationSelectionValue"
              />
              <p class="location-selection__location__item__text">
                {{ name(item.outlet) }}
                <br />
                {{ street(item.outlet) }},
                {{ item.outlet.latinAddress?.zipCode }}
                {{ city(item.outlet) }}
                <br />
                {{ localization?.mainPage?.search?.distance }}:
                {{ Number.parseFloat(item.distance).toFixed(2) }}
                {{ item.unit }}
              </p>
            </label>
          </wb-radio-control>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
export default {
  name: "LocationSelection",
  setup() {
    const store = useStore();
    const localization = computed(
      () => store.getters["localization/localization"]
    );
    const locationSelectionValue = ref(null);
    const locationList = store.getters["search/locationSelectedOnlyVBS"];

    watch(
      () => locationSelectionValue.value,
      () => {
        store.dispatch(
          "search/setLocationForVehicleRequest",
          locationSelectionValue.value
        );
      }
    );

    const language = computed(
      () => store.getters["localization/activeLanguage"]
    );
    const original = computed(() => language.value.useOriginalOutletAddress);
    const name = (outlet) =>
      original.value ? outlet.originalName : outlet.name;
    const street = (outlet) =>
      original.value
        ? outlet.originalAddress?.street
        : outlet.latinAddress?.street;
    const city = (outlet) =>
      original.value ? outlet.originalAddress?.city : outlet.latinAddress?.city;

    return {
      locationSelectionValue,
      locationList,
      localization,
      original,
      name,
      street,
      city,
    };
  },
};
</script>
<style lang="scss">
.location-selection {
  &__headline {
    font-size: 1.2rem !important;
    margin-bottom: 20px;
  }
}
</style>

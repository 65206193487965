/**
 * The fields for requestModeLongTerm are hardcoded here,
 * which is used in the useCheckoutFormValidation when activeField === requestModeLTRActive.
 * The "value" key is needed so the present code works (where fields are coming from BE and are stored in proxy)
 */
export const availableFieldsForRequestMode = {
  value: [
    {
      type: "SALUTATION",
      requestModeActive: true,
      required: false,
    },
    {
      type: "FIRST_NAME",
      requestModeActive: true,
      required: true,
    },
    {
      type: "LAST_NAME",
      requestModeActive: true,
      required: true,
    },
    {
      type: "BIRTHDAY",
      requestModeActive: true,
      required: true,
    },
    {
      type: "EMAIL",
      requestModeActive: true,
      required: true,
    },
    {
      type: "PHONE_NUMBER",
      requestModeActive: true,
      required: true,
    },
    {
      type: "STREET",
      requestModeActive: true,
      required: true,
    },
    {
      type: "HOUSE_NUMBER",
      requestModeActive: true,
      required: true,
    },
    {
      type: "POSTCODE",
      requestModeActive: true,
      required: true,
    },
    {
      type: "CITY",
      requestModeActive: true,
      required: true,
    },
    {
      type: "COUNTRY_OF_RESIDENCE",
      requestModeActive: true,
      required: true,
    },
    {
      type: "REASON_FOR_RENT",
      requestModeActive: true,
      required: false,
    },
    {
      type: "ADDITIONAL_REQUESTS_TEXTFIELD",
      requestModeActive: true,
      required: false,
    },
    {
      type: "TERMS",
      requestModeActive: true,
      required: true,
    },
  ],
};

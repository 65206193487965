<template>
  <wb-modal
    class="request-mode-modal"
    ref="requestModeRef"
    mode="info"
    scroll-lock
  >
    <wb-modal-header class="request-mode-modal__header"></wb-modal-header>
    <wb-modal-content
      class="request-mode-modal__content"
      :headline="localization?.modalHeadline"
    >
      <p>
        {{ localization?.modalDescription }}
      </p>
    </wb-modal-content>
    <wb-modal-footer class="request-mode-modal__footer">
      <button
        @click="navigate"
        type="submit"
        class="wb-button wb-button--primary wb-button--large request-mode-modal__button"
      >
        {{ localization?.modalBtnText }}
      </button>
    </wb-modal-footer>
  </wb-modal>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "PreRequestModeModal",
  props: ["localization"],
  setup(_, { emit }) {
    const requestModeRef = ref(null);
    const router = useRouter();
    const route = useRoute();

    onMounted(() => {
      requestModeRef.value.addEventListener("wbclose", () => emit("close"));
    });

    const navigate = () => {
      router.push({
        name: "requestModeLongTerm",
        query: {
          ...route.query,
        },
      });
    };

    return { requestModeRef, navigate };
  },
};
</script>

<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.request-mode-modal {
  & .wb-modal__outer {
    max-width: 580px;
  }

  &__header {
    padding: 10px 10px 0 10px;
    border-bottom: 0;

    & .wb-round-button__icon {
      width: 32px !important;
      height: 32px !important;
    }
  }

  &__content {
    padding-top: 0 !important;
  }

  &__footer {
    background: var(--wb-grey-90);
    padding: 15px 20px;
  }

  &__button {
    @include breakpoint-from(mq3) {
      margin: 0 0 0 auto;
    }
  }
}
</style>

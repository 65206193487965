<template>
  <slot></slot>
  <wb-input-control
    :error="error"
    :success="error !== null && !error"
    :filled="filled"
    :active="active"
  >
    <wb-input>
      <textarea
        v-bind="$attrs"
        :maxlength="maxLength"
        :placeholder="data.label"
        @focusin="focusin"
        @focusout="focusout"
        @input="
          $emit('update:modelValue', $event.target.value);
          $emit('validateInput');
        "
      ></textarea>
      <label></label>
    </wb-input>

    <wb-control-error v-if="error" id="regexValidationMessage">
      {{ data.regexValidationMessage }}
    </wb-control-error>
  </wb-input-control>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: null,
    },
    regexValidationMessage: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const filled = ref(false);
    const active = ref(false);

    const focusin = () => {
      filled.value = true;
      active.value = true;
    };

    const focusout = () => {
      filled.value = !props.modelValue ? null : true;
      active.value = null;
    };

    return {
      filled,
      active,
      focusin,
      focusout,
    };
  },
};
</script>
<style lang="scss" scoped>
h4,
label {
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--wb-grey-20);
  margin-bottom: 10px;
}

label {
  color: var(--wb-grey-45);
  margin: 5px;
}

textarea {
  opacity: 1;
}
</style>

import { createStore as vuexCreateStore } from "vuex";
import marketConfig from "./modules/marketConfig";
import localization from "./modules/localization";
import menu from "./modules/menu";
import search from "./modules/search";
import vehicles from "./modules/vehicles";
import checkout from "./modules/checkout";
import captcha from "@/store/modules/captcha";
import mainOutlet from "@/store/modules/mainOutlet";
import paymentRedirect from "@/store/modules/paymentRedirect";
import cancellationPage from "@/store/modules/cancellationPage";

const debug = process.env.NODE_ENV !== "production";

const moduleConfiguration = {
  marketConfig,
  localization,
  menu,
  search,
  vehicles,
  checkout,
  captcha,
  mainOutlet,
  paymentRedirect,
  cancellationPage,
};

const storeConfiguration = {
  modules: moduleConfiguration,
  strict: debug,
};

export function createStore(override = {}) {
  return vuexCreateStore({
    ...storeConfiguration,
    modules: {
      ...moduleConfiguration,
      ...override,
    },
  });
}

export default createStore();

import { useRoute } from "vue-router";

const useRouteTo = function () {
  const route = useRoute();

  const routeTo = (name) => {
    return {
      name,
      query: {
        ...route.query,
      },
    };
  };

  return {
    routeTo,
  };
};

export default useRouteTo;

<template>
  <div class="additional-page" v-if="hasVehicle">
    <ModelPriceDateVehicle
      class="content-width"
      :vehicle="selectedVehicle"
      :localization="localization"
    />
    <div class="additional-page__container content-width">
      <div class="additional-page__content">
        <h2 class="wb-type-heading-m wb-margin-bottom-xxs">
          {{ localization?.checkoutPage?.additionalServicesHeadline }}
        </h2>
        <p class="additional-page__text wb-type-copy wb-margin-bottom-m">
          {{ localization?.checkoutPage?.additionalServicesText }}
        </p>

        <AdditionalItems :additionalServices="additionalServices" />
      </div>

      <div
        class="additional-page__actions"
        :class="{ 'fixed-buttons': scrolling }"
      >
        <button
          @click="goToVehicleOffers"
          class="additional-page__btn wb-button wb-button--secondary wb-button--medium wb-type-copy"
        >
          <wb-icon
            class="wb-button__icon"
            aria-hidden="true"
            name="chevron-left"
          >
          </wb-icon>
          {{ localization.checkoutPage?.btnBackToVehicleSearch }}
        </button>
        <button
          @click="goToSelectedOffer"
          class="additional-page__btn wb-button wb-button--secondary wb-button--medium wb-type-copy"
        >
          <wb-icon
            class="wb-button__icon"
            aria-hidden="true"
            name="chevron-left"
          >
          </wb-icon>
          {{ localization.checkoutPage?.btnBackBreadcrumb }}
        </button>
        <button
          @click="goToCheckout(false)"
          data-test-id="btn-continue-checkout"
          :disabled="priceLoading"
          class="additional-page__btn wb-button wb-button--primary wb-button--medium wb-type-copy"
        >
          <wb-spinner
            v-if="priceLoading"
            theme="dark"
            class="btn-spinner"
          ></wb-spinner>
          <Icon
            v-else
            name="chevron-right"
            :size="12"
            :width="18"
            :height="12"
          />
          {{ localization?.checkoutPage?.btnContinue }}
        </button>
      </div>
      <div
        class="fixed-buttons__placeholder"
        :class="{ visible: scrolling }"
      ></div>
    </div>
  </div>
</template>
<script>
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { scrollToTop } from "@/utilities/scroll";
import useHomeRedirect from "@/compositions/useHomeRedirect";
import Icon from "@/components/Icon";
import ModelPriceDateVehicle from "@/components/ModelPriceDateVehicle";
import AdditionalItems from "@/components/AdditionalServices/AdditionalItems";
import useTrackingPage from "@/compositions/useTrackingPage";
import useStickyButtons from "@/compositions/useStickyButtons";

export default {
  name: "AdditionalServices",
  setup() {
    const store = useStore();
    const router = useRouter();
    const localization = computed(
      () => store.getters["localization/localization"]
    );
    const domainId = computed(() => store.getters["localization/domainId"]);
    const selectedVehicle = computed(
      () => store.getters["vehicles/selectedVehicle"]
    );
    const { redirectToHomeOrDealer } = useHomeRedirect();
    const hasVehicle = computed(
      () => !!Object.entries(selectedVehicle.value).length
    );
    const selectedServices = computed(
      () => store.getters["vehicles/selectedAdditionalServices"]
    );
    const priceLoading = ref(false);

    onBeforeMount(() => {
      // redirect to home if no data
      if (!hasVehicle.value) {
        redirectToHomeOrDealer();
      }
    });

    const goToVehicleOffers = () => {
      store.dispatch("vehicles/clearSelectedAdditionalServices");
      router.push({ name: "vehicles" });
    };

    const goToCheckout = async (skipped) => {
      if (Object.keys(selectedServices.value).length && !skipped) {
        priceLoading.value = true;
        await store.dispatch("vehicles/vehiclePriceUpdate", domainId.value);
        priceLoading.value = false;
      }
      if (skipped) {
        await store.dispatch("vehicles/clearSelectedAdditionalServices");
      }
      router.push({ name: "checkout", query: { step: 1 } });
      scrollToTop();
    };

    const goToSelectedOffer = () => {
      router.push({ name: "selectedOffer" });
    };

    const { trackCheckoutAdditionalServices } = useTrackingPage();

    onMounted(() => {
      trackCheckoutAdditionalServices();
    });

    // fixed buttons
    const { scrolling } = useStickyButtons();

    return {
      localization,
      goToVehicleOffers,
      goToCheckout,
      hasVehicle,
      selectedVehicle,
      selectedServices,
      priceLoading,
      scrolling,
      additionalServices: computed(
        () => store.getters["vehicles/additionalServices"]
      ),
      goToSelectedOffer,
    };
  },
  components: {
    ModelPriceDateVehicle,
    AdditionalItems,
    Icon,
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/views/additionalServices";
</style>

import { useStore } from "vuex";

const useCaptcha = function () {
  const store = useStore();

  store.dispatch("captcha/getCaptcha");

  const refreshCaptcha = () => {
    store.dispatch("captcha/getCaptcha");
  };

  const validate = (id, text) => {
    store.dispatch("captcha/validate", { id, text });
  };

  return { refreshCaptcha, validate };
};

export default useCaptcha;

import { onMounted, onUnmounted } from "vue";

export const useClickEvent = function (param) {
  const clickEvent = (event) => {
    const path = event.composedPath();
    const { exclude, include } = param;

    if (exclude) {
      let open = false;
      Object.values(path).forEach((target) => {
        if (
          target.classList?.length &&
          (target.classList.contains(exclude[0]) ||
            target.classList.contains(exclude[1]))
        ) {
          open = true;
        }
      });

      if (!open) {
        param.fn();
      }
    }
    if (include) {
      let open = true;
      Object.values(path).forEach((target) => {
        if (target.classList?.length && target.classList.contains(include[0])) {
          open = false;
        }
      });

      if (!open) {
        param.fn();
      }
    }
  };

  onMounted(() => {
    document.addEventListener("click", clickEvent);
  });

  onUnmounted(() => {
    document.removeEventListener("click", clickEvent);
  });
};

export default useClickEvent;

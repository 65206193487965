import { computed, reactive } from "vue";
import { useStore } from "vuex";
import {
  getBlockedDates,
  DEFAULT_FORMAT,
  calculateFromDay,
  calculateToDay,
  fromTimeAfterMinFromTime,
} from "@/utilities/date";
import { getTimeFromUrl } from "@/utilities/time";
import { parseBrandsFromUrl, parseModelsFromUrl } from "@/utilities/filter";
import { paramNames, attributeList } from "@/components/Vehicle/constants";

const useDirectSearch = function (props) {
  const store = useStore();
  const domainId = computed(() => store.getters["localization/domainId"]);
  const locations = computed(() => store.getters["search/locations"]);
  const rentalPeriod = reactive({
    fromDate: null,
    fromTime: getTimeFromUrl(props[paramNames.timeFrom]),
    toDate: null,
    toTime: getTimeFromUrl(props[paramNames.timeTo]),
  });
  const minFromTime = computed(() => store.getters["search/minFromTime"]);
  const mainOutlet = computed(
    () => store.getters["mainOutlet/mainOutletStructure"]
  );
  const dealer = !!props[paramNames.partnerId];
  const filters = computed(() => store.getters["vehicles/activeFilters"]);

  const retrieveAndStoreLocations = async () => {
    if (!rentalPeriod.fromTime || !rentalPeriod.toTime) {
      throw new Error("timeFrom or timeTo is invalid");
    }

    const configRadius = computed(() => store.getters["marketConfig/radius"]);

    if (dealer) {
      // For direct search in dealer landing page use outlets according
      // to main outlet structure of given partner ID
      await store.dispatch("mainOutlet/getMainOutletStructure", {
        domainId: domainId.value,
        gsId: props[paramNames.partnerId],
      });

      await store.dispatch("search/updateLocations", mainOutlet.value.outlets);
    } else {
      // For standard search use radius and location to determine
      // list of relevant outlets
      const query = props[paramNames.location];
      await store.dispatch("search/updateQuery", decodeURI(query));
      await store.dispatch("search/getLocation", {
        query,
        radius: props[paramNames.radius] ?? configRadius.value,
        domainId: domainId.value,
      });
    }
  };

  const retrieveAndStoreVehicles = async () => {
    const isoCode = computed(() => store.getters["localization/isoCode"]);

    setFromAndToDate();
    if (
      fromTimeAfterMinFromTime(
        rentalPeriod.fromDate,
        rentalPeriod.fromTime,
        minFromTime.value
      )
    ) {
      setFromTimeToMinFromTime();
    }

    if (!rentalPeriod.fromDate || !rentalPeriod.toDate) {
      throw new Error("from or to is invalid");
    }

    setLocations();
    setDateAndTime();

    if (
      (locations.value && locations.value.length) ||
      (dealer && mainOutlet.value.outlets?.length)
    ) {
      const outlets = dealer
        ? mainOutlet.value.outlets.map((outlet) => ({
            gsId: outlet.outletId,
          }))
        : locations.value.map(({ outlet }) => ({
            gsId: outlet.outletId,
          }));

      const request = {
        domainId: domainId.value,
        outlets,
        rentalPeriod,
        isoCode: isoCode.value,
      };
      await store.dispatch("vehicles/getVehicles", request);
      store.dispatch("search/setSearchRequest", request);
    }
  };

  const retrieveAndStoreClassFilter = async () => {
    const availableModels = computed(() => store.getters["vehicles/models"]);
    const parsedClasses = parseModelsFromUrl(
      JSON.parse(JSON.stringify(availableModels.value)),
      props[paramNames.classFilter]
    );

    if (parsedClasses.length) {
      await store.dispatch("vehicles/addFilter", {
        type: "model",
        models: parsedClasses,
        vehicleTypes: undefined,
      });
      await store.dispatch("vehicles/filterVehicles", filters.value);
    }
  };

  const retrieveAndStoreVehicleTypeFilter = async () => {
    const modelFilter = computed(() =>
      store.getters["vehicles/getFilter"]("model")
    );
    const vehicleTypes = props[paramNames.vehicleTypeFilter].split(",");

    if (vehicleTypes.length) {
      await store.dispatch("vehicles/addFilter", {
        type: "model",
        models: modelFilter.value?.models,
        vehicleTypes,
      });
      await store.dispatch("vehicles/filterVehicles", filters.value);
    }
  };

  const retrieveAndStoreBrandFilter = async () => {
    const attributes = parseBrandsFromUrl(
      attributeList,
      props[paramNames.brandFilter]
    );

    if (attributes.length) {
      await store.dispatch("vehicles/addFilter", {
        type: "attribute",
        attribute: [...attributes],
      });
      await store.dispatch("vehicles/filterVehicles", filters.value);
    }
  };

  const setFromAndToDate = () => {
    const minDate = computed(() => store.getters["search/minDate"]);
    const maxToDate = computed(() => store.getters["search/maxToDate"]);
    const maxFromDate = computed(() => store.getters["search/maxFromDate"]);
    const holidays = computed(() => store.getters["marketConfig/holidays"]);
    const blockedDates = getBlockedDates({
      holidays: [...holidays.value],
      locations: [...locations.value],
      minDate: minDate.value,
      maxDate: maxToDate.value,
      dateFormat: DEFAULT_FORMAT,
    });

    rentalPeriod.fromDate = calculateFromDay(
      props[paramNames.from],
      minDate.value,
      maxFromDate.value,
      minFromTime.value,
      blockedDates
    );
    rentalPeriod.toDate = calculateToDay(
      props[paramNames.to],
      {
        entered: props[paramNames.from],
        calculated: rentalPeriod.fromDate,
      },
      minDate.value,
      maxFromDate.value,
      blockedDates
    );
  };

  const setFromTimeToMinFromTime = () => {
    rentalPeriod.fromTime = minFromTime.value;
  };

  const setLocations = () => {
    store.dispatch("search/addMultipleLocationSelection", locations.value);
  };
  const setDateAndTime = () => {
    setDate();
    setTime();
  };
  const setDate = () => {
    store.dispatch("search/setDate", {
      from: rentalPeriod.fromDate,
      to: rentalPeriod.toDate,
    });
  };
  const setTime = () => {
    store.dispatch("search/updateTimeFrom", rentalPeriod.fromTime);
    store.dispatch("search/updateTimeTo", rentalPeriod.toTime);
  };

  return {
    retrieveAndStoreLocations,
    retrieveAndStoreVehicles,
    retrieveAndStoreClassFilter,
    retrieveAndStoreVehicleTypeFilter,
    retrieveAndStoreBrandFilter,
    domainId,
    search: computed(() => store.getters["localization/search"]),
  };
};

export default useDirectSearch;

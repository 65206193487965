import { computed } from "vue";
import { useStore } from "vuex";

const useLinkTrackingBuilder = function () {
  const store = useStore();
  const generalConfig = computed(() => store.getters["marketConfig/general"]);
  const isoCode = computed(() => store.getters["localization/isoCode"]);

  const buildLinkTracking = (pageName, primaryCategory, linkName) => {
    return {
      page: {
        pageInfo: {
          language: getLanguage(isoCode.value),
          geoRegion: generalConfig.value.countryCode,
        },
        category: { primaryCategory: primaryCategory },
      },
      link: { linkInfo: { name: linkName, pageName: pageName } },
    };
  };

  return { buildLinkTracking };
};

const getLanguage = (isoCode) => {
  const splitIsoCode = isoCode.split(new RegExp("[-]"));
  return splitIsoCode[0];
};

export default useLinkTrackingBuilder;

import useTrackingPage from "@/compositions/useTrackingPage";
import { stepNames } from "@/utilities/checkout";
import { scrollToTop } from "@/utilities/scroll";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const useCheckoutStep = function () {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const activeStep = ref(1);
  const stepsLength = ref(null);
  const lastStep = ref(false);
  const steps = computed(() => store.getters["checkout/steps"]);
  const selectedVehicle = computed(
    () => store.getters["vehicles/selectedVehicle"]
  );
  const locationSet = computed(() => store.getters["search/location"]);
  const useAdditionalServicesView = computed(
    () => store.getters["vehicles/useAdditionalServicesView"]
  );

  const initSteps = () => {
    stepsLength.value = steps.value.length;
    changeCurrentComponent(activeStep.value);
  };

  onMounted(() => {
    initSteps();
  });

  const nextStep = () => {
    if (stepsLength.value > activeStep.value) {
      activeStep.value = activeStep.value + 1;
    }
  };

  const disabled = computed(() => store.getters["checkout/disabled"]);
  const clickableSteps = computed(
    () => store.getters["checkout/clickableSteps"]
  );
  const changeStep = (step) => {
    if (
      step?.number < activeStep.value ||
      (!disabled.value && clickableSteps.value.has(step.number))
    ) {
      activeStep.value = step.number;
    }
  };

  watch(
    () => disabled.value,
    (isDisabled) => {
      if (isDisabled) {
        store.dispatch("checkout/removeClickableStep", activeStep.value + 1);
      } else {
        store.dispatch("checkout/setClickableStep", activeStep.value + 1);
      }
    }
  );

  // if browser back click, handle activeStep change
  watch(
    () => route.query.step,
    (changedStep) => {
      activeStep.value = parseInt(changedStep);
    }
  );
  const {
    trackCheckoutContactData,
    trackCheckoutWishesAndConditions,
    trackCheckoutAdditionalInfo,
    trackCheckoutSummary,
  } = useTrackingPage();

  const activeComponent = ref(null);

  const changeCurrentComponent = (currentStep) => {
    const { personalData, rentalData, summary } = stepNames;
    switch (currentStep) {
      case 1:
        store.dispatch("checkout/setLoading", true);
        activeComponent.value = personalData;
        trackCheckoutContactData();
        break;
      case 2:
        store.dispatch("checkout/setLoading", true);
        activeComponent.value = rentalData;
        trackCheckoutAdditionalInfo();
        trackCheckoutWishesAndConditions();
        break;
      case 3:
        activeComponent.value = summary;
        trackCheckoutSummary();
        break;
      default:
        break;
    }
    router.push({ name: "checkout", query: { step: currentStep } });
  };

  const scrollToBreadcrumb = () => {
    setTimeout(() => {
      document.getElementById("breadcrumb").scrollIntoView({
        behavior: "smooth",
      });
    }, 1000);
  };

  const scrollToModelPrice = () => {
    setTimeout(() => {
      document.getElementById("modelPrice").scrollIntoView({
        behavior: "smooth",
      });
    }, 1000);
  };

  const voucherEnabled = computed(
    () => store.getters["marketConfig/useVoucher"]
  );

  const validityStatus = computed(
    () => store.getters["vehicles/voucherStatusCode"]
  );

  watch(
    () => activeStep.value,
    (currentStep) => {
      if (route.name === "checkout") {
        changeCurrentComponent(currentStep);
        if (voucherEnabled.value && validityStatus.value == 1) {
          scrollToModelPrice();
        } else {
          scrollToBreadcrumb();
        }

        if (stepsLength.value === currentStep) {
          lastStep.value = true;
        } else {
          lastStep.value = false;
        }
      }
    }
  );

  const back = () => {
    if (activeStep.value === 1) {
      if (useAdditionalServicesView.value) {
        store.dispatch("vehicles/clearSelectedAdditionalServices");
        router.push({ name: "additionalServices" });
      } else {
        router.push({ name: "selectedOffer" });
      }
      store.dispatch("vehicles/resetVoucherStatusCode");
      store.dispatch("checkout/resetForm");
      scrollToTop();
      return;
    }
    if (lastStep.value) {
      router.push({ name: "vehicles" });
      store.dispatch("vehicles/resetVoucherStatusCode");
      store.dispatch("checkout/resetForm");
      scrollToTop();
      return;
    }
    if (activeStep.value > 1) {
      activeStep.value = activeStep.value - 1;
    }
  };

  const getLocation = (locationList, vehicle) =>
    locationList.find(
      (curLocation) => curLocation?.outlet?.outletId === vehicle.outletId
    );

  const getSelectedLocation = () => {
    return getLocation(locationSet.value.locations, selectedVehicle.value);
  };

  return {
    activeStep,
    nextStep,
    changeStep,
    activeComponent,
    back,
    lastStep,
    getSelectedLocation,
  };
};

export default useCheckoutStep;

<template>
  <div class="selected-offer-accordion">
    <wb-accordion prevent-mobile-flyout>
      <wb-accordion-item
        open
        v-for="service in additionalServices"
        :key="service.categoryName"
      >
        <wb-accordion-toggle>
          <h3 class="wb-type-copy-strong">
            {{ service.categoryName }}
          </h3>
        </wb-accordion-toggle>
        <wb-accordion-section>
          <wb-accordion-mobile-header>Close</wb-accordion-mobile-header>
          <wb-accordion-content>
            <DropDownCollection
              v-if="service.displayType === 'DROPDOWN'"
              :service="service"
              :isoCode="isoCode"
              :currencyCode="currencyCode"
              :numberOfDays="numberOfDays"
              :pricingDetails="pricingDetails"
              :localization="checkoutPageLocalization"
              @selectedItemsChanged="addService"
            />
            <RadioButtons
              v-if="service.displayType === 'RADIOBUTTON'"
              :service="service"
              :isoCode="isoCode"
              :currencyCode="currencyCode"
              :numberOfDays="numberOfDays"
              :localization="checkoutPageLocalization"
              :pricingDetails="pricingDetails"
              @selectedItemChanged="addService"
            />
            <CheckBox
              v-if="service.displayType === 'CHECKBOX'"
              :service="service"
              :isoCode="isoCode"
              :currencyCode="currencyCode"
              :numberOfDays="numberOfDays"
              :pricingDetails="pricingDetails"
              :localization="checkoutPageLocalization"
              @selectedItemsChanged="addService"
            />
          </wb-accordion-content>
        </wb-accordion-section>
      </wb-accordion-item>
    </wb-accordion>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

import DropDownCollection from "@/components/AdditionalServices/FieldTypes/DropDownCollection.vue";
import RadioButtons from "@/components/AdditionalServices/FieldTypes/RadioButtons.vue";
import CheckBox from "@/components/AdditionalServices/FieldTypes/CheckBox.vue";

export default {
  props: {
    additionalServices: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const isoCode = computed(() => store.getters["localization/isoCode"]);
    const domainId = computed(() => store.getters["localization/domainId"]);
    const checkoutPageLocalization = computed(
      () => store.getters["localization/checkoutPage"]
    );

    const currencyCode = computed(() => store.getters["vehicles/currency"]);
    const numberOfDays = computed(
      () =>
        store.getters["vehicles/selectedVehicle"].pricingDetails.numberOfDays
    );
    const addService = (categoryName, additionalServiceObject) => {
      store.dispatch("vehicles/updateSelectedAdditionalServices", {
        categoryName: categoryName,
        serviceWrapper: additionalServiceObject,
      });

      store.dispatch("vehicles/vehiclePriceUpdate", domainId.value);
    };
    const pricingDetails = computed(
      () => store.getters["vehicles/pricingDetailsOfSelectedVehicle"]
    );
    return {
      addService,
      isoCode,
      currencyCode,
      numberOfDays,
      checkoutPageLocalization,
      pricingDetails,
    };
  },
  components: { DropDownCollection, RadioButtons, CheckBox },
};
</script>

import { computed } from "vue";
import { useStore } from "vuex";
import useLinkTrackingBuilder from "./tracking/useLinkTrackingBuilder";

const useTrackingLink = function () {
  const store = useStore();
  const tsLoaded = computed(
    () => store.getters["marketConfig/trackingScriptLoaded"]
  );
  const { buildLinkTracking } = useLinkTrackingBuilder();

  const fillLinkDataAndTrack = (newDigitalData) => {
    if (tsLoaded.value) {
      digitalData = newDigitalData; // update global variable that is defined in index.html
      trackEvent("mbrent_link_click");
    }
  };

  const trackEvent = (eventName) => {
    // uncomment for debugging and tracking-bootcamp
    //console.log("page: ", digitalData.link.linkInfo.pageName);
    //console.log("digitalData: ", digitalData);
    _satellite.track(eventName);
  };

  const trackNavOpenMap = () => {
    const digitalData = buildLinkTracking(
      "search: partner",
      "search",
      "nav: open map"
    );
    fillLinkDataAndTrack(digitalData);
  };

  const trackNavModel = () => {
    const digitalData = buildLinkTracking("home: start", "home", "nav: model");
    fillLinkDataAndTrack(digitalData);
  };

  const trackSortPrice = () => {
    const digitalData = buildLinkTracking(
      "result: overview",
      "result",
      "sort: price"
    );
    fillLinkDataAndTrack(digitalData);
  };

  const trackSortDistance = () => {
    const digitalData = buildLinkTracking(
      "result: overview",
      "result",
      "sort: distance"
    );
    fillLinkDataAndTrack(digitalData);
  };

  const trackInfoItemEquipment = () => {
    const digitalData = buildLinkTracking(
      "product: vehicle details",
      "product",
      "info icon: equipment"
    );
    fillLinkDataAndTrack(digitalData);
  };

  const trackInfoItemCostSharing = () => {
    const digitalData = buildLinkTracking(
      "product: vehicle details",
      "product",
      "info icon: cost sharing"
    );
    fillLinkDataAndTrack(digitalData);
  };

  const trackInfoItemDeposit = () => {
    const digitalData = buildLinkTracking(
      "product: vehicle details",
      "product",
      "info icon: deposit"
    );
    fillLinkDataAndTrack(digitalData);
  };

  const trackInfoItemPromotionBox = () => {
    const digitalData = buildLinkTracking(
      "product: vehicle details",
      "product",
      "info icon: promotion box"
    );
    fillLinkDataAndTrack(digitalData);
  };

  const trackOpenShareLink = () => {
    const digitalData = buildLinkTracking(
      "result: overview",
      "result",
      "result: open sharelink"
    );
    fillLinkDataAndTrack(digitalData);
  };

  const trackCopyShareLink = () => {
    const digitalData = buildLinkTracking(
      "result: overview",
      "result",
      "result: copy sharelink"
    );
    fillLinkDataAndTrack(digitalData);
  };

  return {
    trackNavOpenMap,
    trackNavModel,
    trackSortPrice,
    trackSortDistance,
    trackInfoItemEquipment,
    trackInfoItemCostSharing,
    trackInfoItemDeposit,
    trackInfoItemPromotionBox,
    trackOpenShareLink,
    trackCopyShareLink,
  };
};

export default useTrackingLink;

<template>
  <LoadingScreen v-if="!localization?.faq" />
  <MainTeaser
    v-else
    :imageUrl="imageUrl"
    :headline="localization?.faq?.primaryHeader"
    :contentBottom="true"
  />
  <section class="content-width content-width--no-padding">
    <div v-if="isMobile" class="faq-accordion" theme="dark">
      <wb-accordion
        v-for="item in localization?.faq?.items"
        :key="item.header"
        prevent-mobile-flyout
        theme="dark"
      >
        <wb-accordion-item>
          <wb-accordion-toggle>
            <h2>{{ item.header }}</h2>
          </wb-accordion-toggle>
          <wb-accordion-section>
            <wb-accordion-mobile-header>Close</wb-accordion-mobile-header>
            <wb-accordion-content>
              <div class="richtext" v-html="item.content" />
            </wb-accordion-content>
          </wb-accordion-section>
        </wb-accordion-item>
      </wb-accordion>
    </div>

    <div v-else theme="dark">
      <div
        :class="{
          fixed: imageNotVisible,
        }"
        class="faq-list__links"
      >
        <div
          v-for="(item, index) in localization?.faq?.items"
          :key="item.header"
          :class="{ linkBorder: isItemSelected(index) }"
          class="faq-list__link"
          @click="jumpTo(index)"
        >
          {{ item.header }}
        </div>
      </div>
      <div class="faq-list__items">
        <div
          v-for="(item, index) in localization?.faq?.items"
          :key="item.header"
        >
          <div v-show="isItemSelected(index)">
            <h2 class="faq-list__itemHeader">{{ item.header }}</h2>
            <div class="faq-list__itemContent richtext" v-html="item.content" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import useImage from "@/compositions/useImage";
import { ViewportObserver } from "@workbench/core";
import LoadingScreen from "@/components/LoadingScreen";
import useTrackingPage from "@/compositions/useTrackingPage";
import MainTeaser from "@/components/ContentPage/MainTeaser";

export default {
  name: "FAQ",
  setup() {
    const store = useStore();
    const isMobile = computed(() => store.getters["localization/isMobile"]);
    const imageNotVisible = ref(false);
    const imageObserver = ref(null);
    const itemSelected = ref(0);

    const { getImageUrl } = useImage();

    const activeLanguage = computed(
      () => store.getters["localization/activeLanguage"]
    );
    const tsLoaded = computed(
      () => store.getters["marketConfig/trackingScriptLoaded"]
    );

    const imageUrl = computed(() => {
      let image = "";

      if (isMobile.value) {
        image = getImageUrl(
          localization.value?.faq?.headerImageIdMobile,
          false
        );
      } else {
        image = getImageUrl(
          localization.value?.faq?.headerImageIdDesktop,
          false
        );
      }

      return image;
    });

    const localization = computed(
      () => store.getters["localization/localization"]
    );

    const setImageIsVisible = () => {
      imageNotVisible.value = false;
    };
    const setImageIsNotVisible = () => {
      imageNotVisible.value = true;
    };

    const headerImageRef = ref(null);
    const observeHeaderImage = () => {
      if (headerImageRef.value) {
        imageObserver.value = new ViewportObserver({
          element: headerImageRef.value,
          viewportEnterFn: () => setImageIsVisible(),
          viewportLeaveFn: () => setImageIsNotVisible(),
        });
      }
    };

    const { trackContentFaq } = useTrackingPage();

    watch(
      () => [activeLanguage.value, tsLoaded.value],
      ([language, loaded]) => {
        if (language?.isoCode && loaded) {
          trackContentFaq();
        }
      }
    );

    onMounted(() => {
      observeHeaderImage();

      if (activeLanguage.value.isoCode && tsLoaded.value) {
        trackContentFaq();
      }
    });

    const jumpTo = (item) => {
      itemSelected.value = item;
    };

    const isItemSelected = (item) => {
      return item === itemSelected.value;
    };

    onUnmounted(() => {
      if (imageObserver.value) {
        imageObserver.value.disconnect();
      }
    });

    return {
      localization,
      imageUrl,
      isMobile,
      imageNotVisible,
      jumpTo,
      isItemSelected,
      headerImageRef,
    };
  },
  components: {
    LoadingScreen,
    MainTeaser,
  },
};
</script>
<style lang="scss">
@import "@/styles/components/faq";
</style>

<template>
  <div class="model-price-date">
    <div class="model-price-date__model-container">
      <h1 class="model-price-date__model wb-type-heading-m">
        {{ vehicle.name }}
      </h1>
      <p class="model-price-date__vehicleType wb-type-heading-xs">
        {{ vehicle.modelType?.vehicleTypeSingular }}
      </p>
    </div>
    <div class="model-price-date-price-container">
      <div
        class="model-price-date__price wb-type-heading-xs"
        v-if="vehicle.pricingDetails?.validityStatusCode === 1"
      >
        <p class="model-price-date__standard-price">
          <span> {{ priceLabel() }}:</span>
          <span class="model-price-date__price__wrapper">
            <span
              class="wb-type-copy-secondary model-price-date__price__pricetag"
            >
              <RentalPeriodDisplay
                :localization="localization"
                :vehicle="vehicle"
              />
            </span>
            <span class="model-price-date__price__pricetag-value">
              /
              <del>
                {{ priceTagReducedTotal }}
              </del>
            </span>
          </span>
        </p>
        <p class="model-price-date__voucher-price wb-type-copy-tertiary">
          <span class="model-price-date__voucher-price__text">{{
            localization.checkoutPage?.voucherReducedPrice
          }}</span
          ><span class="wb-type-heading-xs">{{ priceTagTotalGross }}</span>
        </p>
      </div>
      <p class="model-price-date__price wb-type-heading-xs" v-else>
        <span> {{ priceLabel() }}:</span>
        <span class="model-price-date__price__wrapper">
          <span
            class="wb-type-copy-secondary model-price-date__price__pricetag"
          >
            <RentalPeriodDisplay
              :localization="localization"
              :vehicle="vehicle"
            />
          </span>
          <span class="model-price-date__price__pricetag-value">
            / {{ priceTagTotalGross }}
          </span>
        </span>
      </p>
    </div>
    <div
      class="model-price-date__additional-services wb-type-copy-tertiary"
      v-if="showAdditionalServicesText || hasAdditionalServicesAfterPayment"
    >
      {{ localization.checkoutPage?.additionalServicesHeadline }}:
      <span v-if="showAdditionalServicesText">{{ additionalServices }}</span>
      <span v-else>
        {{ localization?.resultPage?.overview?.additionalServicesBooked }}
      </span>
    </div>
    <div class="model-price-date__date">
      <DateTimeFromTo :localization="localization" />
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { getPriceTag } from "@/utilities/price";
import DateTimeFromTo from "@/components/DateTimeFromTo";
import RentalPeriodDisplay from "@/components/RentalPeriodDisplay";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "ModelPriceDate",
  props: ["localization", "vehicle", "additionalServices"],
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const isoCode = computed(() => store.getters["localization/isoCode"]);

    const priceTagTotalGross = computed(() => {
      const { pricingDetails } = props.vehicle;
      return getPriceTag(
        !isNaN(pricingDetails?.totalAmount)
          ? pricingDetails?.totalAmount
          : pricingDetails?.totalGrossPrice,
        pricingDetails?.currencyCode,
        isoCode.value
      );
    });

    const priceTagReducedTotal = computed(() => {
      const { pricingDetails } = props.vehicle;
      return getPriceTag(
        pricingDetails?.totalAmountBeforeDeductions,
        pricingDetails?.currencyCode,
        isoCode.value
      );
    });

    let showAdditionalServicesText = false;
    if (props["additionalServices"] && props["additionalServices"].length > 0) {
      showAdditionalServicesText = true;
    }

    const priceLabel = () => {
      return props.localization.checkoutPage?.totalGrossPrice;
    };

    return {
      priceTagTotalGross,
      priceTagReducedTotal,
      priceLabel,
      showAdditionalServicesText,
      hasAdditionalServicesAfterPayment: computed(
        () => store.getters["paymentRedirect/hasAdditionalServicesCategories"]
      ),
    };
  },
  components: { DateTimeFromTo, RentalPeriodDisplay },
};
</script>
<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.model-price-date {
  background-color: var(--wb-white);

  &__standard-price {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__voucher-price {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 20px 0 0 0;
    color: var(--wb-green-45) !important;

    &__text {
      max-width: 60%;
    }
  }

  &__price {
    background-color: var(--wb-grey-85);
    padding: 13px 8% 13px 8%;
    width: max-content;
    @include breakpoint-from(mq3) {
      margin: 25px 0 25px 0;
      padding: 13px 8% 13px 8%;
    }
    margin: 15px 0;
    line-height: 18px !important;

    &__wrapper {
      padding-left: 8px;
    }

    &__pricetag {
      line-height: 18px !important;
      font-size: 18px !important;
    }

    &__pricetag-value {
      white-space: nowrap;
    }
  }

  &__date,
  &__additional-services {
    padding-left: 8%;
    @include breakpoint-from(mq3) {
      padding-left: 0;
    }
  }

  &__model-container {
    padding-left: 8%;
    @include breakpoint-from(mq3) {
      padding-left: 0;
    }
  }

  &__vehicleType {
    padding-bottom: 8px;
    line-height: 18px !important;
  }

  &__additional-services {
    color: var(--wb-grey-20) !important;
    font-weight: bold !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    padding-bottom: 10px !important;
  }
}
</style>

<template>
  <wb-datepicker-control
    :locale="locale"
    previous-month-button-label="Précédent"
    next-month-button-label="Prochain"
    :maxDate="maxDate"
    :activeYear="activeDate.year"
    :activeMonth="activeDate.month"
  >
    <wb-input-control>
      <wb-input>
        <label>
          {{ name }}
          <span v-if="required">*</span>
        </label>
        <input
          type="date"
          @input="
            $emit('update:modelValue', $event.target.value);
            $emit('validateInput');
          "
          :value="modelValue"
          :required="required"
        />
        <wb-icon
          class="icon hydrated"
          :class="fieldState.class"
          :name="fieldState.name"
          size="24"
        />
      </wb-input>
    </wb-input-control>
  </wb-datepicker-control>
</template>

<script setup>
import { computed, defineProps, reactive, watch } from "vue";

const props = defineProps({
  locale: {
    type: String,
    default: "de-DE",
  },
  name: {
    type: String,
    required: true,
  },
  modelValue: {
    type: String,
    default: "",
  },
  error: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
});

const fieldState = reactive({
  class: "",
  name: "",
});

const activeDate = reactive({
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
});

const maxDate = computed(() => {
  return new Date().toISOString().split("T")[0];
});

watch(
  () => props.error,
  () => {
    if (props.error) {
      fieldState.class = "icon--error";
      fieldState.name = "alert-circle";
    }

    if (!props.error) {
      fieldState.class = "icon--success";
      fieldState.name = "checkmark-circle";
    }
  }
);

watch(
  () => props.modelValue,
  () => {
    const currentDate = props.modelValue;
    const month = currentDate?.split("-")[1] ?? new Date().getMonth();
    const year = currentDate?.split("-")[0] ?? new Date().getFullYear();

    activeDate.month = parseInt(month - 1 ?? new Date().getMonth());
    activeDate.year = year ?? new Date().getFullYear();
  }
);
</script>

<style lang="scss" scoped>
.icon {
  margin: 0 45px;
  &--success {
    color: var(--success-color);
  }
  &--error {
    color: var(--error-color);
  }
}
.datepicker {
  padding: 10px 0;

  &__input {
    border: 1px solid var(--border-color);
  }

  wb-input-control[readonly] > wb-input {
    border-color: var(--wb-grey-70);
  }

  wb-input[status="success"] .wb-input__trailing-icon {
    color: var(--success-color);
  }

  wb-input[status="error"] .wb-input__trailing-icon {
    color: var(--error-color);
  }

  wb-datepicker-control wb-input[name="bds/checkmark-circle/24"] {
    color: var(--success-color);
  }
}
</style>

<template>
  <div class="filter-items">
    <p class="filter-items__label wb-type-copy-strong">
      {{ filterName(name, localization?.filter) }}
    </p>

    <div class="filter-items__tags">
      <TagItem
        v-for="seat in seats"
        :key="seat"
        :tag="seat"
        :active="activeSeat(seat)"
        @toggle="toggle($event)"
      >
        {{ seatName(seat) }}
      </TagItem>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { filterName } from "@/utilities/filter";
import TagItem from "@/components/Vehicle/Filter/TagItem";

export default {
  name: "seats",
  props: ["localization", "name"],
  setup(props) {
    const store = useStore();
    const seatsFilter = computed(() =>
      store.getters["vehicles/getFilter"]("seats")
    );

    const seatName = (seat) => {
      switch (true) {
        case seat === 4:
          return props.localization?.filter["four-seats"];
        case seat === 5:
          return props.localization?.filter["five-seats"];
        case seat > 5:
          return props.localization?.filter["five-plus-seats"];
        default:
          return `${seat} - ${props.localization?.filter["seats"]}`;
      }
    };

    const activeSeat = (seat) => {
      if (seatsFilter.value?.seats) {
        return seatsFilter.value.seats.includes(seat);
      }
      return false;
    };

    const toggle = (event) => {
      const { tag, mode } = event;

      let newSeats = [];
      if (seatsFilter.value?.seats) {
        newSeats = [...seatsFilter.value?.seats];
      }

      if (mode === "add") {
        newSeats.push(tag);
      } else {
        newSeats = newSeats.filter((item) => item !== tag);
      }

      if (newSeats.length) {
        store.dispatch("vehicles/addFilter", {
          type: "seats",
          seats: newSeats,
        });
      } else {
        store.dispatch("vehicles/removeFilter", "seats");
      }
    };

    return {
      filterName,
      seatName,
      activeSeat,
      seats: computed(() => store.getters["vehicles/seats"]),
      toggle,
    };
  },
  components: {
    TagItem,
  },
};
</script>

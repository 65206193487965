<template>
  <div class="vehicle-offer-list">
    <div>
      <ShareLink
        v-if="showShareLink"
        :headline="localization?.resultPage?.shareLink.headline"
        :subheadline="localization?.resultPage?.shareLink.subheadline"
        :shareButton="localization?.resultPage?.shareLink.shareButton"
        :cancelButton="localization?.resultPage?.shareLink.cancelButton"
        :copiedNotification="
          localization?.resultPage?.shareLink.copiedNotification
        "
        @close="showShareLink = false"
      />
    </div>
    <div class="vehicle-offer-list__head">
      <h1 class="vehicle-offer-list__title wb-type-heading-m">
        {{ vehiclesCount }}
        {{ localization?.resultPage?.overview?.offersTitle }}
      </h1>
    </div>

    <div v-if="isModelSelection" class="vehicle-offer-list__item">
      <p
        v-if="matchingVehicles?.length > 0"
        class="vehicle-offer-list__description wb-type-copy"
      >
        {{ localization?.resultPage?.overview?.offersText }}:
        <span>
          {{ matchingVehicles?.length }}
          {{ matchingVehicles[0].modelType.modelClassName }}
          {{
            matchingVehicles?.length === 1
              ? matchingVehicles[0].modelType.vehicleTypeSingular
              : matchingVehicles[0].modelType.vehicleTypePlural
          }}
        </span>
      </p>
      <div class="vehicle-offer-list__slider-wrapper">
        <wb-slider
          class="vehicle-offer-list__slider"
          theme="dark"
          id="slider-matching"
          show-arrow-navigation
          show-dot-navigation
          @mousedown="mouseDown($event, 'slider-matching')"
          @mouseup="mouseUp"
          @mousemove="mouseMove($event)"
        >
          <VehicleOffer
            v-for="vehicle in matchingVehicles"
            :key="vehicle.vehicleId"
            :vehicle="vehicle"
            :localization="localization"
            :isModelSelection="isModelSelection"
          />
        </wb-slider>
      </div>
    </div>
    <div id="jsVehicleTitle" />

    <div class="vehicle-offer-list__actions" :class="{ fixed: scrolling }">
      <div class="vehicle-offer-list__actions__middle">
        <div class="vehicle-offer-list__actions__inner">
          <div
            class="vehicle-offer-list__actions__inner__left"
            :class="{ big: filterModalIsOpen }"
          >
            <Filter
              v-if="activeFilters?.length"
              :localization="localization?.resultPage"
              @open="setOpen"
            />
            <div
              class="vehicle-offer-list__actions__inner__left__share-link wb-type-copy"
            >
              <span
                class="vehicle-offer-list__actions__inner__left__share-link__text"
              >
                {{ localization?.resultPage?.shareLink.openShareLink }}
              </span>
              <button
                type="button"
                class="share__button wb-round-button"
                @click="openShareLink"
              >
                <Icon
                  name="copy"
                  :size="20"
                  class="share__icon wb-round-button__icon share-link__share"
                />
              </button>
            </div>
          </div>
          <div class="vehicle-offer-list__actions__inner__right">
            <Sort :localization="localization?.resultPage?.overview" />
          </div>
        </div>
      </div>
    </div>
    <div style="height: 66px" v-if="scrolling">
      <!-- 66px equals height of actions menu, this div replaces the actions menu in the DOM so no jumping occurs -->
    </div>
    <div v-if="isModelSelection" class="vehicle-offer-list__head addMarginTop">
      <h1 class="vehicle-offer-list__title wb-type-heading-m">
        {{ additionalVehiclesText }}
      </h1>
    </div>

    <div
      class="vehicle-offer-list__item"
      :class="{
        invisible: false, // Changed for VBS-22506: counter > activeVehiclesCount,
      }"
      v-for="(groupedVehicles, i, counter) in vehicles"
      :key="`${i}-${groupedVehicles?.length}`"
    >
      <p
        v-if="
          true // Changed for VBS-22506:  counter < activeVehiclesCount
        "
        class="vehicle-offer-list__description wb-type-copy"
      >
        {{ localization?.resultPage?.overview?.offersText }}:
        <span>
          {{ groupedVehicles?.length }}
          {{
            groupedVehicles?.length === 1
              ? groupedVehicles[0].modelType.vehicleTypeSingular
              : groupName(i)
          }}
        </span>
      </p>
      <div class="vehicle-offer-list__slider-wrapper">
        <wb-slider
          v-if="
            true // Changed for VBS-22506: counter < activeVehiclesCount
          "
          class="vehicle-offer-list__slider"
          theme="dark"
          :key="i"
          :id="`slider-${counter}`"
          show-arrow-navigation
          show-dot-navigation
          @mousedown="mouseDown($event, `slider-${counter}`)"
          @mouseup="mouseUp"
          @mousemove="mouseMove($event)"
        >
          <VehicleOffer
            v-for="vehicle in groupedVehicles"
            :key="vehicle.vehicleId"
            :vehicle="vehicle"
            :localization="localization"
            :isModelSelection="isModelSelection"
          />
        </wb-slider>
      </div>
    </div>
    <!-- Changed for VBS-22506: counter < activeVehiclesCount
    <div
      @click="increaseCounter()"
      class="vehicle-offer-list__view-more-container"
      v-if="moreModelTypesAvailable"
    >
      <div class="vehicle-offer-list__view-more">
        <div class="vehicle-offer-list__view-more__text wb-type-copy-strong">
          {{ localization?.resultPage?.overview?.viewMore }}
        </div>
        <Icon
          name="chevron-down"
          :size="12"
          :width="22"
          :height="22"
          class="vehicle-offer-list__view-more__icon"
        />
      </div>
    </div>
    -->
    <div
      v-if="!Object.keys(vehicles).length"
      class="content-width vehicle-offer-list__no-results wb-type-copy"
    >
      <p>{{ localization?.resultPage?.filter?.noResults }}</p>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import Sort from "@/components/Vehicle/Sort";
import Filter from "@/components/Vehicle/Filter/Filter";
import VehicleOffer from "@/components/Vehicle/VehicleOffer";
import useActionsBarPosition from "@/compositions/useActionsBarPosition";
import Icon from "@/components/Icon";
import useDragContentSlider from "@/compositions/useDragContentSlider";
import useTrackingLink from "@/compositions/useTrackingLink";
import ShareLink from "@/components/ShareLink";

export default {
  name: "VehicleOfferList",
  props: {
    localization: Object,
    vehicles: Object,
    isModelSelection: {
      type: Boolean,
      default: false,
    },
    matchingVehicles: {
      type: Array,
      default: Array.from([]),
    },
  },
  components: {
    Sort,
    VehicleOffer,
    Filter,
    Icon,
    ShareLink,
  },
  setup(props) {
    const store = useStore();
    const activeVehiclesCount = ref(3);
    const moreModelTypesAvailable = ref(true);
    const showShareLink = ref(false);
    const localization = computed(() => props["localization"]);
    const checkIfMoreModelsAvailable = () => {
      if (activeVehiclesCount.value >= Object.keys(props.vehicles).length) {
        moreModelTypesAvailable.value = false;
      }
    };
    checkIfMoreModelsAvailable();

    const groupName = (group) => {
      const groupNames = Object.keys(props.vehicles);
      return groupNames.find((name) => name === group);
    };

    const filteredVehicles = computed(
      () => store.getters["vehicles/filteredVehicles"]
    );
    const vehiclesCount = computed(() => {
      if (props.isModelSelection) {
        return props.matchingVehicles.length;
      } else {
        return filteredVehicles.value?.length;
      }
    });

    const { scrolling } = useActionsBarPosition();

    const increaseCounter = () => {
      activeVehiclesCount.value = activeVehiclesCount.value + 1;
      checkIfMoreModelsAvailable();
    };

    // set the number of additional vehicles in header string
    const additionalVehiclesText = computed(() =>
      localization.value.resultPage?.overview?.moreVehicles.replace(
        "{0}",
        store.getters["vehicles/filteredVehicles"]?.length
      )
    );

    watch(
      () => props.vehicles,
      () => {
        moreModelTypesAvailable.value = true;
        checkIfMoreModelsAvailable();
      }
    );

    const filterModalIsOpen = ref(false);
    const setOpen = (open) => {
      filterModalIsOpen.value = open;
    };

    const { trackOpenShareLink } = useTrackingLink();

    const openShareLink = () => {
      trackOpenShareLink();
      showShareLink.value = true;
    };

    const { mouseDown, mouseMove, mouseUp } = useDragContentSlider();

    return {
      openShareLink,
      showShareLink,
      moreModelTypesAvailable,
      increaseCounter,
      activeVehiclesCount,
      groupName,
      vehiclesCount,
      scrolling,
      mouseDown,
      mouseUp,
      mouseMove,
      additionalVehiclesText,
      activeFilters: computed(
        () => store.getters["marketConfig/activeFilters"]
      ),
      filterModalIsOpen,
      setOpen,
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/components/vehicleOfferList";
</style>

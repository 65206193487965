<template>
  <div class="filter-items">
    <p class="filter-items__label wb-type-copy-strong">
      {{ filterName(name, localization?.filter) }}
    </p>
    <div class="filter-items__tags">
      <TagItem
        v-for="activeFilter in activeEquipmentsFilters"
        :key="activeFilter"
        :tag="activeFilter"
        :active="activeEquipment(activeFilter)"
        @toggle="toggle($event)"
      >
        {{ equipmentName(activeFilter) }}
      </TagItem>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { filterName } from "@/utilities/filter";
import TagItem from "@/components/Vehicle/Filter/TagItem";

export default {
  name: "equipment",
  props: ["localization", "name"],
  setup(props) {
    const store = useStore();
    const equipmentFilter = computed(() =>
      store.getters["vehicles/getFilter"]("equipment")
    );
    const activeEquipmentsFilters = computed(
      () => store.getters["marketConfig/activeEquipmentsFilters"]
    );

    const activeEquipment = (equipment) => {
      if (equipmentFilter.value?.equipments) {
        return equipmentFilter.value.equipments.includes(equipment);
      }
      return false;
    };

    const equipmentName = (activeFilter) => {
      switch (activeFilter) {
        case "allWheel":
          return props.localization?.filter["allWheelDrive"] ?? activeFilter;
        case "heatedSeats":
          return props.localization?.filter["seatHeating"] ?? activeFilter;
        default:
          return props.localization?.filter[activeFilter] ?? activeFilter;
      }
    };

    const toggle = (event) => {
      const { tag, mode } = event;

      let newEquipments = [];
      if (equipmentFilter.value?.equipments) {
        newEquipments = [...equipmentFilter.value?.equipments];
      }

      if (mode === "add") {
        newEquipments.push(tag);
      } else {
        newEquipments = newEquipments.filter((item) => item !== tag);
      }

      if (newEquipments.length) {
        store.dispatch("vehicles/addFilter", {
          type: "equipment",
          equipments: newEquipments,
        });
      } else {
        store.dispatch("vehicles/removeFilter", "equipment");
      }
    };

    return {
      filterName,
      equipmentName,
      equipments: computed(() => store.getters["vehicles/equipments"]),
      activeEquipment,
      toggle,
      activeEquipmentsFilters,
    };
  },
  components: {
    TagItem,
  },
};
</script>

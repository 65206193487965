import { checkoutFields } from "./checkout";

export function createVehicleFromPaymentRedirect(reservationDetails) {
  return {
    vehicleId: reservationDetails.reservationId,
    vehicleVin: null,
    isContractualPartner: null,
    outletId: reservationDetails.outlet.outletId,
    rentalPlatform: null,
    name: reservationDetails.modelLongName,
    modelType: {
      modelClassName: reservationDetails.modelLongName,
      vehicleTypeSingular: reservationDetails.modelLongName,
      modelTypeId: reservationDetails.modelTypeId,
    },
    minAge: reservationDetails.vehicleData.minAge,
    enginePowerKw: reservationDetails.vehicleData.enginePowerKw,
    enginePowerPs: reservationDetails.vehicleData.enginePowerPs,
    fuelType: reservationDetails.vehicleData.fuelType,
    minLicenceHoldingPeriodYears:
      reservationDetails.vehicleData.minLicenceHoldingPeriodYears,
    seats: null,
    mandantId: null,
    orderType: null,
    registrationDate: reservationDetails.vehicleData.registrationDate,
    pricingDetails: reservationDetails.pricingDetails,
    equipments: reservationDetails.vehicleData.equipments,
    vehicleImages: { exterior20ImageUrl: reservationDetails.vehicleImageURL },
  };
}

export function createCheckoutFieldsFromPaymentRedirect(
  customerData,
  activeFields
) {
  const result = [];
  activeFields.filter((field) => {
    if (field["checkoutActive"] && field.type === checkoutFields.firstName) {
      result.push({
        type: checkoutFields.firstName,
        value: customerData.firstName,
      });
    }
    if (field["checkoutActive"] && field.type === checkoutFields.lastName) {
      result.push({
        type: checkoutFields.lastName,
        value: customerData.lastName,
      });
    }
    if (field["checkoutActive"] && field.type === checkoutFields.profileType) {
      result.push({
        type: checkoutFields.profileType,
        value:
          customerData.profileType == 2
            ? "PROFILE_TYPE_VALUE_PRIVAT"
            : "PROFILE_TYPE_VALUE_BUSINESS",
      });
    }
    if (field["checkoutActive"] && field.type === checkoutFields.company) {
      result.push({
        type: checkoutFields.company,
        value: customerData.company,
      });
    }
    if (field["checkoutActive"] && field.type === checkoutFields.taxNumber) {
      result.push({
        type: checkoutFields.taxNumber,
        value: customerData.taxNumber,
      });
    }
    if (field["checkoutActive"] && field.type === checkoutFields.salutation) {
      result.push({
        type: checkoutFields.salutation,
        value: customerData.salutation,
      });
    }
    if (field["checkoutActive"] && field.type === checkoutFields.email) {
      result.push({
        type: checkoutFields.email,
        value: customerData.email,
      });
    }
    if (field["checkoutActive"] && field.type === checkoutFields.phoneNumber) {
      result.push({
        type: checkoutFields.phoneNumber,
        value: customerData.phoneNumber,
      });
    }
    if (field["checkoutActive"] && field.type === checkoutFields.street) {
      result.push({
        type: checkoutFields.street,
        value: customerData.street,
      });
    }
    if (field["checkoutActive"] && field.type === checkoutFields.houseNumber) {
      result.push({
        type: checkoutFields.houseNumber,
        value: customerData.houseNumber,
      });
    }
    if (field["checkoutActive"] && field.type === checkoutFields.city) {
      result.push({
        type: checkoutFields.city,
        value: customerData.city,
      });
    }
    if (field["checkoutActive"] && field.type === checkoutFields.postCode) {
      result.push({
        type: checkoutFields.postCode,
        value: customerData.zipCode,
      });
    }
  });

  return result;
}

<template>
  <span v-if="hourlyPricesActive && isLessThanOneDay">
    {{ rentalPeriodHours }}
    <span v-if="isEqualOrLessThanOneHour">
      {{ localization.resultPage?.overview?.hour }}
    </span>
    <span v-else>{{ localization.resultPage?.overview?.hours }}</span>
  </span>
  <span v-else>
    {{ vehicle?.pricingDetails?.numberOfDays }}
    <span v-if="vehicle?.pricingDetails?.numberOfDays > 1">
      {{ localization.resultPage?.overview?.days }}
    </span>
    <span v-else>{{ localization.resultPage?.overview?.day }}</span>
  </span>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import {
  calcIsEqualOrLessThanOneHour,
  calcIsLessThanOneDay,
  calcRentalPeriodHours,
} from "@/utilities/hourlyPrices";

export default {
  name: "RentalPeriodDisplay",
  props: ["localization", "vehicle"],

  setup() {
    const store = useStore();

    const rentalPeriod = computed(() => store.getters["search/rentalPeriod"]);
    const isoCode = computed(() => store.getters["localization/isoCode"]);

    const fromDate = computed(() =>
      Date.parse(
        `${rentalPeriod.value.fromDate}T${rentalPeriod.value.fromTime}`
      )
    );
    const toDate = computed(() =>
      Date.parse(`${rentalPeriod.value.toDate}T${rentalPeriod.value.toTime}`)
    );

    const rentalPeriodInMilliSec = computed(
      () => toDate.value - fromDate.value
    );

    const isEqualOrLessThanOneHour = computed(() =>
      calcIsEqualOrLessThanOneHour(rentalPeriodInMilliSec.value)
    );

    const rentalPeriodHours = computed(() =>
      new Intl.NumberFormat(isoCode.value).format(
        calcRentalPeriodHours(rentalPeriodInMilliSec.value)
      )
    );

    const hourlyPricesActive = computed(
      () => store.getters["marketConfig/hourlyPricing"]
    );

    return {
      hourlyPricesActive,
      rentalPeriodHours,
      isLessThanOneDay: computed(() =>
        calcIsLessThanOneDay(rentalPeriodInMilliSec.value)
      ),
      isEqualOrLessThanOneHour,
    };
  },
};
</script>

<template>
  <div class="summary">
    <ReservationData
      class="wb-margin-bottom-l"
      :localization="localization"
      :editMode="view === 'confirm' ? false : true"
    />

    <SelectedOfferAccordion
      class="wb-margin-bottom-l"
      v-if="location && selectedVehicle"
      :localization="localization"
      :location="location"
      :vehicle="selectedVehicle"
    />

    <InfoBox
      :title="localization?.selectedOfferAccordion?.noteTitle"
      :text="localization?.selectedOfferAccordion?.noteText"
    />
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import ReservationData from "@/components/Checkout/ReservationData";
import SelectedOfferAccordion from "@/components/SelectedOfferAccordion";
import InfoBox from "@/components/InfoBox";

export default {
  name: "Summary",
  props: ["localization", "view"],
  setup() {
    const store = useStore();
    const selectedVehicle = computed(
      () => store.getters["vehicles/selectedVehicle"]
    );
    const locationSet = computed(() => store.getters["search/location"]);
    const location = ref(null);

    onBeforeMount(() => {
      location.value = getLocation(
        locationSet.value.locations,
        selectedVehicle.value
      );
    });

    const getLocation = (locationList, vehicle) =>
      locationList.find(
        (curLocation) => curLocation?.outlet?.outletId === vehicle.outletId
      );

    return {
      selectedVehicle,
      location,
    };
  },
  components: {
    ReservationData,
    SelectedOfferAccordion,
    InfoBox,
  },
};
</script>
<style lang="scss" scoped>
@import "~@workbench/core/dist/css/extensions/spacing";
</style>

<template>
  <div class="location-item">
    <wb-checkbox-control theme="dark">
      <label>
        <input
          data-test-id="location-item-input"
          type="checkbox"
          :value="location?.outlet?.outletId"
          :checked="selection?.selected.value.has(location)"
          @click="selection?.toggle(location)"
        />
        <p data-test-id="location-item-name">
          {{ name }}
        </p>
        <p>
          {{ streetData }}
        </p>
      </label>
    </wb-checkbox-control>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "LocationItem",
  props: ["localization", "location", "selection"],
  setup(props) {
    const store = useStore();
    const language = computed(
      () => store.getters["localization/activeLanguage"]
    );

    const streetData = computed(() => {
      if (language.value?.useOriginalOutletAddress) {
        const { originalAddress } = props.location?.outlet;
        return `${originalAddress?.street}, 
        ${originalAddress?.zipCode} ${originalAddress?.city}`;
      }

      const { latinAddress } = props.location?.outlet;
      return `${latinAddress?.street}, 
      ${latinAddress?.zipCode} ${latinAddress?.city}`;
    });

    const name = computed(() => {
      if (language.value?.useOriginalOutletAddress) {
        return props.location?.outlet.originalName;
      }
      return props.location?.outlet.name;
    });

    return {
      streetData,
      name,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.location-item {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;

  @include breakpoint-from(mq3) {
    padding-bottom: 25px;
    margin-bottom: 25px;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
  &:after {
    content: "";
    background: var(--wb-grey-40);
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    @include breakpoint-from(mq3) {
      width: 80%;
      margin-right: auto;
    }
  }
}
</style>

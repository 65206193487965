export const paramNames = {
  from: "from",
  to: "to",
  timeFrom: "timeFrom",
  timeTo: "timeTo",
  location: "location",
  radius: "radius",
  classFilter: "classFilter",
  brandFilter: "brandFilter",
  vehicleTypeFilter: "vehicleTypeFilter",
  partnerId: "partnerId",
};

export const attributeList = [
  { name: "AMG", disabled: false, active: false, localization: "AMG" },
  { name: "EQ", disabled: false, active: false, localization: "EQ" },
  { name: "SMART", disabled: false, active: false, localization: "Smart" },
];

<template>
  <div
    class="vehicle-type"
    @click="toggle"
    :class="{ disabled: vehicleType.disabled }"
  >
    <div
      class="vehicle-type__box"
      :class="{ disabled: vehicleType.disabled, active: vehicleType.active }"
    >
      <img
        :src="getVehicleTypeImageUrl(vehicleType)"
        :alt="vehicleType.name"
        class="vehicle-type__icon"
        :class="{ disabled: vehicleType.disabled, active: vehicleType.active }"
      />
      <p class="wb-type-copy-secondary vehicle-type__text">
        {{ vehicleType.name }}
      </p>
    </div>
  </div>
</template>
<script>
import useImage from "@/compositions/useImage";
export default {
  name: "VehicleType",
  props: {
    vehicleType: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { getVehicleTypeImageUrl } = useImage();
    const toggle = () => {
      if (!props.vehicleType?.disabled) {
        emit("toggle", {
          vehicleType: props.vehicleType,
          mode: props.vehicleType?.active ? "delete" : "add",
        });
      }
    };
    return {
      toggle,
      getVehicleTypeImageUrl,
    };
  },
};
</script>
<style lang="scss" scoped>
.vehicle-type {
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  &__box {
    width: 150px;
    height: 70px;
    border: 1px solid var(--wb-grey-45);
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    &.active {
      border-color: var(--wb-blue-45) !important;
    }
    &.disabled {
      border-color: var(--wb-grey-60) !important;
    }
  }
  &__icon {
    max-width: 70px;
    max-height: 30px;
    filter: brightness(0.2);
    transform: scale(-1, 1);
    &.disabled {
      filter: brightness(0.7) !important;
    }
    &.active {
      // generated by : https://codepen.io/sosuke/pen/Pjoqqp  should represent wb-blue-45
      filter: invert(49%) sepia(76%) saturate(6434%) hue-rotate(189deg)
        brightness(92%) contrast(101%);
    }
  }
  &__text {
    text-align: center;
    padding-top: 5px;
    color: var(--wb-grey-20);
  }
}
</style>

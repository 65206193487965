<template>
  <div class="wb-type-copy">
    <p>
      <span class="wb-type-copy">
        {{ localization.checkoutPage?.pickUp }}:
      </span>
      {{ fromDate }},
      {{ selectedTime.from + " " + localization.timeLabel }}
    </p>
    <p>
      <span class="wb-type-copy">
        {{ localization.checkoutPage?.return }}:
      </span>
      {{ toDate }},
      {{ selectedTime.to + " " + localization.timeLabel }}
    </p>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { formatDisplayDate } from "@/utilities/date";
export default {
  name: "DateTimeFromTo",
  props: ["localization"],
  setup() {
    const store = useStore();
    const selectedTime = computed(() => store.getters["search/selectedTime"]);
    const selectedDate = computed(() => store.getters["search/selectedDate"]);
    const isoCode = computed(() => store.getters["localization/isoCode"]);
    const requiredDateFormat = computed(
      () => store.getters["localization/dateFormat"]
    );
    const fromDate = computed(() =>
      formatDisplayDate(
        selectedDate.value?.from,
        isoCode.value,
        requiredDateFormat.value
      )
    );
    const toDate = computed(() =>
      formatDisplayDate(
        selectedDate.value?.to,
        isoCode.value,
        requiredDateFormat.value
      )
    );
    return { selectedTime, fromDate, toDate };
  },
};
</script>

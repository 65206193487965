import { computed } from "vue";
import { useStore } from "vuex";
import ImageService from "@/services/ImageService";

const useImage = function () {
  const store = useStore();
  const mockMode = computed(() => store.getters["marketConfig/mockMode"]);
  const domainId = computed(() => store.getters["marketConfig/domainId"]);

  const getImageUrl = (imageId, isLandingPage) => {
    if (imageId) {
      if (mockMode.value) {
        return require("@/assets/images/" + imageId);
      } else {
        return ImageService.getImageUrl({
          imageId,
          isLandingPage,
        });
      }
    }
  };

  const getModelImageUrl = (modelTypeId, size) => {
    if (modelTypeId) {
      if (mockMode.value) {
        return require("@/assets/images/image_02.jpeg");
      } else {
        return ImageService.getModelImageUrl({
          modelTypeId,
          size,
          domainId: domainId.value,
        });
      }
    }
  };

  const getVehicleTypeImageUrl = (vehicleTypeId) => {
    if (vehicleTypeId) {
      return require(`@/assets/images/vehicleTypes/${vehicleTypeId.globalVehicleType}.svg`);
    }
  };

  const getVehicleAttributeImageUrl = (vehicleAttribute) => {
    return require(`@/assets/images/vehicleTypes/${vehicleAttribute.name}.svg`);
  };

  const getContentImageUrl = (imageId) => {
    if (imageId) {
      if (mockMode.value) {
        return require(`@/assets/images/mocks/content/${imageId}`);
      } else {
        return ImageService.getImageUrl({
          imageId,
          isLandingPage: false,
        });
      }
    }
  };

  return {
    getImageUrl,
    getModelImageUrl,
    getVehicleTypeImageUrl,
    getVehicleAttributeImageUrl,
    getContentImageUrl,
  };
};

export default useImage;

<template>
  <LoadingScreen v-if="!localization?.aboutUsPage" />
  <MainTeaser
    v-else
    :imageUrl="imageUrl"
    :headline="localization?.aboutUsPage?.headline"
  />
  <section class="content-width">
    <div v-if="isMobile" class="about-us-accordion">
      <wb-accordion prevent-mobile-flyout theme="dark">
        <wb-accordion-item>
          <wb-accordion-toggle style="padding-left: 0 !important">
            <h2
              class="richtext"
              v-html="localization?.aboutUsPage?.section1?.header"
            />
          </wb-accordion-toggle>
          <wb-accordion-section>
            <wb-accordion-mobile-header>Close</wb-accordion-mobile-header>
            <wb-accordion-content>
              <div
                class="richtext"
                v-html="localization?.aboutUsPage?.section1?.text"
              />
            </wb-accordion-content>
          </wb-accordion-section>
        </wb-accordion-item>
        <wb-accordion-item>
          <wb-accordion-toggle>
            <h2
              class="richtext"
              v-html="localization?.aboutUsPage?.section2?.header"
            />
          </wb-accordion-toggle>
          <wb-accordion-section>
            <wb-accordion-mobile-header>Close</wb-accordion-mobile-header>
            <wb-accordion-content>
              <div
                class="richtext"
                v-html="localization?.aboutUsPage?.section2?.text"
              />
            </wb-accordion-content>
          </wb-accordion-section>
        </wb-accordion-item>
      </wb-accordion>
    </div>
    <div v-else>
      <div>
        <div
          class="about-us-list__itemHeader richtext wb-margin-top-m"
          v-html="localization?.aboutUsPage?.section1?.header"
        />
      </div>
      <div
        class="about-us-list__itemContent richtext"
        v-html="localization?.aboutUsPage?.section1?.text"
      />
      <div>
        <div
          class="about-us-list__itemHeader richtext wb-margin-top-m"
          v-html="localization?.aboutUsPage?.section2?.header"
        />
      </div>
      <div
        class="about-us-list__itemContent richtext"
        v-html="localization?.aboutUsPage?.section2?.text"
      />
    </div>

    <div class="card-section">
      <Benefits
        v-if="localization?.aboutUsPage?.pcBenefits"
        :benefits="localization?.aboutUsPage?.pcBenefits"
        class="wb-margin-top-m"
      />
      <Benefits
        v-if="localization?.aboutUsPage?.bcBenefits"
        :benefits="localization?.aboutUsPage?.bcBenefits"
        class="wb-margin-top-m"
      />
    </div>
  </section>
</template>

<script>
import { computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useTrackingPage from "@/compositions/useTrackingPage";
import Benefits from "@/components/Benefits";
import useImage from "@/compositions/useImage";
import LoadingScreen from "@/components/LoadingScreen";
import MainTeaser from "@/components/ContentPage/MainTeaser";

export default {
  name: "About",
  setup() {
    const store = useStore();
    const router = useRouter();

    const isMobile = computed(() => store.getters["localization/isMobile"]);

    const { getImageUrl } = useImage();

    const activeLanguage = computed(
      () => store.getters["localization/activeLanguage"]
    );
    const tsLoaded = computed(
      () => store.getters["marketConfig/trackingScriptLoaded"]
    );

    const imageUrl = computed(() => {
      let image = "";
      if (isMobile.value) {
        image = getImageUrl(
          localization.value?.aboutUsPage?.headerImageIdMobile,
          false
        );
      } else {
        image = getImageUrl(
          localization.value?.aboutUsPage?.headerImageIdDesktop,
          false
        );
      }

      return image;
    });

    const localization = computed(
      () => store.getters["localization/localization"]
    );

    const { trackContentAboutUs } = useTrackingPage();

    watch(
      () => [activeLanguage.value, tsLoaded.value],
      ([language, loaded]) => {
        if (language?.isoCode && loaded) {
          trackContentAboutUs();
        }
      }
    );
    const useNewAboutUs = computed(
      () => store.getters["marketConfig/useNewAboutUs"]
    );
    watch(
      () => useNewAboutUs.value,
      () => {
        if (useNewAboutUs.value) {
          router.push({ name: "aboutUs" });
        }
      }
    );

    onMounted(() => {
      if (activeLanguage.value.isoCode && tsLoaded.value) {
        trackContentAboutUs();
      }
    });

    return {
      localization,
      imageUrl,
      isMobile,
    };
  },
  components: {
    Benefits,
    LoadingScreen,
    MainTeaser,
  },
};
</script>

<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_typography";
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.about-us-accordion {
  margin-top: 10px;

  wb-accordion-item[open] {
    background-color: transparent;
  }

  wb-accordion[theme="dark"] .wb-accordion__toggle {
    background-color: transparent;
  }

  wb-accordion[theme="dark"] wb-accordion-item[open] .wb-accordion__toggle {
    background-color: transparent;
  }

  wb-accordion-item[open] .wb-accordion__toggle {
    background-color: transparent;
  }

  wb-accordion-content {
    @include wb-type-heading-xs;
    font-weight: normal;
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  wb-accordion-section {
    background-color: transparent;
  }

  wb-accordion[theme="dark"] wb-accordion-item wb-accordion-section {
    background-color: transparent;
  }

  .wb-accordion__toggle > div > h2 {
    @include wb-type-heading-m;
    padding: 0 !important;
  }

  .wb-accordion__toggle > div {
    padding: 0 !important;
  }

  .wb-accordion__toggle-icon {
    height: 60px;
    width: 36px;
  }

  .wb-accordion__toggle:focus:not(:disabled) {
    outline: none;
  }

  wb-accordion[theme="dark"] .wb-accordion__toggle:focus:not(:disabled),
  wb-accordion[theme="dark"] .wb-accordion__toggle:hover:not(:disabled) {
    background-color: transparent;
  }

  wb-accordion[theme="dark"] {
    border-top: none;
  }
}

.about-us-list {
  &__itemHeader {
    @include wb-type-heading-m;
    padding-top: 7px;
  }

  &__itemContent {
    font-family: "MBCorpo Text", "DaimlerCS-Regular", sans-serif;
    font-style: normal;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: normal;
    padding-top: 18px;
    padding-bottom: 30px;
  }
}

.card-section {
  margin-top: 50px;

  @include breakpoint-from(mq2) {
    margin-top: 0;
  }
}
</style>

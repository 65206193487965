<template>
  <div class="location-selection wb-type-copy">
    <div class="location-selection__checkbox" v-if="showDealerSelectAll">
      <wb-checkbox-control theme="dark">
        <label>
          <input
            data-test-id="location-selection-input"
            type="checkbox"
            :checked="allLocationsSelected"
            @click="selection.bulkSelect(allLocationsSelected, locations)"
          />
          {{ localization?.location?.all }}
        </label>
      </wb-checkbox-control>
    </div>
    <div data-test-id="location-selection-selected">
      {{ selectionText() }}
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import useLocationSelection from "@/compositions/useLocationSelection";

export default {
  name: "LocationSelection",
  props: ["localization", "locations", "showDealerSelectAll"],
  setup(props) {
    const selection = useLocationSelection();
    const numberLocations = computed(() => props.locations?.length);
    const allLocationsSelected = computed(
      () => selection.numberSelected.value === numberLocations.value
    );

    const selectionText = () => {
      const text =
        selection.numberSelected.value === 1
          ? props.localization?.location?.selectionSingular
          : props.localization?.location?.selectionPlural;
      return text.replace("{0}", selection.numberSelected.value);
    };

    return {
      selection,
      numberSelected: selection.numberSelected,
      allLocationsSelected,
      selectionText,
    };
  },
};
</script>

<style lang="scss" scoped>
.location-selection {
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
}
</style>

<template>
  <div class="direct-search">
    <LoadingScreen
      v-if="search?.loadingText"
      :loadingText="search.loadingText"
    />
  </div>
</template>
<script>
import { watch, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { paramNames } from "@/components/Vehicle/constants";
import LoadingScreen from "@/components/LoadingScreen.vue";
import useDirectSearch from "@/compositions/useDirectSearch";
import useTrackingPage from "@/compositions/useTrackingPage";

export default {
  name: "DirectSearch",
  props: [
    paramNames.from,
    paramNames.to,
    paramNames.timeFrom,
    paramNames.timeTo,
    paramNames.location,
    paramNames.radius,
    paramNames.classFilter,
    paramNames.brandFilter,
    paramNames.vehicleTypeFilter,
    paramNames.partnerId,
  ],
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const error = ref("");
    const {
      retrieveAndStoreLocations,
      retrieveAndStoreVehicles,
      retrieveAndStoreClassFilter,
      retrieveAndStoreVehicleTypeFilter,
      retrieveAndStoreBrandFilter,
      domainId,
      search,
    } = useDirectSearch(props);
    const { trackDirectSearch } = useTrackingPage();
    const init = async () => {
      try {
        // Step 1: request locations
        await retrieveAndStoreLocations();
        // Step 2: request vehicles
        await retrieveAndStoreVehicles();
        // Step 3: set classFilter
        if (props[paramNames.classFilter]) {
          await retrieveAndStoreClassFilter();
        }
        // Step 4: set vehicleTypeFilter
        if (props[paramNames.vehicleTypeFilter]) {
          await retrieveAndStoreVehicleTypeFilter();
        }
        // Step 5: set brandFilter
        if (props[paramNames.brandFilter]) {
          await retrieveAndStoreBrandFilter();
        }
      } catch (e) {
        // no error if no vehicles found
        if (e?.message.includes("noVehicles")) {
          return;
        }
        // set same error on any failure
        error.value = "redirectError";
      } finally {
        // Step 4: redirect to vehicles page with directSearch props
        router.push({
          name: "vehicles",
          params: {
            target: "directSearch",
            directSearchError: error.value,
          },
        });
      }
    };
    const marketConfigPreAuthData = computed(
      () => store.getters["marketConfig/preAuthData"]
    );
    const activeLanguage = computed(
      () => store.getters["localization/activeLanguage"]
    );
    const tsLoaded = computed(
      () => store.getters["marketConfig/trackingScriptLoaded"]
    );

    watch(
      () => [
        activeLanguage.value,
        tsLoaded.value,
        marketConfigPreAuthData.value,
      ],
      ([language, loaded, marketConfig]) => {
        if (
          language?.isoCode &&
          loaded &&
          !marketConfig.maintenancePage &&
          marketConfig.domainActive
        ) {
          trackDirectSearch();
        }
      }
    );
    // wait for domainId to be loaded
    watch(
      () => domainId.value,
      () => {
        if (domainId.value) {
          init();
        }
      }
    );

    watch(
      () => computed(() => store.getters["mainOutlet/loadingFailed"]).value,
      () => {
        if (store.getters["mainOutlet/loadingFailed"].value) {
          router.push({
            name: "home",
          });
        }
      }
    );
    return { search };
  },
  components: { LoadingScreen },
};
</script>
<style lang="scss" scoped>
.direct-search {
  height: 100vh;
}
</style>

<template>
  <div class="datepicker">
    <wb-input-control
      class="jsDatepickerInput"
      :theme="themeStyle"
      :success="success"
      :error="error"
      ref="datepickerRef"
    >
      <wb-input class="datepicker__input">
        <label>
          <span v-if="dateLabel">
            {{ dateLabel }}
          </span>
          <span v-else>
            <span v-if="localization?.general?.pickUpAndReturnDate">
              {{ localization?.general?.pickUpAndReturnDate }}
            </span>
          </span>
        </label>
        <input
          id="dateInput"
          type="text"
          aria-labelledby="datepickerError"
          @focus="toggleOpen"
          v-model="date"
          :disabled="disabled"
          readonly
        />
        <Icon
          class="datepicker__icon"
          :class="{ offset: success || error }"
          @click="toggleOpen"
          name="calendar"
          :size="20"
        />
      </wb-input>
      <wb-control-error v-if="error" id="datepickerError">
        {{ localization.noDateSelected }}
      </wb-control-error>
    </wb-input-control>

    <transition
      name="slide-down"
      @after-enter="afterEnter"
      @after-leave="$emit('calendarLeave')"
    >
      <Calendar
        v-if="isOpen"
        :dateFormat="dateFormat"
        :refElement="datepickerRef"
        :themeStyle="themeStyle"
      />
    </transition>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import Calendar from "@/components/Datepicker/Calendar";
import useDateValidation from "@/compositions/useDateValidation";
import useClickEvent from "@/compositions/useClickEvent";
import useTrackingError from "@/compositions/useTrackingError";
import Icon from "@/components/Icon";

export default {
  props: {
    disabled: {
      type: Boolean,
    },
    localization: {
      type: Object,
    },
    themeStyle: {
      type: String,
      default: "dark",
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const error = ref(false);
    const isOpen = ref(false);
    const { date, success, dateFormat, dateLabel } = useDateValidation(
      () => props.localization
    );

    const toggleOpen = () => {
      if (!props.disabled && (!error.value || !isOpen.value)) {
        isOpen.value = !isOpen.value;
        document.getElementById("dateInput").blur();
      }
    };

    const afterEnter = () => {
      scrollTo();
      emit("calendarEnter");
    };

    const datepickerRef = ref(null);
    const isMobile = computed(() => store.getters["localization/isMobile"]);
    // Browser automatically scrolls to input. To prevent a "jump",
    // we scroll exactly to the position where the browser scrolls to.
    // We need different diffs for mobile and desktop because layout and scroll area is different
    const scrollTo = () => {
      if (datepickerRef.value) {
        const diff = isMobile.value ? 90 : 200;
        const top = datepickerRef.value.getBoundingClientRect().top - diff;

        if (window.scrollY < top) {
          window.scrollTo({
            top,
            behavior: "smooth",
          });
        }
      }
    };

    watch(
      () => success.value,
      () => {
        if (success.value) {
          isOpen.value = false;
          error.value = false;
        }
      }
    );

    const { trackErrorDatepicker } = useTrackingError();

    // close calendar if clicked somewhere else
    // and the dates are selected successfully
    useClickEvent({
      exclude: ["jsDatepickerInput", "jsCalendar"],
      fn: () => {
        if (success.value) {
          error.value = false;
          isOpen.value = false;
        } else {
          if (!props.disabled && isOpen.value) {
            error.value = true;
            trackErrorDatepicker(props["localization"].noDateSelected);
          }
        }
      },
    });

    // close suggest when clicked on search input
    useClickEvent({
      include: ["search-input"],
      fn: () => {
        error.value = false;
        isOpen.value = false;
      },
    });

    return {
      isOpen,
      toggleOpen,
      datepickerRef,
      dateLabel,
      date,
      success,
      dateFormat,
      afterEnter,
      error,
    };
  },
  components: {
    Calendar,
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.datepicker {
  padding: 10px 0;

  &__icon {
    visibility: visible;
    position: absolute;
    right: 1.4rem;
    top: 1rem;

    &.offset {
      right: 3.4rem;
    }
  }

  &__input {
    border: 1px solid var(--border-color);
  }

  #dateInput {
    padding-right: 35px;
  }

  wb-input-control[readonly] > wb-input {
    border-color: var(--wb-grey-70);
  }
}
</style>

<template>
  <div class="breadcrumb">
    <div
      class="breadcrumb__item"
      :class="{
        active: active === step.number,
        complete: active >= step.number,
      }"
      v-for="(step, index) in steps"
      :key="step.number"
    >
      <span class="breadcrumb__item-text" @click="changeStep(step)">
        {{ step.number }}
      </span>
      <span
        v-if="steps.length - 1 !== index"
        class="breadcrumb__item-divider"
      ></span>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import { stepDefault } from "@/utilities/checkout";

export default {
  name: "Breadcrumb",
  props: {
    active: {
      type: Number,
      required: true,
    },
    configCheckout: [Object],
  },
  setup(props, { emit }) {
    const store = useStore();
    const steps = ref([]);

    onMounted(() => {
      initSteps();
    });

    const initSteps = () => {
      steps.value = stepDefault;

      store.dispatch("checkout/setSteps", steps.value);
    };

    const changeStep = (step) => {
      if (step !== props.active) {
        emit("stepClicked", step);
      }
    };

    return {
      steps,
      changeStep,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/breadcrumb";
</style>

import { computed } from "vue";
import { useStore } from "vuex";
import useLinkTrackingBuilder from "./tracking/useLinkTrackingBuilder";
import useErrorTrackingBuilder from "./tracking/useErrorTrackingBuilder";

const useTrackingError = function () {
  const store = useStore();
  const tsLoaded = computed(
    () => store.getters["marketConfig/trackingScriptLoaded"]
  );
  const { buildLinkTracking } = useLinkTrackingBuilder();
  const { buildErrorTracking } = useErrorTrackingBuilder();

  const fillLinkDataAndTrack = (newDigitalData) => {
    if (tsLoaded.value) {
      digitalData = newDigitalData; // update global variable that is defined in index.html
      trackEvent("mbrent_event");
    }
  };

  const trackEvent = (eventName) => {
    // uncomment for debugging and tracking-bootcamp
    //console.log("page: ", digitalData.link.linkInfo.pageName);
    //console.log("digitalData: ", digitalData);
    _satellite.track(eventName);
  };

  const trackErrorCancellationOverview = (messageText) => {
    const digitalData = {
      ...buildLinkTracking("cancelation: overview", "cancelation", "error"),
      ...buildErrorTracking(messageText),
    };
    fillLinkDataAndTrack(digitalData);
    return digitalData;
  };

  const mapping = {
    PersonalData: "contact data",
    RentalData: "wishes and conditions",
    Summary: "summary",
  };

  const trackErrorCheckout = (messageText, activeStep) => {
    const digitalData = {
      ...buildLinkTracking(
        "checkout: " + mapping[activeStep],
        "checkout",
        "error"
      ),
      ...buildErrorTracking(messageText),
    };
    fillLinkDataAndTrack(digitalData);
    return digitalData;
  };

  const trackErrorPaymentRedirect = (messageText) => {
    const digitalData = {
      ...buildLinkTracking("payment: redirect", "payment", "error"),
      ...buildErrorTracking(messageText),
    };
    fillLinkDataAndTrack(digitalData);
    return digitalData;
  };

  const trackErrorLongTermRequest = (messageText) => {
    const digitalData = {
      ...buildLinkTracking("request: long term", "request", "error"),
      ...buildErrorTracking(messageText),
    };
    fillLinkDataAndTrack(digitalData);
    return digitalData;
  };

  const trackErrorVehicleRequest = (messageText) => {
    const digitalData = {
      ...buildLinkTracking("request: vehicle form", "request", "error"),
      ...buildErrorTracking(messageText),
    };
    fillLinkDataAndTrack(digitalData);
    return digitalData;
  };

  const trackErrorDatepicker = (messageText) => {
    const digitalData = {
      ...buildLinkTracking("search: date picker", "search", "error"),
      ...buildErrorTracking(messageText, "search error"),
    };
    fillLinkDataAndTrack(digitalData);
    return digitalData;
  };

  const trackErrorSearchInput = (messageText) => {
    const digitalData = {
      ...buildLinkTracking("home: start", "home", "error"),
      ...buildErrorTracking(messageText, "search error"),
    };
    fillLinkDataAndTrack(digitalData);
    return digitalData;
  };

  const trackErrorLocationOverlay = (messageText) => {
    const digitalData = {
      ...buildLinkTracking("search: partner", "search", "error"),
      ...buildErrorTracking(messageText, "search error"),
    };
    fillLinkDataAndTrack(digitalData);
    return digitalData;
  };

  const trackErrorVehicles = (messageText) => {
    const digitalData = {
      ...buildLinkTracking("result: overview", "result", "error"),
      ...buildErrorTracking(messageText, "search error"),
    };
    fillLinkDataAndTrack(digitalData);
    return digitalData;
  };

  return {
    trackErrorCancellationOverview,
    trackErrorCheckout,
    trackErrorPaymentRedirect,
    trackErrorLongTermRequest,
    trackErrorVehicleRequest,
    trackErrorDatepicker,
    trackErrorSearchInput,
    trackErrorLocationOverlay,
    trackErrorVehicles,
  };
};

export default useTrackingError;

import { computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { MediaQueryService } from "@workbench/core";

const useMediaQuery = function () {
  const store = useStore();
  const myMediaQueryService = new MediaQueryService(window);
  const mediaQuery = computed(
    () => store.getters["localization/activeMediaQuery"]
  );

  const resizeEvent = () =>
    store.dispatch(
      "localization/updateMediaQuery",
      myMediaQueryService.getCurrentMediaQuery()
    );

  onMounted(() => {
    const current = myMediaQueryService.getCurrentMediaQuery();
    store.dispatch("localization/updateMediaQuery", current);

    window.addEventListener("resize", resizeEvent);

    myMediaQueryService.observe(true);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", resizeEvent);
    myMediaQueryService.disconnect();
  });

  return {
    mediaQuery,
  };
};

export default useMediaQuery;

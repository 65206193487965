export function getFormatedOpeningHours(openingHours, localization) {
  const result = [];
  let currentStartDay = "";
  let currentEndDay = "";
  for (var i = 0; i < openingHours.length; i++) {
    if (currentStartDay === "") {
      currentStartDay = openingHours[i].day;
    }
    currentEndDay = openingHours[i].day;
    if (i === openingHours.length - 1) {
      result.push({
        from: formatedOpeningDaysString(
          currentStartDay,
          currentEndDay,
          localization.days
        ),
        openingHours: formatAllTimeSlots(openingHours[i], localization),
      });
    } else {
      if (
        formatAllTimeSlots(openingHours[i], localization) !==
        formatAllTimeSlots(openingHours[i + 1], localization)
      ) {
        result.push({
          from: formatedOpeningDaysString(
            currentStartDay,
            currentEndDay,
            localization.days
          ),
          openingHours: formatAllTimeSlots(openingHours[i], localization),
        });
        currentStartDay = "";
      }
    }
  }
  return result;
  /* e.g.:
  [
    { from: "MO-TU", openingHours: "09:00 - 12:00, 14:30 - 19:00" },
    { from: "WE", openingHours: "closed" },
    { from: "TH-SU", openingHours: "07:00 - 18:00" },
  ];
  */
}

export function formatTimeSlot(timeSlot, timeLabel) {
  return `${timeSlot.begin} - ${timeSlot.end} ${timeLabel}`;
}

export function formatAllTimeSlots(timeSlots, localization) {
  let result = "";
  if (timeSlots.closed) {
    return localization.closed;
  } else {
    for (const time of timeSlots.times) {
      result += formatTimeSlot(time, localization.timeLabel) + ", ";
    }
    result = result.substring(0, result.length - 2);
    return result;
  }
}

export function formatedOpeningDaysString(startday, endday, daysTranslation) {
  if (startday === endday) {
    return translateDay(startday, daysTranslation);
  } else {
    return (
      translateDay(startday, daysTranslation) +
      "-" +
      translateDay(endday, daysTranslation)
    );
  }
}

export function translateDay(dayString, translations) {
  let result = "";
  switch (dayString) {
    case "MO":
      result = translations.mo;
      break;
    case "TU":
      result = translations.tu;
      break;
    case "WE":
      result = translations.we;
      break;
    case "TH":
      result = translations.th;
      break;
    case "FR":
      result = translations.fr;
      break;
    case "SA":
      result = translations.sa;
      break;
    case "SU":
      result = translations.su;
      break;
  }
  return result;
}

export function getIntlWeekDay(day, isoCode) {
  let date;
  switch (day) {
    case "MO":
      date = new Date("2021-01-04");
      break;
    case "TU":
      date = new Date("2021-01-05");
      break;
    case "WE":
      date = new Date("2021-01-06");
      break;
    case "TH":
      date = new Date("2021-01-07");
      break;
    case "FR":
      date = new Date("2021-01-08");
      break;
    case "SA":
      date = new Date("2021-01-09");
      break;
    case "SU":
      date = new Date("2021-01-10");
      break;
    default:
      break;
  }
  return new Intl.DateTimeFormat(isoCode, { weekday: "short" }).format(date);
}

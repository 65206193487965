import { CONFIGURED_HOSTS } from "@/assets/hosts";
import oneDocConfig from "@/assets/oneDoc/config";

export default {
  getSPAUrl(host) {
    let url;
    switch (host) {
      case CONFIGURED_HOSTS.localhost:
      case CONFIGURED_HOSTS.de.dev:
      case CONFIGURED_HOSTS.ch.dev:
      case CONFIGURED_HOSTS.de.int:
      case CONFIGURED_HOSTS.ch.int:
      case CONFIGURED_HOSTS.at.int:
        url = oneDocConfig.stage.int;
        break;
      case CONFIGURED_HOSTS.de.preProd:
      case CONFIGURED_HOSTS.ch.preProd:
      case CONFIGURED_HOSTS.at.preProd:
        url = oneDocConfig.stage.preProd;
        break;
      case CONFIGURED_HOSTS.de.prod:
      case CONFIGURED_HOSTS.ch.prod:
      case CONFIGURED_HOSTS.at.prod:
        url = oneDocConfig.stage.prod;
        break;
      default:
        url = undefined;
    }
    return url;
  },
  getLegalEntity(host) {
    let legalEntity;
    switch (host) {
      case CONFIGURED_HOSTS.de.dev:
      case CONFIGURED_HOSTS.de.int:
      case CONFIGURED_HOSTS.de.preProd:
      case CONFIGURED_HOSTS.de.prod:
        legalEntity = oneDocConfig.legalEntity.de;
        break;
      case CONFIGURED_HOSTS.localhost:
      case CONFIGURED_HOSTS.ch.dev:
      case CONFIGURED_HOSTS.ch.int:
      case CONFIGURED_HOSTS.ch.preProd:
      case CONFIGURED_HOSTS.ch.prod:
        legalEntity = oneDocConfig.legalEntity.ch;
        break;
      case CONFIGURED_HOSTS.localhost:
      case CONFIGURED_HOSTS.at.dev:
      case CONFIGURED_HOSTS.at.int:
      case CONFIGURED_HOSTS.at.preProd:
      case CONFIGURED_HOSTS.at.prod:
        legalEntity = oneDocConfig.legalEntity.at;
        break;
      default:
        legalEntity = undefined;
    }
    return legalEntity;
  },
  getSystemName() {
    return oneDocConfig.systemName;
  },
};

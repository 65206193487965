<template>
  <div class="rental-data">
    <AdditionalFields
      :localization="localization"
      :form="form"
      @validateInput="validateInput"
      @radioCheckboxChanged="radioCheckboxChanged"
      @getFieldData="getFieldData"
      v-if="hasAdditionalFields"
    >
      <template #input="{ element, index }">
        <BaseInput
          type="text"
          v-model="form.elements[index].value"
          @validateInput="validateInput(index)"
          :data="getFieldData(element.type)"
          :name="element.type"
          :error="form.elements[index].error"
          :required="form.elements[index].required"
        />
      </template>
      <template #checkbox="{ element, index }">
        <BaseCheckbox
          :name="form.elements[index].type"
          :data="getFieldData(element.type)"
          @changed="radioCheckboxChanged(index)"
          :required="form.elements[index].required"
          v-model="form.elements[index].value"
        />
      </template>
    </AdditionalFields>

    <h2 class="wb-type-heading-m">
      {{
        localization?.checkoutPage?.additionalInformationAndDocumentsHeadline
      }}
    </h2>
    <p class="rental-data__subline" v-if="!hasAdditionalFields">
      {{ localization?.checkoutPage?.requiredText }}
    </p>

    <div class="rental-data__form wb-margin-top-s">
      <div
        class="rental-data__form__element wb-margin-bottom-s"
        v-for="(element, index) in form.elements"
        :key="element.type"
      >
        <template v-if="isCorrectTypeAndNotAdditionalFields(element, 'input')">
          <BaseInput
            type="text"
            v-model="form.elements[index].value"
            @validateInput="validateInput(index)"
            :data="getFieldData(element.type)"
            :name="element.type"
            :error="form.elements[index].error"
            :required="form.elements[index].required"
          >
            <template v-slot:header>
              <h4 v-if="hasTitle(element.type)" class="wb-type-copy">
                {{ getTitle(element.type) }}
              </h4>
            </template>
          </BaseInput>
        </template>
        <template
          v-if="isCorrectTypeAndNotAdditionalFields(element, 'voucher')"
        >
          <BaseInput
            type="text"
            v-model="form.elements[index].value"
            @validateInput="validateAndCheckVoucher(index)"
            :data="getFieldData(element.type)"
            :name="element.type"
            :error="form.elements[index].error || voucherError()"
            :required="form.elements[index].required"
            :loading="isVoucherLoading"
          >
            <template v-slot:header>
              <h4 v-if="hasTitle(element.type)" class="wb-type-copy">
                {{ getTitle(element.type) }}
              </h4>
            </template>
            <template v-slot:error v-if="voucherError()">
              <wb-control-error>
                {{ getVoucherMsg() }}
              </wb-control-error>
            </template>
          </BaseInput>
        </template>
        <template
          v-if="isCorrectTypeAndNotAdditionalFields(element, 'textarea')"
        >
          <BaseTextarea
            v-model="form.elements[index].value"
            @validateInput="validateInput(index)"
            :data="getFieldData(element.type)"
            :name="form.elements[index].type"
            :error="form.elements[index].error"
            :required="form.elements[index].required"
            :max-length="240"
          >
            <h4 v-if="hasTitle(element.type)" class="wb-type-copy">
              {{ getTitle(element.type) }}
            </h4>
          </BaseTextarea>
        </template>
        <template v-if="isCorrectTypeAndNotAdditionalFields(element, 'radio')">
          <BaseRadioGroup
            :name="form.elements[index].type"
            v-model="form.elements[index].value"
            @changed="radioCheckboxChanged(index)"
            :data="getFieldData(element.type)"
            :required="form.elements[index].required"
            :options="getRadioOptions(element.type)"
            :sorted-option-keys="getRadioSortedOptionKeys(element.type)"
          >
          </BaseRadioGroup>
        </template>
        <template
          v-if="isCorrectTypeAndNotAdditionalFields(element, 'checkbox')"
        >
          <div
            v-if="form.elements[index].type === 'PROMOTION_CONSENT'"
            class="consent-spacer"
          ></div>
          <BaseCheckbox
            :name="form.elements[index].type"
            :data="getFieldData(element.type)"
            @changed="radioCheckboxChanged(index)"
            :required="form.elements[index].required"
            v-model="form.elements[index].value"
            :link="form.elements[index].type === 'TERMS'"
            @linkClick="linkClicked"
          />
          <h2 v-if="hasTitle(element.type)" class="wb-type-copy">
            {{ getTitle(element.type) }}
          </h2>
        </template>
      </div>
      <div v-if="isCaptchaActive">
        <BaseInput
          type="text"
          name="Captcha"
          :required="true"
          :data="{ label: captchaLocalization.placeholder }"
          :error="captchaError"
          v-model="captchaInput"
        >
          <h4 class="wb-type-copy">
            {{ captchaLocalization.label }}
          </h4>
        </BaseInput>
        <div class="wb-margin-vertical-xs captcha-container">
          <div v-if="captcha.image">
            <img
              class="captcha-image"
              :src="'data:image/png;base64,' + captcha.image"
              alt="captcha"
            />
          </div>
          <a class="wb-link--standalone" :onclick="updateCaptcha">
            <Icon name="reload" :size="12" :width="18" :height="12" />
            {{ captchaLocalization.loadNewImage }}
          </a>
        </div>
      </div>
    </div>
    <transition name="Slide">
      <Provider
        v-if="providerModalOpen"
        @close="providerModalOpen = false"
        :preselectedTab="linkValue"
      />
    </transition>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { useRoute } from "vue-router";
import BaseInput from "@/components/Form/BaseInput";
import BaseRadioGroup from "@/components/Form/BaseRadioGroup";
import BaseCheckbox from "@/components/Form/BaseCheckbox";
import BaseTextarea from "@/components/Form/BaseTextarea";
import {
  additionalFieldsPossibleFields,
  checkoutFields,
  hasFieldsForStep,
  rentalPossibleFields,
  stepNames,
} from "@/utilities/checkout";
import useCheckoutFormValidation from "@/compositions/useCheckoutFormValidation";
import { useStore } from "vuex";
import { computed, onActivated, ref, watch } from "vue";
import useCaptcha from "@/compositions/useCaptcha";
import Icon from "@/components/Icon";
import Provider from "@/views/Provider";
import AdditionalFields from "./AdditionalFields.vue";

export default {
  name: "RentalData",
  props: {
    localization: Object,
    activeField: { type: String, default: "checkoutActive" },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const providerModalOpen = ref(false);
    const linkValue = ref(null);
    const captchaInput = ref(null);
    const captchaError = ref(null);
    const captchaValid = computed(() => store.getters["captcha/isValid"]);
    const isVoucherLoading = computed(
      () => store.getters["vehicles/isLoading"]
    );
    const captchaLocalization = computed(
      () => props["localization"].checkoutPage.captcha
    );
    let captcha;
    const fields = computed(() => store.getters["marketConfig/fields"]);
    const useVoucher = computed(() => store.getters["marketConfig/useVoucher"]);

    const voucherStatusCode = computed(
      () => store.getters["vehicles/voucherStatusCode"]
    );
    const hasAdditionalFields = computed(() => {
      if (!fields.value) {
        return false;
      }

      return hasFieldsForStep(
        additionalFieldsPossibleFields,
        JSON.parse(JSON.stringify(fields.value)),
        "inquiryActive"
      );
    });
    const isCaptchaActive = computed(
      () => store.getters["marketConfig/captcha"]
    );

    const linkClicked = (value) => {
      providerModalOpen.value = true;
      if (value === "one") {
        linkValue.value = "termsAndConditions";
      } else {
        linkValue.value = "dataPrivacy";
      }
    };

    const captchaService = isCaptchaActive.value ? useCaptcha() : undefined;

    const updateCaptcha = () => {
      captchaService.refreshCaptcha();
      captcha = computed(() => store.getters["captcha/captcha"]);
    };

    if (isCaptchaActive) {
      captcha = computed(() => store.getters["captcha/captcha"]);
    }

    const hasTitle = (type) => {
      return (
        type === checkoutFields.specialWishes ||
        type === checkoutFields.promotionCode ||
        type === checkoutFields.promotionConsent
      );
    };

    const getVoucherMsg = () => {
      switch (voucherStatusCode.value) {
        case 6:
          return props.localization?.checkoutPage?.voucherMessages
            ?.notValidForPriceCategory;
        case 5:
          return props.localization?.checkoutPage?.voucherMessages
            ?.notValidIsUsedUp;
        case 4:
          return props.localization?.checkoutPage?.voucherMessages
            ?.notValidForRentalPeriod;
        case 3:
          return props.localization?.checkoutPage?.voucherMessages
            ?.notValidForRetailer;
        case 2:
          return props.localization?.checkoutPage?.voucherMessages?.unknown;
        default:
          return null;
      }
    };

    const voucherError = () => {
      if (!form.elements.find((field) => field.formType === "voucher").value) {
        return null;
      }
      if (voucherStatusCode.value === 0) {
        return null;
      }
      if (voucherStatusCode.value === 1) {
        return false;
      }
      if (voucherStatusCode.value === 2) {
        return true;
      }
      if (voucherStatusCode.value === 3) {
        return true;
      }
      if (voucherStatusCode.value === 4) {
        return true;
      }
      if (voucherStatusCode.value === 5) {
        return true;
      }
      if (voucherStatusCode.value === 6) {
        return true;
      } else {
        return null;
      }
    };
    const domainId = computed(() => store.getters["localization/domainId"]);

    const checkVoucher = async () => {
      await store.dispatch("vehicles/vehiclePriceUpdate", domainId.value);
    };
    const debouncedCheckVoucher = debounce(checkVoucher, 1500);
    const validateAndCheckVoucher = (index) => {
      validateInput(index);
      if (useVoucher.value && form.elements[index].value) {
        debouncedCheckVoucher();
      }
    };

    const getTitle = (type) => {
      switch (type) {
        case checkoutFields.specialWishes:
          return props.localization?.checkoutPage
            ?.additionalRequestTextfieldTitle;
        case checkoutFields.promotionCode:
          return props.localization?.checkoutPage?.promotionCodeTitle;
        case checkoutFields.promotionConsent:
          return props.localization?.checkoutPage
            ?.allowedCommunicationChannelsTitle;
        default:
          return null;
      }
    };

    const {
      form,
      validateInput,
      radioCheckboxChanged,
      getFieldData,
      getRadioOptions,
      getRadioSortedOptionKeys,
    } = useCheckoutFormValidation(
      rentalPossibleFields,
      stepNames.rentalData,
      route.query.step,
      props["activeField"]
    );

    watch(
      () => captchaInput.value,
      () => {
        store.dispatch("captcha/setUserInput", captchaInput.value);
      }
    );
    watch(
      () => captchaValid.value,
      () => {
        if (captchaValid.value !== null) {
          captchaError.value = !captchaValid.value;
        }
      }
    );
    watch(
      () => form.elements,
      () => {
        if (form.elements.length > 0) {
          store.dispatch("checkout/setLoading", false);
        }
      }
    );
    onActivated(() => {
      // make sure loading status will be disabled in case of
      // navigation back via breadcrumbs
      if (form && form.elements && form.elements.length > 0) {
        store.dispatch("checkout/setLoading", false);
      }
    });

    const isCorrectTypeAndNotAdditionalFields = (element, formType) => {
      return !!(
        element.formType === formType &&
        element.type !== checkoutFields.drivingLicenceNumber &&
        element.type !== checkoutFields.passportNumber &&
        element.type !== checkoutFields.minimumAge
      );
    };

    return {
      form,
      validateInput,
      radioCheckboxChanged,
      getFieldData,
      getRadioOptions,
      getRadioSortedOptionKeys,
      hasTitle,
      getTitle,
      providerModalOpen,
      captcha,
      isCaptchaActive,
      updateCaptcha,
      captchaInput,
      captchaError,
      captchaLocalization,
      linkValue,
      linkClicked,
      hasAdditionalFields,
      isCorrectTypeAndNotAdditionalFields,
      voucherError,
      getVoucherMsg,
      validateAndCheckVoucher,
      isVoucherLoading,
    };
  },
  components: {
    Icon,
    BaseInput,
    BaseRadioGroup,
    BaseCheckbox,
    BaseTextarea,
    Provider,
    AdditionalFields,
  },
};
</script>
<style lang="scss" scoped>
@import "~@workbench/core/dist/css/extensions/spacing";
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.captcha-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  @include breakpoint-to(mq2) {
    flex-direction: column;
  }
}

.captcha-image {
  padding-right: 10px;
  padding-bottom: 10px;
}

.rental-data {
  &__subline {
    color: var(--wb-grey-60);
    font-size: 1rem;
    line-height: 1.5rem;
  }

  h4 {
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--wb-grey-20);
    margin-bottom: 10px;
  }
}

.consent-spacer {
  padding-top: 30px;
}
</style>

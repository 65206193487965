<template>
  <div v-if="title && text" class="info-box wb-type-copy space-below">
    <div class="info-box__textbox">
      <p class="info-box__headline wb-type-copy-strong">
        <Icon name="alert-circle" :size="30" class="tooltip-icon" :paths="2" />
        <span class="space-left">{{ title }}</span>
      </p>
      <p class="info-box__text">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  props: {
    title: String,
    text: String,
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.info-box {
  @include breakpoint-to(mq3) {
    margin: 30px 0 0 0;
  }
  @include breakpoint-from(mq3) {
    margin: 40px 0 40px 0;
  }
  margin-bottom: 20px;
  border: 1px solid var(--wb-grey-85);

  &__textbox {
    padding: 5%;
    @include breakpoint-from(mq3) {
      padding: 20px;
    }
  }
  &__headline {
    margin-bottom: 10px;
  }
  .tooltip-icon.icon-alert-circle .path1:before {
    color: var(--wb-yellow-50);
  }
  .space-left {
    padding-left: 10px;
  }
}
</style>

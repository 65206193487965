<template>
  <ul class="footer-menu wb-type-copy-secondary">
    <li
      v-if="
        !marketConfigPreAuthData?.maintenancePage && !isComingFromLandingPage
      "
      class="footer-menu__item"
      @click="scrollToTop()"
    >
      <router-link :to="routeTo('contact')">
        {{ footer?.contact }}
      </router-link>
    </li>
    <li
      v-if="
        !marketConfigPreAuthData?.maintenancePage && !isComingFromLandingPage
      "
      class="footer-menu__item separator"
    >
      {{ footer?.verticalSeparator }}
    </li>
    <li class="footer-menu__item">
      <span
        class="provider_modal"
        @click="changePreselectedProviderTab('provider')"
      >
        {{ footer?.providers }}
      </span>
    </li>
    <li class="footer-menu__item separator">
      {{ footer?.verticalSeparator }}
    </li>
    <li class="footer-menu__item">
      <span
        class="provider_modal"
        @click="changePreselectedProviderTab('legalNotice')"
      >
        {{ footer?.legalNotice }}
      </span>
    </li>
    <li class="footer-menu__item separator">
      {{ footer?.verticalSeparator }}
    </li>
    <li class="footer-menu__item">
      <span
        class="provider_modal"
        @click="changePreselectedProviderTab('cookies')"
      >
        {{ footer?.cookies }}
      </span>
    </li>
    <li class="footer-menu__item separator">
      {{ footer?.verticalSeparator }}
    </li>
    <li class="footer-menu__item">
      <span
        class="provider_modal"
        @click="changePreselectedProviderTab('dataPrivacy')"
      >
        {{ footer?.protection }}
      </span>
    </li>
    <li class="footer-menu__item separator">
      {{ footer?.verticalSeparator }}
    </li>
    <li class="footer-menu__item">
      <span
        class="provider_modal"
        @click="changePreselectedProviderTab('termsAndConditions')"
      >
        {{ footer?.termsAndConditions }}
      </span>
    </li>
    <li class="footer-menu__item separator">
      {{ footer?.verticalSeparator }}
    </li>
    <li class="footer-menu__item">
      <span
        class="provider_modal"
        @click="changePreselectedProviderTab('legalPreconditions')"
      >
        {{ footer?.legalPreconditions }}
      </span>
    </li>
    <li v-if="dsaEnabled" class="footer-menu__item separator">
      {{ footer?.verticalSeparator }}
    </li>
    <li v-if="dsaEnabled" class="footer-menu__item" @click="scrollToTop()">
      <router-link :to="routeTo('digitalservicesact')">
        {{ footer?.dsaReport }}
      </router-link>
    </li>
    <li v-if="dsaEnabled" class="footer-menu__item footer-menu__spacer" />
  </ul>
  <transition name="Slide">
    <Provider
      v-if="openModalFooterProvider"
      @close="openModalFooterProvider = false"
      :preselectedTab="preselectedProviderTab"
    />
  </transition>
</template>

<script>
import { computed, ref } from "vue";
import Provider from "@/views/Provider";
import { useStore } from "vuex";
import { scrollToTop } from "@/utilities/scroll";
import useRouteTo from "@/compositions/useRouteTo";

export default {
  props: {
    footer: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const marketConfigPreAuthData = computed(
      () => store.getters["marketConfig/preAuthData"]
    );

    const dsaEnabled = computed(() => store.getters["marketConfig/dsaEnabled"]);

    const isComingFromLandingPage = computed(
      () => store.getters["mainOutlet/isComingFromLandingPage"]
    );

    const openModalFooterProvider = ref(false);
    const preselectedProviderTab = ref("");
    const changePreselectedProviderTab = (tabName) => {
      preselectedProviderTab.value = tabName;
      openModalFooterProvider.value = true;
    };

    const { routeTo } = useRouteTo();

    return {
      openModalFooterProvider,
      preselectedProviderTab,
      changePreselectedProviderTab,
      marketConfigPreAuthData,
      scrollToTop,
      routeTo,
      isComingFromLandingPage,
      dsaEnabled: dsaEnabled,
    };
  },
  components: {
    Provider,
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.footer-menu {
  text-align: center;
  margin: auto;
  padding: 20px 0;

  @include breakpoint-from(mq3) {
    margin: 0;
    text-align: left;
    padding: 10px 0;
    order: 2;
  }

  &__item {
    display: inline-block;
    padding: 0 10px 5px;
    color: var(--wb-grey-60);
    font-size: 0.875rem;
    line-height: 1.25rem;

    &:first-child {
      padding-left: 0;
    }

    a {
      color: var(--wb-grey-60);
      text-decoration: none;
    }
  }

  &__spacer {
    padding-right: 70px;
  }
}

.provider_modal {
  cursor: pointer;
}
</style>

<template>
  <div class="rent-conditions">
    <div class="rent-conditions-item-container">
      <div class="rent-conditions__item">
        <p class="wb-type-copy-strong">
          {{ localization.conditions?.distanceInclusiveTitle }}
        </p>
        <p
          class="wb-type-copy-secondary"
          v-if="vehicle?.pricingDetails?.unlimitedInclusiveKm"
        >
          {{ localization.conditions?.distanceInclusiveUnlimited }}
        </p>
        <p class="wb-type-copy-secondary" v-else>
          {{ vehicle?.pricingDetails?.inclusiveKm }}
          {{ localization.conditions?.distanceUnit }}
        </p>
      </div>
      <div
        class="rent-conditions__item"
        v-if="vehicle?.pricingDetails?.unlimitedInclusiveKm === false"
      >
        <p class="wb-type-copy-strong">
          {{ localization.conditions?.distanceAdditionalPriceTitle }}
        </p>
        <p class="wb-type-copy-secondary">
          {{ priceTagMoreKilometers }} /
          {{ localization.conditions?.distanceUnit }}
        </p>
      </div>
      <div class="rent-conditions__item">
        <p class="wb-type-copy-strong">
          {{ localization.conditions?.deductibleTitle }}
          <ToolTip
            :text="localization.conditions?.deductibleTooltip"
            @mouseenter="trackInfoItemCostSharing"
          />
        </p>
        <p class="wb-type-copy-secondary">
          {{ priceTagDeductible }}
        </p>
      </div>
    </div>
    <div class="rent-conditions-item-container">
      <div class="rent-conditions__item">
        <p class="wb-type-copy-strong">
          {{ localization.conditions?.ageTitle }}
        </p>
        <p class="wb-type-copy-secondary">
          {{ ageTextSubstituted }}
        </p>
      </div>
      <div class="rent-conditions__item">
        <p class="wb-type-copy-strong">
          {{ localization.conditions?.drivingLicenceMinimumHoldingPeriodTitle }}
        </p>
        <p class="wb-type-copy-secondary">
          {{ drivingLicenceTextSubstituted }}
        </p>
      </div>
      <div class="rent-conditions__item">
        <p class="wb-type-copy-strong">
          {{ localization.conditions?.depositTitle }}
          <ToolTip
            :text="localization.conditions?.depositTooltip"
            @mouseenter="trackInfoItemDeposit"
          >
          </ToolTip>
        </p>

        <p class="wb-type-copy-secondary">
          {{ priceTagDeposit }}
        </p>
      </div>
    </div>
    <div class="rent-conditions-item-container">
      <div class="rent-conditions__cancel-box">
        <p class="wb-type-copy-strong">
          {{ localization.conditions?.cancelTitle }}
        </p>
        <p class="wb-type-copy-secondary">
          {{ localization.conditions?.cancelText }}
        </p>
      </div>
    </div>
  </div>
  <p
    v-if="localization.conditions?.infoText"
    class="wb-type-copy-tertiary space-below"
  >
    {{ localization.conditions?.infoText }}
  </p>
  <PromoBox
    v-if="isPromotionActive"
    class="space-above space-below"
    :isMobile="isMobile"
    :promotion="promotion"
  />
</template>
<script>
import PromoBox from "@/components/PromoBox";
import { getPriceTag } from "@/utilities/price";
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import ToolTip from "@/components/ToolTip";
import useTrackingLink from "@/compositions/useTrackingLink";

export default {
  name: "RendConditions",
  props: ["localization", "vehicle"],

  setup(props) {
    const store = useStore();
    const vehicle = props["vehicle"];
    const localization = computed(() => props["localization"]);
    const ageTextSubstituted = ref("");
    const minAge = vehicle.minAge;
    const drivingLicenceTextSubstituted = ref("");
    const licenceMinAge = vehicle.minLicenceHoldingPeriodYears;
    const isoCode = computed(() => store.getters["localization/isoCode"]);
    const isMobile = computed(() => store.getters["localization/isMobile"]);
    const isPromotionActive = computed(
      () => store.getters["marketConfig/conditionsPromotionActive"]
    );
    const promotion = computed(() => localization.value.promotion);
    const { trackInfoItemDeposit, trackInfoItemCostSharing } =
      useTrackingLink();
    const priceTagMoreKilometers = getPriceTag(
      vehicle.pricingDetails?.priceMoreKm,
      vehicle.pricingDetails?.currencyCode,
      isoCode.value
    );
    const priceTagDeductible = getPriceTag(
      vehicle.pricingDetails?.deductible,
      vehicle.pricingDetails?.currencyCode,
      isoCode.value
    );
    const priceTagDeposit = getPriceTag(
      vehicle.pricingDetails?.deposit,
      vehicle.pricingDetails?.currencyCode,
      isoCode.value
    );
    const substituteAge = (ageText, age) => {
      return ageText.replace("{0}", age);
    };

    watch(
      () => localization.value,
      () => {
        const ageText = localization.value.conditions?.ageText;
        ageTextSubstituted.value = substituteAge(ageText, minAge);
        if (licenceMinAge > 0) {
          const drivingLicenceText =
            licenceMinAge === 1
              ? localization.value.conditions
                  ?.drivingLicenceMinimumHoldingPeriodTextSingular
              : localization.value.conditions
                  ?.drivingLicenceMinimumHoldingPeriodTextPlural;
          drivingLicenceTextSubstituted.value = substituteAge(
            drivingLicenceText,
            licenceMinAge
          );
        } else {
          drivingLicenceTextSubstituted.value =
            localization.value.conditions?.noConstraint;
        }
      },
      {
        immediate: true,
      }
    );
    return {
      drivingLicenceTextSubstituted,
      ageTextSubstituted,
      priceTagMoreKilometers,
      priceTagDeductible,
      priceTagDeposit,
      isMobile,
      isPromotionActive,
      promotion,
      trackInfoItemCostSharing,
      trackInfoItemDeposit,
    };
  },
  components: {
    ToolTip,
    PromoBox,
  },
};
</script>
<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.rent-conditions {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include breakpoint-from(mq3) {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    @include breakpoint-from(mq3) {
      justify-content: space-between;
      padding-bottom: 30px;
    }
  }

  &__cancel-box {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .rent-conditions-item-container {
    width: 100%;
    @include breakpoint-from(mq3) {
      width: 31%;
    }
  }
}
</style>

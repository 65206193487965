<template>
  <div class="sort wb-type-copy">
    <span class="sort__text" @click="open = !open">
      {{ localization?.sortBy }}
    </span>
    <button
      @click="open = !open"
      type="button"
      class="sort__button wb-round-button wb-round-button--solid-level-0 wb-round-button--small"
      :class="{ down: open }"
    >
      <Icon
        name="chevron-up"
        class="sort__icon wb-round-button__icon"
        :size="8"
        :width="14"
      />
    </button>

    <transition name="slide-down">
      <div class="sort__items" v-if="open">
        <div
          @click="changeSort('price')"
          class="sort__item"
          :class="{ active: sort === 'price' }"
        >
          {{ localization?.sortByPrice }}
        </div>
        <div
          @click="changeSort('distance')"
          class="sort__item"
          :class="{ active: sort === 'distance' }"
        >
          {{ localization?.sortByDistance }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import Icon from "@/components/Icon";
import useTrackingLink from "@/compositions/useTrackingLink";

export default {
  props: ["localization"],
  setup() {
    const store = useStore();
    const open = ref(false);
    const sort = computed(() => store.getters["vehicles/sort"]);

    const { trackSortPrice, trackSortDistance } = useTrackingLink();

    const changeSort = (changedSort) => {
      if (changedSort === "price") {
        trackSortPrice();
      } else {
        trackSortDistance();
      }
      store.dispatch("vehicles/setSorting", changedSort);
      setTimeout(() => {
        open.value = !open.value;
      }, 200);
    };

    return { open, sort, changeSort };
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.sort {
  position: relative;

  &:hover &__button {
    background: var(--wb-grey-15);
  }

  &__text {
    display: inline-block;
    padding-right: 10px;
    font-size: 0.875rem;
    cursor: pointer;

    @include breakpoint-from(mq3) {
      font-size: 1rem;
    }
  }

  &__button {
    background: var(--wb-grey-15);
    border: none;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      background: var(--wb-grey-15) !important;
    }

    transition: transform 0.2s ease-in-out;
    transform: none;

    &.down {
      transform: rotate(180deg);
    }
  }

  &__icon {
    color: var(--wb-white);
  }

  &__items {
    position: absolute;
    top: 100%;
    width: 100%;
    padding-top: 15px;
    z-index: 99;

    .fixed & {
      width: auto;
      background: var(--wb-grey-20);
      padding: 15px 10px 5px;
      left: -20px;
    }
  }

  &__item {
    padding: 7px 0;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    font-size: 0.875rem;
    line-height: 1rem;
    cursor: pointer;

    &:first-child,
    &:last-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: none;
    }

    &.active {
      color: var(--wb-blue-45);
    }
  }
}
</style>

<template>
  <div class="personal-data">
    <h2 class="wb-type-heading-m">
      {{ localization?.checkoutPage?.customerDataHeadline }}
    </h2>
    <p class="personal-data__subline">
      {{ localization?.checkoutPage?.requiredText }}
    </p>

    <div class="personal-data__form wb-margin-top-s">
      <div
        class="personal-data__form__element wb-margin-bottom-s"
        :class="{
          street: isStreet(element.type),
          number: isHouseNumber(element.type),
        }"
        v-for="(element, index) in form.elements"
        :key="element.type"
      >
        <template v-if="element.formType === 'input'">
          <BaseInput
            type="text"
            v-model="form.elements[index].value"
            @validateInput="validateInput(index)"
            :data="getFieldData(element.type)"
            :name="element.type"
            :error="form.elements[index].error"
            :required="
              form.elements[index].required || isCompanyField(element.type)
            "
            v-if="!isBusinessField(element.type) || showBusinessCustomerFields"
          />
        </template>
        <template v-if="element.formType === 'radio'">
          <BaseRadioGroup
            :name="form.elements[index].type"
            v-model="form.elements[index].value"
            @changed="radioCheckboxChanged(index)"
            :options="getRadioOptions(element.type)"
            :sorted-option-keys="getRadioSortedOptionKeys(element.type)"
            :row="true"
            :error="salutationHint"
          />
        </template>
        <template v-if="element.formType === 'checkbox'">
          <BaseCheckbox
            :name="form.elements[index].type"
            :data="getFieldData(element.type)"
            @changed="radioCheckboxChanged(index)"
            :required="form.elements[index].required"
            v-model="form.elements[index].value"
            :link="form.elements[index].type === 'CONSENT_PRIVACY_DATA'"
            @linkClick="providerModalOpen = true"
          />
        </template>
        <template v-if="element.formType === 'date'">
          <BaseDate
            :name="getFieldData(element.type).label"
            :required="form.elements[index].required"
            @validateInput="validateBirthday(index)"
            v-model="form.elements[index].value"
            :error="form.elements[index].error"
          />
        </template>
        <template v-if="element.formType === 'select'">
          <BaseSelect
            :label="getFieldData(element.type).label"
            :options="Object.entries(getFieldData(element.type).valuesMap)"
            v-model="form.elements[index].value"
            :required="form.elements[index].required"
            @validateInput="validateInput(index)"
          />
        </template>
      </div>
    </div>
    <transition name="Slide">
      <Provider
        v-if="providerModalOpen"
        @close="providerModalOpen = false"
        preselectedTab="dataPrivacy"
      />
    </transition>
  </div>
</template>

<script>
import { computed, onActivated, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import BaseInput from "@/components/Form/BaseInput";
import BaseRadioGroup from "@/components/Form/BaseRadioGroup";
import BaseCheckbox from "@/components/Form/BaseCheckbox";
import BaseDate from "@/components/Form/BaseDate";
import Provider from "@/views/Provider";
import {
  checkoutFields,
  personalPossibleFields,
  stepNames,
} from "@/utilities/checkout";
import useCheckoutFormValidation from "@/compositions/useCheckoutFormValidation";
import BaseSelect from "@/components/Form/BaseSelect.vue";

export default {
  name: "PersonalData",
  props: {
    localization: Object,
    activeField: { type: String, default: "checkoutActive" },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const providerModalOpen = ref(false);
    const {
      form,
      salutationHint,
      validateInput,
      validateBirthday,
      radioCheckboxChanged,
      getFieldData,
      getRadioOptions,
      getRadioSortedOptionKeys,
    } = useCheckoutFormValidation(
      personalPossibleFields,
      stepNames.personalData,
      route.query.step,
      props["activeField"]
    );

    const isStreet = (type) => type === checkoutFields.street;
    const isHouseNumber = (type) => type === checkoutFields.houseNumber;
    const isBusinessField = (type) =>
      type === checkoutFields.company || type === checkoutFields.taxNumber;
    const isCompanyField = (type) => type === checkoutFields.company;

    const selectedProfileType = computed(
      () => store.getters["checkout/selectedProfileType"]
    );
    const showBusinessCustomerFields = computed(() => {
      return selectedProfileType.value === "PROFILE_TYPE_VALUE_BUSINESS";
    });

    watch(
      () => form.elements,
      () => {
        if (form.elements.length > 0) {
          store.dispatch("checkout/setLoading", false);
        }
      }
    );

    onActivated(() => {
      // make sure loading status will be disabled in case of
      // navigation back via breadcrumbs
      if (form && form.elements && form.elements.length > 0) {
        store.dispatch("checkout/setLoading", false);
      }
    });

    return {
      form,
      salutationHint,
      validateInput,
      validateBirthday,
      radioCheckboxChanged,
      isStreet,
      isHouseNumber,
      getFieldData,
      getRadioOptions,
      getRadioSortedOptionKeys,
      isBusinessField,
      isCompanyField,
      showBusinessCustomerFields,
      providerModalOpen,
    };
  },
  components: {
    BaseInput,
    BaseRadioGroup,
    BaseCheckbox,
    BaseSelect,
    BaseDate,
    Provider,
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/css/extensions/spacing";

.personal-data {
  &__subline {
    color: var(--wb-grey-60);
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &__form__element {
    &.street,
    &.number {
      display: inline-block;
    }

    &.street {
      width: 68%;
      margin-right: 2%;
    }

    &.number {
      width: 30%;
    }
  }
}
</style>

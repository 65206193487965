<template>
  <div class="benefits">
    <div class="wb-type-heading-m titles">
      {{ benefits?.header }}
    </div>
    <section v-if="isMobile">
      <wb-slider
        theme="dark"
        class="carousel-mineshaft"
        show-dot-navigation
        scroll-snap
      >
        <div
          v-for="benefit in benefits?.benefits"
          :key="benefit.subHeader"
          class="carousel-card wb-slider__item wb-slider__item--full-width"
        >
          <div class="wb-type-heading-l">{{ benefit.subHeader }}</div>
          <div class="card-image__text wb-type-copy">{{ benefit.text }}</div>
        </div>
      </wb-slider>
    </section>
    <section v-else>
      <div class="benefits__flex-box">
        <div
          class="benefits__flex-box__item"
          v-for="benefit in benefits?.benefits"
          :key="benefit.subHeader"
        >
          <img
            :src="getImageUrl(benefit.imageId, false)"
            class="benefits__flex-box__item__image"
            :alt="benefit.subHeader"
          />
          <div class="benefits__flex-box__item__subheadline wb-type-heading-m">
            {{ benefit.subHeader }}
          </div>
          <div class="benefits__flex-box__item__text wb-type-copy">
            {{ benefit.text }}
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import useImage from "@/compositions/useImage";

export default {
  name: "Benefit",
  props: {
    benefits: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const isMobile = computed(() => store.getters["localization/isMobile"]);

    const { getImageUrl } = useImage();

    return {
      isMobile,
      getImageUrl,
    };
  },
};
</script>

<style scoped lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.benefits {
  .carousel-card {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 12% 6% 6%;
    text-align: center;
  }

  .titles {
    @include breakpoint-from(mq3) {
      padding-top: 10px;
    }
  }

  .card-image__text {
    padding: 30px 0 40px;
  }

  .carousel-mineshaft {
    margin: 40px 0 60px 0;
    border: solid 1px var(--wb-grey-20);
    background-color: var(--wb-grey-15);
  }

  &__flex-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px 2%;
    flex-wrap: wrap;

    &__item {
      display: flex;
      flex-direction: column;
      width: 22%;

      &__image {
        height: 50px;
        margin: 30px auto 20px 0;
      }
      &__subheadline {
        margin-bottom: 15px;
        font-size: 1.7rem;
        line-height: 2rem;
      }
      &__text {
        margin-bottom: 20px;
      }
    }
  }
}
</style>

<template>
  <div class="sidebar">
    <transition name="slide">
      <div v-if="isNavOpen" class="sidebar__panel">
        <ul class="sidebar__panel__nav">
          <li
            class="sidebar__panel__item"
            v-if="header?.linkAbout && showApplicationInfo"
          >
            <div
              v-if="useNewAboutUs"
              @click="redirect('aboutUs')"
              class="sidebar__panel__link"
            >
              <span class="menu__top__text wb-type-copy">
                {{ header.linkAbout }}
              </span>
            </div>
            <div v-else @click="redirect('about')" class="sidebar__panel__link">
              <span class="menu__top__text wb-type-copy">
                {{ header.linkAbout }}
              </span>
            </div>
          </li>
          <li class="sidebar__panel__item no-border" v-if="contentPagesActive">
            <div class="sidebar__panel__link">
              <span class="menu__top__text wb-type-copy">
                {{ contentPages.header }}:
              </span>
            </div>
          </li>
          <li
            class="sidebar__panel__item contentpage-item no-border"
            v-if="contentPagesActive"
          >
            <div
              @click="redirect('contentPage', { pageId: '1' })"
              class="sidebar__panel__link"
            >
              <span class="menu__top__text wb-type-copy no-border">
                {{ contentPages.subHeader1 }}
              </span>
            </div>
          </li>
          <li
            class="sidebar__panel__item contentpage-item no-border"
            v-if="contentPagesActive"
          >
            <div
              @click="redirect('contentPage', { pageId: '2' })"
              class="sidebar__panel__link"
            >
              <span class="menu__top__text wb-type-copy">
                {{ contentPages.subHeader2 }}
              </span>
            </div>
          </li>
          <li
            class="sidebar__panel__item contentpage-item"
            v-if="contentPagesActive"
          >
            <div
              @click="redirect('contentPage', { pageId: '3' })"
              class="sidebar__panel__link"
            >
              <span class="menu__top__text wb-type-copy">
                {{ contentPages.subHeader3 }}
              </span>
            </div>
          </li>
          <li
            class="sidebar__panel__item"
            v-if="header?.linkFAQ && showApplicationInfo"
          >
            <div @click="redirect('faq')" class="sidebar__panel__link">
              <span class="menu__top__text wb-type-copy">
                {{ header.linkFAQ }}
              </span>
            </div>
          </li>
          <li class="sidebar__panel__item">
            <div class="sidebar__panel__link">
              <span
                class="menu__top__text wb-type-copy"
                @click="openModalProvider = !openModalProvider"
              >
                {{ header?.linkProviderAndDataProtection }}
              </span>
              <transition name="Slide">
                <Provider
                  v-if="openModalProvider"
                  @close="openModalProvider = false"
                  preselectedTab=""
                />
              </transition>
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import Provider from "@/views/Provider";

export default {
  props: ["header", "contentPages", "showApplicationInfo"],
  setup() {
    const store = useStore();
    const router = useRouter();
    const closeSidebarPanel = () => store.dispatch("menu/setIsNavOpen", false);
    const contentPagesActive = computed(
      () => store.getters["marketConfig/contentPages"]
    );
    const useNewAboutUs = computed(
      () => store.getters["marketConfig/useNewAboutUs"]
    );
    const redirect = (name, params = null) => {
      closeSidebarPanel();
      if (params) {
        router.push({ name, params });
      } else {
        router.push({ name });
      }
    };

    return {
      contentPagesActive,
      isNavOpen: computed(() => store.getters["menu/isNavOpen"]),
      redirect,
      openModalProvider: ref(false),
      useNewAboutUs,
    };
  },
  components: {
    Provider,
  },
};
</script>

<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.sidebar {
  @include breakpoint-from(mq4) {
    display: none;
  }

  &__backdrop {
    position: fixed;
    top: 100px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 100px);
    z-index: 100;
    background-color: var(--wb-black);
    cursor: pointer;
  }

  &__panel {
    overflow-y: auto;
    background-color: var(--wb-black);
    position: fixed;
    left: 0;
    top: 100px;
    height: calc(100vh - 100px);
    z-index: 999;
    padding: 1rem 20px 2rem 20px;
    width: 100%;

    &__nav {
      list-style-type: none;
    }

    &__item {
      padding: 10px;
      border-bottom: 1px solid rgba(102, 102, 102, 0.5);
    }

    &__link {
      color: var(--wb-white);
      text-decoration: none;
      display: block;
      cursor: pointer;
    }
  }

  .sidebar__panel__item.contentpage-item {
    padding-left: 50px;
  }

  .sidebar__panel__item.no-border {
    border: 0;
  }
}
</style>

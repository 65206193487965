import { computed, watch, ref, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import ApiService, { OBP_ACCESS_TOKEN } from "@/services/ApiService";
import UserCentricsService from "@/services/UserCentricsService";
import { updateHtmlLanguage } from "@/utilities/language";

const useAuthentication = function () {
  const store = useStore();
  const domainId = computed(() => store.getters["marketConfig/domainId"]);
  const defaultLanguage = computed(
    () => store.getters["marketConfig/defaultLanguage"]
  );
  const authRequired = computed(
    () => store.getters["marketConfig/authenticationRequired"]
  );
  const router = useRouter();
  const route = useRoute();
  const isLoggedIn = ref(false);

  const preAuthData = computed(() => store.getters["marketConfig/preAuthData"]);

  onBeforeMount(() => {
    // check for mockMode on-init
    if (process.env.NODE_ENV === "mock") {
      store.dispatch("marketConfig/toggleMockMode", true);
    }

    // fetch pre-auth-data on-init
    store.dispatch("marketConfig/getPreAuthData");
  });

  watch(
    () => [authRequired.value, domainId.value, preAuthData.value],
    ([required]) => {
      if (required) {
        // if already in session, use it
        if (sessionStorage.getItem(OBP_ACCESS_TOKEN)) {
          isLoggedIn.value = true;
          requestMarketConfig();
          return;
        }
        isLoggedIn.value = false;

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("code")) {
          requestAccessToken(urlParams.get("code"));
        } else if (preAuthData.value.maintenancePage === false) {
          requestLoginRedirectUrl();
        }
      } else {
        requestMarketConfig();
      }
    }
  );

  const requestAccessToken = (code) => {
    store.dispatch("marketConfig/getAccessToken", code);
    router.push({ name: "home" });
  };

  const token = computed(() => store.getters["marketConfig/accessToken"]);
  watch(
    () => token.value,
    (myToken) => {
      if (myToken) {
        sessionStorage.setItem(OBP_ACCESS_TOKEN, myToken);
        isLoggedIn.value = true;
        requestMarketConfig();
      }
    }
  );

  const requestLoginRedirectUrl = async () => {
    const request = await ApiService.authLogin();

    if (request.status === 200 && request.data) {
      window.location.href = request.data;
    }
  };

  watch(
    () => [defaultLanguage.value, domainId.value],
    ([lang, id]) => {
      if (lang && id) {
        requestLocalization();
        updateHtmlLanguage(lang.isoCode);

        if (process.env.NODE_ENV !== "mock") {
          // init usercentrics after html language is updated
          UserCentricsService.initUserCentricsConfig(window.location.host);
        }
      }
    }
  );

  const languages = computed(() => store.getters["marketConfig/languages"]);
  const requestLocalization = () => {
    const urlLanguage = languages.value.find(
      (language) => language.isoCode === route.query.locale
    );

    store.dispatch("localization/getLocalization", {
      language: urlLanguage ? urlLanguage : defaultLanguage.value ?? null,
      domainId: domainId.value ?? null,
    });
  };

  const requestMarketConfig = () => {
    store.dispatch("marketConfig/getMarketConfig", domainId.value);
  };

  const authLogout = async () => {
    const request = await ApiService.authLogout();

    if (request.status === 200) {
      window.location.href = request.data;
    }
  };

  // if mockMode changes fetch preAuthData
  const mockMode = computed(() => store.getters["marketConfig/mockMode"]);
  watch(
    () => mockMode.value,
    () => {
      store.dispatch("marketConfig/getPreAuthData");
    }
  );

  return {
    authLogout,
    isLoggedIn,
  };
};

export default useAuthentication;

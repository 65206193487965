<template>
  <div class="radius wb-type-copy-secondary">
    <wb-range-control theme="dark">
      <label data-test-id="radius-label" class="radius__label">
        {{ localization?.radius }}
        <span class="active">
          {{ radius }} {{ localization?.labelDistanceUnit }}
        </span>
      </label>
      <input
        name="radius"
        type="range"
        @change="radiusChanged"
        v-model="radius"
        :min="locationMinRadius"
        :max="radiusMax"
        :step="radiusStepping"
      />
    </wb-range-control>
    <div class="radius__output">
      <div class="radius__output__item">
        {{ locationMinRadius }}
        {{ localization?.labelDistanceUnit }}
      </div>
      <div class="radius__output__item">
        {{ radiusMax }} {{ localization?.labelDistanceUnit }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "Radius",
  props: ["localization"],
  setup(_, { emit }) {
    const store = useStore();
    const configRadius = computed(() => store.getters["marketConfig/radius"]);
    const storeRadius = computed(() => store.getters["search/radius"]);
    const radius = ref(null);

    const radiusChanged = (event) => {
      if (parseInt(storeRadius.value) !== parseInt(event.target.value)) {
        emit("radiusChanged", parseInt(event.target.value));
        radius.value = event.target.value;
        store.dispatch("search/updateRadius", parseInt(event.target.value));
      }
    };

    onMounted(() => {
      if (storeRadius.value) {
        radius.value = storeRadius.value;
      } else {
        radius.value = configRadius.value;
        store.dispatch("search/updateRadius", parseInt(configRadius.value));
      }
    });

    return {
      radius,
      radiusChanged,
      locationMinRadius: store.getters["search/locationMinRadius"],
      radiusMax: computed(() => store.getters["marketConfig/radiusMax"]),
      radiusStepping: computed(
        () => store.getters["marketConfig/radiusStepping"]
      ),
    };
  },
};
</script>

<style lang="scss">
.radius {
  &__label {
    margin-bottom: 10px;

    .active {
      color: var(--wb-blue-45);
    }
  }

  &__output {
    display: flex;
    justify-content: space-between;
  }
}
</style>

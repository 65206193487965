<template>
  <slot name="header"></slot>
  <wb-input-control
    :error="error && !loading"
    :success="error !== null && !error"
    :filled="filled"
    :active="active"
  >
    <wb-input>
      <label v-if="data.label">
        {{ data.label }}
        <span v-if="required">*</span>
      </label>
      <input
        v-bind="$attrs"
        :value="modelValue"
        :placeholder="data.label"
        aria-labelledby="regexValidationMessage"
        @focusin="focusin"
        @focusout="focusout"
        @input="
          $emit('update:modelValue', $event.target.value);
          $emit('validateInput');
        "
        :required="required"
      />
      <wb-input-action>
        <wb-spinner v-if="loading"></wb-spinner>
      </wb-input-action>
    </wb-input>
    <slot name="error">
      <wb-control-error v-if="error" id="regexValidationMessage">
        {{ data.regexValidationMessage }}
      </wb-control-error>
    </slot>
  </wb-input-control>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: null,
    },
    regexValidationMessage: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const filled = ref(false);
    const active = ref(false);

    const focusin = () => {
      filled.value = true;
      active.value = true;
    };

    const focusout = () => {
      filled.value = !props.modelValue ? null : true;
      active.value = null;
    };

    return {
      filled,
      active,
      focusin,
      focusout,
    };
  },
};
</script>

<template>
  <div class="model-selection-result">
    <LoadingScreen
      v-if="isLoading"
      :loadingText="localization?.mainPage?.search?.loadingText"
    />
    <div v-else>
      <SearchPageSummary
        class="model-selection-result__summary"
        :searchLocalization="localization?.resultPage?.overview"
      />
      <div>
        <VehicleOfferList
          class="model-selection-result__offers"
          :localization="localization"
          :vehicles="nonMatchingVehicles"
          :isModelSelection="true"
          :matchingVehicles="matchingVehicles"
        />
      </div>
      <div v-if="isInquiryActive" class="model-selection-result__requestheader">
        <h1 class="wb-type-heading-m">
          {{ localization?.vehicleRequestPage?.linkHeader }}
        </h1>
      </div>
      <div class="model-selection-result__actions">
        <button
          @click="vehicleRequest"
          type="button"
          class="model-selection-result__actions__button wb-button wb-button--secondary wb-button--medium wb-button--theme-dark"
          v-if="isInquiryActive"
        >
          <Icon name="chevron-right" :size="12" :width="18" :height="12" />
          {{ localization?.vehicleRequestPage?.linkTitle }}
        </button>
        <button
          @click="redirectToHomeOrDealer"
          type="button"
          class="model-selection-result__actions__button wb-button wb-button--primary wb-button--medium"
        >
          <Icon name="chevron-right" :size="12" :width="18" :height="12" />
          {{ localization?.resultPage?.overview?.btnBackToHome }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { scrollToTop } from "@/utilities/scroll";
import Icon from "@/components/Icon.vue";
import LoadingScreen from "@/components/LoadingScreen";
import VehicleOfferList from "@/components/Vehicle/VehicleOfferList";
import SearchPageSummary from "@/components/Search/SearchPageSummary";
import useTrackingPage from "@/compositions/useTrackingPage";

export default {
  name: "ModelSelectionResult",
  setup() {
    const store = useStore();
    const router = useRouter();
    const isInquiryActive = computed(
      () => store.getters["marketConfig/inquiryMode"]
    );
    const localization = computed(
      () => store.getters["localization/localization"]
    );
    const isLoading = computed(() => store.getters["vehicles/isLoading"]);
    const matchingVehicles = computed(
      () => store.getters["vehicles/matchingVehicles"]
    );
    const nonMatchingVehicles = computed(
      () => store.getters["vehicles/groupedVehicles"]
    );
    const sort = computed(() => store.getters["vehicles/sort"]);
    const activeFilters = computed(
      () => store.getters["vehicles/activeFilters"]
    );

    const vehicleRequest = () => {
      router.push({ name: "vehicleRequest" });
      scrollToTop();
    };

    // re-fetch vehicles if activeFilters updated
    watch(
      () => activeFilters.value,
      (filters) => {
        store.dispatch("vehicles/filterVehicles", filters);
      }
    );

    // re-fetch vehicles if sorting changes
    watch(
      () => sort.value,
      (sortValue) => {
        store.dispatch("vehicles/sortVehicles", sortValue);
      }
    );

    const { trackResultOverview } = useTrackingPage();

    // Tracking when the vehicle-offers finished loading
    watch(
      () => isLoading.value,
      () => {
        trackResultOverview();
      }
    );

    // Tracking when loading is complete but user returns to this view bc of any reason (click "back" etc)
    onMounted(() => {
      if (!isLoading.value) {
        trackResultOverview();
      }
      store.dispatch("vehicles/setModelSelectionIsOrigin", true);
    });
    return {
      matchingVehicles,
      nonMatchingVehicles,
      localization,
      isLoading,
      isInquiryActive,
      vehicleRequest,
    };
  },
  components: { LoadingScreen, VehicleOfferList, SearchPageSummary, Icon },
};
</script>
<style lang="scss">
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.model-selection-result {
  background: var(--wb-black);
  padding-top: 30px;

  &__summary {
    @include breakpoint-from(mq3) {
      margin: 0 7.5%;
    }
  }

  &__offers {
    padding-bottom: 30px;
  }

  &__requestheader {
    padding-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;

    @include breakpoint-from(mq3) {
      margin: 0 7.5%;
    }
  }

  &__actions {
    padding-bottom: 50px;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include breakpoint-from(mq3) {
      margin: 0 7.5%;
      flex-direction: row;
      justify-content: flex-start;
    }

    &__button {
      width: 100%;

      &:first-child {
        margin-bottom: 15px;
      }

      @include breakpoint-from(mq3) {
        flex: 0 0 45%;
        width: 45%;

        &:first-child {
          margin-right: 5%;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>

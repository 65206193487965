import { CONFIGURED_HOSTS } from "@/assets/hosts";

export default {
  async localMetaData(domain, pageName) {
    const locale = new URL(location.href).searchParams.get("locale");
    const defaultMetaData = (
      await import(`@/assets/metaData/${domain}/meta_default`)
    ).default[pageName];

    if (locale) {
      try {
        return (await import(`@/assets/metaData/${domain}/meta_${locale}`))
          .default[pageName];
      } catch (e) {
        return defaultMetaData;
      }
    }
    return defaultMetaData;
  },
  getMetaDataFromHost(host, pageName) {
    switch (host) {
      case CONFIGURED_HOSTS.localhost:
      case CONFIGURED_HOSTS.de.dev:
      case CONFIGURED_HOSTS.de.int:
      case CONFIGURED_HOSTS.de.preProd:
      case CONFIGURED_HOSTS.de.prod:
        return this.localMetaData("de", pageName);
      case CONFIGURED_HOSTS.ch.dev:
      case CONFIGURED_HOSTS.ch.int:
      case CONFIGURED_HOSTS.ch.preProd:
      case CONFIGURED_HOSTS.ch.prod:
        return this.localMetaData("ch", pageName);
      case CONFIGURED_HOSTS.at.dev:
      case CONFIGURED_HOSTS.at.int:
      case CONFIGURED_HOSTS.at.preProd:
      case CONFIGURED_HOSTS.at.prod:
        return this.localMetaData("at", pageName);
      case CONFIGURED_HOSTS.jp.dev:
      case CONFIGURED_HOSTS.jp.int:
      case CONFIGURED_HOSTS.jp.preProd:
      case CONFIGURED_HOSTS.jp.prod:
        return this.localMetaData("jp", pageName);
      default:
        return undefined;
    }
  },
  async initMetaData(host, pageName) {
    const metaData = await this.getMetaDataFromHost(host, pageName);
    this.addMetaData(metaData, host);
  },
  addMetaData(metaData, host) {
    if (!metaData) {
      return;
    }
    // title
    document.title = metaData.title;

    // description
    const description = document.querySelector("meta[name=description]");
    if (description) {
      this.createOrUpdateMetaTag(
        "description",
        "name",
        metaData.description,
        description
      );
    } else {
      this.createOrUpdateMetaTag("description", "name", metaData.description);
    }

    // og:title
    const ogTitle = document.querySelector("meta[property='og:title']");
    if (ogTitle) {
      this.createOrUpdateMetaTag(
        "og:title",
        "property",
        metaData["og:title"],
        ogTitle
      );
    } else {
      this.createOrUpdateMetaTag("og:title", "property", metaData["og:title"]);
    }

    // og:description
    const ogDescription = document.querySelector(
      "meta[property='og:description']"
    );
    if (ogDescription) {
      this.createOrUpdateMetaTag(
        "og:description",
        "property",
        metaData["og:description"],
        ogDescription
      );
    } else {
      this.createOrUpdateMetaTag(
        "og:description",
        "property",
        metaData["og:description"]
      );
    }

    // og:image:width
    const ogImageWidth = document.querySelector(
      "meta[property='og:image:width']"
    );
    if (ogImageWidth) {
      this.createOrUpdateMetaTag(
        "og:image:width",
        "property",
        metaData["og:image:width"],
        ogImageWidth
      );
    } else {
      this.createOrUpdateMetaTag(
        "og:image:width",
        "property",
        metaData["og:image:width"]
      );
    }

    // og:image:height
    const ogImageHeight = document.querySelector(
      "meta[property='og:image:height']"
    );
    if (ogImageHeight) {
      this.createOrUpdateMetaTag(
        "og:image:height",
        "property",
        metaData["og:image:height"],
        ogImageHeight
      );
    } else {
      this.createOrUpdateMetaTag(
        "og:image:height",
        "property",
        metaData["og:image:height"]
      );
    }

    // og:type
    const ogType = document.querySelector("meta[property='og:type']");
    if (ogType) {
      this.createOrUpdateMetaTag(
        "og:type",
        "property",
        metaData["og:type"],
        ogType
      );
    } else {
      this.createOrUpdateMetaTag("og:type", "property", metaData["og:type"]);
    }

    // og:url
    const ogUrl = document.querySelector("meta[property='og:url']");
    if (ogUrl) {
      this.createOrUpdateMetaTag(
        "og:url",
        "property",
        metaData["og:url"],
        ogUrl
      );
    } else {
      this.createOrUpdateMetaTag("og:url", "property", metaData["og:url"]);
    }

    // og:site_name
    const ogSiteName = document.querySelector("meta[property='og:site_name']");
    if (ogSiteName) {
      this.createOrUpdateMetaTag(
        "og:site_name",
        "property",
        metaData["og:site_name"],
        ogSiteName
      );
    } else {
      this.createOrUpdateMetaTag(
        "og:site_name",
        "property",
        metaData["og:site_name"]
      );
    }

    // og:image
    const ogImage = document.querySelector("meta[property='og:image']");
    try {
      const imgUrl = `${
        window.location.origin
      }${require(`@/assets/images/${metaData["og:image"]}`)}`;

      if (ogImage) {
        this.createOrUpdateMetaTag("og:image", "property", imgUrl, ogImage);
      } else {
        this.createOrUpdateMetaTag("og:image", "property", imgUrl);
      }
    } catch (e) {
      if (ogImage) {
        this.createOrUpdateMetaTag("og:image", "property", null, ogImage);
      } else {
        this.createOrUpdateMetaTag("og:image", "property", null);
      }
    }

    // robots
    let robotsContent = "noindex";
    if (
      host === CONFIGURED_HOSTS.de.prod ||
      host === CONFIGURED_HOSTS.ch.prod ||
      host === CONFIGURED_HOSTS.at.prod ||
      host === CONFIGURED_HOSTS.jp.prod
    ) {
      robotsContent = "all";
    }
    const robots = document.querySelector("meta[name='robots']");
    if (robots) {
      this.createOrUpdateMetaTag("robots", "name", robotsContent, robots);
    } else {
      this.createOrUpdateMetaTag("robots", "name", robotsContent);
    }
  },

  createOrUpdateMetaTag(type, attribute, content, tag = undefined) {
    let link;
    if (!tag) {
      link = document.createElement("meta");
    } else {
      link = tag;
    }
    link.setAttribute(attribute, type);
    link.content = content;
    if (!tag) {
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  },
};

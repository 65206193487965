import { computed } from "vue";
import { useStore } from "vuex";

const useLocationSelection = function () {
  const store = useStore();
  const selected = computed(() => store.getters["search/locationSelected"]);

  const toggle = (location) => {
    store.dispatch("search/toggleLocationSelection", location);
  };
  const clear = () => {
    store.dispatch("search/clearLocationSelection");
  };
  const addMultiple = (newLocations) => {
    store.dispatch("search/addMultipleLocationSelection", newLocations);
  };
  const numberSelected = computed(() => selected.value.size);
  const bulkSelect = (allLocationsSelected, locations) => {
    if (allLocationsSelected) {
      clear();
    } else {
      addMultiple(locations);
    }
  };

  return {
    selected,
    numberSelected,
    bulkSelect,
    toggle,
    addMultiple,
    clear,
  };
};

export default useLocationSelection;

export const CONFIGURED_HOSTS = {
  de: {
    dev: "dev-de.mbrent.mercedes-benz.com",
    int: "int-de.mbrent.mercedes-benz.com",
    preProd: "preprod-de.mbrent.mercedes-benz.com",
    prod: "partner-mieten.mercedes-benz.de",
  },
  ch: {
    dev: "dev-ch.mbrent.mercedes-benz.com",
    int: "int-ch.mbrent.mercedes-benz.com",
    preProd: "preprod-ch.mbrent.mercedes-benz.com",
    prod: "rent.mercedes-benz.ch",
  },
  at: {
    dev: "dev-at.mbrent.mercedes-benz.com",
    int: "int-at.mbrent.mercedes-benz.com",
    preProd: "preprod-at.mbrent.mercedes-benz.com",
    prod: "rent.mercedes-benz.com",
  },
  jp: {
    dev: "dev-jp.mbrent.mercedes-benz.com",
    int: "int-jp.mbrent.mercedes-benz.com",
    preProd: "preprod-jp.mbrent.mercedes-benz.com",
    prod: "www.rent.mercedes-benz.co.jp",
  },
  localhost: "localhost:8080",
};

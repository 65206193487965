export const updateHtmlLanguage = (isoCode) => {
  let lang = isoCode.substring(0, 2);
  const urlLocale = localeParam();
  const htmlTag = document.getElementsByTagName("html")[0];
  if (htmlTag) {
    if (urlLocale) {
      lang = urlLocale.substring(0, 2);
    }
    htmlTag.setAttribute("lang", lang);
  }
};

export const updateUserCentricsLanguage = (isoCode) => {
  let lang = isoCode.substring(0, 2);
  const urlLocale = localeParam();
  if (urlLocale) {
    lang = urlLocale.substring(0, 2);
  }

  if (window.usercentrics) {
    window.usercentrics.updateLanguage(lang);
  }
};

export const localeParam = () => {
  const url = new URL(window.location.href);
  return url.searchParams.get("locale");
};

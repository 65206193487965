<script setup>
import { computed, watch, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import SearchInput from "@/components/Search/SearchInput";
import Datepicker from "@/components/Datepicker/Datepicker";
import Timepicker from "@/components/Timepicker/Timepicker";
import LocationOverlay from "@/components/Location/LocationOverlay";
import Icon from "@/components/Icon";
import useTrackingPage from "@/compositions/useTrackingPage";
import useTrackingLink from "@/compositions/useTrackingLink";
import useModelSelection from "@/compositions/useModelSelection";
import useRequestMode from "@/compositions/useRequestMode";
import PreRequestModeModal from "../RequestModeLongTerm/PreRequestModeModal.vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  target: {
    type: String,
    default: "home", // home|landing
  },
  searchBtn: {
    type: String,
    required: true,
  },
  mainOutlet: {
    type: [Object, null],
    required: false,
  },
});
const store = useStore();
const domainId = computed(() => store.getters["localization/domainId"]);
const { modelSelectionFeatureActive } = useModelSelection();
const { showRequestModal, modalClosed } = useRequestMode();

onMounted(() => {
  if (!formDisabled.value) {
    trackSearchComplete();
  }

  if (props.target === "landing") {
    initMainOutlets();
  }
});

const initMainOutlets = () => {
  const outletLocations = props.mainOutlet?.outlets.map((outlet) => {
    return {
      outlet: { ...outlet },
    };
  });
  store.dispatch("search/setLocation", {
    locations: outletLocations,
    maxFromDate: props.mainOutlet?.maxFromDate,
    maxToDate: props.mainOutlet?.maxToDate,
    minFromDate: props.mainOutlet?.minFromDate,
    minFromTime: props.mainOutlet?.minFromTime,
  });
  store.dispatch("search/addMultipleLocationSelection", outletLocations);
};

const requestLocationsOnSuggestSelect = async (query) => {
  const isMobile = store.getters["localization/isMobile"];
  const configRadius = store.getters["marketConfig/radius"];

  await store.dispatch("search/getLocation", {
    query,
    radius: configRadius,
    domainId: domainId.value,
  });
  // wait till keyboard is blured on iphone
  setTimeout(
    () => {
      store.dispatch("search/updateLocationOverlayOpen", true);
    },
    isMobile ? 300 : 0
  );
};

const selectedDate = computed(() => store.getters["search/selectedDate"]);
const selectedTime = computed(() => store.getters["search/selectedTime"]);
const formDisabled = computed(() => {
  const { from: dateFrom, to: dateTo } = selectedDate.value;
  const { from: timeFrom, to: timeTo } = selectedTime.value;

  return !!(
    !dateFrom ||
    !dateTo ||
    !timeFrom ||
    !timeTo ||
    showRequestModal.value
  );
});

const { trackSearchComplete, trackSearchDatePicker } = useTrackingPage();
const { trackNavModel } = useTrackingLink();

watch(
  () => formDisabled.value,
  (disabled) => {
    if (!disabled) {
      trackSearchComplete();
    }
  }
);

const datePickerDisabled = ref(true);
const locationSelected = computed(
  () => store.getters["search/locationSelected"]
);
watch(
  () => locationSelected.value,
  (selectedLocations) => {
    datePickerDisabled.value = !!!selectedLocations.size;
  },
  {
    immediate: true,
  }
);
const timePickerDisabled = computed(() => {
  return !!!(
    !datePickerDisabled.value &&
    selectedDate.value?.from &&
    selectedDate.value?.to
  );
});

const router = useRouter();
const onSearchSubmit = () => {
  const rentalPeriod = computed(() => store.getters["search/rentalPeriod"]);
  const outlets = [];
  Array.from(locationSelected.value).forEach((location) => {
    outlets.push({ gsId: location?.outlet?.outletId });
  });
  const request = {
    domainId: domainId.value,
    outlets,
    rentalPeriod: rentalPeriod.value,
  };

  store.dispatch("vehicles/clearVehicles");
  store.dispatch("search/setSearchRequest", request);
  router.push({
    name: "vehicles",
  });
};

const focusDatepicker = () => {
  const search = computed(() => store.getters["search/query"]);
  const dateInput = document.querySelector(".jsDatepickerInput input");

  if (dateInput && search.value) {
    // Focus and track search date picker only if a location value was selected
    // if the location overlay was closed with the X Button the tracking should not fire
    dateInput.focus();
    trackSearchDatePicker();
  }
};

const redirect = (name) => {
  router.push({ name });
};

const localization = computed(() => store.getters["localization/search"]);
const localizationModelSelection = computed(
  () => store.getters["localization/modelSelection"]
);
const locationOpen = computed(
  () => store.getters["search/locationOverlayOpen"]
);
const transitionComplete = ref(true);
const calendarLeft = ref(false);
const generalConfig = computed(() => store.getters["marketConfig/general"]);
const requestModePage = computed(
  () => store.getters["localization/requestModePage"]
);
</script>

<template>
  <div
    class="search-area content-width"
    :class="{ 'search-area__home': target === 'home' }"
  >
    <form @submit.prevent="onSearchSubmit($event)">
      <h2
        v-if="title"
        class="search-area__title wb-type-heading-m"
        id="titleRef"
      >
        {{ title }}
      </h2>

      <template v-if="target === 'home'">
        <div class="search-area__search">
          <SearchInput
            class="search-area__search__item"
            :localization="localization"
            :transitionComplete="transitionComplete"
            @onSuggestSelect="requestLocationsOnSuggestSelect($event)"
          />
          <a
            v-if="modelSelectionFeatureActive"
            href="#model_slider_heading"
            class="search-area__link wb-type-copy"
            @click="trackNavModel"
          >
            {{ localizationModelSelection?.linkText }}
          </a>
        </div>

        <transition
          name="slide-overlay"
          @before-enter="transitionComplete = false"
          @after-enter="transitionComplete = true"
          @after-leave="focusDatepicker"
        >
          <LocationOverlay
            v-if="locationOpen"
            :transitionComplete="transitionComplete"
            :localization="localization"
            target="home"
          />
        </transition>
      </template>

      <div class="search-area__date">
        <Datepicker
          class="search-area__date__item"
          :disabled="datePickerDisabled"
          :localization="localization"
          @calendarEnter="calendarLeft = false"
          @calendarLeave="calendarLeft = true"
        />
        <div class="search-area__date__item">
          <Timepicker
            :disabled="timePickerDisabled"
            :localization="localization"
            :calendarLeft="calendarLeft"
          />
          <button
            data-test-id="btn-search"
            :disabled="formDisabled"
            type="submit"
            class="search-area__submit wb-button wb-button--primary wb-button--medium"
          >
            <Icon name="chevron-right" :size="12" :width="18" :height="12" />

            <span v-if="searchBtn">{{ searchBtn }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
  <template v-if="target === 'home'">
    <PreRequestModeModal
      v-if="showRequestModal && !modalClosed"
      @close="modalClosed = true"
      :localization="requestModePage"
    />
  </template>
</template>

<style lang="scss" scoped>
@import "@/styles/components/searchArea";
</style>

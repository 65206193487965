<template>
  <wb-select-control>
    <wb-select>
      <label v-if="label">
        {{ label }}
        <span v-if="required">*</span>
      </label>
      <select
        class="field"
        :value="modelValue"
        :required="required"
        v-bind="{
          ...$attrs,
          onChange: ($event) => {
            $emit('update:modelValue', $event.target.value);
            $emit('validateInput');
          },
        }"
      >
        <option value="" disabled selected>
          {{ label }}
          <span v-if="required">*</span>
        </option>
        <option
          v-for="option in sortedOptions"
          :value="option[0]"
          :key="option"
          :selected="option === modelValue"
        >
          {{ option[1] }}
        </option>
      </select>
    </wb-select>
  </wb-select-control>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sortedOptions() {
      return [...this.options].sort();
    },
  },
};
</script>

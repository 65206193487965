<template>
  <LoadingScreen v-if="!aboutUs" />
  <template v-else>
    <MainTeaser
      v-if="imageUrl"
      :imageUrl="imageUrl"
      :headline="aboutUs?.headline"
    />
    <section class="content-width wb-margin-top-l">
      <Introduction
        :headline="aboutUs?.aboutUsContentSection?.header"
        :description="aboutUs?.aboutUsContentSection?.topText"
      />
      <div>
        <ImageAndText
          :option="imageAndTextOption(1)"
          class="wb-margin-top-l wb-margin-bottom-l"
        />
        <ImageAndText
          :option="imageAndTextOption(2, 'right')"
          class="wb-margin-bottom-l"
        />
        <ImageAndText
          :option="imageAndTextOption(3)"
          class="wb-margin-bottom-l"
        />
        <ImageAndText
          :option="imageAndTextOption(4, 'right')"
          class="wb-margin-bottom-l"
        />
      </div>
      <div class="spacer"></div>
      <MoreTopics :topics="topics" :header="contentPages.moreTopicsHeader" />
      <div class="card-section">
        <Benefits
          v-if="localization?.aboutUsPage?.pcBenefits"
          :benefits="localization?.aboutUsPage?.pcBenefits"
          class="wb-margin-top-m"
        />
        <Benefits
          v-if="localization?.aboutUsPage?.bcBenefits"
          :benefits="localization?.aboutUsPage?.bcBenefits"
          class="wb-margin-top-m"
        />
      </div>
    </section>
  </template>
</template>

<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import LoadingScreen from "@/components/LoadingScreen";
import MainTeaser from "@/components/ContentPage/MainTeaser";
import Introduction from "@/components/ContentPage/Introduction";
import ImageAndText from "@/components/ContentPage/ImageAndText";
import MoreTopics from "@/components/ContentPage/MoreTopics";
import Benefits from "@/components/Benefits";
import useImage from "@/compositions/useImage";
import { createTopics } from "@/utilities/contentPages";

export default {
  name: "AboutUs",
  setup() {
    const store = useStore();
    const router = useRouter();
    const contentPages = computed(
      () => store.getters["localization/contentPages"]
    );
    const aboutUs = computed(() => store.getters["localization/aboutUs"]);
    const useNewAboutUs = computed(
      () => store.getters["marketConfig/useNewAboutUs"]
    );

    const localization = computed(
      () => store.getters["localization/localization"]
    );
    const isMobile = computed(() => store.getters["localization/isMobile"]);
    const { getImageUrl } = useImage();

    const topics = computed(() =>
      createTopics(
        contentPages.value,
        aboutUs.value,
        "about-us",
        useNewAboutUs.value
      )
    );

    const imageAndTextOption = (index, position) => {
      return {
        imageUrl: getImageUrl(
          aboutUs.value.aboutUsContentSection[`section${index}`]?.imageId,
          false
        ),
        headline:
          aboutUs.value.aboutUsContentSection[`section${index}`]?.header,
        description:
          aboutUs.value.aboutUsContentSection[`section${index}`]?.text,
        btnText:
          aboutUs.value.aboutUsContentSection[`section${index}`]?.buttonText,
        btnLink:
          aboutUs.value.aboutUsContentSection[`section${index}`]?.buttonUrl,
        imagePosition: position ? position : null,
      };
    };

    watch(
      () => useNewAboutUs.value,
      () => {
        if (!useNewAboutUs.value) {
          router.push({ name: "about" });
        }
      }
    );

    const imageUrl = computed(() => {
      let image = "";
      if (isMobile.value) {
        image = getImageUrl(aboutUs.value?.headerImageIdMobile, false);
      } else {
        image = getImageUrl(aboutUs.value?.headerImageIdDesktop, false);
      }
      return image;
    });

    return {
      aboutUs,
      imageUrl,
      contentPages,
      topics,
      imageAndTextOption,
      localization,
    };
  },
  components: {
    LoadingScreen,
    MainTeaser,
    Introduction,
    ImageAndText,
    MoreTopics,
    Benefits,
  },
};
</script>
<style lang="scss" scoped>
@import "~@workbench/core/dist/scss/utility/_breakpoints";

.spacer {
  height: 75px;
}

.card-section {
  margin-top: 50px;

  @include breakpoint-from(mq2) {
    margin-top: 0;
  }
}
</style>

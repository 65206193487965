<template>
  <div class="topic">
    <div
      class="topic__image"
      :style="{
        backgroundImage: `url(${imageUrl})`,
      }"
    ></div>
    <div class="topic__content">
      <div class="topic__item__headline wb-type-heading-m">
        {{ topic.topTeaserTitle }}
      </div>
      <div class="topic__item__description wb-type-copy-secondary">
        {{ description }}
      </div>
    </div>
    <div class="topic__item__button">
      <button
        type="button"
        class="wb-button wb-button--primary wb-button--large"
        @click="redirect()"
      >
        <span class="topic__item__button__text">
          {{ topic.moreTopicsButtonText }}
        </span>
      </button>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
import useImage from "@/compositions/useImage";

const props = defineProps(["topic"]);
const router = useRouter();
const { getImageUrl } = useImage();

const imageUrl = computed(() => {
  let image = "";
  image = getImageUrl(props["topic"].topTeaserImageIdMobile, false);

  return image;
});
let description = props["topic"].topText;
const overlength = description.length > 200 ? true : false;

if (overlength) {
  description = description.substring(0, 200) + " ...";
}

const redirectToContentPage = () => {
  router.push({
    name: "contentPage",
    params: { pageId: props["topic"].urlId },
  });
};

const redirectToAboutUs = () => {
  router.push({ name: "aboutUs" });
};

const redirect = () => {
  if (props["topic"].urlId === "aboutUs") {
    redirectToAboutUs();
  } else {
    redirectToContentPage();
  }
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
</script>
<style lang="scss" scoped>
.topic {
  width: 100%;
  background-color: var(--wb-grey-25);
  display: flex;
  flex-direction: column;
  justify-items: space-between;
  align-items: stretch;
  &__item {
    &__headline {
      margin-bottom: 10px;
    }
    &__description {
      margin-bottom: 30px;
    }
    &__button {
      width: 90%;
      margin: 0 auto 30px auto;

      .wb-button {
        width: 100%;
      }
    }
  }

  &__image {
    width: 100%;
    height: 300px;
    background-position: center;
    background-size: cover;
  }

  &__content {
    padding: 8%;
    flex-grow: 4;
  }
}
</style>
